import styled from 'styled-components';
import { Colors, Fonts } from '../../../../../theme';

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .bNCnQI {
     width: 816px !important;
   }
`;

export const PrimaryText = styled.text`
  font-family: ${Fonts.type.robotoRegular};
  font-size: 1.07rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: ${Colors.primaryWhite};
  margin-left: 5px;
`;

export const MyForm = styled.form``;

export const BoxContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c1c24;
  flex-direction: column;  
`;

export const ContentContainer = styled.div`
  width: 26vw;
  display: flex;
  flex-direction: column;
  overflow:hidden;
  background-color: #2C2C38;
  border-radius: 10px;
  padding: 5rem 3.4rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1440px) and (min-width: 768px) {
    width: 24vw;
    height: 1%;
    padding-top:0.1rem;
    padding-bottom:1rem;
    margin-top:0.5rem;
  }

  @media screen and (max-width: 1680px) and (min-width: 1440px) {
    width: 26vw;
    padding-top:1.4rem;
    padding-bottom:0.1rem;
    margin-top:0.5rem;
  }

  @media screen and (max-width: 1920px) and (min-width: 1680px) {
    width: 30vw;
    height: 1%;
    padding: 2rem 2rem;
    margin-top: 2rem;
  }

  @media screen and (min-width: 1920px) {
    width: 23vw;
    height: 1%;
  }
`;

export const StyledGooglAuthBlock = styled.div`
  background: #373747;
  border-radius: 5px;
  margin-top: 0.5rem;
  padding: 1rem 1.5rem;
`;

export const BoxHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top:1rem;
`;

export const LogoImage = styled.img`
  width: 110px;
  height: 34px;
  display: flex;
`;

export const BoxContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
`;

export const CopyrightBox = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  flex-direction: column;
`;

export const UpperText = styled.p`
  font-size: 1.07rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-bottom: 17px;
`;

export const Text = styled.p`
  font-size: 1.07rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0px;
`;

