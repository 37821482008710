import { StyledAlert } from './StyledComponents'

const SPMessage = (props) => {
  const { type, message, description, closable } = props

  return (
    <StyledAlert
      type={type}
      message={message}
      description={description}
      showIcon
      closable={closable}
    />
  )
}

export default SPMessage
