import {
  WIDGET_MANAGEMENT_LIST_REQUESTED,
  WIDGET_MANAGEMENT_LIST_SUCCESSED,
  WIDGET_MANAGEMENT_LIST_FAILED,
  RENDER_WIDGET_REQUESTED,
  RENDER_WIDGET_SUCCESSED,
  RENDER_WIDGET_FAILED,
  RESET_WIDGET_DATA,
  GET_WIDGET_SETTINGS_REQUESTED,
  GET_WIDGET_SETTINGS_SUCCESSED,
  GET_WIDGET_SETTINGS_FAILED,
  GET_WIDGET_DATASOURCES_REQUESTED,
  GET_WIDGET_DATASOURCES_SUCCESSED,
  GET_WIDGET_DATASOURCES_FAILED,
  GET_WIDGET_DATA_FIELDS_REQUESTED,
  GET_WIDGET_DATA_FIELDS_SUCCESSED,
  GET_WIDGET_DATA_FIELDS_FAILED
} from '../../constants/actionTypes';

const initialState = {
  listData: {},
  widgetSettings: {},
  widgetData: {},
  dataSources: [],
  dataFields: [],
  isProcessing: true,
  isSuccess: null,
  hasErrors: null,
  loading: false,
  tableLoading: false,
};

export const widgetManagementStore = (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_MANAGEMENT_LIST_REQUESTED: {
      return {
        ...state,
        listData: null,
        hasErrors: null,
        isSuccess: null,
        tableLoading: true
      };
    }
    case WIDGET_MANAGEMENT_LIST_SUCCESSED: {
      return {
        ...state,
        listData: action.data,
        hasErrors: null,
        isProcessing: false,
        tableLoading: false
      };
    }
    case WIDGET_MANAGEMENT_LIST_FAILED: {
      return {
        ...state,
        listData: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
        tableLoading: false
      };
    }
    case RENDER_WIDGET_REQUESTED: {
      return {
        ...state,
        widgetData: null,
        hasErrors: null,
        isSuccess: null,
      };
    }
    case RENDER_WIDGET_SUCCESSED: {
      return {
        ...state,
        widgetData: action.data,
        hasErrors: null,
        isProcessing: false,
        loading: false
      };
    }
    case RENDER_WIDGET_FAILED: {
      return {
        ...state,
        widgetData: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
        loading: false
      };
    }
    case RESET_WIDGET_DATA: {
      return {
        ...state,
        widgetData: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
        loading: false
      };
    }
    case GET_WIDGET_SETTINGS_REQUESTED: {
      return {
        ...state,
        widgetSettings: null,
        hasErrors: null,
        isSuccess: null,
        loading: true
      };
    }
    case GET_WIDGET_SETTINGS_SUCCESSED: {
      return {
        ...state,
        widgetSettings: action.data,
        hasErrors: null,
        isProcessing: false,
        loading: false
      };
    }
    case GET_WIDGET_SETTINGS_FAILED: {
      return {
        ...state,
        widgetSettings: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
        loading: false
      };
    }
    case GET_WIDGET_DATASOURCES_REQUESTED: {
      return {
        ...state,
        dataSources: null,
        hasErrors: null,
        isSuccess: null,
        loading: true
      };
    }
    case GET_WIDGET_DATASOURCES_SUCCESSED: {
      return {
        ...state,
        dataSources: action.data,
        hasErrors: null,
        isProcessing: false,
        loading: false
      };
    }
    case GET_WIDGET_DATASOURCES_FAILED: {
      return {
        ...state,
        dataSources: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
        loading: false
      };
    }
    case GET_WIDGET_DATA_FIELDS_REQUESTED: {
      return {
        ...state,
        dataFields: null,
        hasErrors: null,
        isSuccess: null,
      };
    }
    case GET_WIDGET_DATA_FIELDS_SUCCESSED: {
      return {
        ...state,
        dataFields: action.data,
        hasErrors: null,
        isProcessing: false,
      };
    }
    case GET_WIDGET_DATA_FIELDS_FAILED: {
      return {
        ...state,
        dataFields: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default widgetManagementStore;
