import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoginBox from '../../../components/LoginBox';
import Form from './components/Form';

const ChangePassword = () => {

    const location = useLocation();

    useEffect(() => {
        document.title = 'Change Password';
    }, []);

    return (
        <LoginBox>
            <Form />
        </LoginBox>
    );
};

export default ChangePassword;
