import { put, call, takeLatest } from 'redux-saga/effects';
import {
  GET_APPS_DETAILS_REQUESTED,
  GET_APPS_DETAILS_SUCCESSED,
  GET_APPS_DETAILS_FAILED,
  UPDATE_APPLICATION_DETAILS_REQUESTED,
  UPDATE_APPLICATION_DETAILS_SUCCESSED,
  UPDATE_APPLICATION_DETAILS_FAILED,
  UPDATE_APPLICATION_STATUS_REQUESTED,
  UPDATE_APPLICATION_STATUS_SUCCESSED,
  UPDATE_APPLICATION_STATUS_FAILED,
  GET_CONFIGURATION_DETAILS_REQUESTED,
  GET_CONFIGURATION_DETAILS_SUCCESSED,
  GET_CONFIGURATION_DETAILS_FAILED,
  GET_CONFIGURATION_FIELD_VALUE_REQUESTED,
  GET_CONFIGURATION_FIELD_VALUE_SUCCESSED,
  GET_CONFIGURATION_FIELD_VALUE_FAILED,
  UPDATE_CONFIGURATION_FILEDS_DETAILS_REQUESTED,
  UPDATE_CONFIGURATION_FILEDS_DETAILS_SUCCESSED,
  UPDATE_CONFIGURATION_FILEDS_DETAILS_FAILED,
  CHECK_IS_MULTI_CONFIG_REQUESTED,
  CHECK_IS_MULTI_CONFIG_SUCCESSED,
  CHECK_IS_MULTI_CONFIG_FAILED,
  GET_APPLICATION_VENDOR_LIST_REQUESTED,
  GET_APPLICATION_PUBLISHERS_LIST_REQUESTED,
  GET_APPLICATION_TYPE_LIST_REQUESTED,
  GET_APPLICATION_RATE_LIST_REQUESTED,
  GET_APPLICATION_VENDOR_LIST_SUCCESSED,
  GET_APPLICATION_VENDOR_LIST_FAILED,
  GET_APPLICATION_PUBLISHERS_LIST_SUCCESSED,
  GET_APPLICATION_PUBLISHERS_LIST_FAILED,
  GET_APPLICATION_TYPE_LIST_SUCCESSED,
  GET_APPLICATION_TYPE_LIST_FAILED,
  GET_APPLICATION_RATE_LIST_SUCCESSED,
  GET_APPLICATION_RATE_LIST_FAILED,
  CREATE_APPLICATION_REQUESTED,
  CREATE_APPLICATION_SUCCESSED,
  CREATE_APPLICATION_FAILED,
  GET_APPROVAL_FLOW_LIST_REQUESTED,
  GET_APPROVAL_FLOW_LIST_SUCCESSED,
  GET_APPROVAL_FLOW_LIST_FAILED,
  GET_PRIMARY_APPROVERS_LIST_REQUESTED,
  GET_PRIMARY_APPROVERS_LIST_SUCCESSED,
  GET_PRIMARY_APPROVERS_LIST_FAILED,
  UPDATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED,
  UPDATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED,
  UPDATE_APPROVAL_WORKFLOW_DETAILS_FAILED,
  DELETE_APPROVAL_WORKFLOW_REQUESTED,
  DELETE_APPROVAL_WORKFLOW_FAILED,
  DELETE_APPROVAL_WORKFLOW_SUCCESSED,
  HIDE_LOADER,
  SHOW_LOADER,
  CREATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED,
  CREATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED,
  CREATE_APPROVAL_WORKFLOW_DETAILS_FAILED,
  GET_APPS_ACTION_DETAILS_REQUESTED,
  GET_APPS_ACTION_DETAILS_SUCCESSED,
  GET_APPS_ACTION_DETAILS_FAILED,
  GET_APPS_ACTION_WORKFLOW_REQUESTED,
  GET_APPS_ACTION_WORKFLOW_SUCCESSED,
  GET_APPS_ACTION_WORKFLOW_FAILED,
  UPDATE_APPS_ACTION_WORKFLOW_REQUESTED,
  UPDATE_APPS_ACTION_WORKFLOW_SUCCESSED,
  UPDATE_APPS_ACTION_WORKFLOW_FAILED,
  UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_REQUESTED,
  UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_FAILED,
  UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_SUCCESSED,
  DELETE_APPLICATION_REQUESTED,
  DELETE_APPLICATION_SUCCESSED,
  DELETE_APPLICATION_FAILED,
  UPDATE_APPLICATION_REQUESTED,
  UPDATE_APPLICATION_SUCCESSED,
  UPDATE_APPLICATION_FAILED,
  UPDATE_APPS_ACTION_WORKFLOW_UPGRADE_LIST,
  APP_CONFIG_REQUESTED,
  APP_CONFIG_SUCCESSED,
  APP_CONFIG_FAILED,
  APP_CONFIG_ADD_SUCCESSED,
  APP_CONFIG_ADD_REQUESTED,
  APP_CONFIG_ADD_FAILED,
  UPDATE_LICENSE_REQUESTED,
  UPDATE_LICENSE_SUCCESSED,
  UPDATE_LICENSE_FAILED,
  TEST_CONNECTION_REQUESTED,
  TEST_CONNECTION_SUCCESSED,
  TEST_CONNECTION_FAILED,
  UPDATE_APPS_ACTION_VALIDITY_REQUESTED,
  UPDATE_APPS_ACTION_VALIDITY_SUCCESSED,
  UPDATE_APPS_ACTION_VALIDITY_FAILED,
  UPDATE_APPS_ACTION_UPGRADE_LIST
} from '../../constants/actionTypes';
import {
  applicationSaga,
  checkIsMultiConfigApi,
  createApplicationApi,
  getApplicationPublishersListApi,
  getApplicationRateLimitApi,
  getApplicationTypeListApi,
  getApplicationVendorListApi,
  getConfigurationDetailsApi,
  getConfigurationFieldsDataApi,
  udpateApplicationDetailsApi,
  udpateApplicationStatusApi,
  udpateConfigurationDetailsApi,
  getApprovalFlowListApi,
  getPrimaryListApi,
  udpateApprovalWorkFlowDetailsApi,
  deleteWorkFlowApi,
  createApprovalWorkFlowApi,
  getActionApi,
  getActionWorkFlowApi,
  updateActionWorkFlowApi,
  updateMultiDataPopupDetailsApi,
  deleteApplicationApi,
  updateApplicationApi,
  getAppsConfigsApi,
  appConfigAdd,
  updateLicenseApi,
  testConnection,
  updateActionValidityApi
} from '../../api/appsSaga';
import { getApprovalFlowListRequested, getAppsDetailsRequested } from '../../actions/apps';
import { showToastError, showToastSuccess, showToastLoader } from '../../utils/toasts';

export function* getAppsDetails(action) {
  try {
    const response = yield call(applicationSaga, action.payload);
    if (response.success) {
      yield put({ type: GET_APPS_DETAILS_SUCCESSED, data: response });
    } else {
      yield put({ type: GET_APPS_DETAILS_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPS_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateApplicationDetails(action) {
  try {
    const response = yield call(udpateApplicationDetailsApi, action.payload);
    if (response.success) {
      yield put({ type: UPDATE_APPLICATION_DETAILS_SUCCESSED, data: response });
    } else {
      yield put({ type: UPDATE_APPLICATION_DETAILS_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: UPDATE_APPLICATION_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateApplicationStatus(action) {
  const loaderHandle = showToastLoader('Updating application status...')
  try {
    const response = yield call(udpateApplicationStatusApi, action.payload);
    if (response.success) {
      showToastSuccess(`${response?.data[1]}`, loaderHandle)
      const appResponse = yield call(
        applicationSaga,
        action.payload.updatedData
      );
      if (appResponse.success) {
        // yield put({ type: GET_APPS_DETAILS_SUCCESSED, data: appResponse });
      } else {
        yield put({ type: GET_APPS_DETAILS_FAILED, data: null });
      }
      yield put({ type: UPDATE_APPLICATION_STATUS_SUCCESSED, data: response });
    } else {
      showToastError('application status update failed', loaderHandle)
      yield put({ type: UPDATE_APPLICATION_STATUS_FAILED, data: null });
    }
  } catch (err) {
    showToastError('application status update failed', loaderHandle)
    yield put({
      type: UPDATE_APPLICATION_STATUS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateConfigDetails(action) {
  let onlySave = action?.payload?.isOnlySave;
  let isEnable = action?.payload?.isEnable;
  const loaderHandle = onlySave && !isEnable ? showToastLoader('Updating config details...') : '';
  try {
    const response = yield call(udpateConfigurationDetailsApi, action.payload);
    if (response.success) {
      if (action.payload.isConfig === false) {
        const appResponse = yield call(
          applicationSaga,
          action.payload.updatedData
        );
        if (appResponse.success) {
          // yield put({ type: GET_APPS_DETAILS_SUCCESSED, data: appResponse });
        } else {
          yield put({ type: GET_APPS_DETAILS_FAILED, data: null });
        }
      }
      yield put({
        type: UPDATE_CONFIGURATION_FILEDS_DETAILS_SUCCESSED,
        data: response,
      });
      onlySave && !isEnable ? showToastSuccess(`${response?.data[1]}`, loaderHandle) : '';
    } else {
      onlySave && !isEnable ? showToastError('Update config details failed', loaderHandle) : '';
      yield put({
        type: UPDATE_CONFIGURATION_FILEDS_DETAILS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    onlySave && !isEnable ? showToastError('Update config details failed', loaderHandle) : '';
    yield put({
      type: UPDATE_CONFIGURATION_FILEDS_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getConfigurationDetails(action) {
  try {
    const response = yield call(getConfigurationDetailsApi, action.payload);
    if (response.success) {
      yield put({ type: GET_CONFIGURATION_DETAILS_SUCCESSED, data: response });
    } else {
      yield put({ type: GET_CONFIGURATION_DETAILS_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_CONFIGURATION_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getConfigurationFieldsData(action) {
  try {
    if (action?.payload?.isConfig) {
      const checkResponse = yield call(checkIsMultiConfigApi, action.payload);
      action.payload.multiconfig =
        checkResponse?.data?.app_multi_config_allowed;
    }
    const response = yield call(getConfigurationFieldsDataApi, action.payload);
    if (response.success) {
      yield put({
        type: GET_CONFIGURATION_FIELD_VALUE_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: GET_CONFIGURATION_FIELD_VALUE_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_CONFIGURATION_FIELD_VALUE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* checkIsMultiConfig(action) {
  const loaderHandle = showToastLoader('Checking multi config...')
  try {
    const response = yield call(checkIsMultiConfigApi, action.payload);
    if (response.success) {
      showToastSuccess(`${response?.data[1]}`, loaderHandle)
      yield put({ type: CHECK_IS_MULTI_CONFIG_SUCCESSED, data: response });
    } else {
      showToastError('Multi config check failed', loaderHandle)
      yield put({ type: CHECK_IS_MULTI_CONFIG_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Multi config check failed', loaderHandle)
    yield put({
      type: CHECK_IS_MULTI_CONFIG_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getVendorsList(action) {
  try {
    const response = yield call(getApplicationVendorListApi, action.payload);
    if (response.success) {
      yield put({
        type: GET_APPLICATION_VENDOR_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: GET_APPLICATION_VENDOR_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPLICATION_VENDOR_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getPublishersList(action) {
  try {
    const response = yield call(
      getApplicationPublishersListApi,
      action.payload
    );
    if (response.success) {
      yield put({
        type: GET_APPLICATION_PUBLISHERS_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: GET_APPLICATION_PUBLISHERS_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPLICATION_PUBLISHERS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getTypeList(action) {
  try {
    const response = yield call(getApplicationTypeListApi, action.payload);
    if (response.success) {
      yield put({ type: GET_APPLICATION_TYPE_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: GET_APPLICATION_TYPE_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPLICATION_TYPE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getRateLimitList(action) {
  try {
    const response = yield call(getApplicationRateLimitApi, action.payload);
    if (response.success) {
      yield put({ type: GET_APPLICATION_RATE_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: GET_APPLICATION_RATE_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPLICATION_RATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createApplication(action) {
  const loaderHandle = showToastLoader('Creating application...')
  try {
    const response = yield call(createApplicationApi, action.payload);
    if (response.success) {
      showToastSuccess('Application created', loaderHandle)
      yield put({ type: CREATE_APPLICATION_SUCCESSED, data: response });
    } else {
      showToastError('Application create failed', loaderHandle)
      yield put({ type: CREATE_APPLICATION_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Application create failed', loaderHandle)
    yield put({
      type: CREATE_APPLICATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateApplication(action) {
  try {
    const response = yield call(updateApplicationApi, action.payload);
    if (response.success) {
      yield put({ type: UPDATE_APPLICATION_SUCCESSED, data: response });
    } else {
      yield put({ type: UPDATE_APPLICATION_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: UPDATE_APPLICATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getApprovalFlowList(action) {
  try {
    const response = yield call(getApprovalFlowListApi, action.payload);
    if (response.success) {
      yield put({ type: GET_APPROVAL_FLOW_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: GET_APPROVAL_FLOW_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPROVAL_FLOW_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getPrimaryApproversList(action) {
  try {
    const response = yield call(getPrimaryListApi, action.payload);
    if (response.success) {
      yield put({ type: GET_PRIMARY_APPROVERS_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: GET_PRIMARY_APPROVERS_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_PRIMARY_APPROVERS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateApprovalWorkFlowDetails(action) {
  const loaderHandle = showToastLoader('Updating approval workflow...')
  try {
    const response = yield call(
      udpateApprovalWorkFlowDetailsApi,
      action.payload
    );
    if (response.success) {
      showToastSuccess('Approval workflow updated', loaderHandle)
      yield put({
        type: UPDATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED,
        data: response,
      });
      yield put(getApprovalFlowListRequested({ queryItem: action?.payload?.query }));
    } else {
      showToastError('Workflow details approval failed', loaderHandle)
      yield put({ type: UPDATE_APPROVAL_WORKFLOW_DETAILS_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Workflow details approval failed', loaderHandle)
    yield put({
      type: UPDATE_APPROVAL_WORKFLOW_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteApprovalWorkFlow(action) {
  const loaderHandle = showToastLoader('Deleting approval workflow...')
  try {
    const response = yield call(deleteWorkFlowApi, action.payload);
    if (response.success) {
      showToastSuccess('Approval workflow deleted', loaderHandle)
      yield put({ type: DELETE_APPROVAL_WORKFLOW_SUCCESSED, data: response });
      yield put(getApprovalFlowListRequested({ queryItem: action.query }));
    } else {
      showToastError('Approval workflow delete failed', loaderHandle)
      yield put({ type: DELETE_APPROVAL_WORKFLOW_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Approval workflow delete failed', loaderHandle)
    yield put({
      type: DELETE_APPROVAL_WORKFLOW_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createApproval(action) {
  const loaderHandle = showToastLoader('Creating approval workflow...')
  try {
    const response = yield call(createApprovalWorkFlowApi, action.payload);
    if (response.success) {
      showToastSuccess('Approval workflow created', loaderHandle)
      yield put({
        type: CREATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED,
        data: response,
      });
      yield put(getApprovalFlowListRequested({ queryItem: action.query }));
    } else {
      showToastError('Approval workflow create failed', loaderHandle)
      yield put({ type: CREATE_APPROVAL_WORKFLOW_DETAILS_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Approval workflow create failed', loaderHandle)
    yield put({
      type: CREATE_APPROVAL_WORKFLOW_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateLicense(action) {
  const loaderHandle = showToastLoader('Updating License...')
  try {
    const response = yield call(updateLicenseApi, action.payload);
    if (response.success) {
      showToastSuccess(response.data[0], loaderHandle);
      localStorage.removeItem('organization');
      yield put({
        type: UPDATE_LICENSE_SUCCESSED,
        data: response,
      });
    } else {
      showToastError(response?.data?.message, loaderHandle)
      yield put({ type: UPDATE_LICENSE_FAILED, data: null });
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message, loaderHandle)
    yield put({
      type: UPDATE_LICENSE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchTestConnection(action) {
  // const loaderHandle = showToastLoader('Testing connection...')
  try {
    // yield put({ type: SHOW_LOADER });
    const data = yield call(testConnection, action.payload);

    if (data.success === true) {
      // showToastSuccess('Connection tested', loaderHandle)
      yield put({ type: TEST_CONNECTION_SUCCESSED, data: data?.data });
      // const response = yield call(applicationSaga, action.payload.data);
      // if (response.success) {
      //   yield put({ type: GET_APPS_DETAILS_SUCCESSED, data: response });
      // } else {
      //   yield put({ type: GET_APPS_DETAILS_FAILED, data: null });
      // }

    } else {
      // showToastError('Connection test failed', loaderHandle)
      yield put({ type: TEST_CONNECTION_FAILED, data: null });
    }
    // yield put({ type: HIDE_LOADER });
  } catch (err) {
    // yield put({ type: HIDE_LOADER });
    // showToastError('Connection test failed', loaderHandle)
    yield put({
      type: TEST_CONNECTION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getAppActionDetails(action) {
  try {
    const response = yield call(getActionApi, action.payload);
    if (response.success) {
      yield put({
        type: GET_APPS_ACTION_DETAILS_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: GET_APPS_ACTION_DETAILS_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPS_ACTION_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getAppActionWorkFlowDetails(action) {
  try {
    const response = yield call(getActionWorkFlowApi);
    if (response.success) {
      yield put({
        type: GET_APPS_ACTION_WORKFLOW_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: GET_APPS_ACTION_WORKFLOW_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_APPS_ACTION_WORKFLOW_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* configDetails(action) {
  try {
    const response = yield call(getAppsConfigsApi, action.payload);
    if (response.success) {
      yield put({
        type: APP_CONFIG_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: APP_CONFIG_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: APP_CONFIG_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* addConfigDetails(action) {
  const loaderHandle = showToastLoader('Updating application configuration...');
  try {
    const response = yield call(appConfigAdd, action.id, action.payload);
    if (response.success) {
      showToastSuccess(`${response?.data[1]}`, loaderHandle)
      yield put({
        type: APP_CONFIG_ADD_SUCCESSED,
        data: response,
      });
      yield put(getAppsDetailsRequested({ queryItem: action.query }))
    } else {
      showToastError('Application configuration update failed', loaderHandle);
      yield put({ type: APP_CONFIG_ADD_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Application configuration update failed', loaderHandle);
    yield put({
      type: APP_CONFIG_ADD_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateWorkFlow(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(updateActionWorkFlowApi, action.payload);
    if (response.success) {
      yield put({
        type: UPDATE_APPS_ACTION_WORKFLOW_UPGRADE_LIST,
        data: action.payload,
      });
      yield put({
        type: UPDATE_APPS_ACTION_WORKFLOW_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: UPDATE_APPS_ACTION_WORKFLOW_FAILED, data: null });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: UPDATE_APPS_ACTION_WORKFLOW_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateValidity(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(updateActionValidityApi, action.payload);
    if (response.success) {

      yield put({
        type: UPDATE_APPS_ACTION_UPGRADE_LIST,
        data: action.payload,
      });

      yield put({
        type: UPDATE_APPS_ACTION_VALIDITY_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: UPDATE_APPS_ACTION_VALIDITY_FAILED, data: null });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: UPDATE_APPS_ACTION_VALIDITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* updateMultiConfigDetails(action) {
  try {
    const response = yield call(updateMultiDataPopupDetailsApi, action.payload);
    if (response) {
      const appResponse = yield call(
        applicationSaga,
        action.payload.updatedData
      );
      if (appResponse.success) {
        yield put({ type: GET_APPS_DETAILS_SUCCESSED, data: appResponse });
      } else {
        yield put({ type: GET_APPS_DETAILS_FAILED, data: null });
      }
      yield put({
        type: UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_SUCCESSED,
        data: response,
      });
    } else {
      yield put({
        type: UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteApplication(action) {
  const loaderHandle = showToastLoader('Deleting application...');
  try {
    const response = yield call(deleteApplicationApi, action.payload);
    if (response?.success) {
      showToastSuccess(`${response?.data?.message}`, loaderHandle)
      yield put({
        type: DELETE_APPLICATION_SUCCESSED,
        data: response,
      });
      yield put(getAppsDetailsRequested({ queryItem: action.query }))
    } else {
      showToastError('Deleting application failed', loaderHandle)
      yield put({
        type: DELETE_APPLICATION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Deleting application failed', loaderHandle)
    yield put({
      type: DELETE_APPLICATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export default function* apps() {
  yield takeLatest(GET_APPS_DETAILS_REQUESTED, getAppsDetails);
  yield takeLatest(GET_APPS_ACTION_DETAILS_REQUESTED, getAppActionDetails);
  yield takeLatest(UPDATE_APPS_ACTION_WORKFLOW_REQUESTED, updateWorkFlow);
  yield takeLatest(UPDATE_APPS_ACTION_VALIDITY_REQUESTED, updateValidity);

  yield takeLatest(
    GET_APPS_ACTION_WORKFLOW_REQUESTED,
    getAppActionWorkFlowDetails
  );
  yield takeLatest(
    UPDATE_APPLICATION_DETAILS_REQUESTED,
    updateApplicationDetails
  );
  yield takeLatest(
    UPDATE_APPLICATION_STATUS_REQUESTED,
    updateApplicationStatus
  );
  yield takeLatest(
    GET_CONFIGURATION_DETAILS_REQUESTED,
    getConfigurationDetails
  );
  yield takeLatest(
    GET_CONFIGURATION_FIELD_VALUE_REQUESTED,
    getConfigurationFieldsData
  );
  yield takeLatest(
    UPDATE_CONFIGURATION_FILEDS_DETAILS_REQUESTED,
    updateConfigDetails
  );
  yield takeLatest(
    UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_REQUESTED,
    updateMultiConfigDetails
  );
  yield takeLatest(CHECK_IS_MULTI_CONFIG_REQUESTED, checkIsMultiConfig);
  yield takeLatest(GET_APPLICATION_VENDOR_LIST_REQUESTED, getVendorsList);
  yield takeLatest(
    GET_APPLICATION_PUBLISHERS_LIST_REQUESTED,
    getPublishersList
  );
  yield takeLatest(GET_APPLICATION_TYPE_LIST_REQUESTED, getTypeList);
  yield takeLatest(GET_APPLICATION_RATE_LIST_REQUESTED, getRateLimitList);
  yield takeLatest(CREATE_APPLICATION_REQUESTED, createApplication);
  yield takeLatest(UPDATE_APPLICATION_REQUESTED, updateApplication);
  yield takeLatest(GET_APPROVAL_FLOW_LIST_REQUESTED, getApprovalFlowList);
  yield takeLatest(
    GET_PRIMARY_APPROVERS_LIST_REQUESTED,
    getPrimaryApproversList
  );
  yield takeLatest(
    UPDATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED,
    updateApprovalWorkFlowDetails
  );
  yield takeLatest(DELETE_APPROVAL_WORKFLOW_REQUESTED, deleteApprovalWorkFlow);
  yield takeLatest(CREATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED, createApproval);
  yield takeLatest(DELETE_APPLICATION_REQUESTED, deleteApplication);
  yield takeLatest(APP_CONFIG_REQUESTED, configDetails)
  yield takeLatest(APP_CONFIG_ADD_REQUESTED, addConfigDetails)
  yield takeLatest(UPDATE_LICENSE_REQUESTED, updateLicense)
  yield takeLatest(TEST_CONNECTION_REQUESTED, watchTestConnection);
}
