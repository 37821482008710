import {
  INCIDENT_ACTION_APPS_REQUESTED,
  INCIDENT_ACTION_APPS_PROCESSING,
  INCIDENT_ACTION_APPS_SUCCESSED,
  INCIDENT_ACTION_APPS_FAILED,
  INCIDENT_APP_CONFIG_REQUESTED,
  INCIDENT_APP_CONFIG_SUCCESSED,
  INCIDENT_APP_CONFIG_FAILED,
  INCIDENT_APP_CONFIG_RESET
} from '../../constants/actionTypes';

const initialState = {
  result: {},
  isProcessing: null,
  isSuccess: null,
  hasErrors: null,
  commentAppsConfig: [],
};

export const incidentMasterActionApps = (state = initialState, action) => {
  switch (action.type) {
    case INCIDENT_ACTION_APPS_REQUESTED: {
      return {
        ...state,
        error: null,
        loading: true,
        requested: true,
      };
    }
    case INCIDENT_ACTION_APPS_PROCESSING: {
      return {
        ...state,
        error: null,
        loading: true,
        requested: false,
      };
    }
    case INCIDENT_ACTION_APPS_SUCCESSED: {
      return {
        ...state,
        result: action.data,
        error: null,
        loading: false,
        requested: false,
      };
    }
    case INCIDENT_ACTION_APPS_FAILED: {
      return {
        ...state,
        result: {},
        error: action.data,
        loading: false,
        requested: false,
      };
    }
    case INCIDENT_APP_CONFIG_REQUESTED: {
      return {
        ...state,
        error: null,
        loading: true,
        requested: true,
      };
    }
    case INCIDENT_APP_CONFIG_SUCCESSED: {
      return {
        ...state,
        commentAppsConfig: action.data,
        error: null,
        loading: false,
        requested: false,
      };
    }
    case INCIDENT_APP_CONFIG_FAILED: {
      return {
        ...state,
        commentAppsConfig: [],
        error: action.data,
        loading: false,
        requested: false,
      };
    }
    case INCIDENT_APP_CONFIG_RESET: {
      return {
        ...state,
        commentAppsConfig: [],
        error: null,
        loading: false,
        requested: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default incidentMasterActionApps;
