import { put, call, takeLatest } from 'redux-saga/effects';
import {
  // SHOW_LOADER,
  // HIDE_LOADER,
  INCIDENT_TASK_CREATED_REQUESTED,
  INCIDENT_TASK_CREATED_SUCCESSED,
  INCIDENT_TASK_CREATED_FAILED,
  INCIDENT_TASK_EDIT_REQUESTED,
  INCIDENT_TASK_EDIT_SUCCESSED,
  INCIDENT_TASK_EDIT_FAILED,
  INCIDENT_TASK_LIST_REQUESTED,
  INCIDENT_TASK_LIST_SUCCESSED,
  INCIDENT_TASK_LIST_FAILED,

  INCIDENT_TASK_DELETED_REQUESTED,
  INCIDENT_TASK_DELETED_SUCCESSED,
  INCIDENT_TASK_DELETED_FAILED,
} from '../../constants/actionTypes';

import { taskCreate, listTasks, editTaskSaga, deleteIncidentTask } from '../../api/taskCreate';
import { showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

export function* watchCreateTask(action) {
  let loading, success, error
  const { http_method } = action?.payload?.payload || {};
  if (http_method === 'delete') {
    loading = 'Deleting task...'
    success = 'Task deleted'
    error = 'Task delete failed'
  } else if (http_method === 'put') {
    loading = 'Updating task...'
    success = 'Task updated'
    error = 'Task update failed'
  } else {
    loading = 'Creating task...'
    success = 'Task created'
    error = 'Task creation failed'
  }
  const loaderHandle = showToastLoader(loading)
  try {
    const response = yield call(taskCreate, action.payload);
    if (response.success) {
      showToastSuccess(success, loaderHandle)
      if (action.payload?.callback) {
        action.payload.callback(response.data);
      }
      yield put({ type: INCIDENT_TASK_CREATED_SUCCESSED, data: response });
    } else {
      showToastError(error, loaderHandle)
      yield put({ type: INCIDENT_TASK_CREATED_FAILED, data: null });
    }
  } catch (err) {
    showToastError(error, loaderHandle)
    yield put({
      type: INCIDENT_TASK_CREATED_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchTaskList(action) {
  try {
    const response = yield call(listTasks, action.payload);
    if (response.success) {
      if (action.payload?.callback) {
        action.payload.callback(response.data?.data?.[0]);
      }
      yield put({ type: INCIDENT_TASK_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_TASK_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_TASK_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchEditList(action) {
  const loaderHandle = showToastLoader('Updating task...')
  try {
    const response = yield call(editTaskSaga, action.payload);
    if (response.success) {
      showToastSuccess('Task updated', loaderHandle)
      if (action.payload?.callback) {
        action.payload.callback(response.data);
      }
      yield put({ type: INCIDENT_TASK_EDIT_SUCCESSED, data: response });
    } else {
      showToastError('Task update failed', loaderHandle)
      yield put({ type: INCIDENT_TASK_EDIT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Task update failed', loaderHandle)
    yield put({
      type: INCIDENT_TASK_EDIT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDelete(action) {
  const loaderHandle = showToastLoader('Deleting task...')
  try {
    const response = yield call(deleteIncidentTask, action.payload);
    if (response.success) {
      showToastSuccess('Task deleted', loaderHandle)
      if (action.payload?.callback) {
        action.payload.callback(response.data);
      }
      yield put({ type: INCIDENT_TASK_DELETED_SUCCESSED, data: response });
    } else {
      showToastError('Task delete failed', loaderHandle)
      yield put({ type: INCIDENT_TASK_DELETED_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Task delete failed', loaderHandle)
    yield put({
      type: INCIDENT_TASK_DELETED_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export default function* watcher() {
  yield takeLatest(INCIDENT_TASK_CREATED_REQUESTED, watchCreateTask);
  yield takeLatest(INCIDENT_TASK_LIST_REQUESTED, watchTaskList);
  yield takeLatest(INCIDENT_TASK_EDIT_REQUESTED, watchEditList);
  yield takeLatest(INCIDENT_TASK_DELETED_REQUESTED, watchDelete);
}
