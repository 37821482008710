import {
    ADVISORY_SOURCE_REQUESTED,
    ADVISORY_SOURCE_FAILED,
    ADVISORY_SOURCE_SUCCESSED,
    ADVISORY_SOURCE_PROCESSING
} from '../../constants/actionTypes';

const initialState = {
    listData: {},
    isProcessing: false,
    isSuccess: null,
    hasErrors: null,
};

export const threatIntelligenceSource = (state = initialState, action) => {
    switch (action.type) {
        case ADVISORY_SOURCE_REQUESTED: {
            return {
                ...state,
                listData: null,
                hasErrors: null,
                isProcessing: true,
                isSuccess: null,
            };
        }
        case ADVISORY_SOURCE_PROCESSING: {
            return {
                ...state,
                listData: null,
                hasErrors: null,
                isProcessing: true,
                isSuccess: null,
            };
        }
        case ADVISORY_SOURCE_SUCCESSED: {
            return {
                listData: action.data,
                hasErrors: null,
                isProcessing: false,
                isSuccess: true,
            };
        }
        case ADVISORY_SOURCE_FAILED: {
            return {
                listData: action.data,
                hasErrors: true,
                isProcessing: false,
                isSuccess: null,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default threatIntelligenceSource;
