import {
  NCIS_SCORE_PROCESSING,
  NCIS_SCORE_SUCCESSED,
  NCIS_SCORE_FAILED,
  NCIS_SCORE_REQUESTED,
  NCIS_SCORE_RESET,

} from '../../constants/actionTypes';

const initialState = {
  result: [],
  isProcessing: null,
  isSuccess: null,
  hasErrors: null,
  listData: {},
};

export const ncisStore = (state = initialState, action) => {
  switch (action.type) {
    case NCIS_SCORE_REQUESTED: {
      return {
        ...state,
        listData: null,
        hasErrors: null,
        isProcessing: true,
        isSuccess: null,

      };
    }
    case NCIS_SCORE_PROCESSING: {
      return {
        ...state,
        listData: null,
        hasErrors: null,
        isProcessing: true,
        isSuccess: null,
      };
    }
    case NCIS_SCORE_SUCCESSED: {
      return {
        listData: action.data,
        hasErrors: null,
        isProcessing: false,
        isSuccess: true,
      };
    }
    case NCIS_SCORE_FAILED: {
      return {
        listData: action.data,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
      };
    }

    case NCIS_SCORE_RESET: {
      return {
        ...state,
        listData: {},
        hasErrors: null,
        isProcessing: false,
        isSuccess: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ncisStore;
