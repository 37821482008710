import {
  NETWORK_BLOCK_LIST_REQUESTED,
  NETWORK_BLOCK_CREATE_REQUESTED,
  NETWORK_BLOCK_DELETE_REQUESTED,
  NETWORK_BLOCK_EDIT_REQUESTED,


} from '../constants/actionTypes';


export function listNetworkBlock(page = 1, parameter = '',perPage = 20) {

  return {
    type: NETWORK_BLOCK_LIST_REQUESTED,
    payload: { page, parameter, 'per-page':perPage },
  };
}

export function createNetworkBlock(payload) {
  return {
    type: NETWORK_BLOCK_CREATE_REQUESTED,
    payload,
  };
}

export function deleteNetworkBlock(id) {
  return {
    type: NETWORK_BLOCK_DELETE_REQUESTED,
    id,
  };
}

export function editNetworkBlock(payload, id) {
  return {
    type: NETWORK_BLOCK_EDIT_REQUESTED,
    payload:{...payload,id:id},
  };
}
