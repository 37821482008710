import {    
  INVITED_USER_REQUESTED,
  INVITED_USER_PROCESSING,
  INVITED_USER_SUCCESSED,
  INVITED_USER_FAILED,
  } from '../../constants/actionTypes';
  
  const initialState = {
    listData: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };

 
  export const invitedUserStore = (state = initialState, action) => {
    switch (action.type) {
        case INVITED_USER_REQUESTED: {
            return {
            ...state,
            listData: {},
            loading: true,
            hasErrors: null,
            isProcessing: true,
            isSuccess: null,
            };
        }
        case INVITED_USER_PROCESSING: {
            return {
            ...state,
            listData: {},
            hasErrors: null,
            loading: true,
            isProcessing: true,
            isSuccess: null,
            };
        }
        case INVITED_USER_SUCCESSED: {
            return {
                ...state,
                listData: action.data,
                hasErrors: null,
                loading: false,
                isProcessing: false,
                isSuccess: true,
            };
        }
        case INVITED_USER_FAILED: {
            return {
                ...state,
                listData: {},
                hasErrors: action.data,
                isProcessing: false,
                loading: false,
                isSuccess: false,
            };
        }

        default: {
            return {
            ...state,
            };
        }
    }
  };
  
  export default invitedUserStore;
  