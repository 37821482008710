import {
    VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_GET_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_GET_LIST_FAILED,
    GET_VULNERABLITY_MANAGEMENT_BY_ID_SUCCESSED,
    GET_VULNERABLITY_MANAGEMENT_BY_ID_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ATTACK_TYPE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ATTACK_TYPE_FAILED,
    VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSET_TYPE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSET_TYPE_FAILED,
    VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED,
    VULNERABILITY_MANAGEMENT_SEVERITY_SUCCESSED,
    VULNERABILITY_MANAGEMENT_SEVERITY_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED,
    RESET_VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_SUCCESSED,
    VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_FAILED,
    VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED,
    VIEW_TASK_VULNERABILITY_MANAGEMENT_FAILED,
    VIEW_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED,
    VULNERABILITY_TASK_CATEGORIES_FAILED,
    VULNERABILITY_TASK_CATEGORIES_SUCCESSED,
    VULNERABILITY_TASK_CATEGORIES_REQUESTED,
    VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_CASE_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_CASE_LIST_FAILED,
    VULNERABILITY_VULNERABILITY_ASSET_REQUESTED,
    VULNERABILITY_VULNERABILITY_ASSET_SUCCESSED,
    VULNERABILITY_VULNERABILITY_ASSET_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED,
    VULNERABILITY_MANAGEMENT_RESET_ASSESSMENT,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_FAILED,
    ASSESSMENT_TEMPLATE_CREATE_REQUESTED,
    ASSESSMENT_TEMPLATE_CREATE_SUCCESSED,
    ASSESSMENT_TEMPLATE_CREATE_FAILED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_SUCCESSED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_FAILED,
    VULNERABILITY_TASK_DEPARTMENT_REQUESTED,
    VULNERABILITY_TASK_DEPARTMENT_SUCCESSED,
    VULNERABILITY_TASK_DEPARTMENT_FAILED,
    VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_NAME_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_NAME_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_CONTENT_SUCCESSED,
    VULNERABILITY_MANAGEMENT_CONTENT_FAILED,
    VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED,
    VULNERABILITY_MANAGEMENT_EDIT_SEARCH_SUCCESSED,
    VULNERABILITY_MANAGEMENT_EDIT_SEARCH_FAILED,
    VULNERABILITY_MANAGEMENT_OPEN_CASES_REQUESTED,
    VULNERABILITY_MANAGEMENT_OPEN_CASES_SUCCESSED,
    VULNERABILITY_MANAGEMENT_OPEN_CASES_FAILED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_FAILED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_SUCCESSED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_FAILED
} from '../../constants/actionTypes';

const initialState = {
    valnerabilityList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    valnerabilityTaskList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    selectedValnerability: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    assessmentList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },

    singleAssessmentList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    templatList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    attackTypeListVulnerability: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    assetListVulnerability: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    severityListVulnerability: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    assessmentLogList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    applicationList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    filterList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    actionListVal: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        actionData: [],
    },
    taskCategories: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        actionData: [],
    },
    caseList: {
        isProcessing: false,
        isSuccess: false,
        hasErrors: false,
        caseListData: [],
    },
    vulnerabilityAssetList: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    importNessusAssesment: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
        json: {},
    },
    templateCreation: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    templateTypeList: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    editorList: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
        isUploaded: false,
    },
    departments: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    nameList: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    contentList: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    editSearchList: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    caseCreateList: {
        isLoading: false,
        isSuccess: false,
        hasErrors: false,
        listData: [],
    },
    importedAssessmentList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    importedAssessmentData: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
};

export const vulnerabilityManagementStore = (state = initialState, action) => {
    switch (action.type) {
        case VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED: {
            return {
                ...state,
                valnerabilityList: {
                    ...state.valnerabilityList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_GET_LIST_SUCCESSED: {
            return {
                ...state,
                valnerabilityList: {
                    ...state.valnerabilityList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_GET_LIST_FAILED: {
            return {
                ...state,
                valnerabilityList: {
                    ...state.valnerabilityList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED: {
            return {
                ...state,
                assessmentList: {
                    ...state.assessmentList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case GET_VULNERABLITY_MANAGEMENT_BY_ID_SUCCESSED: {
            return {
                ...state,
                selectedValnerability: {
                    ...state.selectedValnerability,
                    listData: action.data,
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case GET_VULNERABLITY_MANAGEMENT_BY_ID_FAILED: {
            return {
                ...state,
                selectedValnerability: {
                    ...state.selectedValnerability,
                    listData: [],
                    error: action.data,
                    loading: true,
                    requested: true,
                }
            }
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_SUCCESSED: {
            return {
                ...state,
                assessmentList: {
                    ...state.assessmentList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_FAILED: {
            return {
                ...state,
                assessmentList: {
                    ...state.assessmentList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED: {
            return {
                ...state,
                templatList: {
                    ...state.templatList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_SUCCESSED: {
            return {
                ...state,
                templatList: {
                    ...state.templatList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_FAILED: {
            return {
                ...state,
                templatList: {
                    ...state.templatList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: true,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED: {
            return {
                ...state,
                attackTypeListVulnerability: {
                    ...state.attackTypeListVulnerability,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ATTACK_TYPE_SUCCESSED: {
            return {
                ...state,
                attackTypeListVulnerability: {
                    ...state.attackTypeListVulnerability,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ATTACK_TYPE_FAILED: {
            return {
                ...state,
                attackTypeListVulnerability: {
                    ...state.attackTypeListVulnerability,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED: {
            return {
                ...state,
                assetListVulnerability: {
                    ...state.assetListVulnerability,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSET_TYPE_SUCCESSED: {
            return {
                ...state,
                assetListVulnerability: {
                    ...state.assetListVulnerability,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSET_TYPE_FAILED: {
            return {
                ...state,
                assetListVulnerability: {
                    ...state.assetListVulnerability,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED: {
            return {
                ...state,
                severityListVulnerability: {
                    ...state.severityListVulnerability,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_SEVERITY_SUCCESSED: {
            return {
                ...state,
                severityListVulnerability: {
                    ...state.severityListVulnerability,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_SEVERITY_FAILED: {
            return {
                ...state,
                severityListVulnerability: {
                    ...state.severityListVulnerability,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED: {
            return {
                ...state,
                assessmentLogList: {
                    ...state.assessmentLogList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_SUCCESSED: {
            return {
                ...state,
                assessmentLogList: {
                    ...state.assessmentLogList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_FAILED: {
            return {
                ...state,
                assessmentLogList: {
                    ...state.assessmentLogList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }


        case VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED: {
            return {
                ...state,
                singleAssessmentList: {
                    ...state.singleAssessmentList,
                    listData: [],
                    hasErrors: null,
                    loading: true,
                    requested: true,
                    isSuccess: false,
                    isProcessing: true
                }
            }
        }

        case VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_SUCCESSED: {
            return {
                ...state,
                singleAssessmentList: {
                    ...state.singleAssessmentList,
                    listData: action.data,
                    hasErrors: null,
                    loading: false,
                    isSuccess: true,
                    requested: false,
                    isProcessing: false
                }
            }
        }


        case VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_FAILED: {
            return {
                ...state,
                singleAssessmentList: {
                    ...state.singleAssessmentList,
                    listData: [],
                    hasErrors: action.data,
                    loading: false,
                    requested: false,
                    isProcessing: false
                }
            }
        }


        case VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED: {
            return {
                ...state,
                applicationList: {
                    ...state.applicationList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_SUCCESSED: {
            return {
                ...state,
                applicationList: {
                    ...state.applicationList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_FAILED: {
            return {
                ...state,
                applicationList: {
                    ...state.applicationList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED: {
            return {
                ...state,
                filterList: {
                    ...state.filterList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            }
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_SUCCESSED: {
            return {
                ...state,
                filterList: {
                    ...state.filterList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            }
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_FAILED: {
            return {
                ...state,
                filterList: {
                    ...state.filterList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            }
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED: {
            return {
                ...state,
                actionListVal: {
                    ...state.actionListVal,
                    actionData: [],
                    isFieldLoading: true,
                    hasErrors: false,
                    isSuccess: false,
                }
            };
        }

        case RESET_VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED: {
            return {
                ...state,
                actionListVal: {
                    isProcessing: null,
                    isSuccess: null,
                    hasErrors: null,
                    actionData: [],
                }
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_SUCCESSED: {
            return {
                ...state,
                actionListVal: {
                    ...state.actionListVal,
                    actionData: action.data,
                    isFieldLoading: false,
                    hasErrors: false,
                    isSuccess: true,
                }
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_FAILED: {
            return {
                ...state,
                actionListVal: {
                    ...state.actionListVal,
                    actionData: [],
                    isFieldLoading: false,
                    hasErrors: true,
                    isSuccess: false,
                }
            };
        }
        case VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED: {
            return {
                ...state,
                valnerabilityTaskList: {
                    ...state.valnerabilityTaskList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VIEW_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED: {
            return {
                ...state,
                valnerabilityTaskList: {
                    ...state.valnerabilityTaskList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VIEW_TASK_VULNERABILITY_MANAGEMENT_FAILED: {
            return {
                ...state,
                valnerabilityTaskList: {
                    ...state.valnerabilityTaskList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED: {
            return {
                ...state,
                valnerabilityTaskList: {
                    ...state.valnerabilityTaskList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VIEW_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED: {
            return {
                ...state,
                valnerabilityTaskList: {
                    ...state.valnerabilityTaskList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VIEW_TASK_VULNERABILITY_MANAGEMENT_FAILED: {
            return {
                ...state,
                valnerabilityTaskList: {
                    ...state.valnerabilityTaskList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_TASK_CATEGORIES_REQUESTED: {
            return {
                ...state,
                taskCategories: {
                    ...state.taskCategories,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_TASK_CATEGORIES_SUCCESSED: {
            return {
                ...state,
                taskCategories: {
                    ...state.taskCategories,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_TASK_CATEGORIES_FAILED: {
            return {
                ...state,
                taskCategories: {
                    ...state.taskCategories,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED: {
            return {
                ...state,
                valnerabilityList: {
                    ...state.caseList,
                    caseListData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_CASE_LIST_SUCCESSED: {
            return {
                ...state,
                valnerabilityList: {
                    ...state.caseList,
                    caseListData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case VULNERABILITY_MANAGEMENT_CASE_LIST_FAILED: {
            return {
                ...state,
                valnerabilityList: {
                    ...state.caseList,
                    caseListData: [],
                    error: action.data,
                    loading: false,
                    hasErrors: true,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_VULNERABILITY_ASSET_REQUESTED: {
            return {
                ...state,
                vulnerabilityAssetList: {
                    ...state.vulnerabilityAssetList,
                    listData: [],
                    error: null,
                    isLoading: true,
                },
            };
        }

        case VULNERABILITY_VULNERABILITY_ASSET_SUCCESSED: {
            return {
                ...state,
                vulnerabilityAssetList: {
                    ...state.vulnerabilityAssetList,
                    listData: action.data,
                    hasErrors: false,
                    isLoading: false,
                },
            };
        }

        case VULNERABILITY_VULNERABILITY_ASSET_FAILED: {
            return {
                ...state,
                vulnerabilityAssetList: {
                    ...state.vulnerabilityAssetList,
                    listData: [],
                    error: action.data,
                    isLoading: false,
                    hasErrors: true,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED: {
            return {
                ...state,
                importNessusAssesment: {
                    ...state.importNessusAssesment,
                    listData: [],
                    json: {},
                    error: null,
                    isLoading: true,
                }
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_SUCCESSED: {
            return {
                ...state,
                importNessusAssesment: {
                    ...state.importNessusAssesment,
                    listData: action.data,
                    json: action?.data?.json,
                    error: null,
                    isLoading: false,
                }
            };
        }

        case VULNERABILITY_MANAGEMENT_RESET_ASSESSMENT: {
            return {
                ...state,
                importNessusAssesment: {
                    ...state.importNessusAssesment,
                    listData: action.payload,
                    error: null,
                    isLoading: false,
                }
            };
        }

        case VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_FAILED: {
            return {
                ...state,
                importNessusAssesment: {
                    ...state.importNessusAssesment,
                    listData: [],
                    error: action.data,
                    isLoading: false,
                    hasErrors: true,

                }
            };
        }
        case ASSESSMENT_TEMPLATE_CREATE_REQUESTED: {
            return {
                ...state,
                templateCreation: {
                    ...state.templateCreation,
                    listData: [],
                    error: null,
                    isLoading: true,
                }
            }
        }
        case ASSESSMENT_TEMPLATE_CREATE_SUCCESSED: {
            return {
                ...state,
                templateCreation: {
                    ...state.templateCreation,
                    listData: action.data,
                    error: null,
                    isLoading: false,
                }
            }
        }
        case ASSESSMENT_TEMPLATE_CREATE_FAILED: {
            return {
                ...state,
                templateCreation: {
                    ...state.templateCreation,
                    listData: [],
                    error: action.data,
                    isLoading: false,
                    hasErrors: true,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED: {
            return {
                ...state,
                templateTypeList: {
                    ...state.templateTypeList,
                    listData: [],
                    error: null,
                    isLoading: true,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_SUCCESSED: {
            return {
                ...state,
                templateTypeList: {
                    ...state.templateTypeList,
                    listData: action.data,
                    error: null,
                    isLoading: false,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_FAILED: {
            return {
                ...state,
                templateTypeList: {
                    ...state.templateTypeList,
                    listData: [],
                    error: action.data,
                    isLoading: false,
                    hasErrors: true,
                }
            }

        }

        case VULNERABILITY_TASK_DEPARTMENT_REQUESTED: {
            return {
                ...state,
                departments: {
                    ...state.departments,
                    listData: [],
                    hasErrors: false,
                    isLoading: true,
                }
            }
        }
        case VULNERABILITY_TASK_DEPARTMENT_SUCCESSED: {
            return {
                ...state,
                departments: {
                    ...state.departments,
                    listData: action.data,
                    hasErrors: false,
                    isLoading: false,
                }
            }
        }
        case VULNERABILITY_TASK_DEPARTMENT_FAILED: {
            return {
                ...state,
                departments: {
                    ...state.departments,
                    listData: [],
                    error: action.data,
                    hasErrors: true,
                    isLoading: false,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED: {
            return {
                ...state,
                nameList: {
                    ...state.nameList,
                    listData: [],
                    hasErrors: false,
                    isLoading: true,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_NAME_LIST_SUCCESSED: {
            return {
                ...state,
                nameList: {
                    ...state.nameList,
                    listData: action.data,
                    hasErrors: false,
                    isLoading: false,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_NAME_LIST_FAILED: {
            return {
                ...state,
                nameList: {
                    ...state.nameList,
                    listData: [],
                    error: action.data,
                    hasErrors: true,
                    isLoading: false,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED: {
            return {
                ...state,
                contentList: {
                    ...state.contentList,
                    listData: [],
                    hasErrors: false,
                    isLoading: true,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_CONTENT_SUCCESSED: {
            return {
                ...state,
                contentList: {
                    ...state.contentList,
                    listData: action.data,
                    hasErrors: false,
                    isLoading: false,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_CONTENT_FAILED: {
            return {
                ...state,
                contentList: {
                    ...state.contentList,
                    listData: [],
                    error: action.data,
                    hasErrors: true,
                    isLoading: false,
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED: {
            return {
                ...state,
                editSearchList: {
                    ...state.editSearchList,
                    listData: [],
                    hasErrors: false,
                    isLoading: true,
                    hasSuccess: false
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_EDIT_SEARCH_SUCCESSED: {
            return {
                ...state,
                editSearchList: {
                    ...state.editSearchList,
                    listData: action.data,
                    hasErrors: false,
                    isLoading: false,
                    hasSuccess: true
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_EDIT_SEARCH_FAILED: {
            return {
                ...state,
                editSearchList: {
                    ...state.editSearchList,
                    listData: [],
                    error: action.data,
                    hasErrors: true,
                    isLoading: false,
                    hasSuccess: false
                }
            }
        }

        case VULNERABILITY_MANAGEMENT_OPEN_CASES_SUCCESSED: {
            return {
                ...state,
                caseCreateList: {
                    ...state.caseCreateList,
                    listData: action.data,
                    hasErrors: false,
                    isLoading: false,
                    hasSuccess: true
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_OPEN_CASES_FAILED: {
            return {
                ...state,
                caseCreateList: {
                    ...state.caseCreateList,
                    listData: action.data,
                    hasErrors: true,
                    isLoading: false,
                    hasSuccess: false
                }
            }
        }
        case VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED: {
            return {
                ...state,
                importedAssessmentList: {
                    ...state.importedAssessmentList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_SUCCESSED: {
            return {
                ...state,
                importedAssessmentList: {
                    ...state.importedAssessmentList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_FAILED: {
            return {
                ...state,
                importedAssessmentList: {
                    ...state.importedAssessmentList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED: {
            return {
                ...state,
                importedAssessmentData: {
                    ...state.importedAssessmentData,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_SUCCESSED: {
            return {
                ...state,
                importedAssessmentData: {
                    ...state.importedAssessmentData,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
                importNessusAssesment: {
                    ...state.importNessusAssesment,
                    json: action?.data?.json,
                }
            };
        }
        case VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_FAILED: {
            return {
                ...state,
                importedAssessmentData: {
                    ...state.importedAssessmentData,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }

        default: {
            return state;
        }
    }
};

export default vulnerabilityManagementStore;
