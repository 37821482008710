import { toast } from "react-toastify";

export const showToastLoader = (message) => {
    return toast.loading(message)
}

export const dismissToastLoader = (loaderHandle) => {
    toast.dismiss(loaderHandle)
}

export const dismissAllToasts = () => {
    toast.dismiss()
}

export const showToastProgress = (message, progress) => {
    return toast(message, { progress })
}

export const updateToastProgress = (progress, loaderHandle) => {
    toast.update(loaderHandle, { progress })
}

export const showToastSuccess = (successMessage, loaderHandle) => {
    loaderHandle
    ? toast.update(loaderHandle,
        {
            render: successMessage,
            type: 'success',
            isLoading: false,
            autoClose: 2000
        }
    )
    : toast.success(successMessage,{autoClose:2000})
}

export const showToastError = (errorMessage, loaderHandle = null) => {
    loaderHandle
    ? toast.update(loaderHandle,
        {
            render: errorMessage,
            type: 'error',
            isLoading: false,
            autoClose: 2000
        }
    )
    : toast.error(errorMessage)
}

export const showToastInfo = (infoMessage, loaderHandle=null) => {
    loaderHandle
    ? toast.update(loaderHandle,
        {
            render: infoMessage,
            type: 'info',
            isLoading: false,
            autoClose: 2000
        }
    )
    : toast.info(infoMessage)
}
