import { put, call, takeLatest } from 'redux-saga/effects';
import {
  NETWORK_BLOCK_LIST_SUCCESSED,
  NETWORK_BLOCK_LIST_FAILED,
  NETWORK_BLOCK_LIST_REQUESTED,
  NETWORK_BLOCK_CREATE_REQUESTED,
  NETWORK_BLOCK_CREATE_SUCCESSED,
  NETWORK_BLOCK_CREATE_FAILED,
  NETWORK_BLOCK_EDIT_REQUESTED,
  NETWORK_BLOCK_EDIT_SUCCESSED,
  NETWORK_BLOCK_EDIT_FAILED,
  NETWORK_BLOCK_DELETE_REQUESTED,
  NETWORK_BLOCK_DELETE_SUCCESSED,
  NETWORK_BLOCK_DELETE_FAILED,
} from '../../constants/actionTypes';
import {
  listSaga,
  createSaga,
  editSaga,
  deleteSaga,
} from '../../api/networkBlockSaga';
import {
  listNetworkBlock
} from '../../actions/networkBlocks';
import { showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

export function* watchList(action) {
  try {
    const response = yield call(listSaga, action.payload);
    if (response.success) {
      yield put({ type: NETWORK_BLOCK_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: NETWORK_BLOCK_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: NETWORK_BLOCK_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchCreate(action) {
  const loaderHandle = showToastLoader('Creating network block...')
  try {
    const response = yield call(createSaga, action.payload);
    if (response.success) {
      showToastSuccess('Network block created', loaderHandle)
      yield put({ type: NETWORK_BLOCK_CREATE_SUCCESSED, data: response });
      yield put(listNetworkBlock({ queryItem: action.query }));
    } else {
      showToastError('Network block create failed', loaderHandle)
      yield put({ type: NETWORK_BLOCK_CREATE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Network block create failed', loaderHandle)
    yield put({ type: NETWORK_BLOCK_CREATE_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchEdit(action) {
  const loaderHandle = showToastLoader('Updating network block...')
  try {
    const response = yield call(editSaga, action.payload);
    if (response.success) {
      showToastSuccess('Network block updated', loaderHandle)
      yield put({ type: NETWORK_BLOCK_EDIT_SUCCESSED, data: response });
      yield put(listNetworkBlock({ queryItem: action.query }));
    } else {
      showToastError('Network block update failed', loaderHandle)
      yield put({ type: NETWORK_BLOCK_EDIT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Network block update failed', loaderHandle)
    yield put({ type: NETWORK_BLOCK_EDIT_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchDelete(action) {
  const loaderHandle = showToastLoader('Deleting network block...')
  try {
    const response = yield call(deleteSaga, action.id);

    if (response.success) {
      showToastSuccess('Network block deleted', loaderHandle)
      yield put({ type: NETWORK_BLOCK_DELETE_SUCCESSED, data: response });
    } else {
      showToastError('Network block delete failed', loaderHandle)
      yield put({ type: NETWORK_BLOCK_DELETE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Network block delete failed', loaderHandle)
    yield put({ type: NETWORK_BLOCK_DELETE_FAILED, data: err?.response?.data?.data });
  }
}

export default function* watcher() {
  yield takeLatest(NETWORK_BLOCK_LIST_REQUESTED, watchList);
  yield takeLatest(NETWORK_BLOCK_DELETE_REQUESTED, watchDelete);
  yield takeLatest(NETWORK_BLOCK_EDIT_REQUESTED, watchEdit);
  yield takeLatest(NETWORK_BLOCK_CREATE_REQUESTED, watchCreate);
}
