import { put, call, takeLatest } from 'redux-saga/effects';
import {
  MITRE_DASHBOARD_LIST_SUCCESSED,
  MITRE_DASHBOARD_LIST_FAILED,
  MITRE_DASHBOARD_LIST_REQUESTED,
  MITRE_DETAIL_REQUESTED,
  MITRE_DETAIL_SUCCESSED,
  MITRE_DETAIL_FAILED,
  MITRE_WIDGETS_LIST_REQUESTED,
  MITRE_WIDGETS_LIST_SUCCESSED,
  MITRE_WIDGETS_LIST_FAILED
} from '../../constants/actionTypes';
import {
  dashboardList,
  mitreWidgetsList,
  mitreDetail,
} from '../../api/mitreDashboardAPI';
import { dismissToastLoader, showToastLoader } from '../../utils/toasts';

export const getDashboardWidgets = (state) => state.dashboardStore.widgetLocalState.selectedWidgets;

export function* watchDashboardListData(action) {
  const loaderHandle = showToastLoader('Fetching MITRE Data...');
  try {
    const response = yield call(dashboardList, action.payload);
    if (response.success) {
      yield put({
        type: MITRE_DASHBOARD_LIST_SUCCESSED,
        data: response?.data,
      });
    }
  } catch (err) {
    yield put({ type: MITRE_DASHBOARD_LIST_FAILED });
  } finally {
    dismissToastLoader(loaderHandle);
  }
}

export function* watchMitreWidgets(action) {
  const loaderHandle = showToastLoader('Fetching MITRE Assets Mapping...');
  try {
    const response = yield call(mitreWidgetsList, action.payload);
    if (response.success) {
      yield put({
        type: MITRE_WIDGETS_LIST_SUCCESSED,
        data: response?.data,
      });
    }
  } catch (err) {
    yield put({ type: MITRE_WIDGETS_LIST_FAILED });
  } finally {
    dismissToastLoader(loaderHandle);
  }
}

export function* watchMitreDetail(action) {
  try {
    const response = yield call(mitreDetail, action.payload);
    if (response.success) {
      yield put({
        type: MITRE_DETAIL_SUCCESSED,
        data: response?.data,
      });
    }
  } catch (err) {
    yield put({ type: MITRE_DETAIL_FAILED });
  }
}

export default function* watcher() {
  yield takeLatest(MITRE_DASHBOARD_LIST_REQUESTED, watchDashboardListData);
  yield takeLatest(MITRE_WIDGETS_LIST_REQUESTED, watchMitreWidgets);
  yield takeLatest(MITRE_DETAIL_REQUESTED, watchMitreDetail);
}
