import styled from 'styled-components';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { Colors, Fonts } from '../../theme';

export const TextInputWrapper = styled.div`
  width: ${props => (props.type == 'dynamicField' ? '95%' : props.width ? props.width + 'px' : '100%')};
  display: flex;
  align-items: center;
  margin-top: ${props => (props.mTop ? props.mTop : '0px')};
  margin-bottom: ${props => (props.noMargin ? '0px' : '1rem')};
  justify-content: center;
  flex-direction: column;
`;

export const TextInputDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 33px;
  justify-content: center;
  background: #1c1c24;
  border-radius: 5px;
  @media (max-width: 1440px) {
    height: ${props => (props.height && '30px')};
  }
`;
export const StyledSFTP = styled.div`
  background: #373747;
  border-radius: 5px;
  margin-top:10px;
  padding:15px 21px;
  margin-bottom:10px

`;
export const TextInput = styled.input`
  font-size: 1rem;
  width: 100%;
  outline: none;
  text-align: left;
  color: #ffffff;
  border-radius: 5px;
  padding: 0px 11px;
  background: border-box;
  border: 1px solid #ffffff5c;

  input:-webkit-autofill {
    color: #2a2a2a !important;
  }
  ::placeholder {
    font-size: 1rem;
  }
  &:focus {
    border: 1px solid #007FD9;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid green !important;
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
`;
export const StyledInput = styled(Input)`
  background-color: transparent;
  border: 1px solid ${Colors.primaryWhite} !important;
  color: ${Colors.primaryWhite};
  font-family: ${Fonts.type.robotoBold};
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  border-radius: 5px;
  height: 35px;
  margin: 5px 0px;

  .ant-input {
    background-color: transparent;
    color: ${Colors.primaryWhite};
    font-family: ${Fonts.type.robotoRegular};
    margin: 0px 5px;
  }
`;

export const ErrorDiv = styled.p`
  color: #fc5a5a;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 3px;
  margin-top: -5px;
  text-align: left;

  @media (max-width: 768px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 300px) {
    max-width: 122px;
  }
`;

export const LabelDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : 'auto')};
`;

export const Label = styled.label`
  font-size: 1rem;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  margin-bottom: 1rem;
`;

export const EyeIcon = styled(EyeOutlined)`
  width: 20px;
  font-size: 1.2rem;
  height: 20px;
  display: flex;
  color: #ffffff;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const EyeSlashIcon = styled(EyeInvisibleOutlined)`
  width: 20px;
  font-size: 1.2rem;
  height: 20px;
  display: flex;
  color: #ffffff;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const OuterWrongImage = styled.img`
  width: 20px;
  height: auto;
  display: flex;
  position: absolute;
  top: 10px;
  right: -30px;
`;

export const OuterRightImage = styled.img`
  width: 15px;
  height: auto;
  display: flex;
  position: absolute;
  top: 10px;
  right: -30px;
`;

export const CloseDynamicField = styled.div`
  width: 43px;
  height: 33px;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #1c1c24;
  border-radius: 5px;
  color: #ffffff5c;
  border: 1px solid #ffffff5c;
  cursor:pointer;
  :hover{
    color: #fff;
  }
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};
  > div > div > input {
    border: ${props => (props.isBorder ? '1px solid #fc5a5a' : '')};
  }
`;

export const StyledSpan = styled.span`
  color: #fc5a5a;
  font-size: 1rem;
  margin-left: 0.5rem;
`;
