import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Formik, ErrorMessage, Form } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import SPButton from '../../../../../components/SPButton';
import SPUpload from '../../../../../components/SPUpload';
import SPMessage from '../../../../../components/SPMessage';
import { updateLicense } from '../../../../../actions/apps';
import {
  FormContainer,
  FormDiv,
  Query,
  QueryDiv
} from './StyledComponents';

export const ErrorText = styled.div`
   color:#fc5a5a;
   font-size:13px;
   text-align:left;
   font-weight:700;
   margin-left:10px;
   margin-bottom:20px;
   margin-top:10px;
`;

const LicenseUpdateForm = ({ licenseInfo, onUpdateLicense }) => {

  const history = useHistory();
  const validationSchema = Yup.object({
    upload_license: Yup.mixed().required("Required")
  });

  useEffect(() => {
    const organization = localStorage.getItem('organization');
    if (!organization) {
      history.push('/login');
    }
  }, [])

  return (
    <FormContainer>
      <br />
      <FormDiv>
        <Formik
          id="formik"
          validationSchema={validationSchema}
          initialValues={{
            upload_license: undefined
          }}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            const FormData = require('form-data');
            const data = new FormData();
            const organizationId = localStorage.getItem('organization');
            data.append('upload', values.upload_license);
            data.append('organization', organizationId);
            onUpdateLicense(data);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Form>
                {
                  !licenseInfo?.data &&
                  <SPMessage
                    message='Your license has been expired, please update your license.'
                    type='info'
                  />
                }
                <SPUpload
                  width={270}
                  id="upload_license"
                  label="Upload License"
                  name="upload_license"
                  touched={touched.upload_license}
                  value={values.upload_license}
                  errorMessage={errors.upload_license}
                  onInputChange={(name, file) => {
                    setFieldValue(name, file);
                  }}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
                <ErrorMessage name="upload_license" render={msg => <ErrorText>{msg}</ErrorText>} />
                <SPButton
                  title={"Update"}
                  size="small"
                  type="submit"
                  onButtonClick={handleSubmit}
                />
                <Link to='/login' replace>
                  <QueryDiv>
                    <Query> Back to login </Query>
                  </QueryDiv>
                </Link>

              </Form>
            )
          }}
        </Formik>
      </FormDiv>
    </FormContainer>
  );
};

const mapStateToProps = state => {
  return {
    licenseInfo: state.appStore.licenseInfo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateLicense: data => {
      return dispatch(updateLicense(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseUpdateForm);
