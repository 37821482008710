import axios from 'axios';
import API from '../config/endpoints.config';

export async function dashboardList(payload) {
    let api;
    api = `${API.mitreDashboard}`;
    if (payload) {
        api += `?${payload}`;
    }
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function mitreWidgetsList(payload) {
    let api;
    api = `${API.mitreDashboard}/asset-view`;
    if (payload) {
        api += `?${payload}`;
    }
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function mitreDetail(id) {
    let api;
    api = `${API.mitreDashboard}/${id}`;
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}