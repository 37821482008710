import axios from 'axios';
import API from '../config/endpoints.config';

export async function getReportsListApi(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    const expData = 'expand=';
    if (payload.queryItem) {
        api = `${API.rportsModule.reportsList}?${expData}&${payload.queryItem}`;
    } else {
        api = `${API.rportsModule.reportsLis}?${expData}`;
    }
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function deleteReportAPI(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.delete(`${API.rportsModule.reportsList}/report/id=${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function duplicateReportAPI(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.rportsModule.reportsList}/report/id=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
