import styled from 'styled-components';
import { Select } from 'antd';
import { Colors } from '../../theme';
import SelectMulti from "react-draggable-multi-select";

export const TextInputWrapper = styled.div`
  width: ${props => (props.width ? props.width + 'px' : '100%')};
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.noMargin ? '0px' : '1rem')};
  justify-content: center;
  flex-direction: column;
  .ant-select-selection-search-input {
    padding-left: 0 !important;
  }
`;

export const TextInputDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: max-content;
  justify-content: center;
  background: #1c1c24;
  border-radius: 5px;
`;
export const SelectMultiBox = styled(SelectMulti)`

.css-yk16xz-control {
  font-size: 1rem;
  width: 300px !important;
  flex-wrap: flex;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #ffffff5c;
  color: white;
  outline: none;
  min-height: 33px;
  height: ${props =>
    props.mode === 'tags' || props.mode === 'multiple'
      ? 'max-content'
      : '33px'};
  max-width: ${props => (props.width ? props.width + 'px' : '100%')};
  background-color:green;
}
.css-2b097c-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 300px !important;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: orange;
  border-radius: 5px;
}
.css-1laao21-allyText{
  background:green;
  width: 300px !important;
}
.sc-jjjYTi .eWgyLb .css-2b097c-container{
  background-color:pink;
}
.mult{
  background-color:blue !important;
}
`;

export const SelectInput = styled(Select)`
  font-size: 1rem;
  width: 100%;
  flex-wrap: flex;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #ffffff5c;
  color: white;
  outline: none;
  min-height: 33px;
  height: ${props =>
    props.mode === 'tags' || props.mode === 'multiple'
      ? 'max-content'
      : '33px'};
  max-width: ${props => (props.width ? props.width + 'px' : '100%')};

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    font-size: 1rem;
  }
  .ant-select-arrow {
    color: white;
  }
  .ant-select-clear {
    color: #fff;
    background: #000;
  }
`;
export const ErrorDiv = styled.p`
  color: #fc5a5a;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 3px;
  margin-top: -5px;
  text-align: left;

  @media (max-width: 768px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 300px) {
    max-width: 122px;
  }
`;

export const LabelDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  .helper-text{
    margin-left: 5px;
    margin-top: 4px;
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  color: #ffffff;
  color: ${props => (props.isPreviewReport ? '#000000' : '#ffffff')};
  font-weight: 700;
  ${({ fontNormal }) =>
    fontNormal &&
    `;
  font-weight: normal;
`}
  display: flex;
  margin-bottom: 1rem;
`;

export const OuterWrongImage = styled.img`
  width: 20px;
  height: auto;
  display: flex;
  position: absolute;
  top: 10px;
  right: -30px;
`;

export const OuterRightImage = styled.img`
  width: 15px;
  height: auto;
  display: flex;
  position: absolute;
  top: 10px;
  right: -30px;
`;

export const StyledPlusIcon = styled.div`
  border-radius: 3px;
  background-color: #2c2c38;
  color: ${Colors.primaryWhite};
  border: 1.5px solid ${Colors.primaryWhite};
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

export const StyledDiv = styled.div`
  .separatedGroupOption{
    padding-left: 12px;
    padding-right: 12px;
  }
  .ant-select-item-group{
    min-height: unset; 
    hr{
      margin: 0.5rem auto;
    }
  }
  display: flex;
  flex-direction: column;
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};
  padding: ${props => (props.padding ? props.padding + 'px' : 'unset')};
  > div > div > div {
    border: ${props => (props.isBorder ? '1px solid #fc5a5a' : '')};
  }
`;

export const StyledSpan = styled.span`
  color: #fc5a5a;
  margin-left: 0.5rem;
`;
