import React, { useEffect } from 'react';
import LoginBox from '../../../components/LoginBox';
import UserConfirmationForm from './components/Form';

const UserConfirmation = () => {
  useEffect(() => {
    document.title = 'User Confirmation';
  }, []);
  return (
    <LoginBox>
      <UserConfirmationForm />
    </LoginBox>
  );
};

export default UserConfirmation;
