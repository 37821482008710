import {
  ASSET_LIST_REQUESTED,
  ASSET_DETAILS_REQUESTED,
  IMPORT_ASSET_REQUESTED,
  IMPORT_ASSET_LOGS_REQUESTED,
  ASSET_CREATE_REQUESTED,
  ASSET_DELETE_REQUESTED,
  ASSET_EDIT_REQUESTED,
  ASSET_CLASSIFICATION_LIST_REQUESTED,
  RESET_ASSET_CLASSIFICATION_REQUESTED,
  ASSET_CONFIDENTIALITY_LIST_REQUESTED,
  ASSET_AVAILABILITY_LIST_REQUESTED,
  ASSET_INTEGRITY_LIST_REQUESTED,
  ASSET_OS_LIST_REQUESTED,
  ASSET_TYPE_BY_ID_REQUESTED,
  ASSET_DASHBOARD_REQUESTED,
  ASSET_CATEGORIES_LIST_REQUESTED,
  ASSET_ENABLE_FIELDS_LIST_REQUESTED,
  ASSET_TYPES_LIST_REQUESTED,
  ASSET_CATEGORY_FIELDS_REQUESTED,
  ASSET_TEMPLATE_LIST_REQUESTED,
  ASSET_TEMPLATE_CREATE_REQUESTED,
  ASSET_IMPORT_PROCESS_REQUESTED,
  ASSET_TEMPLATE_DRAFT_REQUESTED,
  ASSET_IMPORT_CLEAR_REQUESTED,
  ASSET_IMPORT_PROCESS_CLEAR_REQUESTED,
  ASSET_BULK_DELETE_REQUESTED,
  ASSET_IMPORTED_JOB_DELETE_REQUESTED,
  EXPORT_ASSET_LOGS_REQUESTED,
  ASSET_EXPORT_JOB_DELETE_REQUESTED
} from '../constants/actionTypes';



export function listAssets(page = 1, parameter = '', perPage = 20, sort = '') {
  return {
    type: ASSET_LIST_REQUESTED,
    payload: { page, parameter, 'per-page': perPage, sort: sort },
  };
}

export function singleAsset(id, isExpand) {
  return {
    type: ASSET_DETAILS_REQUESTED,
    id,
    isExpand
  };
}

export function importAsset(file) {
  return {
    type: IMPORT_ASSET_REQUESTED,
    payload: file,
  };
}
export function listImportJobs(page = 1, parameter = '', perPage = 20, sort = '') {
  return {
    type: IMPORT_ASSET_LOGS_REQUESTED,
    payload: { page, parameter, 'per-page': perPage, sort: sort },
  };
}
export function listExportJobs(page = 1, parameter = '', perPage = 20, sort = '',module){
  return {
    type: EXPORT_ASSET_LOGS_REQUESTED,
    payload: {page, parameter, 'per-page': perPage, sort: sort, module}
  }
}
export function createAsset(payload, callback) {
  return {
    type: ASSET_CREATE_REQUESTED,
    payload,
    callback,
  };
}
export function editAsset(payload, callback) {
  return {
    type: ASSET_EDIT_REQUESTED,
    payload,
    callback
  };
}

export function deleteAsset(id, page = 1, parameter = '', perPage = 20) {
  return {
    type: ASSET_DELETE_REQUESTED,
    payload: { id, page, parameter, perPage },
  };
}

export function listAssetClassification() {
  return {
    type: ASSET_CLASSIFICATION_LIST_REQUESTED,
  };
}

export function resetAssetClassification() {
  return {
    type: RESET_ASSET_CLASSIFICATION_REQUESTED,
  };
}

export function listAssetConfidentiality() {
  return {
    type: ASSET_CONFIDENTIALITY_LIST_REQUESTED,
  };
}

export function listAssetAvailability() {
  return {
    type: ASSET_AVAILABILITY_LIST_REQUESTED,
  };
}

export function listAssetIntegrity() {
  return {
    type: ASSET_INTEGRITY_LIST_REQUESTED,
  };
}

export function listAssetOS() {
  return {
    type: ASSET_OS_LIST_REQUESTED,
  };
}

export function getAssetTypeById(id) {

  return {
    type: ASSET_TYPE_BY_ID_REQUESTED,
    id,
  };
}

export function assetDashboard(id) {
  return {
    type: ASSET_DASHBOARD_REQUESTED,
    id,
  };
}

export function listAssetCategories() {
  return {
    type: ASSET_CATEGORIES_LIST_REQUESTED,
  };
}
export function listAssetEnableFields() {
  return {
    type: ASSET_ENABLE_FIELDS_LIST_REQUESTED,
  };
}

export function listAssetTypes(pagination) {
  return {
    type: ASSET_TYPES_LIST_REQUESTED,
    payload: pagination,
  };
}

export function getCategoryFields(field) {
  return {
    type: ASSET_CATEGORY_FIELDS_REQUESTED,
    field
  };
}

export function getAssetTemplates() {
  return {
    type: ASSET_TEMPLATE_LIST_REQUESTED
  }
}

export function createTemplates(payload) {
  return {
    type: ASSET_TEMPLATE_CREATE_REQUESTED,
    payload
  }
}

export function assetDraftTemplate(payload) {
  return {
    type: ASSET_TEMPLATE_DRAFT_REQUESTED,
    payload
  }
}

export function assetImportProcess(payload) {
  return {
    type: ASSET_IMPORT_PROCESS_REQUESTED,
    payload
  }
}


export function clearImportProcess() {
  return {
    type: ASSET_IMPORT_PROCESS_CLEAR_REQUESTED
  }
}

export function clearImport() {
  return {
    type: ASSET_IMPORT_CLEAR_REQUESTED
  }
}

export function bulkDeleteAction(payload, page = 1, parameter = '', perPage = 20) {
  return {
    type: ASSET_BULK_DELETE_REQUESTED,
    payload,
    pageinfo: { page, parameter, perPage }
  };
}

export function deleteImportJob(payload, page = 1, parameter = '', perPage = 20) {
  return {
    type: ASSET_IMPORTED_JOB_DELETE_REQUESTED,
    payload,
    pageinfo: { page, parameter, perPage }
  };
}

export function deleteExportJob (payload){
  return {
    type: ASSET_EXPORT_JOB_DELETE_REQUESTED,
    payload,
  };
}