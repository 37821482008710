import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SPButton from '../../../../../components/SPButton';
import InputBox from '../../../../../components/InputBox';
import { changePassword } from '../../../../../actions/auth';
import {
    FormContainer,
    FormDiv,
    ButtonContainer,
    MyForm,
    StyledText
} from '../../../ResetPassword/components/Form/StyledComponents';

const Form = ({ changePasswordAction }) => {

    const history = useHistory();
    const { id } = useParams();

    const formik = useFormik({
        initialValues: {
            password: '',
            passconf: ''
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .required('Required'),
            password: Yup.string()
                .test('not-same', 'New password cannot be same as old password', function (value) {
                    return value !== this.parent.oldPassword;
                })
                .required('Required')
                .min(8, 'Min 8 characters required')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=,<>?/"':;|{}[\].\\`~])[A-Za-z\d!@#$%^&*()_+\-=,<>?/"':;|{}[\].\\`~]{8,}$/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
                ),
            passconf: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'New password did not match. Try again')
        }),
        onSubmit: values => {
            const dataItem = {
                old_password: values.oldPassword,
                new_password: values.password,
                passconf: values.passconf,
                userId: id
            }
            changePasswordAction(dataItem, history)
            return values;
        },
    });

    return (
        <FormContainer>
            <StyledText>
                Note: Your password has expired. Please use this form to reset your password and login.
            </StyledText>
            <MyForm onSubmit={formik.handleSubmit}>
                <FormDiv>
                    <InputBox
                        id="oldPassword"
                        label="Old Password"
                        name="oldPassword"
                        type='password'
                        placeholder="Old Password"
                        onInputChange={formik.handleChange}
                        value={formik.values.oldPassword}
                        errorMessage={formik.errors.oldPassword}
                        touched={formik.touched.oldPassword}
                    />
                    <InputBox
                        id='password'
                        label='Password'
                        type='password'
                        name='password'
                        placeholder='New Password'
                        onInputChange={formik.handleChange}
                        value={formik.values.password}
                        errorMessage={formik.errors.password}
                        touched={formik.touched.password}
                    />
                    <InputBox
                        id='passconf'
                        label='Confirm Password'
                        type='password'
                        name='passconf'
                        placeholder='Confirm Password'
                        onInputChange={formik.handleChange}
                        value={formik.values.passconf}
                        errorMessage={formik.errors.passconf}
                        touched={formik.touched.passconf}
                    />
                </FormDiv>

                <ButtonContainer>
                    <SPButton
                        title='Change Password'
                        htmlType='submit'
                    />
                </ButtonContainer>
            </MyForm>
        </FormContainer>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        changePasswordAction: (data, history) => {
            return dispatch(changePassword(data, history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
