import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    REPORT_MANAGEMENT_LIST_REQUESTED,
    REPORT_MANAGEMENT_LIST_SUCCESSED,
    REPORT_MANAGEMENT_LIST_FAILED,
    REPORT_DELETION_REQUESTED,
    REPORT_DUPLICATION_REQUESTED,
    SHOW_LOADER,
    HIDE_LOADER,
    SET_FEEDBACK_ALERT,
    SCHEDULE_LIST_REQUESTED,
    SCHEDULE_LIST_SUCCESSED,
    SCHEDULE_LIST_FAILED,
    COVER_IMAGES_REQUESTED,
    COVER_IMAGES_SUCCESSED,
    COVER_IMAGES_FAILED,
    GET_WIDGET_REQUESTED,
    GET_WIDGET_SUCCESSED,
    GET_WIDGET_FAILED,
    GET_REPORT_BY_ID_REQUESTED,
    GET_REPORT_BY_ID_FAILED,
    CREATE_REPORT_REQUESTED,
    CREATE_EXCEL_REPORT_REQUESTED,
    CREATE_SCHEDULE_REQUESTED,
    WIDGET_LIST_REQUESTED,
    WIDGET_LIST_SUCCESSED,
    WIDGET_LIST_FAILED,
    REPORTS_GET_WIDGET_DATA_REQUESTED,
    REPORTS_GET_WIDGET_DATA_FAILED,
    SCHEDULER_DELETION_REQUESTED,
    GENERATE_PDF_REPORT_REQUESTED
} from '../../constants/actionTypes';
import {
    reportlistSaga,
    widgetListSaga,
    reportDeletionSaga,
    reportDuplicationSaga,
    schedulelistSaga,
    coverImageslistSaga,
    widgetDataSaga,
    getReportByIdSaga,
    reportCreationSaga,
    excelReportCreationSaga,
    reportScheduleCreationSaga,
    scheduleDeletionSaga,
    reportGenerationSaga,
    getAllReportWidgets
} from '../../api/reportManagementSaga';
import { getWidgetByIdAPI } from '../../api/dashboardAPI';
import { onWidgetsLayoutChange, onSaveSelectedWidget, onUpdateReportData } from '../../actions/reportManagement';
import { showToastError, showToastLoader, showToastSuccess, dismissToastLoader } from '../../utils/toasts';
import API from '../../config/endpoints.config';
import { getGraphDataSet, getLineWidgetDataSet } from '../../utils/helper.utils';
import { graphColors } from '../../constants';

const moduleName = 'reportManagement';

export const getWidget = (state) => state.reportManagementStore?.widgetsList;
export const getReportWidgets = (state) => state.reportManagementStore?.reportWidgets;

export function* watchReportListing(action) {
    // Listing Reports function
    try {
        const response = yield call(reportlistSaga, action?.payload);
        if (response.success) {
            yield put({ type: REPORT_MANAGEMENT_LIST_SUCCESSED, data: response.data });
        } else {
            yield put({ type: REPORT_MANAGEMENT_LIST_FAILED, data: null });
        }
    } catch (err) {
        yield put({
            type: REPORT_MANAGEMENT_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// Listing Widgets function
export function* watchWidgetListing(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(widgetListSaga, action?.payload);
        if (response.success) {
            yield put({ type: WIDGET_LIST_SUCCESSED, data: response.data });
        } else {
            yield put({ type: WIDGET_LIST_FAILED, data: null });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({ type: HIDE_LOADER });

        yield put({
            type: WIDGET_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// Deleting Report function
export function* watchReportDeletion(action) {
    
    const loaderHandle = showToastLoader(action?.fromSavedSearch?'Deleting search':'Deleting report...');
    try {
        const deleteReportResponse = yield call(reportDeletionSaga, action?.payload);
        if (deleteReportResponse.success) {
            const response = yield call(reportlistSaga, action?.payload);
            if (response.success) {
                yield put({ type: REPORT_MANAGEMENT_LIST_SUCCESSED, data: response.data });
            } else {
                yield put({ type: REPORT_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (deleteReportResponse.success) {
            showToastSuccess(`${action?.fromSavedSearch?'Search deleted':deleteReportResponse?.data?.message}`, loaderHandle);
        } else {
            showToastError(deleteReportResponse?.data?.message, loaderHandle);
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle);
    }
}

// Duplicating Report function
export function* watchReportDuplication(action) {
    const loaderHandle = showToastLoader('Duplicating report...');
    try {
        const duplicateWidgetResponse = yield call(reportDuplicationSaga, action?.payload);
        if (duplicateWidgetResponse.success) {
            const response = yield call(reportlistSaga, action?.payload);
            if (response.success) {
                yield put({ type: REPORT_MANAGEMENT_LIST_SUCCESSED, data: response.data });
            } else {
                yield put({ type: REPORT_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (duplicateWidgetResponse.success) {
            showToastSuccess(duplicateWidgetResponse?.data?.message, loaderHandle)
        } else {
            showToastError(duplicateWidgetResponse?.data?.message, loaderHandle)
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle)
    }
}

// Listing Schedules function
export function* watchScheduleListing(action) {
    try {
        const response = yield call(schedulelistSaga, action?.payload);
        if (response.success) {
            yield put({ type: SCHEDULE_LIST_SUCCESSED, data: response.data });
        } else {
            yield put({ type: SCHEDULE_LIST_FAILED, data: [] });
        }
    } catch (err) {
        yield put({
            type: SCHEDULE_LIST_FAILED,
            data: [],
        });
    }
}

// Listing Cover Images function
export function* watchCoverImagesListing(action) {
    try {
        const response = yield call(coverImageslistSaga, action?.payload);
        if (response.success) {
            yield put({ type: COVER_IMAGES_SUCCESSED, data: response.data });
        } else {
            yield put({ type: COVER_IMAGES_FAILED, data: null });
            yield put({
                type: SET_FEEDBACK_ALERT,
                payload: {
                    feedbackMessage: response?.data?.message,
                    feedbackType: 'error',
                    module: moduleName,
                },
            });
        }
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: err?.response?.data?.data?.message,
                feedbackType: 'error',
                module: moduleName,
            },
        });
        yield put({
            type: COVER_IMAGES_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// Getting Widget By ID function
export function* watchWidgetById(action) {
    try {
        yield put({ type: SHOW_LOADER });
        const response = yield call(widgetDataSaga, action?.payload);

        // Setting Widgets data to render all widgets

        const graphDataSet = getGraphDataSet(action?.payload?.title || '', action?.payload?.type, response?.data?.result);

        const widgets = {
            [action?.payload?.id]: {
                data: graphDataSet,
                dashboardWidgetId: null,
                widgetTitle: action?.payload?.title,
                type: action?.payload?.type
            }
        };

        if (response.success) {
            yield put({ type: GET_WIDGET_SUCCESSED, data: widgets });
        } else {
            yield put({ type: GET_WIDGET_FAILED, data: null });
        }
        yield put({ type: HIDE_LOADER });
    } catch (err) {
        yield put({ type: HIDE_LOADER });
        yield put({
            type: GET_WIDGET_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// Getting Report By ID function
export function* watchGetReportById(action) {
    const loaderHandle = showToastLoader('Fetching widgets...');
    try {
        const response = yield call(getReportByIdSaga, action.payload);

        const getGraphData = (data, type, widgetCategory) => {
            let graphData = [];
            const graphs = ['column', 'radar'];
            if (graphs.includes(type) && widgetCategory == 1) {
                graphData = [{
                    data: [{
                        name: 'Custom Widget',
                        data: data[0]?.data.map(v => Number(v)),
                        backgroundColor: graphColors[5]
                    }],
                    label: data[0]?.label
                }, { Graph_type: type }];
            } else {
                graphData = data;
            }
            return graphData
        }

        if (response.success) {
            const tempResponse = JSON.parse(response?.data?.report_layout_data);
            const dateRanges = response?.data?.report_date_range?.split(',');
            const endPoints = tempResponse?.map(widget => `${API.baseUrl}/get-widget-data?id=${widget.i}&start=${dateRanges[0]}&end=${dateRanges[1]}`)
            const widgetResponse = yield call(getAllReportWidgets, { urls: endPoints, cancelToken: action?.payload?.cancelToken });
            let tempResultWidgets = {};
            for (let i = 0; i < tempResponse?.length; i++) {

                const graphValues = {
                    widgetTitle: widgetResponse[i][1]?.gra_title || '',
                    type: widgetResponse[i][1]?.gra_widget_type,
                    data: (widgetResponse[i][1]?.gra_custom == 1 && widgetResponse[i][1]?.gra_widget_type == 'line') ? getLineGraphData(widgetResponse[i][0]) : getGraphData(widgetResponse[i][0], widgetResponse[i][1]?.gra_widget_type, widgetResponse[i][1]?.gra_custom == 1),
                    dashboardWidgetId: null
                };

                tempResultWidgets[tempResponse[i].i] = {
                    type: widgetResponse[i][1]?.gra_widget_type,
                    data: graphValues,
                    dashboardWidgetId: null,
                    widgetTitle: widgetResponse[i][1]?.gra_title,
                };
            }
            const reportData = JSON.parse(response?.data?.report_data);
            yield put(onUpdateReportData(
                {
                    ...reportData,
                    report_logo: `${response?.data?.report_logo}`,
                    report_name: response?.data?.report_name,
                    report_type: response?.data?.report_type,
                    report_coverpage_id: response?.data?.report_coverpage_id,
                    report_date_range: response?.data?.report_date_range,
                    rp_status: response?.data?.rp_status,
                }));
            yield put(onWidgetsLayoutChange(tempResponse));
            yield put(onSaveSelectedWidget(tempResultWidgets));
        } else {
            yield put({ type: GET_REPORT_BY_ID_FAILED });
        }
        dismissToastLoader(loaderHandle);
    } catch (err) {
        if (err?.response?.status === 404) {
            action.history.push('/reports');
        } else {
            yield put({ type: GET_REPORT_BY_ID_FAILED });
        }
    } finally {
        dismissToastLoader(loaderHandle);
    }
}

// Create Custom Report
export function* watchCreateReport(action) {
    const loaderHandle = showToastLoader(action?.payload?.saveAsDraft ? 'Saving report as draft...' : action?.payload?.id ? 'Updating report...' : 'Creating report...');
    try {
        const { history, setDisabledState, ...reportPayload } = action?.payload;
        const createReportResponse = yield call(reportCreationSaga, reportPayload);
        if (createReportResponse.success) {
            const response = yield call(reportlistSaga);
            if (response.success) {
                yield put({ type: REPORT_MANAGEMENT_LIST_SUCCESSED, data: response.data });
                history.push('/reports'); // redirecting to reports route to display the updated records
            } else {
                yield put({ type: REPORT_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (createReportResponse.success) {
            showToastSuccess(action?.payload?.saveAsDraft ? 'Report saved as draft' : action?.payload?.id ? 'Report updated' : 'Report created', loaderHandle)
        } else {
            setDisabledState();
            showToastError(createReportResponse?.data?.message, loaderHandle)
        }
    } catch (err) {
        setDisabledState();
        showToastError(err?.response?.data?.data[0], loaderHandle)
    }
}

// Create Custom Excel Report
export function* watchCreateExcelReport(action) {
    const loaderHandle = showToastLoader('Saving searches...');
    try {
        const { history, ...reportPayload } = action?.payload;
        const createReportResponse = yield call(excelReportCreationSaga, reportPayload);
        if (createReportResponse.success) {
            if (createReportResponse.success) {
                yield put({ type: REPORT_MANAGEMENT_LIST_SUCCESSED, data: createReportResponse.data });
            } else {
                yield put({ type: REPORT_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (createReportResponse.success) {
            showToastSuccess('Search saved', loaderHandle)
        } else {
            showToastError(createReportResponse?.data?.message, loaderHandle)
        }
    } catch (err) {
        showToastError(err?.response?.data?.data[0], loaderHandle)
    }
}

// Create Generate Report
export function* watchGenerateReport(action) {
    const loaderHandle = showToastLoader('Generating report...');
    try {
        const response = yield call(reportGenerationSaga, action.payload);
        if (response) {
            showToastSuccess('Report generated', loaderHandle)
        } else {
            showToastError(response?.data?.message, loaderHandle)
        }
    } catch (err) {
        showToastError(err?.response?.data?.data[0], loaderHandle)
    }
}

// Create Report Scheduler
export function* watchCreateSchedule(action) {
    const loaderHandle = showToastLoader(action?.payload?.scheduleId ? 'Updating scheduler...' : 'Creating scheduler...');
    try {
        const createScheduleResponse = yield call(reportScheduleCreationSaga, action.payload);
        if (createScheduleResponse.success) {
            const response = yield call(schedulelistSaga, action?.payload?.reportId);
            if (response.success) {
                yield put({ type: SCHEDULE_LIST_SUCCESSED, data: response.data });
            } else {
                yield put({ type: SCHEDULE_LIST_FAILED, data: null });
            }
        }
        if (createScheduleResponse.success) {
            showToastSuccess(createScheduleResponse?.data?.message, loaderHandle)
        } else {
            showToastError(createScheduleResponse?.data?.message, loaderHandle)
        }
    } catch (err) {
        showToastError(err?.response?.data?.data[0], loaderHandle)
    }
}

// Deleting Scheduler function
export function* watchSchedulerDeletion(action) {
    const loaderHandle = showToastLoader('Deleting scheduler...');
    try {
        const deleteScheduleResponse = yield call(scheduleDeletionSaga, action?.payload?.id);
        if (deleteScheduleResponse.success) {
            showToastSuccess(deleteScheduleResponse?.data?.message, loaderHandle);
            try {
                const response = yield call(schedulelistSaga, action?.payload?.reportId);
                if (response.success) {
                    yield put({ type: SCHEDULE_LIST_SUCCESSED, data: response.data });
                } else {
                    yield put({ type: SCHEDULE_LIST_FAILED, data: [] });
                }
            }
            catch (err) {
                yield put({ type: SCHEDULE_LIST_FAILED, data: [] });
            }
        } else {
            showToastError(deleteScheduleResponse?.data?.message, loaderHandle)
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle)
    }
}

const getLineGraphData = (response) => {
    const tempData = response && response[0]?.data?.map((item, index) => {
        let multiLineArr = response[0]?.data?.map(v => 0);
        multiLineArr[index] = Number(item.data);
        return {
            ...item,
            data: multiLineArr
        }
    })
    let tempResponse = response ? [...response] : [];
    if (tempResponse?.length) {
        tempResponse[0].data = tempData;
    }
    return tempResponse;
}

export function* watchDashboardWidgetGraphData(action) {
    let loaderHandle = '';
    if (action?.payload?.showWidgetLoading) {
        loaderHandle = showToastLoader('Fetching widget...');
    }
    try {
        const response = yield call(getWidgetByIdAPI, action.payload);
        const reportWidgets = yield select(getReportWidgets);

        if (response.success) {

            const getGraphData = (data, type, widgetCategory) => {
                let graphData = [];
                const graphs = ['column', 'radar'];
                if (graphs.includes(type) && widgetCategory == 1) {
                    graphData = [{
                        data: [{
                            name: 'Custom Widget',
                            data: data[0]?.data.map(v => Number(v)),
                            backgroundColor: graphColors[5]
                        }],
                        label: data[0]?.label
                    }, { Graph_type: type }];
                } else {
                    graphData = data;
                }
                return graphData
            }

            const graphValues = {
                widgetTitle: action?.payload?.title || '',
                type: action?.payload?.type,
                data: (response?.data[1]?.gra_custom == 1 && response?.data[1]?.gra_widget_type == 'line') ? getLineGraphData(response?.data[0]) : getGraphData(response?.data[0], action.payload.type, response?.data[1]?.gra_custom == 1),
                dashboardWidgetId: null
            };

            if (action.payload.isEditCase) {
                reportWidgets[String(action.payload.id)] = {
                    type: action.payload.type,
                    data: graphValues,
                    dashboardWidgetId: null,
                    widgetTitle: action.payload.title
                };
            } else {
                const selectedWidget = {
                    [String(action.payload.id)]: {
                        type: action.payload.type,
                        data: graphValues,
                        dashboardWidgetId: null,
                        widgetTitle: action.payload.title
                    },
                };
                yield put({ type: GET_WIDGET_SUCCESSED, data: selectedWidget });
            }
        } else {
            yield put({
                type: SET_FEEDBACK_ALERT,
                payload: {
                    feedbackMessage: createReportResponse?.data?.message,
                    feedbackType: 'error',
                    module: moduleName,
                },
            });
            yield put({ type: REPORTS_GET_WIDGET_DATA_FAILED });
        }
        if (loaderHandle) dismissToastLoader(loaderHandle);
    } catch (err) {
        yield put({
            type: SET_FEEDBACK_ALERT,
            payload: {
                feedbackMessage: err?.response?.data?.data[0],
                feedbackType: 'error',
                module: moduleName,
            },
        });
        yield put({ type: REPORTS_GET_WIDGET_DATA_FAILED });
    } finally {
        dismissToastLoader(loaderHandle);
    }
}

export default function* watcher() {
    yield takeLatest(REPORT_MANAGEMENT_LIST_REQUESTED, watchReportListing);
    yield takeLatest(WIDGET_LIST_REQUESTED, watchWidgetListing);
    yield takeLatest(REPORT_DELETION_REQUESTED, watchReportDeletion);
    yield takeLatest(SCHEDULER_DELETION_REQUESTED, watchSchedulerDeletion);
    yield takeLatest(REPORT_DUPLICATION_REQUESTED, watchReportDuplication);
    yield takeLatest(SCHEDULE_LIST_REQUESTED, watchScheduleListing);
    yield takeLatest(COVER_IMAGES_REQUESTED, watchCoverImagesListing);
    yield takeLatest(GET_WIDGET_REQUESTED, watchWidgetById);
    yield takeLatest(GET_REPORT_BY_ID_REQUESTED, watchGetReportById);
    yield takeLatest(CREATE_REPORT_REQUESTED, watchCreateReport);
    yield takeLatest(CREATE_EXCEL_REPORT_REQUESTED, watchCreateExcelReport);
    yield takeLatest(GENERATE_PDF_REPORT_REQUESTED, watchGenerateReport);
    yield takeLatest(CREATE_SCHEDULE_REQUESTED, watchCreateSchedule);
    yield takeLatest(REPORTS_GET_WIDGET_DATA_REQUESTED, watchDashboardWidgetGraphData);
}
