import {
    INCIDENT_SEARCH_LIST_REQUESTED,
    INCIDENT_SEARCH_LIST_PROCESSING,
    INCIDENT_SEARCH_LIST_SUCCESSED,
    INCIDENT_SEARCH_LIST_FAILED,
    INCIDENT_SEARCH_LIST_RESET,
  } from '../../constants/actionTypes';
   
  const initialState = {
    listData: {},
    isProcessing: true,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const incidentSearchStore = (state = initialState, action) => {
    switch (action.type) {
      case INCIDENT_SEARCH_LIST_REQUESTED: {
        return {
          ...state,
          listData: null,
          hasErrors: null,
          isProcessing: true,
          isSuccess: null,
        };
      }
      case INCIDENT_SEARCH_LIST_PROCESSING: {
        return {
          ...state,
          listData: null,
          hasErrors: null,
          isProcessing: true,
          isSuccess: null,
        };
      }
      case INCIDENT_SEARCH_LIST_SUCCESSED: {
        return {
          listData: action.data,
          hasErrors: null,
          isProcessing: false,
          isSuccess: true,
        };
      }
      case INCIDENT_SEARCH_LIST_FAILED: {
        return {
          listData: action.data,
          hasErrors: true,
          isProcessing: false,
          isSuccess: null,
        };
      }
      case INCIDENT_SEARCH_LIST_RESET: {
        return {
          listData: {},
          hasErrors: null,
          isProcessing: false,
          isSuccess: true,
        };
        
     
      }
      default: {
        return {
          ...state,
        };
      }
    }
  };
  
  export default incidentSearchStore;
  