import axios from 'axios';
import API from '../config/endpoints.config';
const FileDownload = require('js-file-download');

export async function riskAssementListAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');

    let expData = '';
    if(payload.queryItem){
      expData = `?expand=${payload.queryItem}`; // TODO: need to fix this later
    }
   const api = `${API.riskAssessmentModule.riskAssessmentList}/${payload.path}${expData}`;
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function riskassementupdateListAPI(id, payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = `${API.riskAssessmentModule.riskAssessmentList}/${id}`;
    const response = await axios.get(api, payload, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data
}
export async function riskassementMarkList(id, payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = `${API.riskAssessmentModule.riskAssessmentList}/${id}`;
    const response = await axios.get(api, payload, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function riskassementdeleteListAPI(id, payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = `${API.riskAssessmentModule.riskAssessmentList}/${id}`;
    const response = await axios.get(api, payload, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function templateListRiskAssessmentAPI() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.riskAssessmentModule.riskAssessmentList}/assessment/template-list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function generateReportRiskAssessmentAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.riskAssessmentModule.riskAssessmentList}/assessment/generate-report`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',

    });
    let ext = 'xlsx';
    if (response?.headers?.['content-type'] && response?.headers?.['content-type'].includes('pdf')) { ext = 'pdf' }
    FileDownload(response.data, `report.${ext}`);
    return response.data;
}

export async function importReportRiskAssessmentAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.riskAssessmentModule.riskAssessmentList}/import`, payload, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data
}

export async function exportPDF(queryItem) {
    const userToken = localStorage.getItem('AccessToken');

    const response = await axios.get(`${API.riskAssessmentModule.riskAssessmentList}/export-pdf?${queryItem}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',
    });
    FileDownload(response.data, 'export.pdf');
    return response.data;
}

export async function exportExcel(queryItem) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.riskAssessmentModule.riskAssessmentList}/export?${queryItem}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',
    });
    FileDownload(response.data, 'export.xlsx');
    return response.data;
}

export async function initiatorAPI() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.riskAssessmentModule.riskAssessmentList}/initial`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',
    });
    FileDownload(response.data, 'export.xlsx');
    return response.data;
}
export async function scopeAPI() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.riskAssessmentModule.riskAssessmentList}/scope-list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',
    });
    FileDownload(response.data, 'export.xlsx');
    return response.data;
}

// Risk Assessment Scope
export async function riskAssessmentScopeApi(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    const expData = '';
    if (payload.queryItem) {
        api = `${API.riskAssessmentModule.riskAssessmentList}?${expData}&${payload.queryItem}`;
    } else {
        api = `${API.riskAssessmentModule.riskAssessmentList}?${expData}`;
    }
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

// Risk Register

export async function riskRegisterAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    const expData = '';
    if (payload.queryItem) {
        api = `${API.riskAssessmentModule.riskRegister}?${expData}&${payload.queryItem}`;
    } else {
        api = `${API.riskAssessmentModule.riskRegister}?${expData}`;
    }
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

// Risk Register


