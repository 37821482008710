import {
  REPORT_MANAGEMENT_LIST_REQUESTED,
  REPORT_MANAGEMENT_LIST_SUCCESSED,
  REPORT_MANAGEMENT_LIST_FAILED,
  WIDGET_LIST_SUCCESSED,
  WIDGET_LIST_FAILED,
  SCHEDULE_LIST_REQUESTED,
  SCHEDULE_LIST_SUCCESSED,
  SCHEDULE_LIST_FAILED,
  COVER_IMAGES_REQUESTED,
  COVER_IMAGES_SUCCESSED,
  COVER_IMAGES_FAILED,
  GET_WIDGET_REQUESTED,
  GET_WIDGET_SUCCESSED,
  GET_WIDGET_FAILED,
  UPDATE_WIDGETS_LAYOUT_REQUESTED,
  SAVE_SELECTED_WIDGET_LIST,
  UPDATE_SELECTED_WIDGET_LIST,
  UPDATE_REPORT_DATA_REQUESTED,
  RESET_WIDGET_DATA_REQUESTED,
} from '../../constants/actionTypes';

const initialState = {
  listData: {},
  schedules: [],
  isProcessing: true,
  isSuccess: null,
  hasErrors: null,
  loading: false,
  tableLoading: false,
  coverImages: [],
  widgetsList: [],
  reportWidgets: {},
  widgetLocalState: {
    removeWidgetId: null,
    addedWidgetId: null,
    selectedWidgets: {},
    layoutData: [],
    reportData: {},
  },
};

export const reportManagementStore = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_MANAGEMENT_LIST_REQUESTED: {
      return {
        ...state,
        listData: null,
        tableLoading: true
      };
    }
    case REPORT_MANAGEMENT_LIST_SUCCESSED: {
      return {
        ...state,
        listData: action.data,
        tableLoading: false
      };
    }
    case REPORT_MANAGEMENT_LIST_FAILED: {
      return {
        ...state,
        listData: null,
        tableLoading: false
      };
    }
    case WIDGET_LIST_SUCCESSED: {
      return {
        ...state,
        widgetsList: action.data,
      };
    }
    case WIDGET_LIST_FAILED: {
      return {
        ...state,
        widgetsList: [],
      };
    }
    case SCHEDULE_LIST_REQUESTED: {
      return {
        ...state,
        schedules: null,
        tableLoading: true
      };
    }
    case SCHEDULE_LIST_SUCCESSED: {
      return {
        ...state,
        schedules: action.data,
        tableLoading: false
      };
    }
    case SCHEDULE_LIST_FAILED: {
      return {
        ...state,
        schedules: [],
        tableLoading: false
      };
    }
    case COVER_IMAGES_REQUESTED: {
      return {
        ...state,
        hasErrors: null,
        isSuccess: null,
        loading: true
      };
    }
    case COVER_IMAGES_SUCCESSED: {
      return {
        ...state,
        hasErrors: null,
        isProcessing: false,
        loading: false,
        coverImages: action.data,
      };
    }
    case COVER_IMAGES_FAILED: {
      return {
        ...state,
        listData: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
        loading: false
      };
    }
    case GET_WIDGET_REQUESTED: {
      return {
        ...state,
        hasErrors: null,
        isSuccess: null,
        loading: true
      };
    }
    case GET_WIDGET_SUCCESSED: {
      return {
        ...state,
        widgetLocalState: {
          ...state.widgetLocalState,
          selectedWidgets: {
            ...state.widgetLocalState.selectedWidgets,
            ...action.data,
          },
        },
      };
    }
    case GET_WIDGET_FAILED: {
      return {
        ...state,
        listData: null,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
        loading: false
      };
    }
    case UPDATE_WIDGETS_LAYOUT_REQUESTED: {
      return {
        ...state,
        widgetLocalState: {
          ...state.widgetLocalState,
          layoutData: action.payload,
        },
      };
    }
    case SAVE_SELECTED_WIDGET_LIST: {
      return {
        ...state,
        widgetLocalState: {
          ...state.widgetLocalState,
          selectedWidgets: {
            ...state.widgetLocalState.selectedWidgets,
            ...action.payload,
          },
        }
      }
    }
    case UPDATE_SELECTED_WIDGET_LIST: {
      return {
        ...state,
        widgetLocalState: {
          ...state.widgetLocalState,
          selectedWidgets: {
            ...action.payload,
          },
        }
      }
    }
    case UPDATE_REPORT_DATA_REQUESTED: {
      return {
        ...state,
        widgetLocalState: {
          ...state.widgetLocalState,
          reportData: action.payload,
        },
      };
    }
    case RESET_WIDGET_DATA_REQUESTED: {
      return {
        ...state,
        widgetLocalState: initialState.widgetLocalState,
        reportWidgets: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default reportManagementStore;
