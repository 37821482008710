import React from 'react'
import Logo from '../../../assets/images/logo.png';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';

export const StyledH1 = styled.h1`
   color: #FFF;
   font-size: 2.5rem;
   font-family: 'Lato', sans-serif;
   font-weight: bold;
`;

export const StyledH2 = styled.h2`
    color: #FFF;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
`;

export const StyledImageWrapper = styled.div`
    padding: 2rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`;

export const StyledContainer = styled.div`
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-image: url(images/bg.jpg);
    height: 100vh;
    padding: 1rem 3rem;
`;

export const StyledWrapper = styled.div`
    color: #FFF;
    padding: 1rem 3rem;
    margin: 0 auto;
    max-width: 800px;

    table {
        border-color: #FFFFFF;
        margin: 1rem 0;
    }
    table td {
        padding: 10px;
        color: #FFF;
        border: 2px solid #fff;
    }

`;

export default function PatchUpdate() {
    return (
        <StyledContainer>
            <StyledImageWrapper>
                <img src={Logo} width={200} />
            </StyledImageWrapper>
            <StyledWrapper>
                <StyledH1>Releases</StyledH1>
                <p>This is an archive of every release we’ve done that we have a record of.</p>
                <br />
                <StyledH2>Latest release</StyledH2>
                <table width="100%" border="1" bordercolor="#FFFFFF" cellspacing="0" cellpadding="0">
                    <tbody>
                        <tr>
                            <td>4.0.3</td>
                            <td>April, 02 2021</td>
                            <td><a href="https://staging.sirp.io/patches/4.0.3.zip">zip</a><br />
                                ( <a href="https://staging.sirp.io/patches/4.0.3.zip">md5</a> | <a href="https://staging.sirp.io/patches/4.0.3.zip">Sha1</a> )</td>
                        </tr>
                    </tbody>
                </table>
                <StyledH2>4.0.4</StyledH2>
                <table width="100%" border="1" bordercolor="#FFFFFF" cellspacing="0" cellpadding="0">
                    <tbody>
                        <tr>
                            <td>4.0.4</td>
                            <td>April, 02 2021</td>
                            <td><a href="https://staging.sirp.io/patches/4.0.4.zip">zip</a><br />
                                ( <a href="https://staging.sirp.io/patches/4.0.4.zip">md5</a> | <a href="https://staging.sirp.io/patches/4.0.4.zip">Sha1</a> )</td>
                        </tr>
                    </tbody>
                </table>
            </StyledWrapper>
        </StyledContainer>
    )
}
