import {
  IMPORT_ASSET_LOGS_REQUESTED,
  IMPORT_ASSET_LOGS_PROCESSING,
  IMPORT_ASSET_LOGS_SUCCESSED,
  IMPORT_ASSET_LOGS_FAILED,
} from '../../constants/actionTypes';

const initialState = {
  listData: {},
  isUpdated: null,
  isProcessing: null,
  isSuccess: null,
  hasErrors: null,
};

export const assetImportLogsStore = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_ASSET_LOGS_REQUESTED: {
      return {
        ...state,
        listData: {},
        error: null,
        loading: true,
        requested: true,
      };
    }
    case IMPORT_ASSET_LOGS_PROCESSING: {
      return {
        ...state,
        listData: {},
        error: null,
        loading: true,
        requested: false,
      };
    }
    case IMPORT_ASSET_LOGS_SUCCESSED: {
      return {
        ...state,
        listData: action.data,
        error: null,
        loading: false,
        requested: false,
      };
    }
    case IMPORT_ASSET_LOGS_FAILED: {
      return {
        ...state,
        listData: {},
        error: action.data,
        loading: false,
        requested: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default assetImportLogsStore;
