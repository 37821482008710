import styled from 'styled-components';
import { Alert } from 'antd';

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;
export const FormDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 0px 0px;
  display: flex;
  flex-direction: column;

  
  
`;
export const QueryDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;
export const Query = styled.p`
  font-size: 1.07rem;
  font-weight: 600;
  display: flex;
  color: #ffffff;
  text-decoration: underline;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
`;
export const AlertBox = styled(Alert)`
  margin-top: 35px;
  margin-bottom: -20px;
  height: 35px;
`;
export const MyForm = styled.form`
.ant-select-dropdown{
  background: yellow !important;
}

`;
