import {
    INCIDENT_ACTION_RUN_REQUESTED_DISPANSE,
    INCIDENT_ACTION_RUN_PROCESSING_DISPANSE,
    INCIDENT_ACTION_RUN_SUCCESSED_DISPANSE,
    INCIDENT_ACTION_RUN_FAILED_DISPANSE,
    INCIDENT_ACTION_CLEAR_REQUESTED_DISPANSE, 
  } from '../../constants/actionTypes';
  
  const initialState = {
    result: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const incidentMasterActionRunDispanse = (state = initialState, action) => {
    switch (action.type) {
      case INCIDENT_ACTION_RUN_REQUESTED_DISPANSE: {
        return {
          ...state,
          error: null,
          loading: true,
          requested: true,
        };
      }
      case INCIDENT_ACTION_RUN_PROCESSING_DISPANSE: {
        return {
          ...state,
          error: null,
          loading: true,
          requested: false,
        };
      }
      case INCIDENT_ACTION_RUN_SUCCESSED_DISPANSE: {
        return {
          ...state,
          result: action.data,
          error: null,
          loading: false,
          requested: false,
        };
      }
      case INCIDENT_ACTION_RUN_FAILED_DISPANSE: {
        return {
          ...state,
          result: {},
          error: action.data,
          loading: false,
          requested: false,
        };
      }
      case INCIDENT_ACTION_CLEAR_REQUESTED_DISPANSE:{
        return {
          result: {},
          isProcessing: null,
          isSuccess: null,
          hasErrors: null,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  
  export default incidentMasterActionRunDispanse;
  