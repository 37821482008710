import { put, call, takeLatest } from 'redux-saga/effects';
import {
  CASE_MEMEBER_USER_REQUESTED,
  CASE_MEMEBER_USER_SUCCESSED,
  CASE_MEMEBER_USER_FAILED,
  CASE_MEMEBER_USER_GROUP_REQUESTED,
  CASE_MEMEBER_USER_GROUP_SUCCESSED,
  CASE_MEMEBER_USER_GROUP_FAILED,
  CASE_SEVERITY_FAILED,
  CASE_SEVERITY_SUCCESSED,
  CASE_SEVERITY_REQUESTED,
  CASE_CATEGORY_SUCCESSED,
  CASE_CATEGORY_FAILED,
  CASE_SUBCATEGORY_SUCCESSED,
  CASE_SUBCATEGORY_FAILED,
  CASE_DISPOSITION_SUCCESSED,
  CASE_DISPOSITION_FAILED,
  CASE_SUBDISPOSITION_SUCCESSED,
  CASE_SUBDISPOSITION_FAILED,
  CASE_LOCATION_SUCCESSED,
  CASE_LOCATION_FAILED,
  CASE_DETECTION_METHODS_SUCCESSED,
  CASE_DETECTION_METHODS_FAILED,
  CASE_CATEGORY_REQUESTED,
  CASE_SUBCATEGORY_REQUESTED,
  CASE_DISPOSITION_REQUESTED,
  CASE_SUBDISPOSITION_REQUESTED,
  CASE_LOCATION_REQUESTED,
  CASE_DEPARTMENT_REQUESTED,
  CASE_DEPARTMENT_SUCCESSED,
  CASE_DEPARTMENT_FAILED,
  CASE_DETECTION_METHODS_REQUESTED,
  CASE_ACTION_APPS_SUCCESSED,
  CASE_ACTION_APPS_FAILED,
  CASE_ACTION_APPS_REQUESTED,
  CASE_ACTION_RUN_SUCCESSED,
  CASE_ACTION_RUN_FAILED,
  CASE_ACTION_RUN_REQUESTED,
  UPDATE_CASES_ADVISORY_REQUEST,
  UPDATE_CASES_ADVISORY_REQUEST_SUCCESSED,
  UPDATE_CASES_ADVISORY_REQUEST_FAILED,
  SHOW_LOADER,
  HIDE_LOADER,
  CASE_ACTION_RUN_SUCCESSED_DISPANSE,
  CASE_ACTION_RUN_FAILED_DISPANSE,
  CASE_ACTION_RUN_REQUESTED_DISPANSE,
} from '../../constants/actionTypes';
import {
  listMemberUserGroups,
  listMembersUser,
  getCaseManagementSeverity,
  getCasecategory,
  getCasesubCategory,
  getCasedetectionMethods,
  getCasedisposition,
  getCaselocation,
  getCasesubDisposition,
  getActionApps,
  runActions,
  updateCaseAdvisoryApi,
  getCasedepartment,
} from '../../api/caseMasterData';
import { showToastError, showToastSuccess, showToastLoader, showToastInfo, dismissToastLoader } from '../../utils/toasts';

const getResponseMessage = (data) => {
  let { module, action } = data || {};
  switch (true) {
    case (module == 'affectedVendors' && action == 'add'):
      return 'Affected Vendor added'
    case (module == 'affectedVendors' && action == 'remove'):
      return 'Affected Vendor deleted'
    case (module == 'affectedProduct' && action == 'add'):
      return 'Affected Product added'
    case (module == 'affectedProduct' && action == 'remove'):
      return 'Affected Product deleted'
    default:
      return 'Cases Advisory updated';
  }
}

export function* watchUsersList(action) {
  try {
    const response = yield call(listMembersUser, action.payload);
    if (response.success) {

      yield put({ type: CASE_MEMEBER_USER_SUCCESSED, data: response });
    } else {
      yield put({ type: CASE_MEMEBER_USER_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: CASE_MEMEBER_USER_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchMemberGroupsList(action) {
  try {
    const response = yield call(listMemberUserGroups, action.payload);
    if (response.success) {
      yield put({
        type: CASE_MEMEBER_USER_GROUP_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: CASE_MEMEBER_USER_GROUP_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: CASE_MEMEBER_USER_GROUP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseManagementSeverity(action) {
  try {
    const response = yield call(getCaseManagementSeverity, action.payload);
    if (response.success) {
      yield put({ type: CASE_SEVERITY_SUCCESSED, data: response.data });
    } else {
      yield put({ type: CASE_SEVERITY_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: CASE_SEVERITY_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchCaseManagementcategory(action) {
  try {
    const response = yield call(getCasecategory, action.payload);
    if (response.success) {
      yield put({ type: CASE_CATEGORY_SUCCESSED, data: response.data });
    } else {
      yield put({ type: CASE_CATEGORY_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: CASE_CATEGORY_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchCaseManagementsubCategory(action) {
  try {

    const response = yield call(getCasesubCategory, action.payload);
    if (response.success) {
      yield put({ type: CASE_SUBCATEGORY_SUCCESSED, data: response.data });
    } else {
      yield put({ type: CASE_SUBCATEGORY_FAILED, data: null });
    }

  } catch (err) {
    yield put({
      type: CASE_SUBCATEGORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseManagementdisposition(action) {
  try {
    const response = yield call(getCasedisposition, action.payload);
    if (response.success) {
      yield put({ type: CASE_DISPOSITION_SUCCESSED, data: response.data });
    } else {
      yield put({ type: CASE_DISPOSITION_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: CASE_DISPOSITION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseManagementsubDisposition(action) {
  try {
    const response = yield call(getCasesubDisposition, action.payload);
    if (response.success) {
      yield put({
        type: CASE_SUBDISPOSITION_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: CASE_SUBDISPOSITION_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: CASE_SUBDISPOSITION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseManagementlocation(action) {
  try {
    const response = yield call(getCaselocation, action.payload);
    if (response.success) {
      yield put({ type: CASE_LOCATION_SUCCESSED, data: response.data });
    } else {
      yield put({ type: CASE_LOCATION_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: CASE_LOCATION_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchCaseManagementdepartment(action) {
  try {
    const response = yield call(getCasedepartment, action.payload);
    if (response.success) {
      yield put({ type: CASE_DEPARTMENT_SUCCESSED, data: response.data });
    } else {
      yield put({ type: CASE_DEPARTMENT_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: CASE_DEPARTMENT_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchCaseManagementdetectionMethods(action) {
  try {
    const response = yield call(getCasedetectionMethods, action.payload);
    if (response.success) {
      yield put({
        type: CASE_DETECTION_METHODS_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: CASE_DETECTION_METHODS_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: CASE_DETECTION_METHODS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseManagementActionsApps(action) {
  try {
    const response = yield call(getActionApps, action.payload);
    if (response.success) {
      yield put({ type: CASE_ACTION_APPS_SUCCESSED, data: response.data });
    } else {
      yield put({ type: CASE_ACTION_APPS_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: CASE_ACTION_APPS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseManagementRunAction(action) {
  const loaderHandle = showToastLoader('Processing...')
  try {
    const response = yield call(runActions, action.payload?.data);
    if (response.success) {
      if (response?.data?.message === 'Action scheduled') {
        showToastSuccess('Action executed', loaderHandle)
      } else {
        dismissToastLoader(loaderHandle);
      }
      yield put({ type: CASE_ACTION_RUN_SUCCESSED, data: response.data });
    } else {
      showToastError('Action execute failed', loaderHandle)
      yield put({ type: CASE_ACTION_RUN_FAILED, data: null });
    }
    if (typeof action.payload?.callback === 'function') {
      action.payload.callback();
    }
  } catch (err) {
    showToastError('Action execute failed', loaderHandle)
    yield put({
      type: CASE_ACTION_RUN_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCaseManagementRunActionDispanse(action) {
  const loaderHandle = showToastLoader('Processing...')
  try {
    const response = yield call(runActions, action.payload?.data);
    if (response.success) {
      if (response?.data?.message) {
        showToastSuccess('Action executed', loaderHandle)
      } else {
        dismissToastLoader(loaderHandle);
      }
      yield put({ type: CASE_ACTION_RUN_SUCCESSED_DISPANSE, data: response.data });
    } else {
      showToastError('Action execute failed', loaderHandle)
      yield put({ type: CASE_ACTION_RUN_FAILED_DISPANSE, data: null });
    }
    if (typeof action.payload?.callback === 'function') {
      action.payload.callback();
    }
  } catch (err) {
    showToastError('Action execute failed', loaderHandle)
    yield put({
      type: CASE_ACTION_RUN_FAILED_DISPANSE,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseAdvisoryUpdate(action) {
  const loaderHandle = showToastLoader('Updating advisory...')
  try {
    const response = yield call(updateCaseAdvisoryApi, action?.payload);
    if (response.success) {
      const msg = getResponseMessage(action.payload.module);
      showToastSuccess(msg, loaderHandle)
      yield put({
        type: UPDATE_CASES_ADVISORY_REQUEST_SUCCESSED,
        data: action?.payload?.data,
        response: response?.data
      });
    } else {
      showToastError('Advisory update failed', loaderHandle)
      yield put({
        type: UPDATE_CASES_ADVISORY_REQUEST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Advisory update failed', loaderHandle)
    yield put({ type: UPDATE_CASES_ADVISORY_REQUEST_FAILED, data: err });
  }
}

export default function* watcher() {
  yield takeLatest(CASE_MEMEBER_USER_REQUESTED, watchUsersList);
  yield takeLatest(CASE_MEMEBER_USER_GROUP_REQUESTED, watchMemberGroupsList);
  yield takeLatest(CASE_SEVERITY_REQUESTED, watchCaseManagementSeverity);
  yield takeLatest(CASE_CATEGORY_REQUESTED, watchCaseManagementcategory);
  yield takeLatest(CASE_SUBCATEGORY_REQUESTED, watchCaseManagementsubCategory);
  yield takeLatest(CASE_DISPOSITION_REQUESTED, watchCaseManagementdisposition);
  yield takeLatest(
    CASE_SUBDISPOSITION_REQUESTED,
    watchCaseManagementsubDisposition
  );
  yield takeLatest(CASE_LOCATION_REQUESTED, watchCaseManagementlocation);
  yield takeLatest(CASE_DEPARTMENT_REQUESTED, watchCaseManagementdepartment);
  
  yield takeLatest(
    CASE_DETECTION_METHODS_REQUESTED,
    watchCaseManagementdetectionMethods
  );
  yield takeLatest(CASE_ACTION_APPS_REQUESTED, watchCaseManagementActionsApps);
  yield takeLatest(CASE_ACTION_RUN_REQUESTED, watchCaseManagementRunAction);
  yield takeLatest(CASE_ACTION_RUN_REQUESTED_DISPANSE, watchCaseManagementRunActionDispanse);
  yield takeLatest(UPDATE_CASES_ADVISORY_REQUEST, watchCaseAdvisoryUpdate);
}
