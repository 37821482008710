import styled from 'styled-components';
import { Alert } from 'antd';


export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .bNCnQI {
     width: 816px !important;
   }
`;
export const FormDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 26px 0px;
  display: flex;
  flex-direction: column;

`;
export const QueryDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: center;
`;
export const Query = styled.p`
  font-size: 1.07rem;
  font-weight: 600;
  display: flex;
  color: #ffffff;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
`;
export const AlertBox = styled(Alert)`
  display: flex;
  width: 500px;
  margin: 0px auto;
  text-align:left;
  padding: 20px;
  padding-left: 60px;
  padding-right:50px;
  margin-top:13px;
`;

export const MyForm = styled.form``;
