import React, { useEffect } from 'react';
import LoginBox from '../../../components/LoginBox';
import LicenseUpdateForm from './components/Form';

const LicenseUpdate = () => {
  useEffect(() => {
    document.title = 'License Update';
  }, []);
  return (
    <LoginBox>
      <LicenseUpdateForm />
    </LoginBox>
  );
};

export default LicenseUpdate;
