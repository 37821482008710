import React, { useState, useEffect, useParams } from 'react';
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SPButton from '../../../../../components/SPButton';
import InputBox from '../../../../../components/InputBox';
import SPMessage from '../../../../../components/SPMessage';
import { verifyCode, userLogout } from '../../../../../actions/auth';
import { resetUser } from '../../../../../actions/user';
import {
  FormContainer,
  FormDiv,
  QueryDiv,
  Query,
  ButtonContainer,
  MyForm,
} from './StyledComponents';

const Form = ({ verifyCodeStore, verifyCodeAction, resetUserAction, userLogoutAction }) => {

  const [isFieldEmpty, setIsFieldEmpty] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (verifyCodeStore.error && verifyCodeStore.error.code === 401) {
      localStorage.removeItem('AccessToken');
      resetUserAction();
      history.push('/login');
      userLogoutAction();
    }
  }, [verifyCodeStore.error]);

  useEffect(() => {
    if (verifyCodeStore.listData.success) {
      const accessTokenfa = localStorage.getItem('AccessToken2FA');
      localStorage.setItem('AccessToken', accessTokenfa);
      localStorage.removeItem('AccessToken2FA');
      const accessToken = localStorage.getItem('AccessToken');
      if (accessToken !== null) {
        history.push('/dashboard');
      }
    }
  }, [verifyCodeStore?.listData]);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: values => {
      const codeItem = values.code;
      if (codeItem === "") {
        setIsFieldEmpty(true);
      }
      if (codeItem) {
        const dataItem = {
          mastercode: codeItem,
        };
        verifyCodeAction(dataItem);
      }
      return values;
    },
  });

  return (
    <FormContainer>
      {
        verifyCodeStore?.error?.message &&
        <SPMessage
          message={verifyCodeStore.error?.message}
          type='error'
          closable
        />
      }
      {
        isFieldEmpty &&
        <SPMessage
          message='Code Field cannot be blank'
          type='error'
          closable
        />
      }
      <MyForm onSubmit={formik.handleSubmit}>
        <FormDiv>
          <InputBox
            id="code"
            label="Enter Master Code"
            type="password"
            name="code"
            noMargin={true}
            placeholder=""
            onInputChange={formik.handleChange}
            errorMessage={formik.errors.code}
            value={formik.values.code}
          />
          <ButtonContainer>
            <SPButton
              title="Verify"
              htmlType="submit"
              isLoading={verifyCodeStore.loading} 
              height={30}
              />
          </ButtonContainer>
          <Link to="/login">
            <QueryDiv>
              <Query>Enter authentication code, instead</Query>
            </QueryDiv>
          </Link>
        </FormDiv>
      </MyForm>
    </FormContainer>
  );
};

const mapStateToProps = state => {
  return {
    verifyCodeStore: state.verifyCodeStore,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyCodeAction: data => {
      return dispatch(verifyCode(data));
    },
    resetUserAction: data => {
      return dispatch(resetUser(data));
    },
    userLogoutAction: data => {
      return dispatch(userLogout(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
