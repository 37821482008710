import {
    ADVISORY_THREAT_ACTORS_REQUESTED,
    ADVISORY_THREAT_ACTORS_FAILED,
    ADVISORY_THREAT_ACTORS_SUCCESSED,
    ADVISORY_THREAT_ACTORS_PROCESSING
} from '../../constants/actionTypes';

const initialState = {
    listData: {},
    isProcessing: false,
    isSuccess: null,
    hasErrors: null,
};

export const threatIntelligenceThreatActors = (state = initialState, action) => {
    switch (action.type) {
        case ADVISORY_THREAT_ACTORS_REQUESTED: {
            return {
                ...state,
                listData: null,
                hasErrors: null,
                isProcessing: true,
                isSuccess: null,
            };
        }
        case ADVISORY_THREAT_ACTORS_PROCESSING: {
            return {
                ...state,
                listData: null,
                hasErrors: null,
                isProcessing: true,
                isSuccess: null,
            };
        }
        case ADVISORY_THREAT_ACTORS_SUCCESSED: {
            return {
                listData: action.data,
                hasErrors: null,
                isProcessing: false,
                isSuccess: true,
            };
        }
        case ADVISORY_THREAT_ACTORS_FAILED: {
            return {
                listData: action.data,
                hasErrors: true,
                isProcessing: false,
                isSuccess: null,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default threatIntelligenceThreatActors;
