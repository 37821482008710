import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import SPButton from '../../../../../components/SPButton';
import InputBox from '../../../../../components/InputBox';
import { invitedUser } from '../../../../../actions/auth';

import {
  FormContainer,
  FormDiv,
  ButtonContainer,
  MyForm
} from './StyledComponents';
import SPMessage from '../../../../../components/SPMessage';

const Form = ({ invitedUserAction, invitedUserStore }) => {
  useEffect(() => {
    if (invitedUserStore.isSuccess) {
      setTimeout(function () { history.push('/') }, 3000);
    }
  }, [invitedUserStore.isSuccess]);

  const history = useHistory();
  function generateError(data) {
    let errorItem = null
    if (typeof data === 'object' && data !== null && !data.message) {
      const items = Object.keys(data).map((item, index) =>
        <div key={index} dangerouslySetInnerHTML={{ __html: data[item][0] }} />
      )
      errorItem = items
    } else {
      errorItem = data.message
    }
    return errorItem
  }

  const formik = useFormik({
    initialValues: {
      usr_password: '',
      passconf: '',
      usr_name: '',
      usr_designation: '',
      usr_phone_number: '',
      isTermsChecked: false,
    },

    validationSchema: Yup.object({
      usr_name: Yup.string().required('Required'),
      usr_designation: Yup.string().required('Required'),
      usr_phone_number: Yup.string().required('Required'),
      usr_password: Yup.string().required('Required').min(8, 'Min 8 characters required'),
      passconf: Yup.string().oneOf([Yup.ref('usr_password'), null], 'Passwords must match')
    }),

    onSubmit: values => {
      const dataItem = {
        usr_name: values.usr_name,
        usr_designation: values.usr_designation,
        usr_phone_number: values.usr_phone_number,
        usr_password: values.usr_password,
        passconf: values.passconf
      }
      invitedUserAction(dataItem)

      return values
    }
  })

  return (
    <FormContainer>
      <br />
      {
        invitedUserStore.hasErrors &&
        <SPMessage
          message={(invitedUserStore.hasErrors?.message) ? "Restriction" : "Password Error"}
          description={generateError(invitedUserStore.hasErrors)}
          type='error'
          closable
        />
      }
      <MyForm onSubmit={formik.handleSubmit}>
        <FormDiv>
          <InputBox
            id='usr_name'
            label='Name'
            name='usr_name'
            placeholder='Name'
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.errors.usr_name}
            touched={formik.touched.usr_name}
            value={formik.values.usr_name}
          />
          <InputBox
            id='usr_designation'
            label='Designation'
            name='usr_designation'
            placeholder='Designation'
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.errors.usr_designation}
            touched={formik.touched.usr_designation}
            value={formik.values.usr_designation}
          />
          <InputBox
            id='usr_phone_number'
            label='Phone Number'
            type='string'
            name='usr_phone_number'
            placeholder='e.g 7856864521'
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.errors.usr_phone_number}
            touched={formik.touched.usr_phone_number}
            value={formik.values.usr_phone_number}
          />
          <InputBox
            id='usr_password'
            label='Password'
            type='password'
            name='usr_password'
            onInputChange={formik.handleChange}
            value={formik.values.usr_password}
            touched={formik.touched.usr_password}
            errorMessage={formik.errors.usr_password}
          />
          <InputBox
            id='passconf'
            label='Confirm Password'
            type='password'
            name='passconf'
            onInputChange={formik.handleChange}
            touched={formik.touched.passconf}
            value={formik.values.passconf}
            errorMessage={formik.errors.passconf}
          />

          <Checkbox
            name='isTermsChecked'
            checked={formik.values.isTermsChecked}
            onChange={formik.handleChange}
          >
            <label style={{ fontSize: '13px', color: '#FFF' }}>
              I have read and agree to the <a href="/terms-of-services" target='_blank'> Terms Of Services </a>
            </label>
          </Checkbox>
        </FormDiv>

        <ButtonContainer>
          <SPButton
            title='Create'
            htmlType='submit'
            isLoading={invitedUserStore.loading}
            disabled={!formik.values.isTermsChecked}
          />
        </ButtonContainer>
      </MyForm >
    </FormContainer >
  );
};

const mapStateToProps = state => {
  return {
    invitedUserStore: state.invitedUserStore,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    invitedUserAction: data => {
      return dispatch(invitedUser(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
