import styled from 'styled-components';
import { Alert } from 'antd';
import { Colors, Fonts } from '../../../../../theme';

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  
`;
export const FormDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 30px 0px 0px;
  display: flex;
  flex-direction: column;

`;
export const QueryDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: center;
`;
export const Query = styled.p`
  font-size: 1.07rem;
  font-weight: 600;
  display: flex;
  color: #ffffff;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
`;
export const AlertBox = styled(Alert)`
  display: flex;
  width: auto;
  margin: 0px auto;
  text-align:left;
  padding: 0;
  padding-left: 30px;
  padding-right:30px;
  margin-top:13px;
`;

export const StyledText = styled.text`
  color: ${Colors.primaryWhite};
  font-family: ${Fonts.type.robotoRegular};
  font-size: 1rem;
  font-style: normal;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '400')};
  text-align: left;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: -1rem;
`;

export const MyForm = styled.form``;
