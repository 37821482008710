import {
    INGESTION_OUTPUT_REQUESTED,
    INGESTION_OUTPUT_PROCESSING,
    INGESTION_OUTPUT_SUCCESSED,
    INGESTION_OUTPUT_FAILED,
  } from '../../constants/actionTypes';
   
  const initialState = {
    listData: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const ingestionOutput = (state = initialState, action) => {
    switch (action.type) {
      case INGESTION_OUTPUT_REQUESTED: {
        return {
          ...state,
          listData: null,
          hasErrors: null,
          isProcessing: true,
          isSuccess: null,
        };
      }
      case INGESTION_OUTPUT_PROCESSING: {
        return {
          ...state,
          listData: null,
          hasErrors: null,
          isProcessing: true,
          isSuccess: null,
        };
      }
      case INGESTION_OUTPUT_SUCCESSED: {
        return {
          listData: action.data,
          hasErrors: null,
          isProcessing: false,
          isSuccess: true,
        };
      }
      case INGESTION_OUTPUT_FAILED: {
        return {
          listData: action.data,
          hasErrors: true,
          isProcessing: false,
          isSuccess: null,
        };
      }
      default: {
        return {
          ...state,
        };
      }
    }
  };
  
  export default ingestionOutput;
  
