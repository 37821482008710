import API from '../config/endpoints.config';
import { axiosInstance } from '../utils/helper.utils';

export async function widgetlistSaga(query) {
  let api = `/custom-widget`;
  if (query) {
    api = `/custom-widget` + `?${query.replace('iti_showing', 'per-page')}`;
  }
  const response = await axiosInstance.get(api);
  return response.data;
}

export async function widgetSettingsSaga() {
  const api = `/get-widget-settings`;

  const response = await axiosInstance.get(api);
  return response.data;
}

export async function widgetDataSourcesSaga() {
  const api = `/get-widget-datasources`;

  const response = await axiosInstance.get(api);
  return response.data;
}

export async function widgetDataFieldsSaga(dataSource) {
  let payload = '';
  if (Array.isArray(dataSource)) {
    payload = dataSource?.map(el => el?.replaceAll('Threat Intel', 'advisory'));
  } else if (typeof dataSource == 'string') {
    payload = dataSource?.replaceAll('Threat Intel', 'advisory');
  }

  const api = `/get-widget-datafields?datasource=${payload}`;

  const response = await axiosInstance.get(api);
  return response.data;
}

export async function widgetDeletionSaga(widgetId) {
  const api = `/custom-widget/${widgetId}`;
  const response = await axiosInstance.delete(api);
  return response.data;
}

export async function widgetDuplicationSaga(payload) {
  const api = `/duplicate-widget`;
  var data = new FormData();
  data.append("widget_id", Number(payload));
  const response = await axiosInstance.post(api, data);
  return response.data;
}

export async function widgetRenderSaga(payload) {
  const api = `/test-widget-query`;

  let data = new FormData();
  let payloadObj = { ...payload };

  if (!payloadObj.clause.includes('WHERE')) {
    payloadObj.where = [];
  } if (!payloadObj.clause.includes('INNER JOIN')) {
    payloadObj.innerJoin = [];
  } if (!payloadObj.clause.includes('LEFT JOIN')) {
    payloadObj.leftJoin = [];
  } if (!payloadObj.clause.includes('RIGHT JOIN')) {
    payloadObj.rightJoin = [];
  } if (!payloadObj.clause.includes('GROUP BY')) {
    payloadObj.groupBy = [];
  } if (!payloadObj.clause.includes('HAVING')) {
    payloadObj.having = [];
  } if (!payloadObj.clause.includes('DISTINCT')) {
    payloadObj.distinct = [];
  }

  const jsonPayload = JSON.stringify(payloadObj, (key, value) => {
    if (key == "where") {
      return value?.map(v => {
        return { ...v, value: (v?.value == "\"\"" || v?.value == "''") ? '' : v?.value }
      });
    } else {
      return value;
    }
  });

  data.append("json_query", jsonPayload);
  const response = await axiosInstance.post(api, data);
  return response.data;
}

export async function widgetCreationSaga(payload) {
  const { id, edit } = payload || {};
  let api = `/custom-widget`;

  if (id) {
    api = `/custom-widget/${id}`;
  }
  let payloadObj = { ...payload };

  if (!payloadObj.clause.includes('WHERE')) {
    payloadObj.where = [];
  } if (!payloadObj.clause.includes('INNER JOIN')) {
    payloadObj.innerJoin = [];
  } if (!payloadObj.clause.includes('LEFT JOIN')) {
    payloadObj.leftJoin = [];
  } if (!payloadObj.clause.includes('RIGHT JOIN')) {
    payloadObj.rightJoin = [];
  } if (!payloadObj.clause.includes('GROUP BY')) {
    payloadObj.groupBy = [];
  } if (!payloadObj.clause.includes('HAVING')) {
    payloadObj.having = [];
  } if (!payloadObj.clause.includes('DISTINCT')) {
    payloadObj.distinct = [];
  }

  const data = new FormData();
  data.append("json_query", JSON.stringify(payloadObj));

  if (id) {
    const response = await axiosInstance.put(api, data);
    return response.data;
  } else {
    const response = await axiosInstance.post(api, data);
    return response.data;
  }
}

export async function widgetSaveAsDraftSaga(payload) {
  const { id, edit } = payload || {};
  let api = `${API.baseUrl}/custom-widget`;

  if (id) {
    api = `${API.baseUrl}/custom-widget/${id}`;
  }

  const data = new FormData();
  data.append("json_query", JSON.stringify(payload));
  data.append("save_as_draft", 1);

  if (id) {
    const response = await axiosInstance.put(api, data);
    return response.data;
  } else {
    const response = await axiosInstance.post(api, data);
    return response.data;
  }
}