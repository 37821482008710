import React, { useEffect } from 'react';
import {
    BoxContainer,
    ContentContainer,
    BoxHeader,
    LogoImage,
    BoxContent,
    CopyrightBox,
    UpperText,
    Text,
} from './components/Form/StyledComponents';
import Logo from '../../../assets/images/logo.png';
import packageJson from '../../../../package.json';
import Form from './components/Form';

const AuthenticateUser = (props) => {
    useEffect(() => {
        document.title = 'Google Authentication';
    }, []);
    const { version } = props;
    return (
        <BoxContainer>
            <ContentContainer>
                <BoxHeader>
                    <LogoImage src={Logo} />
                </BoxHeader>
                <BoxContent>
                    <Form />
                </BoxContent>
            </ContentContainer>
            <CopyrightBox>
                <UpperText>Version:&nbsp;&nbsp;{packageJson.version}</UpperText>
                <Text>Copyright@ {(new Date().getFullYear())} SIRP</Text>
                <Text>All rights reserved</Text>
            </CopyrightBox>
        </BoxContainer>


    );
};

export default AuthenticateUser;
