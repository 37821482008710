import {
  ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ORGANIZATION_LIST_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_DELETE_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_CREATE_LIST_REQUESTED,
  ADMINISTRATION_OWNERS_LIST_REQUESTED,
  ADMINISTRATION_OWNERS_DELETE_REQUESTED,
  ADMINISTRATION_OWNERS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_OWNERS_CREATE_LIST_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED,
  ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED,
  ADMINISTRATION_DELETE_ASSETS_GROUPS_REQUESTED,
  ADMINISTRATION_EDIT_ASSETS_GROUPS_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_CREATE_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_DELETE_REQUESTED,
  ADMINISTRATION_ORGANIZATION_UPDATE_REQUESTED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED,
  ADMINISTRATION_SET_SELECTED_ORGANIZATION_SUCCESSED,
  ADMINISTRATION_SET_SELECTED_ORGANIZATION_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED,
  ADMINISTRATION_WORKFLOW_LIST_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED,
  ORAGANIZATION_REQUESTED,
  ORAGANIZATION_USER_UPDATE_REQUESTED,
  ORAGANIZATION_USER_DELETE_REQUESTED,
  ORAGANIZATION_USER_CREATE_REQUESTED,
  ADMINISTRATION_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_DELETE_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_CREATE_LIST_REQUESTED,
  ADMINISTRATION_WORKFLOW_CHANGE_STATUS_REQUESTED,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_CREATE_ASSET_SUB_GROUP_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_SUB_GROUP_REQUESTED,
  ADMINISTRATION_DELETE_ASSET_SUB_GROUP_REQUESTED,
  ADMINISTRATION_WORKFLOW_DUPLICATE_REQUESTED,
  ADMINISTRATION_WORKFLOW_DELETE_REQUESTED,
  ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED,
  ADMINISTRATION_WORKFLOW_TAGS_REQUESTED,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_CREATE_REQUESTED,
  ADMINISTRATION_WORKFLOW_UPDATE_REQUESTED,
  ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED,
  ADMINISTRATION_CREATE_ASSET_VALUE_LIST_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_VALUE_REQUESTED,
  ADMINISTRATION_DELETE_ASSET_VALUE_REQUESTED,
  ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_REQUESTED,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED,
  ADMINISTRATION_CREATE_OPERATING_SYSTEM_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED,
  ORGANIZATION_INFOMATION_POST_REQUESTED,
  EMAIL_CONFIGURATION_POST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED,
  ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_VENDORS_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_VENDORS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_VENDORS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_EDIT_OPERATING_SYSTEM_REQUESTED,
  ADMINISTRATION_DELETE_OPERATING_SYSTEM_REQUESTED,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED,
  ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_REQUESTED,
  ADMINISTRATION_EDIT_DEPARTMENT_REQUESTED,
  ADMINISTRATION_DELETE_DEPARTMENT_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_REQUESTED,
  ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_REQUESTED,
  ADMINISTRATION_DELETE_ASSET_TYPE_REQUESTED,
  ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITION_CREATE_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITION_DELETE_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITION_UPDATE_PROCESSING,
  ADMINISTRATION_CASES_DISPOSITION_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_CREATE_ASSET_TYPE_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED,
  ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED,
  ADMINISTRATION_CASES_LOCATION_DELETE_REQUESTED,
  ADMINISTRATION_CASES_LOCATION_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_LOCATION_CREATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_DELETE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_CREATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_UPDATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_REQUESTED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_SEVERITY_REQUESTED,
  ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_DELETE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_NON_UPDATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_TYPE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CREATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_DISPOSITION_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_DISPOSITION_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_DISPOSITION_DELETE_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_INGESTION_DELETE_LIST_REQUESTED,
  INGESTION_TYPE_REQUESTED,
  INGESTION_WIDGET_REQUESTED,
  INGESTION_FREQUENCY_REQUESTED,
  INGESTION_APPLICATION_REQUESTED,
  INGESTION_USERS_REQUESTED,
  INGESTION_ACTION_REQUESTED,
  ENABLE_ADVISORY_REQUESTED,
  ADMINISTRATION_AUTOMATION_INGESTION_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_INGESTION_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED,
  ACTIONS_TYPE_REQUESTED,
  ACTIONS_SCRIPT_TYPE_REQUESTED,
  ACTIONS_IO_TYPE_REQUESTED,
  ACTIONS_APPLICATION_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_CREATE_LIST_REQUESTED,
  GET_INGESTION_MULTI_DATA_REQUESTED,
  ADD_INGESTION_MULTI_DATA_REQUESTED,
  GET_INGESTION_MAPPING_REQUESTED,
  ADMINISTRATION_LOGS_APPS_LIST_REQUESTED,
  APP_LOGS_NOTIFICATION_LIST_REQUESTED,
  ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED,
  ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED,
  ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED,
  ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED,
  ADMINISTRATION_PRODUCT_FAILED_REGISTER_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED,
  ADMINISTRATION_ACCESS_PERMISSION_REQUESTED,
  ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED,
  ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED,
  ADMINISTRATION_ACCESS_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_REQUESTED,
  ADMINISTRATION_ACCESS_USER_INVITE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_REQUESTED,
  ADMINISTRATION_FIELD_SETTING_LIST_REQUESTED,
  ADMINISTRATION_FIELD_SETTING_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_REQUESTED,
  ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED,
  ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED,
  ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_FAILED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_REQUESTED,
  ADMINISTRATION_CASE_FIELD_SETTING_LIST_REQUESTED,
  ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_REQUESTED,
  ASSET_CLASSIFICATION_CHANGE_TITLE_REQUESTED,
  ASSET_CLASSIFICATION_FORMULA_CHANGE_REQUESTED,
  ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_CREATE_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_DELETE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_DELETE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_CREATE_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_LIST_REQUESTED,
  ADMINISTRATION_GET_TABS_HEADING_REQUESTED,
  ADMINISTRATION_CASES_SLA_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_DELETE_REQUESTED,
  ADMINISTRATION_CASES_SLA_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED,
  ADMINISTRATION_RESET_RISK_MATRIX_REQUESTED,
  ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_REQUESTED,
  AUTOMATION_ACTION_DUPLICATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_REQUESTED,
  ADMINISTRATION_CREATE_RISK_MATRIX_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED,
  AUTOMATION_ACTION_CONFIGURATION_REQUESTED,
  AUTOMATION_ACTION_CONFIGURATION_POST_REQUESTED,
  AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED,
  AUTOMATION_ACTION_MULTIDATA_POST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED,
  ADMINISTRATION_CASES_DESPOSITION_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_CREATE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_CREATE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_UPDATE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_DELETE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_REQUESTED,
  ADMINISTRATION_PRODUCT_SETTINGS_PATCH_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_REQUESTED,
  ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_REQUESTED,
  ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED,
  ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED_Old,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_ACTOR_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED,

  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED,
  GET_USER_EMAILS_LIST_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_LOG_LIST_REQUESTED,
  ADMINISTRATION_CASES_LESSON_LIST_REQUESTED,
  ADMINISTRATION_CASES_LESSON_DELETE_REQUESTED,
  ADMINISTRATION_CASES_LESSON_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_LESSON_CREATE_REQUESTED,

  ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_DELETE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_CREATE_REQUESTED,

  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_REQUESTED,
  ADMINISTRATION_ALERTS_NOTIFY_LIST_REQUESTED,
  ADMINISTRATION_ALERTS_NOTIFY_UPDATE_REQUESTED,
  INGESTION_OUTPUT_REQUESTED,
  UPDATE_INGESTION_OUTPUT_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_CREATE_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_UPDATE_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_DELETE_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_CREATE_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_DELETE_REQUESTED,
  UPDATE_SLA_STATUS_REQUESTED,
  ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_REQUESTED,
  SET_ALERT_COUNT,
  PRE_INGESTION_RULE_REQUESTED,
  PRE_INGESTION_RULE_CREATE_REQUESTED,
  PRE_INGESTION_RULE_UPDATE_REQUESTED,
  PRE_INGESTION_RULE_DELETE_REQUESTED,
  ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED,
} from '../constants/actionTypes'

export const getOrganizationList = payload => {
  return {
    type: ADMINISTRATION_ORGANIZATION_LIST_REQUESTED,
    payload,
  }
}

export const getClassificationList = payload => {
  return {
    type: ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED,
    payload,
  }
}

export const updateClassificationList = payload => {
  return {
    type: ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const classificationChangeTitle = payload => {
  return {
    type: ASSET_CLASSIFICATION_CHANGE_TITLE_REQUESTED,
    payload,
  }
}
export const classificationFormulaChange = payload => {
  return {
    type: ASSET_CLASSIFICATION_FORMULA_CHANGE_REQUESTED,
    payload,
  }
}
export const getclassificationFormula = () => {
  return {
    type: ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED,
  }
}

export const getIntegirtyListRequest = payload => {
  return {
    type: ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED,
    payload,
  }
}
export const updateIntegirtyList = (id, payload, query) => {
  return {
    type: ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}
export const createIntegirtyList = (payload, query) => {
  return {
    type: ASSET_CLASSIFICATION_INTEGIRTY_CREATE_REQUESTED,
    payload,
    query
  }
}
export const deleteIntegirtyList = (id, query) => {
  return {
    type: ASSET_CLASSIFICATION_INTEGIRTY_DELETE_REQUESTED,
    id,
    query
  }
}

export const getAvaiabilityListRequest = payload => {
  return {
    type: ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED,
    payload,
  }
}


export const deleteAvaiabilityList = (id, query) => {
  return {
    type: ASSET_CLASSIFICATION_AVAILABILITY_DELETE_REQUESTED,
    id,
    query
  }
}
export const updateAvaiabilityList = (id, payload, query) => {
  return {
    type: ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}
export const createAvaiabilityList = (payload, query) => {
  return {
    type: ASSET_CLASSIFICATION_AVAILABILITY_CREATE_REQUESTED,
    payload,
    query
  }
}

export const deleteClassification = payload => {
  return {
    type: ADMINISTRATION_CLASSIFICATION_DELETE_REQUESTED,
    payload,
  }
}

export const createClassiication = payload => {
  return {
    type: ADMINISTRATION_CLASSIFICATION_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const getOwnersList = payload => {
  return {
    type: ADMINISTRATION_OWNERS_LIST_REQUESTED,
    payload,
  }
}

export const deleteOwner = payload => {
  return {
    type: ADMINISTRATION_OWNERS_DELETE_REQUESTED,
    payload,
  }
}

export const updateOwnerList = payload => {
  return {
    type: ADMINISTRATION_OWNERS_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const createOwner = payload => {
  return {
    type: ADMINISTRATION_OWNERS_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const getAssetGroupList = payload => {
  return {
    type: ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED,
    payload,
  }
}
export const createOraganization = (payload, query) => {
  return {
    type: ADMINISTRATION_ORAGANIZATION_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const deleteOraganization = (id, query) => {
  return {
    type: ADMINISTRATION_ORAGANIZATION_DELETE_REQUESTED,
    id,
    query,
  }
}

export const organizations = () => {
  return {
    type: ORAGANIZATION_REQUESTED,
  }
}

export const getIntialDataOraganizationInformation = () => {
  return {
    type: ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED
  }
}
export const getIntialEmailConfiguration = () => {
  return {
    type: ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED
  }
}
export const getEmailInitialConnectivity = () => {
  return {
    type: ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED
  }
}
export const createOraganizationUser = (payload, query) => {
  return {
    type: ORAGANIZATION_USER_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const updateOraganizationUser = (id, payload, query) => {
  return {
    type: ORAGANIZATION_USER_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}

export const deleteOraganizationUser = (id, query) => {
  return {
    type: ORAGANIZATION_USER_DELETE_REQUESTED,
    id,
    query,
  }
}

export const updateOraganization = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ORGANIZATION_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const ongetSelectedOraganization = () => {
  return {
    type: ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED,
  }
}
export const setSelectedOraganization = (payload, callback) => {
  return {
    type: ADMINISTRATION_SET_SELECTED_ORGANIZATION_REQUESTED,
    payload,
    callback
  }
}

export const oraganizationUsers = payload => {
  return {
    type: ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED,
    payload,
  }
}

export const oraganizationMembers = () => {
  return {
    type: ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED,
  }
}

export const oraganizationTimeZone = () => {
  return {
    type: ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED,
  }
}

export const getAssetGroupSourceList = payload => {
  return {
    type: ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED,
    payload,
  }
}

export const getAssetGroupSubgroupList = payload => {
  return {
    type: ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED,
    payload,
  }
}

export const createAssetsGroup = payload => {
  return {
    type: ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED,
    payload,
  }
}

export const deleteAssetsGroup = payload => {
  return {
    type: ADMINISTRATION_DELETE_ASSETS_GROUPS_REQUESTED,
    payload,
  }
}

export const oraganizationInfo = (payload) => {
  return {
    type: ORGANIZATION_INFOMATION_POST_REQUESTED,
    payload
  }
}
export const emailConfigPostInfo = payload => {
  return {
    type: EMAIL_CONFIGURATION_POST_REQUESTED,
    payload,
  }
}

export const editAssetsGroup = payload => {
  return {
    type: ADMINISTRATION_EDIT_ASSETS_GROUPS_REQUESTED,
    payload,
  }
}

export const getWorkflowList = payload => ({
  type: ADMINISTRATION_WORKFLOW_LIST_REQUESTED,
  payload,
})

export const getCategoryList = payload => {
  return {
    type: ADMINISTRATION_CATEGORY_LIST_REQUESTED,
    payload,
  }
}

export const updateCategoryList = payload => {
  return {
    type: ADMINISTRATION_CATEGORY_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const deleteCategoryList = payload => {
  return {
    type: ADMINISTRATION_CATEGORY_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const createCategoryList = payload => {
  return {
    type: ADMINISTRATION_CATEGORY_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const changeWorkflowStatus = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_CHANGE_STATUS_REQUESTED,
    payload,
  }
}

export const getAssetSubGroupList = payload => {
  return {
    type: ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED,
    payload,
  }
}

export const getAssetGroupDropDownList = payload => {
  return {
    type: ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED,
    payload,
  }
}

export const createAssetsSubGroup = payload => {
  return {
    type: ADMINISTRATION_CREATE_ASSET_SUB_GROUP_REQUESTED,
    payload,
  }
}

export const deleteAssetsSubGroup = payload => {
  return {
    type: ADMINISTRATION_DELETE_ASSET_SUB_GROUP_REQUESTED,
    payload,
  }
}

export const editAssetsSubGroup = payload => {
  return {
    type: ADMINISTRATION_EDIT_ASSET_SUB_GROUP_REQUESTED,
    payload,
  }
}
export const duplicateWorkFlow = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_DUPLICATE_REQUESTED,
    payload,
  }
}

export const deleteWorkFlow = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_DELETE_REQUESTED,
    payload,
  }
}

export const getWorkFlowCategory = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED,
    payload,
  }
}
export const getWorkFlowSubCategory = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED,
    payload,
  }
}
export const getWorkFlowTaskCategory = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED,
    payload,
  }
}
export const getWorkFlowMitreTactics = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED,
    payload,
  }
}
export const getWorkFlowMitreTechniques = payload => {
  // alert(payload)
  return {
    type: ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED,
    payload,
  }
}
export const getWorkFlowMitreSubTechniques = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED,
    payload,
  }
}
export const getWorkFlowTags = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_TAGS_REQUESTED,
    payload,
  }
}
export const createWorkFlowList = payload => {
  return {
    type: ADMINISTRATION_WORKFLOW_CREATE_REQUESTED,
    payload,
  }
}

export const updateWorkFlowList = ({ payload }) => {
  return {
    type: ADMINISTRATION_WORKFLOW_UPDATE_REQUESTED,
    payload,
  }
}

export const getAssetValueList = payload => {
  return {
    type: ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED,
    payload,
  }
}

export const createAssetsValue = payload => {
  return {
    type: ADMINISTRATION_CREATE_ASSET_VALUE_LIST_REQUESTED,
    payload,
  }
}

export const editAssetsValue = payload => {
  return {
    type: ADMINISTRATION_EDIT_ASSET_VALUE_REQUESTED,
    payload,
  }
}

export const deleteAssetsValue = (payload, query) => {
  return {
    type: ADMINISTRATION_DELETE_ASSET_VALUE_REQUESTED,
    payload,
  }
}

export const changeAssetValueTitle = payload => {
  return {
    type: ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_REQUESTED,
    payload,
  }
}

export const getAssetOperatingSystemList = payload => {
  return {
    type: ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED,
    payload,
  }
}

export const createOperatingSystem = payload => {
  return {
    type: ADMINISTRATION_CREATE_OPERATING_SYSTEM_REQUESTED,
    payload,
  }
}

export const riskManagementThreatRegisterList = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED,
    payload,
  }
}

export const riskManagementThreatRegisterValueList = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED,
    payload,
  }
}

export const getSelectedProbability = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_REQUESTED,
    payload,
  }
}

export const riskManagementProbablity = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED,
    payload,
  }
}

export const deleteRiskManagementProbablity = (id, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED,
    id,
    query
  }
}
export const updateRiskManagementProbablity = (id, payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED,
    id,
    payload,
    query
  }
}
export const changeTitleRequest = (payload, isRiskScore = false) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_REQUESTED,
    payload,
    isRiskScore
  }
}
export const createRiskManagementProbablity = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED,
    payload,
    query
  }
}


export const updateFormulaForProbabilty = (payload, isRiskScore = false) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_REQUESTED,
    payload,
    isRiskScore
  }
}

export const getFormulaForProbabilty = () => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_REQUESTED,
  }
}



export const riskManagementThreatRegisterVulnerabilitiesList = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED,
    payload,
  }
}

export const createRiskManagementThreatRegister = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_REQUESTED,
    payload,
    query
  }
}

export const deleteRiskManagementThreatRegister = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_REQUESTED,
    payload,
  }
}

export const updateRiskManagementThreatRegister = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_REQUESTED,
    payload,
    query
  }
}



export const riskManagementThreatValueList = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED,
    payload,
  }
}

export const createRiskManagementThreatValue = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED,
    payload,
    query
  }
}

export const deleteRiskManagementThreatValue = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED,
    payload,
  }
}

export const updateRiskManagementThreatValue = (payload, id, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED,
    payload,
    id,
    query
  }
}

export const riskManagementControlRegisterList = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED,
    payload,
  }
}

export const createRiskManagementControlRegister = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED,
    payload,
    query
  }
}

export const updateDiskManagementControlRegister = (payload, id, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED,
    payload,
    id,
    query
  }
}

export const deleteRiskManagementControlRegister = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_REQUESTED,
    payload,
    query
  }
}

export const riskManagementBusinessImpactList = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED,
    payload,
  }
}

export const deleteRiskManagementBusinessImpact = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED,
    payload,
    query
  }
}

export const createRiskManagementBusinessImpact = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED,
    payload,
    query
  }
}

export const updateRiskManagementBusinessImpact = (payload, id, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED,
    payload,
    id,
    query
  }
}

export const RiskManagementBusinessImpactChangeTitle = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_REQUESTED,
    payload,
  }
}

export const riskManagementComplianceList = payload => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED,
    payload,
  }
}

export const createRiskManagementCompliance = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED,
    payload,
    query
  }
}

export const deleteRiskManagementCompliance = (payload, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED,
    payload,
    query
  }
}

export const updateRiskManagementCompliance = (payload, id, query) => {
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED,
    payload,
    id,
    query
  }
}

export const automationVendorsList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED,
    payload,
  }
}

export const createAutomationVendorsList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_VENDORS_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const updateAutomationVendorsList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_VENDORS_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const deleteAutomationVendorsList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_VENDORS_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const automationPublishersList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED,
    payload,
  }
}

export const createAutomationPublishers = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_PUBLISHERS_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const updateAutomationPublishers = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_PUBLISHERS_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const deleteAutomationPublishers = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_PUBLISHERS_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const automationActionIOList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED,
    payload,
  }
}

export const createAutomationActionIO = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONIO_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const automationActionIOValidation = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED,
    payload,
  }
}

export const updateAutomationActionIO = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONIO_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const deleteAutomationActionIO = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONIO_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const duplicateAutomationAction = (id, query) => {
  return {
    type: AUTOMATION_ACTION_DUPLICATE_REQUESTED,
    id,
    query
  }
}

export const actionOutputList = (id) => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED,
    id,
  }
}
export const automationThreatList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED,
    payload,
  }
}

export const createAutomationThreatList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_THREAT_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const updateAutomationThreatList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_THREAT_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const deleteAutomationThreatList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_THREAT_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const automationFamilyList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED,
    payload,
  }
}

export const createAutomationfamilyList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_CASE_FAMILY_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const updateAutomationfamilyList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_CASE_FAMILY_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const deleteAutomationFamilyList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_CASE_FAMILY_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const editOperatingSystem = payload => {
  return {
    type: ADMINISTRATION_EDIT_OPERATING_SYSTEM_REQUESTED,
    payload,
  }
}

export const deleteOperatingSystem = payload => {
  return {
    type: ADMINISTRATION_DELETE_OPERATING_SYSTEM_REQUESTED,
    payload,
  }
}

export const getAssetDepartmentList = payload => {
  return {
    type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED,
    payload,
  }
}

export const createAssetDepartment = payload => {
  return {
    type: ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_REQUESTED,
    payload,
  }
}

export const editAssetDepartment = payload => {
  return {
    type: ADMINISTRATION_EDIT_DEPARTMENT_REQUESTED,
    payload,
  }
}

export const deleteDepartments = payload => {
  return {
    type: ADMINISTRATION_DELETE_DEPARTMENT_REQUESTED,
    payload,
  }
}

export const administratorCasesCategoriesList = payload => {
  return {
    type: ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED,
    payload,
  }
}

export const administratorCasesCategoryDisposition = () => {
  return {
    type: ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED,
  }
}

export const administratorCasesCategoryDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_CATEGORY_DELETE_REQUESTED,
    id,
    query,
  }
}

export const administratorCasesCategoryUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_CATEGORY_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}

export const administratorCasesCategoryCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_CATEGORY_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const administratorCasesSubCategoriesList = payload => {
  return {
    type: ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED,
    payload,
  }
}

export const administratorSubCasesCategoryDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorSubCasesCategoryUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorCasesSubCategoryCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const getAssetBusinessGroupList = payload => {
  return {
    type: ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED,
    payload,
  }
}

export const createBusinessGroup = payload => {
  return {
    type: ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_REQUESTED,
    payload,
  }
}

export const editBusinessGroup = payload => {
  return {
    type: ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_REQUESTED,
    payload,
  }
}

export const deleteBusinessGroup = payload => {
  return {
    type: ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_REQUESTED,
    payload,
  }
}

export const changeAssetBusinessGroupTitle = payload => {
  return {
    type: ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_REQUESTED,
    payload,
  }
}

export const getAssetTypeList = payload => {
  return {
    type: ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED,
    payload,
  }
}

export const changeAssetTypeTitle = payload => {
  return {
    type: ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_REQUESTED,
    payload,
  }
}

export const assetConfidentiality = (payload) => {
  return {
    type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED,
    payload,
  }
}

export const createConfidentiality = (payload) => {
  return {
    type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_REQUESTED,
    payload,
  }
}

export const deleteConfidentiality = (id, query) => {
  return {
    type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_REQUESTED,
    id,
    query
  }
}

export const updateConfidentiality = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const deleteAssetType = payload => {
  return {
    type: ADMINISTRATION_DELETE_ASSET_TYPE_REQUESTED,
    payload,
  }
}

export const administratorCasesPrimarySubCategoryCreate = () => {
  return {
    type: ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED,
  }
}

export const administratorCasesDispositions = payload => {
  return {
    type: ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED,
    payload,
  }
}
export const administratorCasesDispositionDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_DISPOSITION_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorCasesDispositionUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_DISPOSITION_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorCasesDispositionCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_DISPOSITION_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const administratorSubCasesDispositions = payload => {
  return {
    type: ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED,
    payload,
  }
}

export const administratorSubCasesDispositionsDropdownList = () => {
  return {
    type: ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED,
  }
}

export const administratorCasesSubDispositionDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorCasesSubDispositionUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorCasesSubDispositionCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const vulnerabilityTechnicalList = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED,
    payload,
  }
}

export const assetDropDownList = payload => {
  return {
    type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED,
    payload,
  }
}

export const deleteTechnicalVulnerability = (id, query) => {
  return {
    type: ADMINISTRATION_VULNERABILITY_TECHNICAL_DELETE_REQUESTED,
    id,
    query
  }
}

export const subGroupDropDownList = payload => {
  return {
    type: ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED,
    payload,
  }
}

export const vulnerabilityTechnicalSeverityList = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED,
    payload,
  }
}

export const valTechserverity = payload => {
  return {
    type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
    payload,
  }
}
export const deleteVulnerabilityTechserverity = (id, query) => {
  return {
    type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
    id,
    query
  }
}
export const updateVulnerabilityTechserverity = (id, payload, query) => {
  return {
    type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
    id,
    payload,
    query
  }
}
export const createVulnerabilityTechserverity = (payload, query) => {
  return {
    type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
    payload,
    query
  }
}
export const valNoTechserverity = payload => {
  return {
    type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
    payload,
  }
}
export const deleteNonValTechserverity = (id, query) => {
  return {
    type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
    id,
    query
  }
}
export const updateNonValTechserverity = (id, payload, query) => {
  return {
    type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
    id,
    payload,
    query
  }
}
export const createNonValTechserverity = (payload, query) => {
  return {
    type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
    payload,
    query
  }
}

export const ownerDropDownList = payload => {
  return {
    type: ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED,
    payload,
  }
}

export const createTechnicalVulnerability = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_TECHNICAL_CREATE_REQUESTED,
    payload,
  }
}

export const departmentDropDownList = payload => {
  return {
    type: ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED,
    payload,
  }
}

export const assetTypeDropDownList = payload => {
  return {
    type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED,
    payload,
  }
}
export const updateTechnicalVulnerability = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_TECHNICAL_UPDATE_REQUESTED,
    payload,
  }
}

export const createAssetType = payload => {
  return {
    type: ADMINISTRATION_CREATE_ASSET_TYPE_REQUESTED,
    payload,
  }
}

export const vulnerabilityNonTechnicalList = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_REQUESTED,
    payload,
  }
}

export const administratorCasesDitectionsMethod = payload => {
  return {
    type: ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED,
    payload,
  }
}

export const administratorCasesSlaList = payload => {
  return {
    type: ADMINISTRATION_CASES_SLA_LIST_REQUESTED,
    payload,
  }
}




export const administratorCasesSlaCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_SLA_CREATE_REQUESTED,
    payload,
    query
  }
}

export const administratorCasesSlaDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_SLA_DELETE_REQUESTED,
    id,
    query
  }
}

export const administratorCasesSlaUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_SLA_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const administratorCasesSlaViewLog = (id) => {
  return {
    type: ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED,
    id
  }
}

export const administratorCasesSlaViewRule = (id) => {
  return {
    type: ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED,
    id
  }
}

export const administratorCasesDitectionMethodDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorCasesDitectionMethodUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorCasesDitectionMethodCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const administratorCasesNcissList = payload => {
  return {
    type: ADMINISTRATION_CASES_NCISS_LIST_REQUESTED,
    payload,
  }
}

export const administratorCasesNcissUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorCasesNcissCategoryList = () => {
  return {
    type: ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED,
  }
}

export const administratorCasesNcissCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_CREATE_REQUESTED,
    payload,
    query,
  }
}

export const administratorCasesNcisscategories = payload => {
  return {
    type: ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED,
    payload,
  }
}
export const administratorCasesNcisscategorieUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorCasesNcisscategorieCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_REQUESTED,
    payload,
    query,
  }
}
export const administratorCasesNcisscategorieDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorCasesNcissPerioritiesList = payload => {
  return {
    type: ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED,
    payload,
  }
}

export const administratorCasesNcissPerioritieDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_REQUESTED,
    id,
    query,
  }
}

export const administratorCasesNcissPerioritieUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}

export const administratorCasesNcissPerioritieCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_REQUESTED,
    payload,
    query,
  }
}
export const administratorCasesNcissDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_NCISS_DELETE_LIST_REQUESTED,
    id,
    query,
  }
}
export const administratorCasesLocationList = payload => {
  return {
    type: ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED,
    payload,
  }
}
export const administratorLocationDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_LOCATION_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorLocationUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_LOCATION_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorLocationCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_LOCATION_CREATE_REQUESTED,
    payload,
    query,
  }
}
export const administratorCasesContainmentStatusList = payload => {
  return {
    type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED,
    payload,
  }
}
export const administratorContainmentStatusDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorContainmentStatusUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorContainmentStatusCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_REQUESTED,
    payload,
    query,
  }
}
export const administratorCasesLessonList = payload => {
  return {
    type: ADMINISTRATION_CASES_LESSON_LIST_REQUESTED,
    payload,
  }
}
export const administratorLessonDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_LESSON_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorLessonUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_LESSON_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorLessonCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_LESSON_CREATE_REQUESTED,
    payload,
    query,
  }
}
export const administratorCasesContainedByList = payload => {
  return {
    type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED,
    payload,
  }
}
export const administratorContainedByDelete = (id, query) => {
  return {
    type: ADMINISTRATION_CASES_CONTAINED_BY_DELETE_REQUESTED,
    id,
    query,
  }
}
export const administratorContainedByUpdate = (id, payload, query) => {
  return {
    type: ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_REQUESTED,
    id,
    payload,
    query,
  }
}
export const administratorContainedByCreate = (payload, query) => {
  return {
    type: ADMINISTRATION_CASES_CONTAINED_BY_CREATE_REQUESTED,
    payload,
    query,
  }
}
export const vulnerabilityNonTechnicalSeverityList = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_SEVERITY_REQUESTED,
    payload,
  }
}

export const vulnerabilityThreatList = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED,
    payload,
  }
}

export const createNonTechnicalVulnerability = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_CREATE_REQUESTED,
    payload,
  }
}

export const updateNonTechnicalVulnerability = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_TECHNICAL_NON_UPDATE_REQUESTED,
    payload,
  }
}

export const deleteNonTechnicalVulnerability = payload => {
  return {
    type: ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_DELETE_REQUESTED,
    payload,
  }
}

export const threatIntelCategoryList = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED,
    payload,
  }
}

export const createthreatIntelCategoryList = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_CATEGORY_CREATE_REQUESTED,
    payload,
  }
}

export const deletethreatIntelCategory = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_CATEGORY_DELETE_REQUESTED,
    payload,
  }
}

export const updatethreatIntelCategory = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_CATEGORY_UPDATE_REQUESTED,
    payload,
  }
}

export const threatIntelSubCategoryList = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED,
    payload,
  }
}

export const createthreatIntelSubCategoryList = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_CREATE_REQUESTED,
    payload,
  }
}

export const deletethreatIntelSubCategory = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_DELETE_REQUESTED,
    payload,
  }
}

export const updatethreatIntelSubCategory = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_UPDATE_REQUESTED,
    payload,
  }
}

export const threatIntelSeverityList = () => {
  return { type: ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED }
}

export const threatIntelPrimaryCategoryList = () => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED,
  }
}

export const editAssetType = payload => {
  return {
    type: ADMINISTRATION_EDIT_ASSET_TYPE_REQUESTED,
    payload,
  }
}

export const getThreatDispositionList = payload => {
  return {
    type: ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED,
    payload,
  }
}

export const createThreatDisposition = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_DISPOSITION_CREATE_REQUESTED,
    payload,
  }
}

export const updateThreatDisposition = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_DISPOSITION_UPDATE_REQUESTED,
    payload,
  }
}

export const deleteThreatDisposition = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_DISPOSITION_DELETE_REQUESTED,
    payload,
  }
}

export const automationIngestionList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED,
    payload,
  }
}

export const enableAdvisory = payload => {
  return {
    type: ENABLE_ADVISORY_REQUESTED,
    payload,
  }
}

export const createAutomationIngestion = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_INGESTION_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const updateAutomationIngestion = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_INGESTION_UPDATE_LIST_REQUESTED,
    payload,
  }
}

export const deleteAutomationIngestion = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_INGESTION_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const intestionType = payload => {
  return {
    type: INGESTION_TYPE_REQUESTED,
    payload,
  }
}

export const ingestionWidget = payload => {
  return {
    type: INGESTION_WIDGET_REQUESTED,
    payload,
  }
}

export const ingestionFrequency = payload => {
  return {
    type: INGESTION_FREQUENCY_REQUESTED,
    payload,
  }
}

export const ingestionApplication = payload => {
  return {
    type: INGESTION_APPLICATION_REQUESTED,
    payload,
  }
}

export const ingestionUsers = payload => {
  return {
    type: INGESTION_USERS_REQUESTED,
    payload,
  }
}

export const ingestionAction = payload => {
  return {
    type: INGESTION_ACTION_REQUESTED,
    payload,
  }
}

export const automationActionsList = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED,
    payload,
  }
}

export const createAutomationActions = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONS_CREATE_LIST_REQUESTED,
    payload,
  }
}

export const updateAutomationActions = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONS_UPDATE_LIST_REQUESTED,
    payload,
  }
}
export const deleteAutomationActions = payload => {
  return {
    type: ADMINISTRATION_AUTOMATION_ACTIONS_DELETE_LIST_REQUESTED,
    payload,
  }
}

export const automationConfigurationData = (id) => {
  return {
    type: AUTOMATION_ACTION_CONFIGURATION_REQUESTED,
    id,
  }
}
export const automationConfigurationDataPost = (id, payload, query) => {
  return {
    type: AUTOMATION_ACTION_CONFIGURATION_POST_REQUESTED,
    id,
    payload,
    query
  }
}

export const automationViewScript = (id) => {
  return {
    type: AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED,
    id
  }
}
export const automationnMultiDataPost = (id, payload, query) => {
  return {
    type: AUTOMATION_ACTION_MULTIDATA_POST_REQUESTED,
    id,
    payload,
    query
  }
}

export const actionsType = payload => {
  return {
    type: ACTIONS_TYPE_REQUESTED,
    payload,
  }
}

export const actionsScriptType = payload => {
  return {
    type: ACTIONS_SCRIPT_TYPE_REQUESTED,
    payload,
  }
}

export const actionsIoType = payload => {
  return {
    type: ACTIONS_IO_TYPE_REQUESTED,
    payload,
  }
}

export const actionsApplication = payload => {
  return {
    type: ACTIONS_APPLICATION_REQUESTED,
    payload,
  }
}

export const getIngestionMultiData = payload => {
  return {
    type: GET_INGESTION_MULTI_DATA_REQUESTED,
    payload,
  }
}

export const addIngestionMultiData = payload => {
  return {
    type: ADD_INGESTION_MULTI_DATA_REQUESTED,
    payload,
  }
}

export const getIngestionDropDown = payload => {
  return {
    type: GET_INGESTION_MAPPING_REQUESTED,
    payload,
  }
}

export const logsAppsList = payload => {
  return {
    type: ADMINISTRATION_LOGS_APPS_LIST_REQUESTED,
    payload,
  }
}

export const appLogsNotifications = payload => {
  return {
    type: APP_LOGS_NOTIFICATION_LIST_REQUESTED,
    payload,
  }
}
export const setAlertCountAction = payload => {
  return {
    type:   SET_ALERT_COUNT,
    payload,
  }
}


export const logsAuthenticateList = payload => {
  return {
    type: ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED,
    payload,
  }
}

export const logsActivityList = payload => {
  return {
    type: ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED,
    payload,
  }
}

export const logsIngestionList = payload => {
  return {
    type: ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED,
    payload,
  }
}

export const logsPreIngestionList = payload => {
  return {
    type: ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED,
    payload,
  }
}

export const productsRegisterList = payload => {
  return {
    type: ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED,
    payload,
  }
}

export const productsRegisterDeleteList = payload => {
  return {
    type: ADMINISTRATION_PRODUCT_FAILED_REGISTER_DELETE_REQUESTED,
    payload,
  }
}

export const accessUsersList = payload => {
  return {
    type: ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED,
    payload,
  }
}

export const accessUserDeleteList = payload => {
  return {
    type: ADMINISTRATION_ACCESS_USERS_DELETE_REQUESTED,
    payload,
  }
}

export const accessUsersAuthenticationList = payload => {
  return {
    type: ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED,
    payload,
  }
}

export const accessUsersPermissionList = payload => {
  return {
    type: ADMINISTRATION_ACCESS_PERMISSION_REQUESTED,
    payload,
  }
}

export const accessUsersDashboardList = payload => {
  return {
    type: ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED,
    payload,
  }
}

export const accessUsersLandingPageList = payload => {
  return {
    type: ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED,
    payload,
  }
}

export const accessUsersGroupList = payload => {
  return {
    type: ADMINISTRATION_ACCESS_GROUP_REQUESTED,
    payload,
  }
}


export const getSessionPassword = payload => {
  return {
    type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED,
    payload,
  }
}

export const getSessionPasswordUpdate = (id, payload) => {
  return {
    type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_REQUESTED,
    id,
    payload,
  }
}

export const onUpdateAccessUser = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_USERS_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const onInviteUser = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_USER_INVITE_REQUESTED,
    payload,
    query
  }
}

export const onResetPassword = (id, payload, onCloseModal, setResetError) => {
  return {
    type: ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_REQUESTED,
    id,
    payload,
    onCloseModal,
    setResetError
  }
}

export const onGetAccessControlGroupsTab = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED,
    payload,
  }
}

export const onUpdateAccessControlGroup = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const onDeleteAccessControlGroup = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_REQUESTED,
    id,
    query,
  }
}

export const onCreateAccessControlGroup = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_REQUESTED,
    payload,
    query
  }
}


export const onGetAccessControlRolesTab = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED,
    payload,
  }
}

export const onUpdateAccessControlRole = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const onDeleteAccessControlRole = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_REQUESTED,
    id,
    query,
  }
}

export const onCreateAccessControlRole = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_REQUESTED,
    payload,
    query
  }
}

export const onGetAccessControlActionTab = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED,
    payload,
  }
}
export const onGetAccessControlActionGroup = () => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED,
  }
}

export const onUpdateAccessAction = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const onDeleteAccessAction = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_REQUESTED,
    id,
    query,
  }
}

export const onCreateAccessAction = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_REQUESTED,
    payload,
    query
  }
}

export const onGetAccessControlActionGroupTab = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED,
    payload,
  }
}

export const onUpdateAccessActionGroup = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const onDeleteAccessActionGroup = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_REQUESTED,
    id,
    query,
  }
}

export const onCreateAccessActionGroup = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_REQUESTED,
    payload,
    query
  }
}

export const onGetAccessControlAuthSettingList = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED,
    payload,
  }
}

export const onGetAuthencticationType = () => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED
  }
}
export const onGetAuthenticationVendor = () => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED
  }
}
export const onGetListOfPrivileges = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED,
    payload,
  }
}
export const onGetAllPriviliges = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED,
    payload,
  }
}
export const updatePrivileges = (id, payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_REQUESTED,
    id,
    payload,
  }
}
export const createPrivileges = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_REQUESTED,
    payload,
  }
}
export const deletePrivileges = (id, payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_REQUESTED,
    id,
    payload,
  }
}

export const onGetListOfRoles = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED
  }
}

export const onUpdateAccessThirdPartyAuth = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_REQUESTED,
    id,
    payload,
    query
  }
}

export const onDeleteAccessThirdPartyAuth = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_REQUESTED,
    id,
    query,
  }
}

export const onCreateAccessThirdPartyAuth = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_REQUESTED,
    payload,
    query
  }
}

export const onGetListOfProductFeedback = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED,
    payload,
  }
}
export const onDeleteListProductFeedback = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_REQUESTED,
    id,
    query
  }
}

export const onProductSettingHealth = ()=>{
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED
  }
}

export const onGetServerConnectivity = ()=>{
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_REQUESTED
  }
}

export const onGetListofBackups = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED,
    payload,
  }
}

export const onDowloadBackup = (id) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_REQUESTED,
    id
  }
}
export const onDeleteListBackupList = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_REQUESTED,
    id,
    query
  }
}
export const onCreateBackup = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_REQUESTED,
    payload,
    query
  }
}
export const administratorProductSettingNotify = () => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED,
  }
}
export const administratorGetBackupSetting = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_REQUESTED,
    payload
  }
}
export const administratorGetSftpConfigure = (payload) =>{
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_REQUESTED,
    payload
  }
}
export const onImporatBackup = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_REQUESTED,
    payload
  }
}


export const onRestore = (id) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_REQUESTED,
    id
  }
}

export const configureAutoBckUps = (payload) => {
  return {
    type: ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_REQUESTED,
    payload
  }
}

export const SFTPConfigure = (payload) => {
  return {
    type: ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED,
    payload,
  }
}
export const SFTPConfigureOld = (payload) => {
  return {
    type: ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED_Old,
    payload,
  }
}

export const onGetWidgetList = (payload) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED,
    payload,
  }
}

export const onUpdateWidgetList = (id, payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}

export const onDeleteWidgetList = (id, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_REQUESTED,
    id,
    query,
  }
}

export const onCreateWidgetList = (payload, query) => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_REQUESTED,
    payload,
    query
  }
}

export const onGetWidgetSource = () => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED,
  }
}
export const onGetWidgetGroup = () => {
  return {
    type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED,
  }
}

export const onGetLicencesInfo = (payload) => {
  return {
    type: ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED,
    payload
  }
}


export const uploadLicenseInfo = (payload) => {
  return {
    type: ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_REQUESTED,
    payload
  }
}

export const getModuleSources = () => {
  return {
    type: ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED,
  }
}
export const onPostProductDetails = (payload) => {
  return {
    type: ADMINISTRATION_PRODUCT_LICENSE_CREATE_REQUESTED,
    payload
  }
}

export const onUpdateLicenseDetails = (id, payload, query) => {
  return {
    type: ADMINISTRATION_PRODUCT_LICENSE_UPDATE_REQUESTED,
    id,
    payload,
    query
  }
}


export const onDeleteLicense = (id, query) => {
  return {
    type: ADMINISTRATION_PRODUCT_LICENSE_DELETE_REQUESTED,
    id,
    query,
  }
}
export const onDownloadLicense = (id,name,query) => {
  return {
    type: ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_REQUESTED,
    id,
    name,
    query,
  }
}
export const onPatchLicenseRequest = (payload) => {
  return {
    type: ADMINISTRATION_PRODUCT_SETTINGS_PATCH_REQUESTED,
    payload
  }
}

export const getNotifications = () => {
  return {
    type: ADMINISTRATION_NOTIFICATIONS_LIST_REQUESTED,
  }
}

export const updateNotifictions = (payload) => {
  return {
    type: ADMINISTRATION_NOTIFICATIONS_UPDATE_REQUESTED,
    payload
  }
}

export const getFieldSettings = () => {
  return {
    type: ADMINISTRATION_FIELD_SETTING_LIST_REQUESTED,
  }
}

export const updateFieldSettings = (payload) => {
  return {
    type: ADMINISTRATION_FIELD_SETTING_UPDATE_REQUESTED,
    payload
  }
}

export const getCaseFieldSettings = () => {
  return {
    type: ADMINISTRATION_CASE_FIELD_SETTING_LIST_REQUESTED,
  }
}

export const updateCaseFieldSettings = (payload) => {
  return {
    type: ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_REQUESTED,
    payload
  }
}
export const duplicateSettings = (payload) => {
  return {
    type: ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_REQUESTED,
    payload
  }
}


export const getAllTabbsHeading = (payload) => {
  return {
    type: ADMINISTRATION_GET_TABS_HEADING_REQUESTED,
    payload
  }
}

export const getRiskMtrix = () => {
  return {
    type: ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED,
  }
}

export const onGetRiskMetaList = ()=>{
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED
  }
}

export const onRiskMetaLabelUpdate = (id, payload)=>{
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_REQUESTED,
    id,
    payload
  }
}

export const onUpdateRiskMeta = (payload)=>{
  return {
    type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_REQUESTED,
    payload
  }
}

export const createRiskMatrix = (payload) => {
  return {
    type: ADMINISTRATION_CREATE_RISK_MATRIX_REQUESTED,
    payload,
  }
}

export const resetRiskColor = (payload) => {
  return {
    type: ADMINISTRATION_RESET_RISK_MATRIX_REQUESTED,
    payload
  }
}

export const getRiskDropDownsData = (payload) => {
  return {
    type: ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_REQUESTED,
    payload
  }
}


export function getCasesContainerList() {
  return {
    type: ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED
  }
}


export function getCasesDespositionList(name) {
  return {
    type: ADMINISTRATION_CASES_DESPOSITION_REQUESTED,
    name
  }
}


export function getCasesCategoryList(name) {
  return {
    type: ADMINISTRATION_CASES_CATEGORY_REQUESTED,
    name
  }
}

export function getThreatActorList() {
  return {
    type: ADMINISTRATION_THREAT_ACTOR_REQUESTED
  }
}
export const threatIntelThreatActorList = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED,
    payload,
  }
}



export const createThreatIntelThreatActor = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_REQUESTED,
    payload,
  }
}

export const deleteThreatInteThreatActor = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_REQUESTED,
    payload,
  }
}
export const updateThreatIntelThreatActor = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED,
    payload,
  }
}

export const associateGroupThreatActor = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED,
    payload,
  }
}

export const associateGroupThreatActorList =() => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED,
  }
}

export const threatIntelAssociatedThreatActorList = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED,
    payload,
  }
}

export const createThreatIntelAssociatedThreatActor = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_REQUESTED,
    payload,
  }
}

export const deleteThreatInteAssociatedThreatActor = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_REQUESTED,
    payload,
  }
}
export const updateThreatIntelAssociatedThreatActor = payload => {
  return {
    type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED,
    payload,
  }
}


export const getUserList = () => {
  return {
    type: GET_USER_EMAILS_LIST_REQUESTED,
  }
}
export const updateLogNotifictions = (payload) => {
  return {
    type: ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_REQUESTED,
    payload
  }
}
export const getLogNotifications = () => {
  return {
    type: ADMINISTRATION_NOTIFICATIONS_LOG_LIST_REQUESTED,
  }
}

export const getAlertsNotify = () => {
  return {
    type: ADMINISTRATION_FIELD_SETTING_LIST_REQUESTED,
  }
}

export const updateAlertsNotify = (payload) => {
  return {
    type: ADMINISTRATION_FIELD_SETTING_UPDATE_REQUESTED,
    payload
  }
}

export function getIngestionOutputAction(payload) {
  return {
    type: INGESTION_OUTPUT_REQUESTED,
    payload,
  }
}
export function updateIngestionOutputAction(payload) {
  return {
    type: UPDATE_INGESTION_OUTPUT_REQUESTED,
    payload,
  }
}



export const AffectedVendorList = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED,
    payload,
  }
}

export const createAffectedVendor = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_VENDOR_CREATE_REQUESTED,
    payload,
  }
}

export const deleteAffectedVendor = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_VENDOR_DELETE_REQUESTED,
    payload,
  }
}

export const updateAffectedVendor = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_VENDOR_UPDATE_REQUESTED,
    payload,
  }
}
export const AffectedProductList = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED,
    payload,
  }
}

export const createAffectedProduct = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_PRODUCT_CREATE_REQUESTED,
    payload,
  }
}

export const deleteAffectedProduct = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_PRODUCT_DELETE_REQUESTED,
    payload,
  }
}

export const updateAffectedProduct = payload => {
  return {
    type: ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_REQUESTED,
    payload,
  }
}
export const updateSLAStatus = payload => {
  return {
    type: UPDATE_SLA_STATUS_REQUESTED,
    payload,
  }
}
export const preIngestionRuleList = payload => {
  return {
    type: PRE_INGESTION_RULE_REQUESTED,
    payload,
  }
}
export const createPreIngestionRule = payload => {
  return {
    type: PRE_INGESTION_RULE_CREATE_REQUESTED,
    payload,
  }
}

export const updatePreIngestionRule = payload => {
  return {
    type: PRE_INGESTION_RULE_UPDATE_REQUESTED,
    payload,
  }
}

export const deletePreIngestionRule = payload => {
  return {
    type: PRE_INGESTION_RULE_DELETE_REQUESTED,
    payload,
  }
}