import axios from 'axios';
import API from '../config/endpoints.config';

const FileDownload = require('js-file-download');

export async function reportlistSaga(query) {
  const userToken = localStorage.getItem('AccessToken');
  let api = `${API.reportsModule}`;
  if (query) {
    api = `${API.reportsModule}` + `?${query.replace('iti_showing', 'per-page')}`;
  }

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function widgetListSaga(query) {
  const userToken = localStorage.getItem('AccessToken');
  let api = '';
  if (query) {
    api = `${API.dashboards}/all-widget-list?expand=graGroup,dataPath${query}`;
  } else {
    api = `${API.dashboards}/all-widget-list?expand=graGroup,dataPath`;
  }

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function reportDeletionSaga(reportId) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.reportsModule}/${reportId}`;

  const response = await axios.delete(api, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function reportDuplicationSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.baseUrl}/duplicate-report`;

  const data = new FormData();
  data.append("report_id", Number(payload));

  const response = await axios.post(api, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function schedulelistSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.baseUrl}/scheduler?id=${payload}`;
  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function coverImageslistSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.baseUrl}/get-cover-pages`;
  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function widgetDataSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const data = new FormData();
  data.append("widget_id", parseInt(payload?.id));
  const api = `${API.baseUrl}/test-widget-query`;
  const response = await axios.post(api, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getReportByIdSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.baseUrl}/report?id=${payload}`;
  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function reportCreationSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const { id, ...respPayload } = payload || {};
  let api = `${API.baseUrl}/report`;

  if (id) {
    api = `${API.baseUrl}/report/${id}`;
  }

  let formData = new FormData();
  for (let key in respPayload) {
    if (key == 'report_layout_data') {
      formData.append(key, JSON.stringify(respPayload[key]));
    } else if (key == 'report_data') {
      formData.append(key, JSON.stringify(respPayload[key]));
    }
    else {
      formData.append(key, respPayload[key]);
    }
  }
  if (payload?.saveAsDraft) {
    formData.append("save_as_draft", 1);
  }

  if (id) {
    const response = await axios.put(api, formData, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response.data;
  } else {
    const response = await axios.post(api, formData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response?.data;
  }
}

export async function excelReportCreationSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const { id, ...respPayload } = payload || {};
  let api = `${API.baseUrl}/report`;

  if (id) {
    api = `${API.baseUrl}/report/${id}`;
  }

  let formData = new FormData();
  for (let key in respPayload) {
    if (key == 'report_layout_data') {
      formData.append(key, JSON.stringify(respPayload[key]));
    } else if (key == 'report_data') {
      formData.append(key, JSON.stringify(respPayload[key]));
    }
    else {
      formData.append(key, respPayload[key]);
    }
  }

  if (id) {
    const response = await axios.put(api, formData, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response.data;
  } else {
    const response = await axios.post(api, formData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response?.data;
  }
}

export async function reportScheduleCreationSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api = `${API.baseUrl}/scheduler`;
  let response = {};
  if (payload?.scheduleId) {
    api = `${API.baseUrl}/scheduler/${payload?.scheduleId}`;
  }

  let formData = new FormData();
  for (let key in payload) {
    formData.append(key, payload[key]);
  }

  if (payload.scheduleId) {
    response = await axios.put(api, formData, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    });
  } else {
    response = await axios.post(api, formData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    });
  }
  return response?.data;
}

export async function scheduleDeletionSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.baseUrl}/scheduler/${id}`;

  const response = await axios.delete(api, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function reportGenerationSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.baseUrl}/process-pdf-report?id=${payload?.id}`;

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
    responseType: 'blob',
  });
  FileDownload(response.data, `${payload?.name}.pdf`);
  return response.data;
}

export async function getAllReportWidgets(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let response = [];
  const { urls, cancelToken } = payload || {};
  const responses = await axios.all(urls.map((endpoint) =>
    axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
        cancelToken: cancelToken,
      }
    }))).then(
      (data) => {
        response = data?.map(item => item.data.data)
      },
    );
  return response;
}