
import {
    VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED,
    GET_VULNERABLITY_MANAGEMENT_BY_ID_REQUESTED,
    VULNERABILITY_EXPORT_PDF_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED,
    VUNERABILITY_EXPORT_EXCEL_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_DELETE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_REQUESTED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_BULK_UPDATE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_REQUESTED,
    VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_REQUESTED,
    VULNERABILITY_MANAGEMENT_UPDATE_REQUESTED,
    VULNERABILITY_MANAGEMENT_ADD_ASSET_REQUESTED,
    VULNERABILITY_MANAGEMENT_DELETE_ASSET_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED,
    RESET_VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_GET_ASSESSMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED,
    VULNERABILITY_MANAGEMENT_RESET_ASSESSMENT,
    UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_REQUESTED,
    ADD_TASK_VULNERABILITY_MANAGEMENT_REQUESTED,
    VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_UPLOAD_REQUESTED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED,
    VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_REQUESTED,
    VULNERABILITY_TASK_CATEGORIES_REQUESTED,
    VULNERABILITY_DELETE_ATTACHMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_COMMENT_FAILED,
    VULNERABILITY_MANAGEMENT_COMMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_OPEN_CASES_REQUESTED,
    VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED,
    VULNERABILITY_VULNERABILITY_ASSET_REQUESTED,
    VULNERABILITY_SEARCH_BY_NAME_ASSET_REQUESTED,
    VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_SUCCESSED,
    VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_SUCCESSED,
    ASSESSMENT_TEMPLATE_CREATE_REQUESTED,
    VULNERABILITY_TASK_DEPARTMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED,
    VULNERABILITY_IMAGE_LOAD_REQUESTED,
    VULNERABILITY_UPDATE_ASSESSMENT_REQUESTED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED,
    VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_REQUESTED,
    DOWNLOAD_XML_NESSUS_FILE,
    ASSESSMENT_DUPLICATE_REQUESTED,
    RESET_DUPLICATE_ASSESSMENT_STORE,
    VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED,
} from '../constants/actionTypes'

export const vulnerabilityListAction = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED,
        payload,
    };
};
export const vulnerabilityUpdateAction = (id, payload, isDetail = false, assessmentId = false, module = '') => {
    return {
        type: VULNERABILITY_MANAGEMENT_UPDATE_REQUESTED,
        id,
        payload,
        isDetail,
        assessmentId,
        module
    };
};

export const vulnerabilityUpdateAssessmentAction = (id, payload, isDetail = false, assessmentId = false, assetId = false, module = '', callback) => {
    return {
        type: VULNERABILITY_UPDATE_ASSESSMENT_REQUESTED,
        id,
        payload,
        isDetail,
        assessmentId,
        assetId,
        module,
        callback
    };
};
export const vulnerabilityAddAsset = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ADD_ASSET_REQUESTED,
        payload
    };
};
export const vulnerabilityDeleteAsset = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_DELETE_ASSET_REQUESTED,
        payload
    };
};

export const vulnerabilityDeleteListAction = (id, query) => {
    return {
        type: VULNERABILITY_MANAGEMENT_DELETE_LIST_REQUESTED,
        id,
        query
    };
};

export const getVulnerabilityDetail = (payload) => {
    return {
        type: GET_VULNERABLITY_MANAGEMENT_BY_ID_REQUESTED,
        payload
    };
}

export function exportPDF(payload) {
    return {
        type: VULNERABILITY_EXPORT_PDF_LIST_REQUESTED,
        payload,
    };
}
// export function exportExel(payload) {
//     return {
//         type: VUNERABILITY_EXPORT_EXCEL_LIST_REQUESTED,
//         payload,
//     };
// }
export function assessmentListAction(payload) {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED,
        payload,
    };
}
export function assessmentImportAction(payload, history) {

    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED,
        payload,
        history
    };
}
export function resetAssessmentImportAction(payload) {

    return {
        type: VULNERABILITY_MANAGEMENT_RESET_ASSESSMENT,
        payload,
    };
}

export const vulnerabilityAssessmentDeleteListAction = (id, query) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_REQUESTED,
        id,
        query
    };
};


export const vulnerabilityAssessmentGenerateReportAction = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_REQUESTED,
        payload
    };
};
export const vulnerabilityAssessmentTemplateListAction = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED,
        payload
    };
};
export function vulnerabilityAssessmentTemplateCreation(payload) {
    return {
        type: ASSESSMENT_TEMPLATE_CREATE_REQUESTED,
        payload
    }
}
export const assessmentLogListAction = (id) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED,
        id
    };
};
export const vulnerabilityAssessmentBulkUpdateAction = (payload, query, path) => {
    return {
        type: VULNERABILITY_MANAGEMENT_BULK_UPDATE_REQUESTED,
        payload,
        query,
        path
    };
};
export const attackTypeListVulnerabilityAction = () => {
    return {
        type: VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED,
    };
};
export const assetTypeListVulnerabilityAction = () => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED,
    };
};
export const severityListTypeListVulnerabilityAction = () => {
    return {
        type: VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED,
    };
};

export const vulnerabilityAssessmentCreateAction = (payload, query, callback) => {
    return {
        type: VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_REQUESTED,
        payload,
        query,
        callback
    };
};

export const vulnerabilityAssessmentUpdateAction = (id, payload, query, callback) => {
    return {
        type: VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_REQUESTED,
        id,
        payload,
        query,
        callback
    };
};

export const vulnerabilityAssessmentApplicationList = (source) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED,
        source
    };
};

export const vulnerabilityAssessmentSourceFilterList = (source) => {
    return{
        type:VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED,
        source
    }
}

export const vulnerabilityAssessmentActionList = (id) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED,
        id
    };
};

export const resetVulnerabilityAssessmentActionList = () => {
    return {
        type: RESET_VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED,
    };
};

export const vulnerabilityExcutionAssessmentActionList = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_REQUESTED,
        payload,
    };
};

export const deleteMultiVulnerabilityActionList = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_REQUESTED,
        payload,
    };
};


export const getValnerabilityAssessments = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED,
        payload
    }
}

export const addAssessmentVulnerability = (payload, assessmentId, callback) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_REQUESTED,
        payload,
        assessmentId,
        callback
    }
}

export const deleteAssessmentVulnerability = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_REQUESTED,
        payload
    }
}
export const updatePartialVulnerability = (id, data, showMessage = false) => {
    return {
        type: UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_REQUESTED,
        payload: {
            id,
            data,
            showMessage
        },
    };
};
// Add Task
export const addTaskVulnerabilityManagement = (payload) => {
    return {
        type: ADD_TASK_VULNERABILITY_MANAGEMENT_REQUESTED,
        payload
    };
};
export const viewTaskVulnerabilityManagement = () => {
    return {
        type: VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED
    };
};

export const postCommentVulnerability = ({ id, payload }) => {
    return {
        type: VULNERABILITY_MANAGEMENT_COMMENT_REQUESTED,
        payload: { id, payload }
    };
};
export const uploadNessusVulnerability = (payload, history) => {
    return {
        type: VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_REQUESTED,
        payload,
        history
    };
};

export const templateTypeVulnerability = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED,
        payload
    };
};

export const vulnerabilityTaskCategoriesList = () => {
    return {
        type: VULNERABILITY_TASK_CATEGORIES_REQUESTED
    };
};

export const vulnerabilityTaskDepartments = () => {
    return {
        type: VULNERABILITY_TASK_DEPARTMENT_REQUESTED
    }
}

// Delete Attachment
export const vulnerabilityDeleteAttachment = (payload) => {
    return {
        type: VULNERABILITY_DELETE_ATTACHMENT_REQUESTED,
        payload
    };
};

export const casesCreateVulnerability = (newCase, vulnerabilityId) => {
    return {
        type: VULNERABILITY_MANAGEMENT_OPEN_CASES_REQUESTED,
        payload: {
            newCase,
            vulnerabilityId
        }
    };
};

export const caseListVulnerability = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED,
        payload
    };
};
export const vulnerabilityAssetRequest = (id, page) => {
    return {
        type: VULNERABILITY_VULNERABILITY_ASSET_REQUESTED,
        id,
        page,
    };
};
export const vulnerabilitySearchAsset = (searchValue) => {
    return {
        type: VULNERABILITY_SEARCH_BY_NAME_ASSET_REQUESTED,
        searchValue
    };
};
// VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_SUCCESSED
export const vulnerabilityImportAssesment = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_SUCCESSED,
        payload
    }
}
//TEMPLATE CREATED
export const vulnerabilityTemplateCreate = () => {

    return {
        type: VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED,

    }
}

export const VulnerabilityNameList = () => {

    return {
        type: VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED,

    }
}

export const VulnerabilityContent = (payload) => {

    return {
        type: VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED,
        payload
    }
}

export const VulnerabilityEditSearch = (payload) => {
    return {
        type: VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED,
        payload
    }
}

export const VulnerabilityImageUrl = (paylod) => {
    return {
        type: VULNERABILITY_IMAGE_LOAD_REQUESTED,
        payload
    }
}
export function importedAssessmentListAction(payload) {
    return {
        type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED,
        payload,
    };
}
export function assessmentImportAfterAction(payload, history) {

    return {
        type: VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED,
        payload,
        history
    };
}
export const vulnerabilityImportedAssessmentDeleteAction = (id, query) => {
    return {
        type: VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_REQUESTED,
        id,
        query
    };
};
export function downloadFile(payload) {
    return {
        type: DOWNLOAD_XML_NESSUS_FILE,
        payload,
    };
}

export function duplicateAssessment(id, query) {
    return {
        type: ASSESSMENT_DUPLICATE_REQUESTED,
        id,
        query,
    };
}
export function resetDuplicateAssessmentstore() {
    return {
        type: RESET_DUPLICATE_ASSESSMENT_STORE,
    };
}

