import { put, call, takeLatest } from 'redux-saga/effects';
import {
  CASE_DETAILS_REQUESTED,
  CASE_DETAILS_SUCCESSED,
  CASE_DETAILS_FAILED,
  CASE_MANAGEMENT_EDIT_REQUESTED,
  CASE_MANAGEMENT_EDIT_SUCCESSED,
  CASE_MANAGEMENT_EDIT_FAILED,
  CASE_MANAGEMENT_CREATE_REQUESTED,
  CASE_MANAGEMENT_CREATE_SUCCESSED,
  CASE_MANAGEMENT_CREATE_FAILED,
  CASE_EXPORT_PDF_LIST_REQUESTED,
  CASE_EXPORT_PDF_LIST_SUCCESSED,
  CASE_EXPORT_PDF_LIST_FAILED,
  CASE_SEND_EMAIL_REQUESTED,
  CASE_SEND_EMAIL_SUCCESSED,
  CASE_SEND_EMAIL_FAILED,
  CASE_POST_COMMENT_SUCCESSED,
  CASE_POST_COMMENT_FAILED,
  CASE_POST_COMMENT_REQUESTED,
  CASE_ASSET_DELETE_SUCCESSED,
  CASE_ASSET_DELETE_FAILED,
  CASE_ASSET_DELETE_REQUESTED,
} from '../../constants/actionTypes';
import {
  caseDetailsSaga,
  exportPdf,
  sendEmailSaga,
  createSaga,
  editCaseSaga,
  postComment,
  deleteCaseAsset,
} from '../../api/caseManagementSaga';
import { showToastError, showToastSuccess, showToastLoader } from '../../utils/toasts';

function delay(duration) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration)
  })
  return promise
}

export function* watchCaseDetails(action) {
  try {
    var isEdit = localStorage.getItem('isEdit');
    var cnt = false;
    while (window.location.pathname == "/cases/details/" + action.id && isEdit != 1) {
      const response = yield call(caseDetailsSaga, action.id, action.param, action.isEdit);
      if (response.success) {
        yield put({ type: CASE_DETAILS_SUCCESSED, data: response });
        if (cnt) {
          yield call(delay, 5000);
        }
      } else {
        yield put({ type: CASE_DETAILS_FAILED, data: null });
      }
      isEdit = localStorage.getItem('isEdit');
      cnt = true;
    }
    if (window.location.pathname.split('/')[1] == "cases") {

      const response = yield call(caseDetailsSaga, action.id, action.param, action.isEdit);
      if (response.success) {
        yield put({ type: CASE_DETAILS_SUCCESSED, data: response });
      } else {
        yield put({ type: CASE_DETAILS_FAILED, data: null });
      }
    }
  } catch (err) {
    yield put({
      type: CASE_DETAILS_FAILED,
      // data: err?.response?.data?.data,
    });
    window.location.replace('/cases');
  }
}

export function* watchCreate(action) {
  try {
    const response = yield call(createSaga, action.payload);
    if (response.success) {
      yield put({ type: CASE_MANAGEMENT_CREATE_SUCCESSED, data: response });
    } else {
      yield put({ type: CASE_MANAGEMENT_CREATE_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: CASE_MANAGEMENT_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchEdit(action) {
  const loaderHandle = showToastLoader('Updating case..')
  try {
    let responseMessage = 'Case updated';
    const response = yield call(editCaseSaga, action.payload);
    if (response.success) {
      yield put({ type: CASE_MANAGEMENT_EDIT_SUCCESSED, data: response });
      if (action.payload.iti_compromised_asset) {
        responseMessage = 'Asset added'
      } else if (action.payload.iti_closed_remediation) {
        responseMessage = 'Remediation updated'
      } else if (action.payload.iti_suggestions_recovery) {
        responseMessage = 'Remediation details updated'
      } else if (action.payload.iti_disposition_id) {
        responseMessage = 'Disposition changed';
      } else if (action.payload.iti_ticket_status) {
        responseMessage = 'Status changed';
      } else if (action.payload.iti_assign_to) {
        responseMessage = 'Analyst changed';
      }
      showToastSuccess(responseMessage, loaderHandle);
      if (action.payload?.callback) action.payload.callback({ isSuccess: true, data: response?.data });
    } else {
      showToastError(action?.payload?.caseType == 'vulnerability' ? 'Vulnerability case update failed' : 'Threat Intelligence case update failed', loaderHandle)
      yield put({ type: CASE_MANAGEMENT_EDIT_FAILED, data: null });
      if (action.payload?.callback) action.payload.callback({ isSuccess: false })
    }
  } catch (err) {
    showToastError(action?.payload?.caseType == 'vulnerability' ? 'Vulnerability case update failed' : 'Threat Intelligence case update failed', loaderHandle)
    yield put({
      type: CASE_MANAGEMENT_EDIT_FAILED,
      data: err?.response?.data?.data,
    });
    if (action.payload?.callback) action.payload.callback({ isSuccess: false })
  }
}

export function* watchPdfExport(action) {
  const loaderHandle = showToastLoader('Exporting pdf...')
  try {
    const response = yield call(exportPdf, action?.payload);
    if (response.size) {
      showToastSuccess('PDF downloaded', loaderHandle)
      yield put({ type: CASE_EXPORT_PDF_LIST_SUCCESSED, data: response });
      // downloadjs.download(response.data, 'export.pdf', 'application/pdf');
    } else {
      showToastError('PDF export failed', loaderHandle)
      yield put({ type: CASE_EXPORT_PDF_LIST_FAILED, data: null });
    }
  } catch (err) {
    showToastError('PDF export failed', loaderHandle)
    yield put({
      type: CASE_EXPORT_PDF_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSendEmail(action) {
  const loaderHandle = showToastLoader('Sending mail...')
  try {
    const response = yield call(sendEmailSaga, action.payload);
    if (response.success) {
      showToastSuccess('Email sent', loaderHandle)
      yield put({ type: CASE_SEND_EMAIL_SUCCESSED, data: response });
    } else {
      showToastError('Email sending failed', loaderHandle)
      yield put({ type: CASE_SEND_EMAIL_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Email sending failed', loaderHandle)
    yield put({
      type: CASE_SEND_EMAIL_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchPostComment(action) {
  const loaderHandle = showToastLoader('Adding comment...')
  try {
    const response = yield call(postComment, action.payload);
    if (response.success) {
      showToastSuccess('Comment added', loaderHandle)
      yield put({ type: CASE_POST_COMMENT_SUCCESSED, data: response });
    } else {
      showToastError('Comment add failed', loaderHandle)
      yield put({ type: CASE_POST_COMMENT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Comment add failed', loaderHandle)
    yield put({
      type: CASE_POST_COMMENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteAsset(action) {
  const loaderHandle = showToastLoader('Deleting asset...')
  try {
    const response = yield call(deleteCaseAsset, action.payload);
    if (response.success) {
      showToastSuccess('Asset deleted', loaderHandle)
      yield put({ type: CASE_ASSET_DELETE_SUCCESSED, data: response });
      if (action.payload.callback) action.payload.callback(response);
    } else {
      showToastError('Asset delete failed', loaderHandle)
      yield put({ type: CASE_ASSET_DELETE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Asset delete failed', loaderHandle)
    yield put({
      type: CASE_ASSET_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export default function* watcher() {
  yield takeLatest(CASE_DETAILS_REQUESTED, watchCaseDetails);
  yield takeLatest(CASE_MANAGEMENT_CREATE_REQUESTED, watchCreate);
  yield takeLatest(CASE_MANAGEMENT_EDIT_REQUESTED, watchEdit);
  yield takeLatest(CASE_EXPORT_PDF_LIST_REQUESTED, watchPdfExport);
  yield takeLatest(CASE_SEND_EMAIL_REQUESTED, watchSendEmail);
  yield takeLatest(CASE_POST_COMMENT_REQUESTED, watchPostComment);
  yield takeLatest(CASE_ASSET_DELETE_REQUESTED, watchDeleteAsset);
}
