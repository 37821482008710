import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  PLAYBOOK_LIST_SUCCESSED,
  PLAYBOOK_LIST_FAILED,
  PLAYBOOK_LIST_REQUESTED,
  PLAYBOOK_MARKETPLACE_LIST_FAILED,
  PLAYBOOK_MARKETPLACE_LIST_REQUESTED,
  PLAYBOOK_MARKETPLACE_LIST_SUCCESSED,

  PLAYBOOK_MARKETPLACE_VIEW_FAILED,
  PLAYBOOK_MARKETPLACE_VIEW_REQUESTED,
  PLAYBOOK_MARKETPLACE_VIEW_SUCCESSED,

  PLAYBOOK_MARKETPLACE_DATA_FAILED,
  PLAYBOOK_MARKETPLACE_DATA_REQUESTED,
  PLAYBOOK_MARKETPLACE_DATA_SUCCESSED,

  PLAYBOOKS_LOGS_SUCCESSED,
  PLAYBOOKS_LOGS_FAILED,
  PLAYBOOKS_LOGS_REQUESTED,
  PLAYBOOK_DELETE_SUCCESSED,
  PLAYBOOK_DELETE_FAILED,
  PLAYBOOK_DELETE_REQUESTED,
  PLAYBOOK_MP_IMPORT_REQUESTED,
  PLAYBOOK_MP_IMPORT_SUCCESSED,
  PLAYBOOK_MP_IMPORT_FAILED,
  PLAYBOOK_LOG_DELETE_SUCCESSED,
  PLAYBOOK_LOG_DELETE_FAILED,
  PLAYBOOK_LOG_DELETE_REQUESTED,
  PLAYBOOK_DUPLICATE_SUCCESSED,
  PLAYBOOK_DUPLICATE_FAILED,
  PLAYBOOK_DUPLICATE_REQUESTED,
  PLAYBOOK_RULES_LIST_REQUESTED,
  PLAYBOOK_RULES_LIST_SUCCESSED,
  PLAYBOOK_RULES_LIST_FAILED,
  CUSTOM_PLAYBOOK_EXECUTE_SUCCESSED,
  CUSTOM_PLAYBOOK_EXECUTE_FAILED,
  CUSTOM_PLAYBOOK_EXECUTE_REQUESTED,

  PLAYBOOK_RULES_CREATE_LIST_REQUESTED,
  PLAYBOOK_RULES_UPDATE_LIST_REQUESTED,
  PLAYBOOK_RULES_DELETE_LIST_REQUESTED,
  PLAYBOOK_RULES_DELETE_LIST_SUCCESSED,
  PLAYBOOK_RULES_DELETE_LIST_FAILED,
  PLAYBOOK_RULES_UPDATE_LIST_SUCCESSED,
  PLAYBOOK_RULES_UPDATE_LIST_FAILED,
  PLAYBOOK_RULES_CREATE_LIST_SUCCESSED,
  PLAYBOOK_RULES_CREATE_LIST_FAILED,
  PLAYBOOK_CATEOGRY_LIST_REQUESTED,
  PLAYBOOK_SUB_CATEOGRY_LIST_REQUESTED,
  PLAYBOOK_DISPOSITION_LIST_REQUESTED,
  PLAYBOOK_SUB_DISPOSITION_LIST_REQUESTED,
  PLAYBOOK_LOCATION_LIST_REQUESTED,
  PLAYBOOK_RISK_RATING_LIST_REQUESTED,
  PLAYBOOK_CATEOGRY_LIST_SUCCESSED,
  PLAYBOOK_CATEOGRY_LIST_FAILED,
  PLAYBOOK_SUB_CATEOGRY_LIST_FAILED,
  PLAYBOOK_DISPOSITION_LIST_SUCCESSED,
  PLAYBOOK_DISPOSITION_LIST_FAILED,
  PLAYBOOK_SUB_DISPOSITION_LIST_SUCCESSED,
  PLAYBOOK_SUB_DISPOSITION_LIST_FAILED,
  PLAYBOOK_LOCATION_LIST_SUCCESSED,
  PLAYBOOK_LOCATION_LIST_FAILED,
  PLAYBOOK_RISK_RATING_LIST_SUCCESSED,
  PLAYBOOK_RISK_RATING_LIST_FAILED,
  PLAYBOOK_SUB_CATEOGRY_LIST_SUCCESSED,
  PLAYBOOK_DOWNLOAD_SUCCESSED,
  PLAYBOOK_DOWNLOAD_FAILED,
  PLAYBOOK_DOWNLOAD_REQUESTED,
  PLAYBOOK_QUEUE_LOG_SUCCESSED,
  PLAYBOOK_QUEUE_LOG_FAILED,
  PLAYBOOK_QUEUE_LOG_REQUESTED,
  PLAYBOOK_IMPORT_REQUESTED,
  PLAYBOOK_IMPORT_SUCCESSED,
  PLAYBOOK_IMPORT_FAILED,
  SHARE_PLAYBOOK_REQUESTED,
  SHARE_PLAYBOOK_SUCCESSED,
  SHARE_PLAYBOOK_FAILED,
} from '../../constants/actionTypes';
import {
  playbookLogsSaga,
  playbookSaga,
  playbookMarketplaceSaga,
  deleteplaybookSaga,
  importplaybookSaga,
  deletePlaybooklogsaga,
  duplicatePlaybooksaga,
  rulesAPI,
  deleteRulesAPI,
  updateRulesAPI,
  createRulesAPI,
  executeCustomPlaybook,
  subCategoryListAPI,
  categoryListAPI,
  dispositionListAPI,
  subDispositionListAPI,
  riskRatingAPI,
  locationListAPI,
  dowloadPlayboook,
  plabookQueueViewLogs,
  plabookImportAPI,
  configureMarketplaceAPI,
  playbookMarketplaceViewSaga,
  sharePlayBookAPI
} from '../../api/playbooksSaga';
import {
  listPlaybook,
  listPlaybookMarketplace,
  listPlayBookslogs,
  getRulesList
} from '../../actions/playbooks';
import { showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

function delay(duration) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration)
  })
  return promise
}

export function* watchPlaybooksList(action) {
  try {
    const playbookData = yield call(playbookSaga, action.payload);

    if (playbookData.success) {
      yield put({ type: PLAYBOOK_LIST_SUCCESSED, data: playbookData });
    } else {
      yield put({ type: PLAYBOOK_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: PLAYBOOK_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchPlaybooksMarketplaceList(action) {
  try {
    const playbookData = yield call(playbookMarketplaceSaga, action.payload);

    if (playbookData.success) {
      yield put({ type: PLAYBOOK_MARKETPLACE_LIST_SUCCESSED, data: playbookData });
    } else {
      yield put({ type: PLAYBOOK_MARKETPLACE_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: PLAYBOOK_MARKETPLACE_LIST_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchPlaybooksMarketplaceView(action) {
  try {
    const playbookData = yield call(
      playbookMarketplaceViewSaga,
      action.id
    );

    if (playbookData.success) {
      yield put({ type: PLAYBOOK_MARKETPLACE_VIEW_SUCCESSED, data: playbookData });
    } else {
      yield put({ type: PLAYBOOK_MARKETPLACE_VIEW_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: PLAYBOOK_MARKETPLACE_VIEW_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchPlayBookslogs(action) {
  try {
    let cnt = false;
    while (window.location.pathname.includes("/playbooks-queue")) {
      const playbooksLogsData = yield call(playbookLogsSaga, action.payload);
      if (playbooksLogsData.success) {
        yield put({ type: PLAYBOOKS_LOGS_SUCCESSED, data: playbooksLogsData });
        if (cnt) {
          yield call(delay, 5000);
        }
      } else {
        yield put({ type: PLAYBOOKS_LOGS_FAILED, data: null });
      }
      cnt = true;
    }
    const modules = ["/threatIntelligence/details", "/incidentManagement/details", "/cases/advisory", "/cases/vulnerability/"];
    if (window.location.pathname.includes(modules[0]) ||
      window.location.pathname.includes(modules[1]) ||
      window.location.pathname.includes(modules[2]) ||
      window.location.pathname.includes(modules[3])) {
      const playbooksLogsData = yield call(playbookLogsSaga, action.payload);
      if (playbooksLogsData.success) {
        yield put({ type: PLAYBOOKS_LOGS_SUCCESSED, data: playbooksLogsData });
      } else {
        yield put({ type: PLAYBOOKS_LOGS_FAILED, data: null });
      }
    }
  } catch (err) {
    yield put({ type: PLAYBOOKS_LOGS_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchDelete(action) {
  const loaderHandle = showToastLoader('Deleting playbook...')
  try {
    const response = yield call(deleteplaybookSaga, action.id);

    if (response.success) {
      showToastSuccess('Playbook deleted', loaderHandle)
      yield put({ type: PLAYBOOK_DELETE_SUCCESSED, data: response });
      yield put(listPlaybook({ queryItem: action.query }));
    } else {
      showToastError('You can not delete this playbook as it is linked with playbookRule', loaderHandle)
      yield put({ type: PLAYBOOK_DELETE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('You can not delete this playbook as it is linked with playbookRule', loaderHandle)
    yield put({
      type: PLAYBOOK_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchImport(action) {
  const loaderHandle = showToastLoader('Importing playbook...')
  try {
    const response = yield call(importplaybookSaga, action.id);

    if (response.success) {
      showToastSuccess('Playbook imported', loaderHandle)
      yield put({ type: PLAYBOOK_MP_IMPORT_SUCCESSED, data: response });
      yield put(listPlaybookMarketplace({ queryItem: action.query }));
    } else {
      showToastError('Playbook import failed', loaderHandle)
      yield put({ type: PLAYBOOK_MP_IMPORT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Playbook import failed', loaderHandle)
    yield put({
      type: PLAYBOOK_MP_IMPORT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchLogDelete(action) {
  const loaderHandle = showToastLoader('Deleting playbook log...')
  try {
    const response = yield call(deletePlaybooklogsaga, action.id);

    if (response.success) {
      showToastSuccess('Playbook log deleted', loaderHandle)
      yield put({ type: PLAYBOOK_LOG_DELETE_SUCCESSED, data: response });
      yield put(listPlayBookslogs({ queryItem: action.queryItem }));
    } else {
      showToastError('Playbook log delete failed', loaderHandle)
      yield put({ type: PLAYBOOK_LOG_DELETE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Playbook log delete failed', loaderHandle)
    yield put({
      type: PLAYBOOK_LOG_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDuplicate(action) {
  const loaderHandle = showToastLoader('Duplicating playbook...')
  try {
    const response = yield call(duplicatePlaybooksaga, action.id);
    if (response.success) {
      showToastSuccess('Playbook duplicated', loaderHandle)
      yield put({ type: PLAYBOOK_DUPLICATE_SUCCESSED, data: response });
      yield put(listPlaybook({ queryItem: action.query }));
    } else {
      showToastError('Playbook duplicate failed', loaderHandle)
      yield put({ type: PLAYBOOK_DUPLICATE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Playbook duplicate failed', loaderHandle)
    yield put({
      type: PLAYBOOK_DUPLICATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRulesList(action) {
  try {
    const response = yield call(rulesAPI, action.payload);

    if (response.success) {
      yield put({ type: PLAYBOOK_RULES_LIST_SUCCESSED, data: response.data });
      yield put(listPlaybook({ queryItem: action.query }));
    } else {
      yield put({ type: PLAYBOOK_RULES_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_RULES_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRulesCreateList(action) {
  const loaderHandle = showToastLoader('Creating rule...')
  try {
    const response = yield call(createRulesAPI, action.payload);
    if (response.success) {
      showToastSuccess('Rule created', loaderHandle)
      yield put({
        type: PLAYBOOK_RULES_CREATE_LIST_SUCCESSED,
        data: response.data,
      });
      yield put(getRulesList({ id: action?.payload?.rootId, queryItem: action?.payload?.pageQuery }))
    } else {
      showToastError('Rule creation failed', loaderHandle)
      yield put({ type: PLAYBOOK_RULES_CREATE_LIST_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Rule creation failed', loaderHandle)
    yield put({
      type: PLAYBOOK_RULES_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}



export function* watchPlaybookCustomExe(action) {
  const loaderHandle = showToastLoader('Executing custom playbook...')
  try {
    const response = yield call(executeCustomPlaybook, action.payload);
    if (response.success) {
      showToastSuccess('Custom playbook executed', loaderHandle)
      yield put({
        type: CUSTOM_PLAYBOOK_EXECUTE_SUCCESSED,
        data: response.data,
      });
    } else {
      showToastError('Custom playbook execute failed', loaderHandle)
      yield put({ type: CUSTOM_PLAYBOOK_EXECUTE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Custom playbook execute failed', loaderHandle)
    yield put({
      type: CUSTOM_PLAYBOOK_EXECUTE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRulesUpdateList(action) {
  const loaderHandle = showToastLoader('Updating rule...')
  try {
    const response = yield call(updateRulesAPI, action.payload);

    if (response.success) {
      showToastSuccess('Rule updated', loaderHandle)
      yield put({
        type: PLAYBOOK_RULES_UPDATE_LIST_SUCCESSED,
        data: response.data,
      });
      yield put(getRulesList({ id: action?.payload?.rootId, queryItem: action?.payload?.pageQuery }))
    } else {
      showToastError('Rule update failed', loaderHandle)
      yield put({ type: PLAYBOOK_RULES_UPDATE_LIST_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Rule update failed', loaderHandle)
    yield put({
      type: PLAYBOOK_RULES_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRulesDeleteList(action) {
  const loaderHandle = showToastLoader('Deleting rule...')
  try {
    const response = yield call(deleteRulesAPI, action.payload.id);
    if (response.success) {
      showToastSuccess('Rule deleted', loaderHandle)
      yield put({
        type: PLAYBOOK_RULES_DELETE_LIST_SUCCESSED,
        data: response.data,
      });
      yield put(getRulesList({ id: action.payload.rootId, queryItem: action.payload.pageQuery }))
    } else {
      showToastError('Rule delete failed', loaderHandle)
      yield put({ type: PLAYBOOK_RULES_DELETE_LIST_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Rule delete failed', loaderHandle)
    yield put({
      type: PLAYBOOK_RULES_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCategoryList(action) {
  try {
    const response = yield call(categoryListAPI, action.payload);

    if (response.success) {
      yield put({
        type: PLAYBOOK_CATEOGRY_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: PLAYBOOK_CATEOGRY_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_CATEOGRY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSubCategoryList(action) {
  try {
    const response = yield call(subCategoryListAPI, action.payload);

    if (response.success) {
      yield put({
        type: PLAYBOOK_SUB_CATEOGRY_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: PLAYBOOK_SUB_CATEOGRY_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_SUB_CATEOGRY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDespositionList(action) {
  try {
    const response = yield call(dispositionListAPI, action.payload);

    if (response.success) {
      yield put({
        type: PLAYBOOK_DISPOSITION_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: PLAYBOOK_DISPOSITION_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_DISPOSITION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSubDespositionList(action) {
  try {
    const response = yield call(subDispositionListAPI, action.payload);

    if (response.success) {
      yield put({
        type: PLAYBOOK_SUB_DISPOSITION_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: PLAYBOOK_SUB_DISPOSITION_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_SUB_DISPOSITION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchLocationList(action) {
  try {
    const response = yield call(locationListAPI, action.payload);

    if (response.success) {
      yield put({
        type: PLAYBOOK_LOCATION_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: PLAYBOOK_LOCATION_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_LOCATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRiskList(action) {
  try {
    const response = yield call(riskRatingAPI, action.payload);

    if (response.success) {
      yield put({
        type: PLAYBOOK_RISK_RATING_LIST_SUCCESSED,
        data: response.data,
      });
    } else {
      yield put({ type: PLAYBOOK_RISK_RATING_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_RISK_RATING_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchPlaybookDowload(action) {
  const loaderHandle = showToastLoader('Downloading playbook...')
  try {
    const response = yield call(dowloadPlayboook, action.id, action.playBookName);
    if (response.size) {
      showToastSuccess('Playbook downloaded', loaderHandle)
      yield put({ type: PLAYBOOK_DOWNLOAD_SUCCESSED, data: response });
      yield put(listPlaybook({ queryItem: action.query }));
    } else {
      showToastError('Playbook download failed', loaderHandle)
      yield put({ type: PLAYBOOK_DOWNLOAD_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Playbook download failed', loaderHandle)
    yield put({
      type: PLAYBOOK_DOWNLOAD_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchPlaybookQueue(action) {
  try {
    const response = yield call(plabookQueueViewLogs, action.queueId, action.actionId);
    if (response.success) {
      yield put({ type: PLAYBOOK_QUEUE_LOG_SUCCESSED, data: response });
    } else {
      yield put({ type: PLAYBOOK_QUEUE_LOG_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: PLAYBOOK_QUEUE_LOG_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchPlaybookDownload(action) {
  const loaderHandle = showToastLoader('Importing playbook...')
  try {
    const response = yield call(plabookImportAPI, action.payload);
    if (response.success) {
      showToastSuccess('Playbook imported', loaderHandle)
      yield put({ type: PLAYBOOK_IMPORT_SUCCESSED, data: response });
      yield put(listPlaybook({ queryItem: action.query }));
    } else {
      showToastError('Playbook imported failed', loaderHandle)
      yield put({ type: PLAYBOOK_IMPORT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Playbook imported failed', loaderHandle)
    yield put({
      type: PLAYBOOK_IMPORT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchPlaybooksMarketplaceData(action) {
  const loaderHandle = showToastLoader('Updating playbook marketplace...')
  try {
    const response = yield call(configureMarketplaceAPI, action);
    if (response.success) {
      const msg = response.data[1] || 'Playbook Marketplace updated';
      showToastSuccess(msg, loaderHandle)
      yield put({
        type: PLAYBOOK_MARKETPLACE_DATA_SUCCESSED,
        data: response?.data,
      });
      yield put(listPlaybook({ queryItem: action.query }));
    } else {
      yield put({ type: PLAYBOOK_MARKETPLACE_DATA_FAILED, data: null });
      showToastError('Playbook marketplace update failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: PLAYBOOK_MARKETPLACE_DATA_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
  
export function* watchPlaybookShare(data) {
  const loaderHandle = showToastLoader('Coping playbook...')
  try {
    const response = yield call(sharePlayBookAPI, data);
    if (response.success) {
      const msg = `Playbook copied`;
      showToastSuccess(msg, loaderHandle)
      yield put({ type: SHARE_PLAYBOOK_SUCCESSED, data: response?.data });
      const playbookData = yield call(playbookSaga, data);

      if (playbookData.success) {
        yield put({ type: PLAYBOOK_LIST_SUCCESSED, data: playbookData });
      } else {
        yield put({ type: PLAYBOOK_LIST_FAILED, data: null });
      }
    } else {
      showToastError('Playbook copy failed', loaderHandle)
      yield put({ type: SHARE_PLAYBOOK_FAILED, data: null });
    }

    if (data?.payload?.callback) {
      data.payload.callback();
    }
  } catch (err) {
    showToastError('Playbook copy failed', loaderHandle)
    yield put({ type: SHARE_PLAYBOOK_FAILED, data: err });
    if (data?.payload?.callback) {
      data.payload.callback();
    }
  }
}

export default function* watcher() {
  yield takeLatest(PLAYBOOK_LIST_REQUESTED, watchPlaybooksList);
  yield takeLatest(PLAYBOOK_MARKETPLACE_LIST_REQUESTED, watchPlaybooksMarketplaceList);
  yield takeLatest(PLAYBOOKS_LOGS_REQUESTED, watchPlayBookslogs);
  yield takeEvery(PLAYBOOK_DELETE_REQUESTED, watchDelete);
  yield takeEvery(PLAYBOOK_MP_IMPORT_REQUESTED, watchImport);
  yield takeLatest(PLAYBOOK_LOG_DELETE_REQUESTED, watchLogDelete);
  yield takeEvery(PLAYBOOK_DUPLICATE_REQUESTED, watchDuplicate);
  yield takeLatest(PLAYBOOK_RULES_LIST_REQUESTED, watchRulesList);
  yield takeLatest(PLAYBOOK_RULES_CREATE_LIST_REQUESTED, watchRulesCreateList);
  yield takeLatest(PLAYBOOK_RULES_UPDATE_LIST_REQUESTED, watchRulesUpdateList);
  yield takeLatest(PLAYBOOK_RULES_DELETE_LIST_REQUESTED, watchRulesDeleteList);

  yield takeLatest(CUSTOM_PLAYBOOK_EXECUTE_REQUESTED, watchPlaybookCustomExe);
  yield takeLatest(PLAYBOOK_CATEOGRY_LIST_REQUESTED, watchCategoryList);
  yield takeLatest(PLAYBOOK_SUB_CATEOGRY_LIST_REQUESTED, watchSubCategoryList);
  yield takeLatest(PLAYBOOK_DISPOSITION_LIST_REQUESTED, watchDespositionList);
  yield takeLatest(PLAYBOOK_MARKETPLACE_VIEW_REQUESTED, watchPlaybooksMarketplaceView);
  yield takeLatest(PLAYBOOK_MARKETPLACE_DATA_REQUESTED, watchPlaybooksMarketplaceData);
  yield takeLatest(
    PLAYBOOK_SUB_DISPOSITION_LIST_REQUESTED,
    watchSubDespositionList
  );
  yield takeLatest(PLAYBOOK_LOCATION_LIST_REQUESTED, watchLocationList);
  yield takeLatest(PLAYBOOK_RISK_RATING_LIST_REQUESTED, watchRiskList);
  yield takeLatest(PLAYBOOK_DOWNLOAD_REQUESTED, watchPlaybookDowload);
  yield takeLatest(PLAYBOOK_QUEUE_LOG_REQUESTED, watchPlaybookQueue);
  yield takeLatest(PLAYBOOK_IMPORT_REQUESTED, watchPlaybookDownload);
  yield takeLatest(SHARE_PLAYBOOK_REQUESTED, watchPlaybookShare);
}
