
import {
  VERIFY_CODE_REQUESTED,
  RESET_VERIFY_CODE_REQUESTED,
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  FORGOT_PASSWORD_REQUESTED,
  GOOGLE_AUTH_LOGIN_REQUESTED,
  RESET_PASSWORD_REQUESTED,
  INVITED_USER_REQUESTED,
  MASTER_AUTH_LOGIN_REQUESTED,
  CHANGE_PASSWORD_REQUESTED
} from '../constants/actionTypes';


export function loginDetails(payload, history, previousPath) {
  return {
    type: LOGIN_REQUESTED,
    payload,
    history,
    previousPath
  };
}


export function userLogout(payload) {
  return {
    type: LOGOUT_REQUESTED,
    payload,

  };
}

export function forgotPassword(payload) {

  return {
    type: FORGOT_PASSWORD_REQUESTED,
    payload,
  };
}

export function resetPassword(payload) {

  return {
    type: RESET_PASSWORD_REQUESTED,
    payload,
  };
}

export function changePassword(payload, history) {
  return {
    type: CHANGE_PASSWORD_REQUESTED,
    payload,
    history
  };
}

export function invitedUser(payload) {

  return {
    type: INVITED_USER_REQUESTED,
    payload,
  };
}

export function googleLogin(payload) {

  return {
    type: GOOGLE_AUTH_LOGIN_REQUESTED,
    payload,
  };
}

export function masterLogin(payload, history) {

  return {
    type: MASTER_AUTH_LOGIN_REQUESTED,
    payload,
    history,
  };
}


export function verifyCode(payload) {

  return {
    type: VERIFY_CODE_REQUESTED,
    payload,
  };
}

export function resetVerifyCodeState(payload) {

  return {
    type: RESET_VERIFY_CODE_REQUESTED,
    payload,
  };
}