import { put, call, takeLatest, takeEvery, select } from 'redux-saga/effects';
import API from '../../config/endpoints.config';
import {
  DASHBOARD_GET_LIST_SUCCESSED,
  DASHBOARD_GET_LIST_REQUESTED,
  DASHBOARD_GET_LIST_FAILED,
  DASHBOARD_DELETE_REQUESTED,
  DASHBOARD_REMOVE_REQUESTED,
  SHOW_LOADER,
  HIDE_LOADER,
  DASHBOARD_REMOVE_SUCCESSED,
  DASHBOARD_DELETE_SUCCESSED,
  DASHBOARD_ADD_SUCCESSED,
  DASHBOARD_ADD_REQUESTED,
  DASHBOARD_ADD_FAILED,
  DASHBOARD_DELETE_FAILED,
  DASHBOARD_SET_DEFAULT_REQUESTED,
  DASHBOARD_SET_DEFAULT_FAILED,
  DASHBOARD_SET_DEFAULT_SUCCESSED,
  DASHBOARD_REMOVE_FAILED,
  DAHSBOARD_GET_WIDGET_LIST_REQUESTED,
  DAHSBOARD_GET_WIDGET_LIST_FAILED,
  DAHSBOARD_GET_WIDGET_LIST_SUCCESSED,
  DASHBOARD_WIDGET_GET_GROUPS_REQUESTED,
  DASHBOARD_WIDGET_GET_GROUPS_SUCCESSES,
  DASHBOARD_WIDGET_GET_GROUPS_FAIELD,
  GET_DASHBOARD_BY_ID_REQUESTED,
  GET_DASHBOARD_WIDGETS_BY_ID_REQUESTED,
  GET_DASHBOARD_BY_ID_SUCCESSED,
  GET_DASHBOARD_BY_ID_FAILED,
  DASHBOARD_CREATE_REQUESTED,
  DASHBOARD_CREATE_SUCCESSED,
  DASHBOARD_CREATE_FAILED,
  DASHBOARD_UPDATE_REQUESTED,
  DASHBOARD_UPDATE_SUCCESSED,
  DASHBOARD_UPDATE_FAILED,
  DASHBOARD_GET_WIDGET_DATA_REQUESTED,
  DASHBOARD_GET_WIDGET_DATA_SUCCESSED,
  DASHBOARD_GET_WIDGET_DATA_FAILED,
  DASHBOARD_ADD_WIDGET_REQUESTED,
  DASHBOARD_ADD_WIDGET_FAILED,
  DASHBOARD_ADD_WIDGET_SUCCESSED,
  DASHBOARD_UPDATE_WIDGET_REQUESTED,
  DASHBOARD_DELETE_WIDGET_REQUESTED,
  DASHBOARD_DELETE_WIDGET_SUCCESSED,
  DASHBOARD_DELETE_WIDGET_FAILED,
  DASHBOARD_UPDATE_WIDGET_SUCCESSED,
  DASHBOARD_UPDATE_WIDGET_FAILED,
  DASHBOARD_LIST_SUCCESSED,
  DASHBOARD_LIST_FAILED,
  DASHBOARD_LIST_REQUESTED,
  GET_DASHBOARD_WIDGET_REQUESTED
} from '../../constants/actionTypes';

import {
  dashboardWidgetGetGroupList,
  dashboardAddAPI,
  dashboardDeleteAPI,
  dashboardGetWidgetList,
  dashboardListAPI,
  dashboardRemoveAPI,
  dashboardSetDefaultAPI,
  getDashboardByIdAPI,
  getWidgetByIdAPI,
  dashboardCreateAPI,
  dashboardUpdateAPI,
  getAllDashboardWidgets,
  dashboardAddWidget,
  dashboardDeleteWidget,
  dashboardUpdateWidget,
  dashboardList,
  widgetDataSaga
} from '../../api/dashboardAPI';

import {
  dashboardWidgetUpdate,
  getDashboardById,
  getDashboardList,
  onDashboardWidgetAdd,
  onDashboardWidgetsLayoutChange,
  onDeleteSelectedWidget,
  onGetWidgetGroups,
  onGetwidgetList,
  onSaveSelectedWidget,
  onResetDashboardDropdnState
} from '../../actions/dashboard';
import { getUserProfile } from '../../actions/user';
import { getGraphDataSet, getLineWidgetDataSet } from '../../utils/helper.utils';
import { graphColors } from '../../constants';
import { dismissToastLoader, showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

export const getDashboardWidgets = (state) => state.dashboardStore.widgetLocalState.selectedWidgets;

const getGraphData = (data, type, widgetCategory) => {
  let graphData = [];
  const graphs = ['column', 'radar'];
  if (graphs.includes(type) && widgetCategory == 1) {
    graphData = [{
      data: [{
        name: 'Custom Widget',
        data: data[0]?.data.map(v => Number(v)),
        backgroundColor: graphColors[5]
      }],
      label: data[0]?.label
    }, { Graph_type: type }];
  } else {
    graphData = data;
  }
  return graphData
}

export function* watchDashboardList(action) {
  const loaderHandle = showToastLoader('Loading dashboards...')
  try {
    const response = yield call(dashboardListAPI, action.payload);

    if (response.success) {
      dismissToastLoader(loaderHandle)
      yield put({
        type: DASHBOARD_GET_LIST_SUCCESSED,
        data: response?.data.items,
      });
    } else {
      showToastError('Error While fetching dashboard', loaderHandle)
      yield put({ type: DASHBOARD_GET_LIST_FAILED });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    showToastError('Error While fetching dashboard', loaderHandle)
    yield put({ type: DASHBOARD_GET_LIST_FAILED });
  }
}

export function* watchDashboardDelete(action) {
  const loaderHandle = showToastLoader('Deleting dashboard...')
  try {
    const response = yield call(dashboardDeleteAPI, action.id);
    if (response.success) {
      showToastSuccess('Dashboard deleted', loaderHandle)
      yield put({
        type: DASHBOARD_DELETE_SUCCESSED,
        data: response?.data.items,
      });
      const expand = 'isDraft,checkForDefault,checkForRemove,checkForAdd,makeDefault';
      yield put(getDashboardList(expand));
      yield put(getUserProfile());
    } else {
      showToastError('Error while deleting dashboard', loaderHandle)
      yield put({ type: DASHBOARD_DELETE_FAILED });
    }
  } catch (err) {
    showToastError('Error while deleting dashboard', loaderHandle)
    yield put({ type: DASHBOARD_DELETE_FAILED });
  }
}

export function* watchDashboardRemove(action) {
  const loaderHandle = showToastLoader('Removing dashboard...')
  try {
    const response = yield call(dashboardRemoveAPI, action.id);

    if (response.success) {
      yield put({
        type: DASHBOARD_REMOVE_SUCCESSED,
        data: response?.data.items,
      });
      const msg = response?.data?.message || 'Dashboard removed';
      showToastSuccess(`${msg}`, loaderHandle)
      const expand = 'isDraft,checkForDefault,checkForRemove,checkForAdd,makeDefault';
      yield put(getDashboardList(expand));
    } else {
      showToastError('Error while removing dashboard', loaderHandle)
      yield put({ type: DASHBOARD_REMOVE_FAILED });
    }
  } catch (err) {
    showToastError('Error while removing dashboard', loaderHandle)
    yield put({ type: DASHBOARD_REMOVE_FAILED });
  }
}

export function* watchDashboardAdd(action) {
  const loaderHandle = showToastLoader('Adding dashboard...')
  try {
    const response = yield call(dashboardAddAPI, action.id);

    if (response.success) {
      const msg = response?.data?.message || 'Dashboard added';
      showToastSuccess(`${msg}`, loaderHandle)
      yield put({
        type: DASHBOARD_ADD_SUCCESSED,
        data: response?.data.items,
      });
      const expand = 'isDraft,checkForDefault,checkForRemove,checkForAdd,makeDefault';
      yield put(getDashboardList(expand));
    } else {
      const msg = response?.data?.message || 'Error while adding dashboard';
      showToastError(`${msg}`, loaderHandle)
      yield put({ type: DASHBOARD_ADD_FAILED });
    }
  } catch (err) {
    const msg = err?.message || 'Error while adding dashboard';
    showToastError(`${msg}`, loaderHandle)
    yield put({ type: DASHBOARD_ADD_FAILED });
  }
}

export function* watchDashboardListData(action) {
  try {
    const response = yield call(dashboardList);
    if (response.success) {
      yield put({
        type: DASHBOARD_LIST_SUCCESSED,
        data: response?.data,
      });
      // yield put(getDashboardById(action.payload, action.history, true));
    }
  } catch (err) {
    yield put({ type: DASHBOARD_LIST_FAILED });
  }
}

export function* watchDashboardSetDefault(action) {
  const loaderHandle = showToastLoader('Setting default dashboard...')
  try {
    const response = yield call(dashboardSetDefaultAPI, action.id);

    if (response.success) {
      const msg = response?.data?.message || 'Dashboard set to default';
      showToastSuccess(`${msg}`, loaderHandle)
      yield put({
        type: DASHBOARD_SET_DEFAULT_SUCCESSED,
        data: response?.data.items,
      });
      yield put(getUserProfile());
      if (action.isList) {
        const expand = 'isDraft,checkForDefault,checkForRemove,checkForAdd,makeDefault';
        yield put(getDashboardList(expand));
      } else {
        yield put(getDashboardById(action.payload, action.history, action.isView));
      }
    } else {
      showToastError('Error while set default dashboard', loaderHandle)
      yield put({ type: DASHBOARD_SET_DEFAULT_FAILED });
    }
  } catch (err) {
    showToastError('Error while set default dashboard', loaderHandle)
    yield put({ type: DASHBOARD_SET_DEFAULT_FAILED });
  }
}

export function* watchDashboardGetWidgetList(action) {
  try {
    const response = yield call(dashboardGetWidgetList, action.payload);

    if (response.success) {
      yield put({
        type: DAHSBOARD_GET_WIDGET_LIST_SUCCESSED,
        data: response?.data.items,
      });
    } else {
      showToastError('Error while fetching widgets')
      yield put({ type: DAHSBOARD_GET_WIDGET_LIST_FAILED });
    }
  } catch (err) {
    showToastError('Error while fetching widgets')
    yield put({ type: DAHSBOARD_GET_WIDGET_LIST_FAILED });
  }
}

export function* watchDashboardWidgetGroup() {
  try {
    const response = yield call(dashboardWidgetGetGroupList);

    if (response.success) {
      yield put({
        type: DASHBOARD_WIDGET_GET_GROUPS_SUCCESSES,
        data: response?.data,
      });
    } else {
      yield put({ type: DASHBOARD_WIDGET_GET_GROUPS_FAIELD });
    }
  } catch (err) {
    yield put({ type: DASHBOARD_WIDGET_GET_GROUPS_FAIELD });
  }
}


// Getting Widgets while edit any any dashboard

export function* watchGetDashboardWidgetsById(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getDashboardByIdAPI, action.payload);
    if (response.success) {
      yield put({
        type: GET_DASHBOARD_BY_ID_SUCCESSED,
        data: response?.data,
      });
      if (action.payload?.type !== 'isView') {
        let query = '';
        if (action.payload.das_subject) {
          query = `&GraphsSearch[search]=${action.payload.das_subject}`
        }
        yield put(onGetwidgetList(query));
        yield put(onGetWidgetGroups());
      }

      const widgetList = response?.data?.dashboardGraphs;
      if (widgetList.length > 0) {
        let selectedWidgetsUpdated = {};
        const layoutData = [];
        for (let item of widgetList) {

          const actionPayload = {
            start: localStorage.getItem('start'),
            end: localStorage.getItem('end'),
            id: item?.gsu_graph_id,
          }
          const widgetResponse = yield call(getWidgetByIdAPI, actionPayload);

          selectedWidgetsUpdated = {
            ...selectedWidgetsUpdated,
            [item?.gsu_graph_id]: {
              type: item?.gsuGraph?.gra_widget_type,
              data: (item?.gsuGraph?.gra_widget_type == 'line' && item?.gsuGraph?.gra_custom == 1)
                ? getLineWidgetDataSet(widgetResponse?.data[0], item?.gsuGraph?.gra_widget_type)
                : getGraphData(widgetResponse?.data[0], item?.gsuGraph?.gra_widget_type, item?.gsuGraph?.gra_custom),
              dashboardWidgetId: item?.gsu_id,
              widgetTitle: item?.gsuGraph?.gra_title,
            },
          };
          const layoutObj = JSON.parse(item.gsu_layout_data);

          if (action.isView) {
            layoutObj['static'] = true;
            layoutObj['isDraggable'] = false;
          }
          layoutData.push(layoutObj);
        }
        yield put(onDashboardWidgetsLayoutChange(layoutData));
        yield put(onSaveSelectedWidget(selectedWidgetsUpdated));

      } else {
        yield put({ type: HIDE_LOADER });
      }
    } else {
      yield put({ type: GET_DASHBOARD_BY_ID_FAILED });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    if (err?.response?.status === 404) {
      action.history.push('/dashboard/list');
    } else {
      yield put({ type: HIDE_LOADER });
      yield put({ type: GET_DASHBOARD_BY_ID_FAILED });
    }
  }
}


export function* watchGetDashboardById(action) {
  try {
    yield put({ type: HIDE_LOADER });
    const response = yield call(getDashboardByIdAPI, action.payload);
    if (response.success) {
      yield put({
        type: GET_DASHBOARD_BY_ID_SUCCESSED,
        data: response?.data,
      });
      if (action.payload?.type !== 'isView') {
        let query = '';
        if (action.payload.das_subject) {
          query = `&GraphsSearch[search]=${action.payload.das_subject}`
        }
        yield put(onGetwidgetList(query));
        yield put(onGetWidgetGroups());
      }

      const widgetList = response?.data?.dashboardGraphs;

      if (widgetList.length > 0) {
        let selectedWidgetsUpdated = {};
        const layoutData = [];
        for (let i = 0; i < widgetList.length; i++) {

          selectedWidgetsUpdated = {
            ...selectedWidgetsUpdated,
            [widgetList[i]?.gsu_graph_id]: {
              type: widgetList[i]?.gsuGraph?.gra_widget_type,
              data: [{ data: [] }, { isLoading: true }],
              dashboardWidgetId: widgetList[i]?.gsu_id,
              widgetTitle: widgetList[i]?.gsuGraph?.gra_title,
            },
          };
          const layoutObj = JSON.parse(widgetList[i].gsu_layout_data);
          if (action.isView) {
            layoutObj['static'] = true;
            layoutObj['isDraggable'] = false;
          }
          layoutData.push(layoutObj);
        }
        yield put(onDashboardWidgetsLayoutChange(layoutData));
        yield put(onSaveSelectedWidget(selectedWidgetsUpdated));

        // For loading dashboard widgets one by one
        const dashboardWidgets = yield select(getDashboardWidgets);
        const endPoints = widgetList.map(widget => `${API.baseUrl}/get-widget-data?id=${widget.gsu_graph_id}&start=${localStorage.getItem('start')}&end=${localStorage.getItem('end')}`)
        const widgetResponse = yield call(getAllDashboardWidgets, { urls: endPoints, cancelToken: action?.payload?.cancelToken });
        for (let i = 0; i < widgetList.length; i++) {
          dashboardWidgets[widgetList[i]?.gsu_graph_id].data = (widgetList[i]?.gsuGraph?.gra_widget_type == 'line' && widgetList[i]?.gsuGraph?.gra_custom == 1)
            ? getLineWidgetDataSet(widgetResponse[i][0], widgetList[i]?.gsuGraph?.gra_widget_type)
            : getGraphData(widgetResponse[i][0], widgetList[i]?.gsuGraph?.gra_widget_type, widgetList[i]?.gsuGraph?.gra_custom),
            yield put(onSaveSelectedWidget(dashboardWidgets));
        }
        yield put({ type: GET_DASHBOARD_BY_ID_SUCCESSED, data: response?.data });
      } else {
        yield put({ type: HIDE_LOADER });
      }
    } else {
      yield put({ type: GET_DASHBOARD_BY_ID_FAILED });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    if (err?.response?.status === 404) {
      // action.history.push('/dashboard/list');
      yield put(getDashboardById({...action.payload,id:4}, action.history, action.isView));
    } else {
      yield put({ type: HIDE_LOADER });
      yield put({ type: GET_DASHBOARD_BY_ID_FAILED });
    }
  } finally {
    yield put(onResetDashboardDropdnState());
  }
}

export function* watchCreateDashboard(action) {
  const loaderHandle = showToastLoader('Creating dashboard...')
  yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(dashboardCreateAPI, action.payload);
    if (response.success) {
      showToastSuccess('Dashboard created', loaderHandle)
      yield put({
        type: DASHBOARD_CREATE_SUCCESSED,
        data: response?.data,
      });
      action.history.push(`/dashboard/edit/${response?.data?.das_id}`);
      yield put({ type: HIDE_LOADER });
    } else {
      showToastError('Dashboard already exists', loaderHandle)
      yield put({ type: DASHBOARD_CREATE_FAILED });
      yield put({ type: HIDE_LOADER });
    }
  } catch (err) {
    showToastError('Dashboard already exists', loaderHandle)
    yield put({ type: DASHBOARD_CREATE_FAILED, data: err?.response?.data?.data });
    yield put({ type: HIDE_LOADER });
  }
}

// export function* watchGetSingleDashboard(action) {
//   try {
//     const response = yield call(dashboardGetSingalAPI, action.payload);

//     if (response.success) {
//       yield put({
//         type: DASHBOARD_GET_SINGLE_SUCCESSED,
//         data: response?.data,
//       });

//       // const expand = 'isDraft,checkForDefault,checkForRemove,checkForAdd';
//       const query = '?expand=gsuGraph.graGroup,gsuGraph.dataPath';
//       yield put(onGetwidgetList(query));
//       yield put(onGetWidgetGroups());
//     } else {
//       yield put({ type: DASHBOARD_GET_SINGLE_FAILED });

//     }
//   } catch (err) {
//     yield put({ type: DASHBOARD_GET_SINGLE_FAILED });
//   }
// }

export function* watchUpdateDashboard(action) {
  const loaderHandle = showToastLoader('Updating dashboard...')
  try {
    const response = yield call(dashboardUpdateAPI, action.id, action.payload);

    if (response.success) {
      showToastSuccess('Dashboard updated', loaderHandle)
      yield put({
        type: DASHBOARD_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put({ type: GET_DASHBOARD_BY_ID_SUCCESSED, data: response?.data });
    } else {
      showToastError('Dashboard already exists', loaderHandle)
      yield put({ type: DASHBOARD_UPDATE_FAILED });
    }
  } catch (err) {
    showToastError('Dashboard already exists', loaderHandle)
    yield put({ type: DASHBOARD_UPDATE_FAILED });
  }
}

export function* watchDashboardWidgetGraphData(action) {
  const loaderHandle = showToastLoader('Preparing to add widget...')
  try {
    const response = yield call(getWidgetByIdAPI, action.payload);

    if (response.success) {
      yield put({
        type: DASHBOARD_GET_WIDGET_DATA_SUCCESSED,
        data: response?.data,
      });

      const selectedWidget = {
        [String(action.payload.id)]: {
          type: action.payload.type,
          data: (action.payload.type == 'line' && action?.payload?.gra_custom == 1)
            ? getLineWidgetDataSet(response?.data[0], action.payload.type)
            : getGraphData(response?.data[0], action.payload.type, action?.payload?.gra_custom),
          widgetTitle: action.payload.title
        },
      };

      const addWidget = {
        das_id: action.payload.das_id,
        gsu_graph_id: action.payload.id,
        gsu_layout_data: JSON.stringify(action.payload.layoutData),
        widgetTitle: action.payload.title
      }
      dismissToastLoader(loaderHandle)
      yield put(onDashboardWidgetAdd(addWidget, selectedWidget, action.payload?.allLayout));
    } else {
      showToastError('Error while fetching widget data', loaderHandle)
      yield put({ type: DASHBOARD_GET_WIDGET_DATA_FAILED });
    }
  } catch (err) {
    showToastError('Error while fetching widget data', loaderHandle)
    yield put({ type: DASHBOARD_GET_WIDGET_DATA_FAILED });
  }
}

export function* watchDashboardAddWidget(action) {
  const loaderHandle = showToastLoader('Adding widget...')
  try {
    const response = yield call(dashboardAddWidget, action?.payload?.addWidget);
    if (response.success) {
      yield put({ type: DASHBOARD_ADD_WIDGET_SUCCESSED });
      showToastSuccess('Widget added', loaderHandle)
      if (action?.payload.selectedWidget) {
        const dashboardWidgets = {
          ...action?.payload.selectedWidget,
          [action?.payload?.addWidget.gsu_graph_id]: {
            ...action?.payload.selectedWidget[action?.payload?.addWidget.gsu_graph_id],
            dashboardWidgetId: response?.data?.datamap?.gsu_id,
            widgetTitle: response?.data?.datamap?.gsuGraph?.gra_title || action?.payload?.addWidget?.widgetTitle,
          }
        };
        yield put(onSaveSelectedWidget(dashboardWidgets));
        if (action.payload.allLayout) {
          yield put(dashboardWidgetUpdate(action.payload.allLayout))
        }
      }
    } else {
      showToastError('Error while adding widget', loaderHandle)
      yield put({ type: DASHBOARD_ADD_WIDGET_FAILED });
    }
  } catch (err) {
    showToastError('Error while adding widget', loaderHandle)
    yield put({ type: DASHBOARD_ADD_WIDGET_FAILED });
  }
}

export function* watchDashboardDeleteWidget(action) {
  const loaderHandle = showToastLoader('Deleting widget...')
  try {
    const response = yield call(dashboardDeleteWidget, action?.payload?.dashboardWidgetId);
    if (response.success) {
      showToastSuccess('Widget deleted', loaderHandle)
      yield put({ type: DASHBOARD_DELETE_WIDGET_SUCCESSED });
      yield put(onDashboardWidgetsLayoutChange(action?.payload?.layoutData));
      yield put(onDeleteSelectedWidget(action?.payload?.widgetId));
    } else {
      showToastError('Error while deleting widget', loaderHandle)
      yield put({ type: DASHBOARD_DELETE_WIDGET_FAILED });
    }
  } catch (err) {
    showToastError('Error while deleting widget', loaderHandle)
    yield put({ type: DASHBOARD_DELETE_WIDGET_FAILED });
  }
}

export function* watchDashboardUpdateWidget(action) {
  try {
    const response = yield call(dashboardUpdateWidget, action.payload);
    if (response.success) {
      yield put({ type: DASHBOARD_UPDATE_WIDGET_SUCCESSED });
    } else {
      showToastError('Something went wrong while updating UI')
      yield put({ type: DASHBOARD_UPDATE_WIDGET_FAILED });
    }
  } catch (err) {
    showToastError('Something went wrong while updating UI')
    yield put({ type: DASHBOARD_UPDATE_WIDGET_FAILED });
  }
}

// Getting Widget By ID function
export function* watchWidgetById(action) {
  const loaderHandle = showToastLoader('Preparing to add widget...')
  try {
    const response = yield call(widgetDataSaga, action?.payload);

    // Setting Widgets data to render all widgets

    const graphDataSet = getGraphDataSet(action?.payload?.title || '', action?.payload?.type, response?.data?.result);

    const selectedWidget = {
      [String(action.payload.id)]: {
        type: action.payload.type,
        data: graphDataSet.data,
        widgetTitle: action.payload.title
      },
    };

    const addWidget = {
      das_id: action.payload.das_id,
      gsu_graph_id: action.payload.id,
      gsu_layout_data: JSON.stringify(action.payload.layoutData),
      widgetTitle: action.payload.title
    }
    dismissToastLoader(loaderHandle);

    if (response.success) {
      yield put(onDashboardWidgetAdd(addWidget, selectedWidget, action.payload?.allLayout));
    } else {
      showToastError('Error while fetching widget data', loaderHandle)
    }
  } catch (err) {
    showToastError('Error while fetching widget data', loaderHandle)
  }
}

export default function* watcher() {
  yield takeLatest(DASHBOARD_GET_LIST_REQUESTED, watchDashboardList);
  yield takeLatest(DASHBOARD_DELETE_REQUESTED, watchDashboardDelete);
  yield takeLatest(DASHBOARD_REMOVE_REQUESTED, watchDashboardRemove);
  yield takeLatest(DASHBOARD_ADD_REQUESTED, watchDashboardAdd);
  yield takeLatest(DASHBOARD_SET_DEFAULT_REQUESTED, watchDashboardSetDefault);
  yield takeLatest(DAHSBOARD_GET_WIDGET_LIST_REQUESTED, watchDashboardGetWidgetList);
  yield takeEvery(DASHBOARD_WIDGET_GET_GROUPS_REQUESTED, watchDashboardWidgetGroup);
  yield takeEvery(DASHBOARD_UPDATE_WIDGET_REQUESTED, watchDashboardUpdateWidget);
  yield takeEvery(DASHBOARD_DELETE_WIDGET_REQUESTED, watchDashboardDeleteWidget);
  yield takeEvery(GET_DASHBOARD_BY_ID_REQUESTED, watchGetDashboardById);
  yield takeEvery(GET_DASHBOARD_WIDGETS_BY_ID_REQUESTED, watchGetDashboardWidgetsById);
  yield takeEvery(DASHBOARD_ADD_WIDGET_REQUESTED, watchDashboardAddWidget);
  yield takeLatest(DASHBOARD_CREATE_REQUESTED, watchCreateDashboard);
  yield takeLatest(DASHBOARD_UPDATE_REQUESTED, watchUpdateDashboard);
  yield takeLatest(DASHBOARD_GET_WIDGET_DATA_REQUESTED, watchDashboardWidgetGraphData);
  yield takeLatest(DASHBOARD_LIST_REQUESTED, watchDashboardListData);
  yield takeLatest(GET_DASHBOARD_WIDGET_REQUESTED, watchWidgetById);
}
