import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import SPMessage from '../../../../../components/SPMessage';
import SPButton from '../../../../../components/SPButton';
import SelectBox from '../../../../../components/SelectBox';
import { confirmUser } from '../../../../../actions/apps';
import '../../../../../components/SPSelect/index.css';
import {
  FormContainer,
  FormDiv,
  Query,
  QueryDiv
} from './StyledComponents';

export const ErrorText = styled.div`
   color:#fc5a5a;
   font-size:13px;
   text-align:left;
   font-weight:700;
   margin-left:10px;
   margin-bottom:20px;
   margin-top:10px;
`;

const UserConfirmationForm = ({ onConfirmUser }) => {

  const history = useHistory();
  const validationSchema = Yup.object({
    confirm: Yup.mixed().required("Required")
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('pp_id');
    if (!id) {
      // history.push('/login');
    }
  }, [])

  return (
    <FormContainer>
      <br />
      <FormDiv>
        <Formik
          id="formik"
          validationSchema={validationSchema}
          initialValues={{
            confirm: ''
          }}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            onConfirmUser(values.confirm);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Form>
                <SPMessage
                  message='Please select "Yes/No" in the field below to confirm your response'
                  type='info'
                />
                <SelectBox
                  id="confirm"
                  label="Confirm"
                  name="confirm"
                  onInputChange={setFieldValue}
                  onBlur={handleBlur}
                  errorMessage={errors.confirm}
                  value={values.confirm}
                  touched={touched.confirm}
                  options={[{ key: 'yes', value: 'Yes', label: 'Yes' }, { key: 'no', value: 'No', label: 'No' }]}
                />
                <SPButton
                  title={"Confirm"}
                  size="small"
                  type="submit"
                  onButtonClick={handleSubmit}
                />

                <Link to='/login' replace>
                  <QueryDiv>
                    <Query> Back to login </Query>
                  </QueryDiv>
                </Link>
              </Form>
            )
          }}
        </Formik>
      </FormDiv>
    </FormContainer>
  );
};

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onConfirmUser: data => {
      return dispatch(confirmUser(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserConfirmationForm);
