// login
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_PROCESSING = 'LOGIN_PROCESSING'
export const LOGIN_SUCCESSED = 'LOGIN_SUCCESSED'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const GOOGLE_AUTH_LOGIN_REQUESTED = 'GOOGLE_AUTH_LOGIN_REQUESTED'
export const GOOGLE_AUTH_LOGIN_PROCESSING = 'GOOGLE_AUTH_LOGIN_PROCESSING'
export const GOOGLE_AUTH_LOGIN_SUCCESSED = 'GOOGLE_AUTH_LOGIN_SUCCESSED'
export const GOOGLE_AUTH_LOGIN_FAILED = 'GOOGLE_AUTH_LOGIN_FAILED'

export const MASTER_AUTH_LOGIN_REQUESTED = 'MASTER_AUTH_LOGIN_REQUESTED'
export const MASTER_AUTH_LOGIN_PROCESSING = 'MASTER_AUTH_LOGIN_PROCESSING'
export const MASTER_AUTH_LOGIN_SUCCESSED = 'MASTER_AUTH_LOGIN_SUCCESSED'
export const MASTER_AUTH_LOGIN_FAILED = 'MASTER_AUTH_LOGIN_FAILED'

export const ASSET_IMPORT_PROCESS_CLEAR_REQUESTED = 'ASSET_IMPORT_PROCESS_CLEAR_REQUESTED'
export const ASSET_IMPORT_CLEAR_REQUESTED = 'ASSET_IMPORT_CLEAR_REQUESTED'
export const LOGOUT_USER = 'LOGOUT_USER'

export const CLEAR_DELETE_CASE_REQUESTED = "CLEAR_DELETE_CASE_REQUESTED"

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED'
export const LOGOUT_PROCESSING = 'LOGOUT_PROCESSING'
export const LOGOUT_SUCCESSED = 'LOGOUT_SUCCESSED'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

export const AUTOMATION_IMPORT_REQUESTED = 'AUTOMATION_IMPORT_REQUESTED'
export const AUTOMATION_IMPORT_PROCESSING = 'AUTOMATION_IMPORT_PROCESSING'
export const AUTOMATION_IMPORT_SUCCESSED = 'AUTOMATION_IMPORT_SUCCESSED'
export const AUTOMATION_IMPORT_FAILED = 'AUTOMATION_IMPORT_FAILED'

export const VERIFY_CODE_REQUESTED = 'VERIFY_CODE_REQUESTED'
export const VERIFY_CODE_PROCESSING = 'VERIFY_CODE_PROCESSING'
export const VERIFY_CODE_SUCCESSED = 'VERIFY_CODE_SUCCESSED'
export const VERIFY_CODE_FAILED = 'VERIFY_CODE_FAILED'

export const RESET_VERIFY_CODE_REQUESTED = 'RESET_VERIFY_CODE_REQUESTED'

export const USER_PROFILE_REQUESTED = 'USER_PROFILE_REQUESTED'
export const USER_PROFILE_PROCESSING = 'USER_PROFILE_PROCESSING'
export const USER_PROFILE_SUCCESSED = 'USER_PROFILE_SUCCESSED'
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED'

export const USER_CHANGE_PASSWORD_REQUESTED = 'USER_CHANGE_PASSWORD_REQUESTED'
export const USER_CHANGE_PASSWORD_PROCESSING = 'USER_CHANGE_PASSWORD_PROCESSING'
export const USER_CHANGE_PASSWORD_SUCCESSED = 'USER_CHANGE_PASSWORD_SUCCESSED'
export const USER_CHANGE_PASSWORD_FAILED = 'USER_CHANGE_PASSWORD_FAILED'

export const USER_PROFILE_UPDATE_REQUESTED = 'USER_PROFILE_UPDATE_REQUESTED'
export const USER_PROFILE_UPDATE_PROCESSING = 'USER_PROFILE_UPDATE_PROCESSING'
export const USER_PROFILE_UPDATE_SUCCESSED = 'USER_PROFILE_UPDATE_SUCCESSED'
export const USER_PROFILE_UPDATE_FAILED = 'USER_PROFILE_UPDATE_FAILED'

export const USER_GOOGLE_QR_REQUESTED = 'USER_GOOGLE_QR_REQUESTED'
export const USER_GOOGLE_QR_PROCESSING = 'USER_GOOGLE_QR_PROCESSING'
export const USER_GOOGLE_QR_SUCCESSED = 'USER_GOOGLE_QR_SUCCESSED'
export const USER_GOOGLE_QR_FAILED = 'USER_GOOGLE_QR_FAILED'

export const USER_GOOGLE_AUTH_REQUESTED = 'USER_GOOGLE_AUTH_REQUESTED'
export const USER_GOOGLE_AUTH_PROCESSING = 'USER_GOOGLE_AUTH_PROCESSING'
export const USER_GOOGLE_AUTH_SUCCESSED = 'USER_GOOGLE_AUTH_SUCCESSED'
export const USER_GOOGLE_AUTH_FAILED = 'USER_GOOGLE_AUTH_FAILED'

export const UNSAVED_CHANGES_REQUESTED = 'UNSAVED_CHANGES_REQUESTED'

// cases
export const CASES_LIST_REQUESTED = 'CASES_LIST_REQUESTED'
export const CASES_LIST_PROCESSING = 'CASES_LIST_PROCESSING'
export const CASES_LIST_SUCCESSED = 'CASES_LIST_SUCCESSED'
export const CASES_LIST_FAILED = 'CASES_LIST_FAILED'

export const FILTERED_CASES_REQUESTED = 'FILTERED_CASES_REQUESTED'
export const FILTERED_CASES_PROCESSING = 'FILTERED_CASES_PROCESSING'
export const FILTERED_CASES_SUCCESSED = 'FILTERED_CASES_SUCCESSED'
export const FILTERED_CASES_FAILED = 'FILTERED_CASES_FAILED'

export const DELETE_CASE_REQUESTED = 'DELETE_CASE_REQUESTED'
export const DELETE_CASE_PROCESSING = 'DELETE_CASE_PROCESSING'
export const DELETE_CASE_SUCCESSED = 'DELETE_CASE_SUCCESSED'
export const DELETE_CASE_FAILED = 'DELETE_CASE_FAILED'

export const UPDATE_CASES_ADVISORY_REQUEST = 'UPDATE_CASES_ADVISORY_REQUEST'
export const UPDATE_CASES_ADVISORY_REQUEST_SUCCESSED = 'UPDATE_CASES_ADVISORY_REQUEST_SUCCESSED'
export const UPDATE_CASES_ADVISORY_REQUEST_FAILED = 'UPDATE_CASES_ADVISORY_REQUEST_FAILED'

export const CATEGORY_FILTER_REQUESTED = 'CATEGORY_FILTER_REQUESTED'
export const CATEGORY_FILTER_PROCESSING = 'CATEGORY_FILTER_PROCESSING'
export const CATEGORY_FILTER_SUCCESSED = 'CATEGORY_FILTER_SUCCESSED'
export const CATEGORY_FILTER_FAILED = 'CATEGORY_FILTER_FAILED'

export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

export const HELP_LIST_REQUESTED = 'HELP_LIST_REQUESTED'
export const HELP_LIST_PROCESSING = 'HELP_LIST_PROCESSING'
export const HELP_LIST_SUCCESSED = 'HELP_LIST_SUCCESSED'
export const HELP_LIST_FAILED = 'HELP_LIST_FAILED'

export const HELP_DETAILS_REQUESTED = 'HELP_DETAILS_REQUESTED'
export const HELP_DETAILS_PROCESSING = 'HELP_DETAILS_PROCESSING'
export const HELP_DETAILS_SUCCESSED = 'HELP_DETAILS_SUCCESSED'
export const HELP_DETAILS_FAILED = 'HELP_DETAILS_FAILED'

// Asset
export const ASSET_LIST_REQUESTED = 'ASSET_LIST_REQUESTED'
export const ASSET_LIST_PROCESSING = 'ASSET_LIST_PROCESSING'
export const ASSET_LIST_SUCCESSED = 'ASSET_LIST_SUCCESSED'
export const ASSET_LIST_FAILED = 'ASSET_LIST_FAILED'

export const ASSET_DETAILS_REQUESTED = 'ASSET_DETAILS_REQUESTED'
export const ASSET_DETAILS_PROCESSING = 'ASSET_DETAILS_PROCESSING'
export const ASSET_DETAILS_SUCCESSED = 'ASSET_DETAILS_SUCCESSED'
export const ASSET_DETAILS_FAILED = 'ASSET_DETAILS_FAILED'

export const ASSET_DASHBOARD_REQUESTED = 'ASSET_DASHBOARD_REQUESTED'
export const ASSET_DASHBOARD_PROCESSING = 'ASSET_DASHBOARD_PROCESSING'
export const ASSET_DASHBOARD_SUCCESSED = 'ASSET_DASHBOARD_SUCCESSED'
export const ASSET_DASHBOARD_FAILED = 'ASSET_DASHBOARD_FAILED'

export const ASSET_DELETE_REQUESTED = 'ASSET_DELETE_REQUESTED'
export const ASSET_DELETE_PROCESSING = 'ASSET_DELETE_PROCESSING'
export const ASSET_DELETE_SUCCESSED = 'ASSET_DELETE_SUCCESSED'
export const ASSET_DELETE_FAILED = 'ASSET_DELETE_FAILED'

export const ASSET_CREATE_REQUESTED = 'ASSET_CREATE_REQUESTED'
export const ASSET_CREATE_PROCESSING = 'ASSET_CREATE_PROCESSING'
export const ASSET_CREATE_SUCCESSED = 'ASSET_CREATE_SUCCESSED'
export const ASSET_CREATE_FAILED = 'ASSET_CREATE_FAILED'

export const ASSET_EDIT_REQUESTED = 'ASSET_EDIT_REQUESTED'
export const ASSET_EDIT_PROCESSING = 'ASSET_EDIT_PROCESSING'
export const ASSET_EDIT_SUCCESSED = 'ASSET_EDIT_SUCCESSED'
export const ASSET_EDIT_FAILED = 'ASSET_EDIT_FAILED'

export const ASSET_BULK_DELETE_REQUESTED = 'ASSET_BULK_DELETE_REQUESTED'
export const ASSET_BULK_DELETE_SUCCESSED = 'ASSET_BULK_DELETE_SUCCESSED'
export const ASSET_BULK_DELETE_FAILED = 'ASSET_BULK_DELETE_FAILED'

export const ASSET_IMPORTED_JOB_DELETE_REQUESTED = 'ASSET_IMPORTED_JOB_DELETE_REQUESTED'
export const ASSET_IMPORTED_JOB_DELETE_SUCCESSED = 'ASSET_IMPORTED_JOB_DELETE_SUCCESSED'
export const ASSET_IMPORTED_JOB_DELETE_FAILED = 'ASSET_IMPORTED_JOB_DELETE_FAILED'

export const ASSET_EXPORT_JOB_DELETE_REQUESTED = 'ASSET_EXPORT_JOB_DELETE_REQUESTED'
export const ASSET_EXPORT_JOB_DELETE_SUCCESSED = 'ASSET_EXPORT_JOB_DELETE_SUCCESSED'
export const ASSET_EXPORT_JOB_DELETE_FAILED = 'ASSET_EXPORT_JOB_DELETE_FAILED'

//Newtwork Block
export const NETWORK_BLOCK_LIST_REQUESTED = 'NETWORK_BLOCK_LIST_REQUESTED'
export const NETWORK_BLOCK_LIST_PROCESSING = 'NETWORK_BLOCK_LIST_PROCESSING'
export const NETWORK_BLOCK_LIST_SUCCESSED = 'NETWORK_BLOCK_LIST_SUCCESSED'
export const NETWORK_BLOCK_LIST_FAILED = 'NETWORK_BLOCK_LIST_FAILED'

export const NETWORK_BLOCK_CREATE_REQUESTED = 'NETWORK_BLOCK_CREATE_REQUESTED'
export const NETWORK_BLOCK_CREATE_PROCESSING = 'NETWORK_BLOCK_CREATE_PROCESSING'
export const NETWORK_BLOCK_CREATE_SUCCESSED = 'NETWORK_BLOCK_CREATE_SUCCESSED'
export const NETWORK_BLOCK_CREATE_FAILED = 'NETWORK_BLOCK_CREATE_FAILED'


export const NETWORK_BLOCK_DELETE_REQUESTED = 'NETWORK_BLOCK_DELETE_REQUESTED'
export const NETWORK_BLOCK_DELETE_PROCESSING = 'NETWORK_BLOCK_DELETE_PROCESSING'
export const NETWORK_BLOCK_DELETE_SUCCESSED = 'NETWORK_BLOCK_DELETE_SUCCESSED'
export const NETWORK_BLOCK_DELETE_FAILED = 'NETWORK_BLOCK_DELETE_FAILED'

export const NETWORK_BLOCK_EDIT_REQUESTED = 'NETWORK_BLOCK_EDIT_REQUESTED'
export const NETWORK_BLOCK_EDIT_PROCESSING = 'NETWORK_BLOCK_EDIT_PROCESSING'
export const NETWORK_BLOCK_EDIT_SUCCESSED = 'NETWORK_BLOCK_EDIT_SUCCESSED'
export const NETWORK_BLOCK_EDIT_FAILED = 'NETWORK_BLOCK_EDIT_FAILED'

// AssetsRule
export const ASSET_RULE_LIST_REQUESTED = 'ASSET_RULE_LIST_REQUESTED'
export const ASSET_RULE_LIST_PROCESSING = 'ASSET_RULE_LIST_PROCESSING'
export const ASSET_RULE_LIST_SUCCESSED = 'ASSET_RULE_LIST_SUCCESSED'
export const ASSET_RULE_LIST_FAILED = 'ASSET_RULE_LIST_FAILED'

export const ASSET_RULE_VIEW_LIST_REQUESTED = 'ASSET_RULE_VIEW_LIST_REQUESTED'
export const ASSET_RULE_VIEW_LIST_PROCESSING = 'ASSET_RULE_VIEW_LIST_PROCESSING'
export const ASSET_RULE_VIEW_LIST_SUCCESSED = 'ASSET_RULE_VIEW_LIST_SUCCESSED'
export const ASSET_RULE_VIEW_LIST_FAILED = 'ASSET_RULE_VIEW_LIST_FAILED'

export const ASSET_RULE_VIEW_DELETE_REQUESTED = 'ASSET_RULE_VIEW_DELETE_REQUESTED'
export const ASSET_RULE_VIEW_DELETE_PROCESSING = 'ASSET_RULE_VIEW_DELETE_PROCESSING'
export const ASSET_RULE_VIEW_DELETE_SUCCESSED = 'ASSET_RULE_VIEW_DELETE_SUCCESSED'
export const ASSET_RULE_VIEW_DELETE_FAILED = 'ASSET_RULE_VIEW_DELETE_FAILED'

export const ASSET_RULE_VIEW_SAVE_REQUESTED = 'ASSET_RULE_VIEW_SAVE_REQUESTED'
export const ASSET_RULE_VIEW_SAVE_PROCESSING = 'ASSET_RULE_VIEW_SAVE_PROCESSING'
export const ASSET_RULE_VIEW_SAVE_SUCCESSED = 'ASSET_RULE_VIEW_SAVE_SUCCESSED'
export const ASSET_RULE_VIEW_SAVE_FAILED = 'ASSET_RULE_VIEW_SAVE_FAILED'

export const ASSET_RULE_DELETE_REQUESTED = 'ASSET_RULE_DELETE_REQUESTED'
export const ASSET_RULE_DELETE_PROCESSING = 'ASSET_RULE_DELETE_PROCESSING'
export const ASSET_RULE_DELETE_SUCCESSED = 'ASSET_RULE_DELETE_SUCCESSED'
export const ASSET_RULE_DELETE_FAILED = 'ASSET_RULE_DELETE_FAILED'

export const ASSET_RULE_VIEW_SINGLE_DELETE_REQUESTED = 'ASSET_RULE_VIEW_SINGLE_DELETE_REQUESTED'
export const ASSET_RULE_VIEW_SINGLE_DELETE_PROCESSING = 'ASSET_RULE_VIEW_SINGLE_DELETE_PROCESSING'
export const ASSET_RULE_VIEW_SINGLE_DELETE_SUCCESSED = 'ASSET_RULE_VIEW_SINGLE_DELETE_SUCCESSED'
export const ASSET_RULE_VIEW_SINGLE_DELETE_FAILED = 'ASSET_RULE_VIEW_SINGLE_DELETE_FAILED'

export const ASSET_RULE_VIEW_SINGLE_SAVE_REQUESTED = 'ASSET_RULE_VIEW_SINGLE_SAVE_REQUESTED'
export const ASSET_RULE_VIEW_SINGLE_SAVE_PROCESSING = 'ASSET_RULE_VIEW_SINGLE_SAVE_PROCESSING'
export const ASSET_RULE_VIEW_SINGLE_SAVE_SUCCESSED = 'ASSET_RULE_VIEW_SINGLE_SAVE_SUCCESSED'
export const ASSET_RULE_VIEW_SINGLE_SAVE_FAILED = 'ASSET_RULE_VIEW_SINGLE_SAVE_FAILED'

export const ASSET_RULE_CREATE_REQUESTED = 'ASSET_RULE_CREATE_REQUESTED'
export const ASSET_RULE_CREATE_PROCESSING = 'ASSET_RULE_CREATE_PROCESSING'
export const ASSET_RULE_CREATE_SUCCESSED = 'ASSET_RULE_CREATE_SUCCESSED'
export const ASSET_RULE_CREATE_FAILED = 'ASSET_RULE_CREATE_FAILED'

export const ASSET_RULE_PLAY_REQUESTED = 'ASSET_RULE_PLAY_REQUESTED'
export const ASSET_RULE_PLAY_PROCESSING = 'ASSET_RULE_PLAY_PROCESSING'
export const ASSET_RULE_PLAY_SUCCESSED = 'ASSET_RULE_PLAY_SUCCESSED'

export const ASSET_RULE_PLAY_FAILED = 'ASSET_RULE_PLAY_FAILED'

export const ASSET_RULE_EDIT_REQUESTED = 'ASSET_RULE_EDIT_REQUESTED'
export const ASSET_RULE_EDIT_PROCESSING = 'ASSET_RULE_EDIT_PROCESSING'
export const ASSET_RULE_EDIT_SUCCESSED = 'ASSET_RULE_EDIT_SUCCESSED'
export const ASSET_RULE_EDIT_FAILED = 'ASSET_RULE_EDIT_FAILED'

export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED'
export const FORGOT_PASSWORD_PROCESSING = 'FORGOT_PASSWORD_PROCESSING'
export const FORGOT_PASSWORD_SUCCESSED = 'FORGOT_PASSWORD_SUCCESSED'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'

export const UPDATE_LICENSE_REQUESTED = 'UPDATE_LICENSE_REQUESTED'
export const UPDATE_LICENSE_PROCESSING = 'UPDATE_LICENSE_PROCESSING'
export const UPDATE_LICENSE_SUCCESSED = 'UPDATE_LICENSE_SUCCESSED'
export const UPDATE_LICENSE_FAILED = 'UPDATE_LICENSE_FAILED'

export const USER_CONFIRMATION_REQUESTED = 'USER_CONFIRMATION_REQUESTED'
export const USER_CONFIRMATION_SUCCESSED = 'USER_CONFIRMATION_SUCCESSED'
export const USER_CONFIRMATION_FAILED = 'USER_CONFIRMATION_FAILED'

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED'
export const RESET_PASSWORD_PROCESSING = 'RESET_PASSWORD_PROCESSING'
export const RESET_PASSWORD_SUCCESSED = 'RESET_PASSWORD_SUCCESSED'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED'
export const CHANGE_PASSWORD_PROCESSING = 'CHANGE_PASSWORD_PROCESSING'
export const CHANGE_PASSWORD_SUCCESSED = 'CHANGE_PASSWORD_SUCCESSED'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const INVITED_USER_REQUESTED = 'INVITED_USER_REQUESTED'
export const INVITED_USER_PROCESSING = 'INVITED_USER_PROCESSING'
export const INVITED_USER_SUCCESSED = 'INVITED_USER_SUCCESSED'
export const INVITED_USER_FAILED = 'INVITED_USER_FAILED'

export const IMPORT_ASSET_REQUESTED = 'IMPORT_ASSET_REQUESTED'
export const IMPORT_ASSET_PROCESSING = 'IMPORT_ASSET_PROCESSING'
export const IMPORT_ASSET_SUCCESSED = 'IMPORT_ASSET_SUCCESSED'
export const IMPORT_ASSET_FAILED = 'IMPORT_ASSET_FAILED'

export const IMPORT_ASSET_LOGS_REQUESTED = 'IMPORT_ASSET_LOGS_REQUESTED'
export const IMPORT_ASSET_LOGS_PROCESSING = 'IMPORT_ASSET_LOGS_PROCESSING'
export const IMPORT_ASSET_LOGS_SUCCESSED = 'IMPORT_ASSET_LOGS_SUCCESSED'
export const IMPORT_ASSET_LOGS_FAILED = 'IMPORT_ASSET_LOGS_FAILED'

export const EXPORT_ASSET_LOGS_REQUESTED = 'EXPORT_ASSET_LOGS_REQUESTED'
export const EXPORT_ASSET_LOGS_PROCESSING = 'EXPORT_ASSET_LOGS_PROCESSING'
export const EXPORT_ASSET_LOGS_SUCCESSED = 'EXPORT_ASSET_LOGS_SUCCESSED'
export const EXPORT_ASSET_LOGS_FAILED = 'EXPORT_ASSET_LOGS_FAILED'

export const GOOGLE_OAUTH2 = 'GOOGLE_OAUTH2'

export const OWNER_LIST_REQUESTED = 'OWNER_LIST_REQUESTED'
export const OWNER_LIST_PROCESSING = 'OWNER_LIST_PROCESSING'
export const OWNER_LIST_SUCCESSED = 'OWNER_LIST_SUCCESSED'
export const OWNER_LIST_FAILED = 'OWNER_LIST_FAILED'

export const OWNER_DETAILS_REQUESTED = 'OWNER_DETAILS_REQUESTED'
export const OWNER_DETAILS_PROCESSING = 'OWNER_DETAILS_PROCESSING'
export const OWNER_DETAILS_SUCCESSED = 'OWNER_DETAILS_SUCCESSED'
export const OWNER_DETAILS_FAILED = 'OWNER_DETAILS_FAILED'

export const LOCATION_LIST_REQUESTED = 'LOCATION_LIST_REQUESTED'
export const LOCATION_LIST_PROCESSING = 'LOCATION_LIST_PROCESSING'
export const LOCATION_LIST_SUCCESSED = 'LOCATION_LIST_SUCCESSED'
export const LOCATION_LIST_FAILED = 'LOCATION_LIST_FAILED'

export const LOCATION_DETAILS_REQUESTED = 'LOCATION_DETAILS_REQUESTED'
export const LOCATION_DETAILS_PROCESSING = 'LOCATION_DETAILS_PROCESSING'
export const LOCATION_DETAILS_SUCCESSED = 'LOCATION_DETAILS_SUCCESSED'
export const LOCATION_DETAILS_FAILED = 'LOCATION_DETAILS_FAILED'

export const ORGANIZATION_LIST_REQUESTED = 'ORGANIZATION_LIST_REQUESTED'
export const ORGANIZATION_LIST_PROCESSING = 'ORGANIZATION_LIST_PROCESSING'
export const ORGANIZATION_LIST_SUCCESSED = 'ORGANIZATION_LIST_SUCCESSED'
export const ORGANIZATION_LIST_FAILED = 'ORGANIZATION_LIST_FAILED'

export const ORGANIZATION_INFOMATION_POST_REQUESTED = 'ORGANIZATION_INFOMATION_POST_REQUESTED'
export const ORGANIZATION_INFOMATION_POST_PROCESSING = 'ORGANIZATION_INFOMATION_POST_PROCESSING'
export const ORGANIZATION_INFOMATION_POST_SUCCESSED = 'ORGANIZATION_INFOMATION_POST_SUCCESSED'
export const ORGANIZATION_INFOMATION_POST_FAILED = 'ORGANIZATION_INFOMATION_POST_FAILED'

export const ORGANIZATION_DETAILS_REQUESTED = 'ORGANIZATION_DETAILS_REQUESTED'
export const ORGANIZATION_DETAILS_PROCESSING = 'ORGANIZATION_DETAILS_PROCESSING'
export const ORGANIZATION_DETAILS_SUCCESSED = 'ORGANIZATION_DETAILS_SUCCESSED'
export const ORGANIZATION_DETAILS_FAILED = 'ORGANIZATION_DETAILS_FAILED'

export const DISPOSITION_LIST_REQUESTED = 'DISPOSITION_LIST_REQUESTED'
export const DISPOSITION_LIST_PROCESSING = 'DISPOSITION_LIST_PROCESSING'
export const DISPOSITION_LIST_SUCCESSED = 'DISPOSITION_LIST_SUCCESSED'
export const DISPOSITION_LIST_FAILED = 'DISPOSITION_LIST_FAILED'

export const DISPOSITION_DETAILS_REQUESTED = 'DISPOSITION_DETAILS_REQUESTED'
export const DISPOSITION_DETAILS_PROCESSING = 'DISPOSITION_DETAILS_PROCESSING'
export const DISPOSITION_DETAILS_SUCCESSED = 'DISPOSITION_DETAILS_SUCCESSED'
export const DISPOSITION_DETAILS_FAILED = 'DISPOSITION_DETAILS_FAILED'
export const ASSET_CLASSIFICATION_LIST_REQUESTED = 'ASSET_CLASSIFICATION_LIST_REQUESTED'
export const ASSET_CLASSIFICATION_LIST_PROCESSING = 'ASSET_CLASSIFICATION_LIST_PROCESSING'
export const ASSET_CLASSIFICATION_LIST_SUCCESSED = 'ASSET_CLASSIFICATION_LIST_SUCCESSED'
export const ASSET_CLASSIFICATION_LIST_FAILED = 'ASSET_CLASSIFICATION_LIST_FAILED'
export const RESET_ASSET_CLASSIFICATION_REQUESTED = 'RESET_ASSET_CLASSIFICATION_REQUESTED'

export const ASSET_CONFIDENTIALITY_LIST_REQUESTED = 'ASSET_CONFIDENTIALITY_LIST_REQUESTED'
export const ASSET_CONFIDENTIALITY_LIST_PROCESSING = 'ASSET_CONFIDENTIALITY_LIST_PROCESSING'
export const ASSET_CONFIDENTIALITY_LIST_SUCCESSED = 'ASSET_CONFIDENTIALITY_LIST_SUCCESSED'
export const ASSET_CONFIDENTIALITY_LIST_FAILED = 'ASSET_CONFIDENTIALITY_LIST_FAILED'

export const ASSET_AVAILABILITY_LIST_REQUESTED = 'ASSET_AVAILABILITY_LIST_REQUESTED'
export const ASSET_AVAILABILITY_LIST_PROCESSING = 'ASSET_AVAILABILITY_LIST_PROCESSING'
export const ASSET_AVAILABILITY_LIST_SUCCESSED = 'ASSET_AVAILABILITY_LIST_SUCCESSED'
export const ASSET_AVAILABILITY_LIST_FAILED = 'ASSET_AVAILABILITY_LIST_FAILED'

export const ASSET_INTEGRITY_LIST_REQUESTED = 'ASSET_INTEGRITY_LIST_REQUESTED'
export const ASSET_INTEGRITY_LIST_PROCESSING = 'ASSET_INTEGRITY_LIST_PROCESSING'
export const ASSET_INTEGRITY_LIST_SUCCESSED = 'ASSET_INTEGRITY_LIST_SUCCESSED'
export const ASSET_INTEGRITY_LIST_FAILED = 'ASSET_INTEGRITY_LIST_FAILED'

export const ASSET_CLASSIFICATION_CHANGE_TITLE_REQUESTED = 'ASSET_CLASSIFICATION_CHANGE_TITLE_REQUESTED'
export const ASSET_CLASSIFICATION_CHANGE_TITLE_PROCESSING = 'ASSET_CLASSIFICATION_CHANGE_TITLE_PROCESSING'
export const ASSET_CLASSIFICATION_CHANGE_TITLE_SUCCESSED = 'ASSET_CLASSIFICATION_CHANGE_TITLE_SUCCESSED'
export const ASSET_CLASSIFICATION_CHANGE_TITLE_FAILED = 'ASSET_CLASSIFICATION_CHANGE_TITLE_FAILED'

export const ASSET_CLASSIFICATION_FORMULA_CHANGE_REQUESTED = 'ASSET_CLASSIFICATION_FORMULA_CHANGE_REQUESTED'
export const ASSET_CLASSIFICATION_FORMULA_CHANGE_PROCESSING = 'ASSET_CLASSIFICATION_FORMULA_CHANGE_PROCESSING'
export const ASSET_CLASSIFICATION_FORMULA_CHANGE_SUCCESSED = 'ASSET_CLASSIFICATION_FORMULA_CHANGE_SUCCESSED'
export const ASSET_CLASSIFICATION_FORMULA_CHANGE_FAILED = 'ASSET_CLASSIFICATION_FORMULA_CHANGE_FAILED'

export const ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED = 'ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED'
export const ASSET_CLASSIFICATION_GET_FORMULA_PROCESSING = 'ASSET_CLASSIFICATION_GET_FORMULA_PROCESSING'
export const ASSET_CLASSIFICATION_GET_FORMULA_SUCCESSED = 'ASSET_CLASSIFICATION_GET_FORMULA_SUCCESSED'
export const ASSET_CLASSIFICATION_GET_FORMULA_FAILED = 'ASSET_CLASSIFICATION_GET_FORMULA_FAILED'

export const ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED = 'ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED'
export const ASSET_CLASSIFICATION_INTEGIRTY_LIST_PROCESSING = 'ASSET_CLASSIFICATION_INTEGIRTY_LIST_PROCESSING'
export const ASSET_CLASSIFICATION_INTEGIRTY_LIST_SUCCESSED = 'ASSET_CLASSIFICATION_INTEGIRTY_LIST_SUCCESSED'
export const ASSET_CLASSIFICATION_INTEGIRTY_LIST_FAILED = 'ASSET_CLASSIFICATION_INTEGIRTY_LIST_FAILED'

export const ASSET_CLASSIFICATION_INTEGIRTY_DELETE_REQUESTED = 'ASSET_CLASSIFICATION_INTEGIRTY_DELETE_REQUESTED'
export const ASSET_CLASSIFICATION_INTEGIRTY_DELETE_PROCESSING = 'ASSET_CLASSIFICATION_INTEGIRTY_DELETE_PROCESSING'
export const ASSET_CLASSIFICATION_INTEGIRTY_DELETE_SUCCESSED = 'ASSET_CLASSIFICATION_INTEGIRTY_DELETE_SUCCESSED'
export const ASSET_CLASSIFICATION_INTEGIRTY_DELETE_FAILED = 'ASSET_CLASSIFICATION_INTEGIRTY_DELETE_FAILED'

export const ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_REQUESTED = 'ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_REQUESTED'
export const ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_PROCESSING = 'ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_PROCESSING'
export const ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_SUCCESSED = 'ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_SUCCESSED'
export const ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_FAILED = 'ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_FAILED'

export const ASSET_CLASSIFICATION_INTEGIRTY_CREATE_REQUESTED = 'ASSET_CLASSIFICATION_INTEGIRTY_CREATE_REQUESTED'
export const ASSET_CLASSIFICATION_INTEGIRTY_CREATE_PROCESSING = 'ASSET_CLASSIFICATION_INTEGIRTY_CREATE_PROCESSING'
export const ASSET_CLASSIFICATION_INTEGIRTY_CREATE_SUCCESSED = 'ASSET_CLASSIFICATION_INTEGIRTY_CREATE_SUCCESSED'
export const ASSET_CLASSIFICATION_INTEGIRTY_CREATE_FAILED = 'ASSET_CLASSIFICATION_INTEGIRTY_CREATE_FAILED'

export const ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED = 'ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED'
export const ASSET_CLASSIFICATION_AVAILABILITY_LIST_PROCESSING = 'ASSET_CLASSIFICATION_AVAILABILITY_LIST_PROCESSING'
export const ASSET_CLASSIFICATION_AVAILABILITY_LIST_SUCCESSED = 'ASSET_CLASSIFICATION_AVAILABILITY_LIST_SUCCESSED'
export const ASSET_CLASSIFICATION_AVAILABILITY_LIST_FAILED = 'ASSET_CLASSIFICATION_AVAILABILITY_LIST_FAILED'

export const ASSET_CLASSIFICATION_AVAILABILITY_CREATE_REQUESTED = 'ASSET_CLASSIFICATION_AVAILABILITY_CREATE_REQUESTED'
export const ASSET_CLASSIFICATION_AVAILABILITY_CREATE_PROCESSING = 'ASSET_CLASSIFICATION_AVAILABILITY_CREATE_PROCESSING'
export const ASSET_CLASSIFICATION_AVAILABILITY_CREATE_SUCCESSED = 'ASSET_CLASSIFICATION_AVAILABILITY_CREATE_SUCCESSED'
export const ASSET_CLASSIFICATION_AVAILABILITY_CREATE_FAILED = 'ASSET_CLASSIFICATION_AVAILABILITY_CREATE_FAILED'

export const ASSET_CLASSIFICATION_AVAILABILITY_DELETE_REQUESTED = 'ASSET_CLASSIFICATION_AVAILABILITY_DELETE_REQUESTED'
export const ASSET_CLASSIFICATION_AVAILABILITY_DELETE_PROCESSING = 'ASSET_CLASSIFICATION_AVAILABILITY_DELETE_PROCESSING'
export const ASSET_CLASSIFICATION_AVAILABILITY_DELETE_SUCCESSED = 'ASSET_CLASSIFICATION_AVAILABILITY_DELETE_SUCCESSED'
export const ASSET_CLASSIFICATION_AVAILABILITY_DELETE_FAILED = 'ASSET_CLASSIFICATION_AVAILABILITY_DELETE_FAILED'

export const ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_REQUESTED = 'ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_REQUESTED'
export const ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_PROCESSING = 'ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_PROCESSING'
export const ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_SUCCESSED = 'ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_SUCCESSED'
export const ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_FAILED = 'ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_FAILED'

export const ASSET_OS_LIST_REQUESTED = 'ASSET_OS_LIST_REQUESTED'
export const ASSET_OS_LIST_PROCESSING = 'ASSET_OS_LIST_PROCESSING'
export const ASSET_OS_LIST_SUCCESSED = 'ASSET_OS_LIST_SUCCESSED'
export const ASSET_OS_LIST_FAILED = 'ASSET_OS_LIST_FAILED'

export const PLAYBOOK_LIST_REQUESTED = 'PLAYBOOK_LIST_REQUESTED'
export const PLAYBOOK_LIST_PROCESSING = 'PLAYBOOK_LIST_PROCESSING'
export const PLAYBOOK_LIST_SUCCESSED = 'PLAYBOOK_LIST_SUCCESSED'
export const PLAYBOOK_LIST_FAILED = 'PLAYBOOK_LIST_FAILED'


export const PLAYBOOK_MARKETPLACE_LIST_REQUESTED = 'PLAYBOOK_MARKETPLACE_LIST_REQUESTED'
export const PLAYBOOK_MARKETPLACE_LIST_PROCESSING = 'PLAYBOOK_MARKETPLACE_LIST_PROCESSING'
export const PLAYBOOK_MARKETPLACE_LIST_SUCCESSED = 'PLAYBOOK_MARKETPLACE_LIST_SUCCESSED'
export const PLAYBOOK_MARKETPLACE_LIST_FAILED = 'PLAYBOOK_MARKETPLACE_LIST_FAILED'


export const PLAYBOOK_MARKETPLACE_VIEW_REQUESTED = 'PLAYBOOK_MARKETPLACE_VIEW_REQUESTED'
export const PLAYBOOK_MARKETPLACE_VIEW_PROCESSING = 'PLAYBOOK_MARKETPLACE_VIEW_PROCESSING'
export const PLAYBOOK_MARKETPLACE_VIEW_SUCCESSED = 'PLAYBOOK_MARKETPLACE_VIEW_SUCCESSED'
export const PLAYBOOK_MARKETPLACE_VIEW_FAILED = 'PLAYBOOK_MARKETPLACE_VIEW_FAILED'

export const PLAYBOOK_RULES_LIST_REQUESTED = 'PLAYBOOK_RULES_LIST_REQUESTED'
export const PLAYBOOK_RULES_LIST_SUCCESSED = 'PLAYBOOK_RULES_LIST_SUCCESSED'
export const PLAYBOOK_RULES_LIST_FAILED = 'PLAYBOOK_RULES_LIST_FAILED'

export const PLAYBOOK_RULES_CREATE_LIST_REQUESTED = 'PLAYBOOK_RULES_CREATE_LIST_REQUESTED'
export const PLAYBOOK_RULES_CREATE_LIST_SUCCESSED = 'PLAYBOOK_RULES_CREATE_LIST_SUCCESSED'
export const PLAYBOOK_RULES_CREATE_LIST_FAILED = 'PLAYBOOK_RULES_CREATE_LIST_FAILED'



export const CUSTOM_PLAYBOOK_EXECUTE_REQUESTED = 'CUSTOM_PLAYBOOK_EXECUTE_REQUESTED'
export const CUSTOM_PLAYBOOK_EXECUTE_SUCCESSED = 'CUSTOM_PLAYBOOK_EXECUTE_SUCCESSED'
export const CUSTOM_PLAYBOOK_EXECUTE_FAILED = 'CUSTOM_PLAYBOOK_EXECUTE_FAILED'


export const PLAYBOOK_RULES_UPDATE_LIST_REQUESTED = 'PLAYBOOK_RULES_UPDATE_LIST_REQUESTED'
export const PLAYBOOK_RULES_UPDATE_LIST_SUCCESSED = 'PLAYBOOK_RULES_UPDATE_LIST_SUCCESSED'
export const PLAYBOOK_RULES_UPDATE_LIST_FAILED = 'PLAYBOOK_RULES_UPDATE_LIST_FAILED'

export const PLAYBOOK_RULES_DELETE_LIST_REQUESTED = 'PLAYBOOK_RULES_DELETE_LIST_REQUESTED'
export const PLAYBOOK_RULES_DELETE_LIST_SUCCESSED = 'PLAYBOOK_RULES_DELETE_LIST_SUCCESSED'
export const PLAYBOOK_RULES_DELETE_LIST_FAILED = 'PLAYBOOK_RULES_DELETE_LIST_FAILED'

export const PLAYBOOKS_LOGS_REQUESTED = 'PLAYBOOKS_LOGS_REQUESTED'
export const PLAYBOOKS_LOGS_PROCESSING = 'PLAYBOOKS_LOGS_PROCESSING'
export const PLAYBOOKS_LOGS_SUCCESSED = 'PLAYBOOKS_LOGS_SUCCESSED'
export const PLAYBOOKS_LOGS_FAILED = 'PLAYBOOKS_LOGS_FAILED'

export const PLAYBOOK_DELETE_REQUESTED = 'PLAYBOOK_DELETE_REQUESTED'
export const PLAYBOOK_DELETE_PROCESSING = 'PLAYBOOK_DELETE_PROCESSING'
export const PLAYBOOK_DELETE_SUCCESSED = 'PLAYBOOK_DELETE_SUCCESSED'
export const PLAYBOOK_DELETE_FAILED = 'PLAYBOOK_DELETE_FAILED'


export const PLAYBOOK_MARKETPLACE_DATA_REQUESTED = 'PLAYBOOK_MARKETPLACE_DATA_REQUESTED'
export const PLAYBOOK_MARKETPLACE_DATA_PROCESSING = 'PLAYBOOK_MARKETPLACE_DATA_PROCESSING'
export const PLAYBOOK_MARKETPLACE_DATA_SUCCESSED = 'PLAYBOOK_MARKETPLACE_DATA_SUCCESSED'
export const PLAYBOOK_MARKETPLACE_DATA_FAILED = 'PLAYBOOK_MARKETPLACE_DATA_FAILED'

export const PLAYBOOK_MP_IMPORT_REQUESTED = 'PLAYBOOK_MP_IMPORT_REQUESTED'
export const PLAYBOOK_MP_IMPORT_PROCESSING = 'PLAYBOOK_MP_IMPORT_PROCESSING'
export const PLAYBOOK_MP_IMPORT_SUCCESSED = 'PLAYBOOK_MP_IMPORT_SUCCESSED'
export const PLAYBOOK_MP_IMPORT_FAILED = 'PLAYBOOK_MP_IMPORT_FAILED'

export const PLAYBOOK_LOG_DELETE_REQUESTED = 'PLAYBOOK_LOG_DELETE_REQUESTED'
export const PLAYBOOK_LOG_DELETE_PROCESSING = 'PLAYBOOK_LOG_DELETE_PROCESSING'
export const PLAYBOOK_LOG_DELETE_SUCCESSED = 'PLAYBOOK_LOG_DELETE_SUCCESSED'
export const PLAYBOOK_LOG_DELETE_FAILED = 'PLAYBOOK_LOG_DELETE_FAILED'
export const ASSET_TYPE_BY_ID_REQUESTED = 'ASSET_TYPE_BY_ID_REQUESTED'
export const ASSET_TYPE_BY_ID_PROCESSING = 'ASSET_TYPE_BY_ID_PROCESSING'
export const ASSET_TYPE_BY_ID_SUCCESSED = 'ASSET_TYPE_BY_ID_SUCCESSED'
export const ASSET_TYPE_BY_ID_FAILED = 'ASSET_TYPE_BY_ID_FAILED'

export const PLAYBOOK_DUPLICATE_REQUESTED = 'PLAYBOOK_DUPLICATE_REQUESTED'
export const PLAYBOOK_DUPLICATE_PROCESSING = 'PLAYBOOK_DUPLICATE_PROCESSING'
export const PLAYBOOK_DUPLICATE_SUCCESSED = 'PLAYBOOK_DUPLICATE_SUCCESSED'
export const PLAYBOOK_DUPLICATE_FAILED = 'PLAYBOOK_DUPLICATE_FAILED'
export const RESET_DUPLICATION_STORE = 'RESET_DUPLICATION_STORE'

export const RESET_AUTOMATION_STORE = 'RESET_AUTOMATION_STORE'

export const ASSET_VALUE_LIST_REQUESTED = 'ASSET_VALUE_LIST_REQUESTED'
export const ASSET_VALUE_LIST_PROCESSING = 'ASSET_VALUE_LIST_PROCESSING'
export const ASSET_VALUE_LIST_SUCCESSED = 'ASSET_VALUE_LIST_SUCCESSED'
export const ASSET_VALUE_LIST_FAILED = 'ASSET_VALUE_LIST_FAILED'

export const DEPARTMENT_LIST_REQUESTED = 'DEPARTMENT_LIST_REQUESTED'
export const DEPARTMENT_LIST_PROCESSING = 'DEPARTMENT_LIST_PROCESSING'
export const DEPARTMENT_LIST_SUCCESSED = 'DEPARTMENT_LIST_SUCCESSED'
export const DEPARTMENT_LIST_FAILED = 'DEPARTMENT_LIST_FAILED'

export const ASSET_STATUS_LIST_REQUESTED = 'ASSET_STATUS_LIST_REQUESTED'
export const ASSET_STATUS_LIST_PROCESSING = 'ASSET_STATUS_LIST_PROCESSING'
export const ASSET_STATUS_LIST_SUCCESSED = 'ASSET_STATUS_LIST_SUCCESSED'
export const ASSET_STATUS_LIST_FAILED = 'ASSET_STATUS_LIST_FAILED'

export const ASSET_REMOTEAPP_LIST_REQUESTED = 'ASSET_REMOTEAPP_LIST_REQUESTED'
export const ASSET_REMOTEAPP_LIST_PROCESSING = 'ASSET_REMOTEAPP_LIST_PROCESSING'
export const ASSET_REMOTEAPP_LIST_SUCCESSED = 'ASSET_REMOTEAPP_LIST_SUCCESSED'
export const ASSET_REMOTEAPP_LIST_FAILED = 'ASSET_REMOTEAPP_LIST_FAILED'

export const ASSET_CATEGORY_FIELDS_REQUESTED = 'ASSET_CATEGORY_FIELDS_REQUESTED'
export const ASSET_CATEGORY_FIELDS_PROCESSING = 'ASSET_CATEGORY_FIELDS_PROCESSING'
export const ASSET_CATEGORY_FIELDS_SUCCESSED = 'ASSET_CATEGORY_FIELDS_SUCCESSED'
export const ASSET_CATEGORY_FIELDS_FAILED = 'ASSET_CATEGORY_FIELDS_FAILED'

export const ASSET_CATEGORIES_LIST_REQUESTED = 'ASSET_CATEGORIES_LIST_REQUESTED'
export const ASSET_CATEGORIES_LIST_PROCESSING = 'ASSET_CATEGORIES_LIST_PROCESSING'
export const ASSET_CATEGORIES_LIST_SUCCESSED = 'ASSET_CATEGORIES_LIST_SUCCESSED'
export const ASSET_CATEGORIES_LIST_FAILED = 'ASSET_CATEGORIES_LIST_FAILED'

export const ASSET_ENABLE_FIELDS_LIST_REQUESTED = 'ASSET_ENABLE_FIELDS_LIST_REQUESTED'
export const ASSET_ENABLE_FIELDS_LIST_PROCESSING = 'ASSET_ENABLE_FIELDS_LIST_PROCESSING'
export const ASSET_ENABLE_FIELDS_LIST_SUCCESSED = 'ASSET_ENABLE_FIELDS_LIST_SUCCESSED'
export const ASSET_ENABLE_FIELDS_LIST_FAILED = 'ASSET_ENABLE_FIELDS_LIST_FAILED'

export const ASSET_TYPES_LIST_REQUESTED = 'ASSET_TYPES_LIST_REQUESTED'
export const ASSET_TYPES_LIST_PROCESSING = 'ASSET_TYPES_LIST_PROCESSING'
export const ASSET_TYPES_LIST_SUCCESSED = 'ASSET_TYPES_LIST_SUCCESSED'
export const ASSET_TYPES_LIST_FAILED = 'ASSET_TYPES_LIST_FAILED'

export const INCIDENT_MANAGEMENT_LIST_REQUESTED = 'INCIDENT_MANAGEMENT_LIST_REQUESTED'
export const INCIDENT_MANAGEMENT_LIST_PROCESSING = 'INCIDENT_MANAGEMENT_LIST_PROCESSING'
export const INCIDENT_MANAGEMENT_LIST_SUCCESSED = 'INCIDENT_MANAGEMENT_LIST_SUCCESSED'
export const INCIDENT_MANAGEMENT_LIST_FAILED = 'INCIDENT_MANAGEMENT_LIST_FAILED'

export const INCIDENT_SOURCES_LIST_REQUESTED = 'INCIDENT_SOURCES_LIST_REQUESTED'
export const INCIDENT_SOURCES_LIST_PROCESSING = 'INCIDENT_SOURCES_LIST_PROCESSING'
export const INCIDENT_SOURCES_LIST_SUCCESSED = 'INCIDENT_SOURCES_LIST_SUCCESSED'
export const INCIDENT_SOURCES_LIST_FAILED = 'INCIDENT_SOURCES_LIST_FAILED'

export const REPORTS_LIST_REQUESTED = 'REPORTS_LIST_REQUESTED'
export const REPORTS_LIST_PROCESSING = 'REPORTS_LIST_PROCESSING'
export const REPORTS_LIST_SUCCESSED = 'REPORTS_LIST_SUCCESSED'
export const REPORTS_LIST_FAILED = 'REPORTS_LIST_FAILED'

export const INCIDENT_DEFAULT_SAVED_SEARCH_REQUESTED = 'INCIDENT_DEFAULT_SAVED_SEARCH_REQUESTED'


export const INCIDENT_CUSTOMERS_LIST_REQUESTED = 'INCIDENT_CUSTOMERS_LIST_REQUESTED'
export const INCIDENT_CUSTOMERS_LIST_PROCESSING = 'INCIDENT_CUSTOMERS_LIST_PROCESSING'
export const INCIDENT_CUSTOMERS_LIST_SUCCESSED = 'INCIDENT_CUSTOMERS_LIST_SUCCESSED'
export const INCIDENT_CUSTOMERS_LIST_FAILED = 'INCIDENT_CUSTOMERS_LIST_FAILED'

export const INCIDENT_SEVERITY_LIST_REQUESTED = 'INCIDENT_SEVERITY_LIST_REQUESTED'
export const INCIDENT_SEVERITY_LIST_PROCESSING = 'INCIDENT_SEVERITY_LIST_PROCESSING'
export const INCIDENT_SEVERITY_LIST_SUCCESSED = 'INCIDENT_SEVERITY_LIST_SUCCESSED'
export const INCIDENT_SEVERITY_LIST_FAILED = 'INCIDENT_SEVERITY_LIST_FAILED'

export const ASSET_SYSTEMTYPES_LIST_REQUESTED = 'ASSET_SYSTEMTYPES_LIST_REQUESTED'
export const ASSET_SYSTEMTYPES_LIST_PROCESSING = 'ASSET_SYSTEMTYPES_LIST_PROCESSING'
export const ASSET_SYSTEMTYPES_LIST_SUCCESSED = 'ASSET_SYSTEMTYPES_LIST_SUCCESSED'
export const ASSET_SYSTEMTYPES_LIST_FAILED = 'ASSET_SYSTEMTYPES_LIST_FAILED'

export const ASSET_NETWORKTYPE_LIST_REQUESTED = 'ASSET_NETWORKTYPE_LIST_REQUESTED'
export const ASSET_NETWORKTYPE_LIST_PROCESSING = 'ASSET_NETWORKTYPE_LIST_PROCESSING'
export const ASSET_NETWORKTYPE_LIST_SUCCESSED = 'ASSET_NETWORKTYPE_LIST_SUCCESSED'
export const ASSET_NETWORKTYPE_LIST_FAILED = 'ASSET_NETWORKTYPE_LIST_FAILED'

export const ASSET_POWERSTATUS_LIST_REQUESTED = 'ASSET_POWERSTATUS_LIST_REQUESTED'
export const ASSET_POWERSTATUS_LIST_PROCESSING = 'ASSET_POWERSTATUS_LIST_PROCESSING'
export const ASSET_POWERSTATUS_LIST_SUCCESSED = 'ASSET_POWERSTATUS_LIST_SUCCESSED'
export const ASSET_POWERSTATUS_LIST_FAILED = 'ASSET_POWERSTATUS_LIST_FAILED'

export const ASSET_DOCUMENTTYPE_LIST_REQUESTED = 'ASSET_DOCUMENTTYPE_LIST_REQUESTED'
export const ASSET_DOCUMENTTYPE_LIST_PROCESSING = 'ASSET_DOCUMENTTYPE_LIST_PROCESSING'
export const ASSET_DOCUMENTTYPE_LIST_SUCCESSED = 'ASSET_DOCUMENTTYPE_LIST_SUCCESSED'
export const ASSET_DOCUMENTTYPE_LIST_FAILED = 'ASSET_DOCUMENTTYPE_LIST_FAILED'

export const ASSET_SIEMS_LIST_REQUESTED = 'ASSET_SIEMS_LIST_REQUESTED'
export const ASSET_SIEMS_LIST_PROCESSING = 'ASSET_SIEMS_LIST_PROCESSING'
export const ASSET_SIEMS_LIST_SUCCESSED = 'ASSET_SIEMS_LIST_SUCCESSED'
export const ASSET_SIEMS_LIST_FAILED = 'ASSET_SIEMS_LIST_FAILED'

export const ASSET_ZONE_LIST_REQUESTED = 'ASSET_ZONE_LIST_REQUESTED'
export const ASSET_ZONE_LIST_PROCESSING = 'ASSET_ZONE_LIST_PROCESSING'
export const ASSET_ZONE_LIST_SUCCESSED = 'ASSET_ZONE_LIST_SUCCESSED'
export const ASSET_ZONE_LIST_FAILED = 'ASSET_ZONE_LIST_FAILED'

export const ASSET_PRODUCT_LIST_REQUESTED = 'ASSET_PRODUCT_LIST_REQUESTED'
export const ASSET_PRODUCT_LIST_PROCESSING = 'ASSET_PRODUCT_LIST_PROCESSING'
export const ASSET_PRODUCT_LIST_SUCCESSED = 'ASSET_PRODUCT_LIST_SUCCESSED'
export const ASSET_PRODUCT_LIST_FAILED = 'ASSET_PRODUCT_LIST_FAILED'
export const ASSET_PRODUCT_LIST_RESET = 'ASSET_PRODUCT_LIST_RESET'

export const ASSET_VENDORS_LIST_REQUESTED = 'ASSET_VENDORS_LIST_REQUESTED'
export const ASSET_VENDORS_LIST_PROCESSING = 'ASSET_VENDORS_LIST_PROCESSING'
export const ASSET_VENDORS_LIST_SUCCESSED = 'ASSET_VENDORS_LIST_SUCCESSED'
export const ASSET_VENDORS_LIST_FAILED = 'ASSET_VENDORS_LIST_FAILED'
export const ASSET_VENDORS_LIST_RESET = 'ASSET_VENDORS_LIST_RESET'

export const ASSET_SERVER_LIST_REQUESTED = 'ASSET_SERVER_LIST_REQUESTED'
export const ASSET_SERVER_LIST_PROCESSING = 'ASSET_SERVER_LIST_PROCESSING'
export const ASSET_SERVER_LIST_SUCCESSED = 'ASSET_SERVER_LIST_SUCCESSED'
export const ASSET_SERVER_LIST_FAILED = 'ASSET_SERVER_LIST_FAILED'

export const ASSET_TEMPLATE_LIST_REQUESTED = 'ASSET_TEMPLATE_LIST_REQUESTED'
export const ASSET_TEMPLATE_LIST_PROCESSING = 'ASSET_TEMPLATE_LIST_PROCESSING'
export const ASSET_TEMPLATE_LIST_SUCCESSED = 'ASSET_TEMPLATE_LIST_SUCCESSED'
export const ASSET_TEMPLATE_LIST_FAILED = 'ASSET_TEMPLATE_LIST_FAILED'

export const ASSET_TEMPLATE_CREATE_REQUESTED = 'ASSET_TEMPLATE_CREATE_REQUESTED'
export const ASSET_TEMPLATE_CREATE_PROCESSING = 'ASSET_TEMPLATE_CREATE_PROCESSING'
export const ASSET_TEMPLATE_CREATE_SUCCESSED = 'ASSET_TEMPLATE_CREATE_SUCCESSED'
export const ASSET_TEMPLATE_CREATE_FAILED = 'ASSET_TEMPLATE_CREATE_FAILED'

export const ASSET_TEMPLATE_DRAFT_REQUESTED = 'ASSET_TEMPLATE_DRAFT_REQUESTED'
export const ASSET_TEMPLATE_DRAFT_PROCESSING = 'ASSET_TEMPLATE_DRAFT_PROCESSING'
export const ASSET_TEMPLATE_DRAFT_SUCCESSED = 'ASSET_TEMPLATE_DRAFT_SUCCESSED'
export const ASSET_TEMPLATE_DRAFT_FAILED = 'ASSET_TEMPLATE_DRAFT_FAILED'

export const ASSET_IMPORT_PROCESS_REQUESTED = 'ASSET_IMPORT_PROCESS_REQUESTED'
export const ASSET_IMPORT_PROCESS_PROCESSING = 'ASSET_IMPORT_PROCESS_PROCESSING'
export const ASSET_IMPORT_PROCESS_SUCCESSED = 'ASSET_IMPORT_PROCESS_SUCCESSED'
export const ASSET_IMPORT_PROCESS_FAILED = 'ASSET_IMPORT_PROCESS_FAILED'

export const INCIDENT_DISPOSITION_LIST_REQUESTED = 'INCIDENT_DISPOSITION_LIST_REQUESTED'
export const INCIDENT_DISPOSITION_LIST_PROCESSING = 'INCIDENT_DISPOSITION_LIST_PROCESSING'
export const INCIDENT_DISPOSITION_LIST_SUCCESSED = 'INCIDENT_DISPOSITION_LIST_SUCCESSED'
export const INCIDENT_DISPOSITION_LIST_FAILED = 'INCIDENT_DISPOSITION_LIST_FAILED'

export const USER_RESET_PROFILE_REQUESTED = 'USER_RESET_PROFILE_REQUESTED'

export const INCIDENT_SINGLE_EXPORT_PDF_LIST_REQUESTED = 'INCIDENT_SINGLE_EXPORT_PDF_LIST_REQUESTED '
export const INCIDENT_SINGLE_EXPORT_PDF_LIST_PROCESSING = 'INCIDENT_SINGLE_EXPORT_PDF_LIST_PROCESSING '
export const INCIDENT_SINGLE_EXPORT_PDF_LIST_SUCCESSED = 'INCIDENT_SINGLE_EXPORT_PDF_LIST_SUCCESSED '
export const INCIDENT_SINGLE_EXPORT_PDF_LIST_FAILED = 'INCIDENT_SINGLE_EXPORT_PDF_LIST_FAILED '

export const INCIDENT_EXPORT_PDF_LIST_REQUESTED = 'INCIDENT_EXPORT_PDF_LIST_REQUESTED '
export const INCIDENT_EXPORT_PDF_LIST_PROCESSING = 'INCIDENT_EXPORT_PDF_LIST_PROCESSING '
export const INCIDENT_EXPORT_PDF_LIST_SUCCESSED = 'INCIDENT_EXPORT_PDF_LIST_SUCCESSED '
export const INCIDENT_EXPORT_PDF_LIST_FAILED = 'INCIDENT_EXPORT_PDF_LIST_FAILED '

export const INCIDENT_EXPORT_EXCEL_LIST_REQUESTED = 'INCIDENT_EXPORT_EXCEL_LIST_REQUESTED '
export const INCIDENT_EXPORT_EXCEL_LIST_PROCESSING = 'INCIDENT_EXPORT_EXCEL_LIST_PROCESSING '
export const INCIDENT_EXPORT_EXCEL_LIST_SUCCESSED = 'INCIDENT_EXPORT_EXCEL_LIST_SUCCESSED '
export const INCIDENT_EXPORT_EXCEL_LIST_FAILED = 'INCIDENT_EXPORT_EXCEL_LIST_FAILED '

export const INCIDENT_DETAILS_REQUESTED = 'INCIDENT_DETAILS_REQUESTED '
export const INCIDENT_DETAILS_PROCESSING = 'INCIDENT_DETAILS_PROCESSING '
export const INCIDENT_DETAILS_SUCCESSED = 'INCIDENT_DETAILS_SUCCESSED '
export const INCIDENT_DETAILS_FAILED = 'INCIDENT_DETAILS_FAILED '
export const INCIDENT_DETAILS_UPDATE = 'INCIDENT_DETAILS_UPDATE'

export const INCIDENT_MANAGEMENT_CREATE_REQUESTED = 'INCIDENT_MANAGEMENT_CREATE_REQUESTED '
export const INCIDENT_MANAGEMENT_CREATE_PROCESSING = 'INCIDENT_MANAGEMENT_CREATE_PROCESSING '
export const INCIDENT_MANAGEMENT_CREATE_SUCCESSED = 'INCIDENT_MANAGEMENT_CREATE_SUCCESSED '
export const INCIDENT_MANAGEMENT_CREATE_FAILED = 'INCIDENT_MANAGEMENT_CREATE_FAILED '
export const INCIDENT_MANAGEMENT_CREATE_RESET = 'INCIDENT_MANAGEMENT_CREATE_RESET '
export const INCIDENT_MANAGEMENT_EDIT_REQUESTED = 'INCIDENT_MANAGEMENT_EDIT_REQUESTED '
export const INCIDENT_MANAGEMENT_EDIT_PROCESSING = 'INCIDENT_MANAGEMENT_EDIT_PROCESSING '
export const INCIDENT_MANAGEMENT_EDIT_SUCCESSED = 'INCIDENT_MANAGEMENT_EDIT_SUCCESSED '
export const INCIDENT_MANAGEMENT_EDIT_FAILED = 'INCIDENT_MANAGEMENT_EDIT_FAILED '

export const INCIDENT_SEND_EMAIL_REQUESTED = 'INCIDENT_SEND_EMAIL_REQUESTED '
export const INCIDENT_SEND_EMAIL_PROCESSING = 'INCIDENT_SEND_EMAIL_PROCESSING '
export const INCIDENT_SEND_EMAIL_SUCCESSED = 'INCIDENT_SEND_EMAIL_SUCCESSED '
export const INCIDENT_SEND_EMAIL_FAILED = 'INCIDENT_SEND_EMAIL_FAILED '

export const INCIDENT_DELETE_REQUESTED = 'INCIDENT_DELETE_REQUESTED'
export const INCIDENT_DELETE_PROCESSING = 'INCIDENT_DELETE_PROCESSING '
export const INCIDENT_DELETE_SUCCESSED = 'INCIDENT_DELETE_SUCCESSED'
export const INCIDENT_DELETE_FAILED = 'INCIDENT_DELETE_FAILED '

export const INCIDENT_MEMEBER_USER_REQUESTED = 'INCIDENT_MEMEBER_USER_REQUESTED'
export const INCIDENT_MEMEBER_USER_PROCESSING = 'INCIDENT_MEMEBER_USER_PROCESSING '
export const INCIDENT_MEMEBER_USER_SUCCESSED = 'INCIDENT_MEMEBER_USER_SUCCESSED'
export const INCIDENT_MEMEBER_USER_FAILED = 'INCIDENT_MEMEBER_USER_FAILED '

export const INCIDENT_MEMEBER_USER_GROUP_REQUESTED = 'INCIDENT_MEMEBER_USER_GROUP_REQUESTED'
export const INCIDENT_MEMEBER_USER_GROUP_PROCESSING = 'INCIDENT_MEMEBER_USER_GROUP_PROCESSING '
export const INCIDENT_MEMEBER_USER_GROUP_SUCCESSED = 'INCIDENT_MEMEBER_USER_GROUP_SUCCESSED'
export const INCIDENT_MEMEBER_USER_GROUP_FAILED = 'INCIDENT_MEMEBER_USER_GROUP_FAILED '

export const INCIDENT_POST_COMMENT_REQUESTED = 'INCIDENT_POST_COMMENT_REQUESTED '
export const INCIDENT_POST_COMMENT_PROCESSING = 'INCIDENT_POST_COMMENT_PROCESSING '
export const INCIDENT_POST_COMMENT_SUCCESSED = 'INCIDENT_POST_COMMENT_SUCCESSED '
export const INCIDENT_POST_COMMENT_FAILED = 'INCIDENT_POST_COMMENT_FAILED '

// threatIntelligence
export const THREAT_INTELLIGENCE_LIST_REQUESTED = 'THREAT_INTELLIGENCE_LIST_REQUESTED'
export const THREAT_INTELLIGENCE_LIST_PROCESSING = 'THREAT_INTELLIGENCE_LIST_PROCESSING'
export const THREAT_INTELLIGENCE_LIST_SUCCESSED = 'THREAT_INTELLIGENCE_LIST_SUCCESSED'
export const THREAT_INTELLIGENCE_LIST_FAILED = 'THREAT_INTELLIGENCE_LIST_FAILED'

export const THREAT_INTELLIGENCE_FEED_LIST_REQUESTED = 'THREAT_INTELLIGENCE_FEED_LIST_REQUESTED'
export const THREAT_INTELLIGENCE_FEED_LIST_PROCESSING = 'THREAT_INTELLIGENCE_FEED_LIST_PROCESSING'
export const THREAT_INTELLIGENCE_FEED_LIST_SUCCESSED = 'THREAT_INTELLIGENCE_FEED_LIST_SUCCESSED'
export const THREAT_INTELLIGENCE_FEED_LIST_FAILED = 'THREAT_INTELLIGENCE_FEED_LIST_FAILED'

export const THREAT_INTELLIGENCE_FEED_DELETE_REQUESTED = 'THREAT_INTELLIGENCE_FEED_DELETE_REQUESTED'

export const THREAT_INTELLIGENCE_SEND_ADVISORY_REQUESTED = 'THREAT_INTELLIGENCE_SEND_ADVISORY_REQUESTED'
export const THREAT_INTELLIGENCE_SEND_ADVISORY_PROCESSING = 'THREAT_INTELLIGENCE_SEND_ADVISORY_PROCESSING'
export const THREAT_INTELLIGENCE_SEND_ADVISORY_SUCCESSED = 'THREAT_INTELLIGENCE_SEND_ADVISORY_SUCCESSED'
export const THREAT_INTELLIGENCE_SEND_ADVISORY_FAILED = 'THREAT_INTELLIGENCE_SEND_ADVISORY_FAILED'

export const DELETE_THREAT_INTELLIGENCE_REQUESTED = 'DELETE_THREAT_INTELLIGENCE_REQUESTED'
export const DELETE_THREAT_INTELLIGENCE_PROCESSING = 'DELETE_THREAT_INTELLIGENCE_PROCESSING'
export const DELETE_THREAT_INTELLIGENCE_SUCCESSED = 'DELETE_THREAT_INTELLIGENCE_SUCCESSED'
export const DELETE_THREAT_INTELLIGENCE_FAILED = 'DELETE_THREAT_INTELLIGENCE_FAILED'

export const THREAT_INTELLIGENCE_CATEGORIES_REQUESTED = 'THREAT_INTELLIGENCE_CATEGORIES_REQUESTED '
export const THREAT_INTELLIGENCE_CATEGORIES_PROCESSING = 'THREAT_INTELLIGENCE_CATEGORIES_PROCESSING '
export const THREAT_INTELLIGENCE_CATEGORIES_SUCCESSED = 'THREAT_INTELLIGENCE_CATEGORIES_SUCCESSED '
export const THREAT_INTELLIGENCE_CATEGORIES_FAILED = 'THREAT_INTELLIGENCE_CATEGORIES_FAILED'

export const THREAT_INTELLIGENCE_SUB_CATEGORIES_REQUESTED = 'THREAT_INTELLIGENCE_SUB_CATEGORIES_REQUESTED '
export const THREAT_INTELLIGENCE_SUB_CATEGORIES_PROCESSING = 'THREAT_INTELLIGENCE_SUB_CATEGORIES_PROCESSING '
export const THREAT_INTELLIGENCE_SUB_CATEGORIES_SUCCESSED = 'THREAT_INTELLIGENCE_SUB_CATEGORIES_SUCCESSED '
export const THREAT_INTELLIGENCE_SUB_CATEGORIES_FAILED = 'THREAT_INTELLIGENCE_SUB_CATEGORIES_FAILED'

export const THREAT_INTELLIGENCE_CASE_CATEGORIES_REQUESTED = 'THREAT_INTELLIGENCE_CASE_CATEGORIES_REQUESTED '
export const THREAT_INTELLIGENCE_CASE_CATEGORIES_PROCESSING = 'THREAT_INTELLIGENCE_CASE_CATEGORIES_PROCESSING '
export const THREAT_INTELLIGENCE_CASE_CATEGORIES_SUCCESSED = 'THREAT_INTELLIGENCE_CASE_CATEGORIES_SUCCESSED '
export const THREAT_INTELLIGENCE_CASE_CATEGORIES_FAILED = 'THREAT_INTELLIGENCE_CASE_CATEGORIES_FAILED'

export const THREAT_INTELLIGENCE_SEVERITY_REQUESTED = 'THREAT_INTELLIGENCE_SEVERITY_REQUESTED '
export const THREAT_INTELLIGENCE_SEVERITY_PROCESSING = 'THREAT_INTELLIGENCE_SEVERITY_PROCESSING '
export const THREAT_INTELLIGENCE_SEVERITY_SUCCESSED = 'THREAT_INTELLIGENCE_SEVERITY_SUCCESSED '
export const THREAT_INTELLIGENCE_SEVERITY_FAILED = 'THREAT_INTELLIGENCE_SEVERITY_FAILED'

export const THREAT_INTELLIGENCE_ASSET_REQUESTED = 'THREAT_INTELLIGENCE_ASSET_REQUESTED '
export const THREAT_INTELLIGENCE_ASSET_PROCESSING = 'THREAT_INTELLIGENCE_ASSET_PROCESSING '
export const THREAT_INTELLIGENCE_ASSET_SUCCESSED = 'THREAT_INTELLIGENCE_ASSET_SUCCESSED '
export const THREAT_INTELLIGENCE_ASSET_FAILED = 'THREAT_INTELLIGENCE_ASSET_FAILED'

export const THREAT_INTELLIGENCE_DEPARTMENTS_REQUESTED = 'THREAT_INTELLIGENCE_DEPARTMENTS_REQUESTED '
export const THREAT_INTELLIGENCE_DEPARTMENTS_PROCESSING = 'THREAT_INTELLIGENCE_DEPARTMENTS_PROCESSING '
export const THREAT_INTELLIGENCE_DEPARTMENTS_SUCCESSED = 'THREAT_INTELLIGENCE_DEPARTMENTS_SUCCESSED '
export const THREAT_INTELLIGENCE_DEPARTMENTS_FAILED = 'THREAT_INTELLIGENCE_DEPARTMENTS_FAILED'

export const THREAT_INTELLIGENCE_EVIDENCE_REQUESTED = 'THREAT_INTELLIGENCE_EVIDENCE_REQUESTED '
export const THREAT_INTELLIGENCE_EVIDENCE_PROCESSING = 'THREAT_INTELLIGENCE_EVIDENCE_PROCESSING '
export const THREAT_INTELLIGENCE_EVIDENCE_SUCCESSED = 'THREAT_INTELLIGENCE_EVIDENCE_SUCCESSED'
export const THREAT_INTELLIGENCE_EVIDENCE_FAILED = 'THREAT_INTELLIGENCE_EVIDENCE_FAILED'

export const THREAT_INTELLIGENCE_DISPOSITION_REQUESTED = 'THREAT_INTELLIGENCE_DISPOSITION_REQUESTED '
export const THREAT_INTELLIGENCE_DISPOSITION_PROCESSING = 'THREAT_INTELLIGENCE_DISPOSITION_PROCESSING '
export const THREAT_INTELLIGENCE_DISPOSITION_SUCCESSED = 'THREAT_INTELLIGENCE_DISPOSITION_SUCCESSED '
export const THREAT_INTELLIGENCE_DISPOSITION_FAILED = 'THREAT_INTELLIGENCE_DISPOSITION_FAILED'

export const THREAT_INTELLIGENCE_ADVISORY_VENDORS_REQUESTED = 'THREAT_INTELLIGENCE_ADVISORY_VENDORS_REQUESTED'
export const THREAT_INTELLIGENCE_ADVISORY_VENDORS_PROCESSING = 'THREAT_INTELLIGENCE_ADVISORY_VENDORS_PROCESSING'
export const THREAT_INTELLIGENCE_ADVISORY_VENDORS_SUCCESSED = 'THREAT_INTELLIGENCE_ADVISORY_VENDORS_SUCCESSED'
export const THREAT_INTELLIGENCE_ADVISORY_VENDORS_FAILED = 'THREAT_INTELLIGENCE_ADVISORY_VENDORS_FAILED'
export const THREAT_INTELLIGENCE_ADVISORY_VENDORS_RESET = 'THREAT_INTELLIGENCE_ADVISORY_VENDORS_RESET'

export const THREAT_INTELLIGENCE_PRODUCTS_REQUESTED = 'THREAT_INTELLIGENCE_PRODUCTS_REQUESTED'
export const THREAT_INTELLIGENCE_PRODUCTS_PROCESSING = 'THREAT_INTELLIGENCE_PRODUCTS_PROCESSING'
export const THREAT_INTELLIGENCE_PRODUCTS_SUCCESSED = 'THREAT_INTELLIGENCE_PRODUCTS_SUCCESSED'
export const THREAT_INTELLIGENCE_PRODUCTS_FAILED = 'THREAT_INTELLIGENCE_PRODUCTS_FAILED'
export const THREAT_INTELLIGENCE_PRODUCTS_RESET = 'THREAT_INTELLIGENCE_PRODUCTS_RESET'

export const THREAT_INTELLIGENCE_DETAILS_LIST_REQUESTED = 'THREAT_INTELLIGENCE_DETAILS_LIST_REQUESTED'
export const THREAT_INTELLIGENCE_DETAILS_LIST_PROCESSING = 'THREAT_INTELLIGENCE_DETAILS_LIST_PROCESSING'
export const THREAT_INTELLIGENCE_DETAILS_LIST_SUCCESSED = 'THREAT_INTELLIGENCE_DETAILS_LIST_SUCCESSED'
export const THREAT_INTELLIGENCE_DETAILS_LIST_FAILED = 'THREAT_INTELLIGENCE_DETAILS_LIST_FAILED'

export const THREAT_INTELLIGENCE_DETAILS_UPDATE = 'THREAT_INTELLIGENCE_DETAILS_UPDATE'

export const THREAT_INTELLIGENCE_CREATE_ADVISORY_REQUESTED = 'THREAT_INTELLIGENCE_CREATE_ADVISORY_REQUESTED'
export const THREAT_INTELLIGENCE_CREATE_ADVISORY_PROCESSING = 'THREAT_INTELLIGENCE_CREATE_ADVISORY_PROCESSING'
export const THREAT_INTELLIGENCE_CREATE_ADVISORY_SUCCESSED = 'THREAT_INTELLIGENCE_CREATE_ADVISORY_SUCCESSED'
export const THREAT_INTELLIGENCE_CREATE_ADVISORY_FAILED = 'THREAT_INTELLIGENCE_CREATE_ADVISORY_FAILED'
export const THREAT_INTELLIGENCE_CREATE_ADVERSORY_DETAILS_RESET = 'THREAT_INTELLIGENCE_CREATE_ADVERSORY_DETAILS_RESET'

export const OPEN_CASES_REQUESTED = 'OPEN_CASES_REQUESTED'
export const OPEN_CASES_PROCESSING = 'OPEN_CASES_PROCESSING'
export const OPEN_CASES_SUCCESSED = 'OPEN_CASES_SUCCESSED'
export const OPEN_CASES_FAILED = 'OPEN_CASES_FAILED'
export const OPEN_CASES_RESET = 'OPEN_CASES_RESET'
export const ADVISORY_SOURCE_REQUESTED = 'ADVISORY_SOURCE_REQUESTED'
export const ADVISORY_SOURCE_PROCESSING = 'ADVISORY_SOURCE_PROCESSING'
export const ADVISORY_SOURCE_SUCCESSED = 'ADVISORY_SOURCE_SUCCESSED'
export const ADVISORY_SOURCE_FAILED = 'ADVISORY_SOURCE_FAILED'

export const ADVISORY_THREAT_ACTORS_REQUESTED = 'ADVISORY_THREAT_ACTORS_REQUESTED'
export const ADVISORY_THREAT_ACTORS_PROCESSING = 'ADVISORY_THREAT_ACTORS_PROCESSING'
export const ADVISORY_THREAT_ACTORS_SUCCESSED = 'ADVISORY_THREAT_ACTORS_SUCCESSED'
export const ADVISORY_THREAT_ACTORS_FAILED = 'ADVISORY_THREAT_ACTORS_FAILED'

export const CASES_ADVISORY_LOCATIONS_REQUESTED = 'CASES_ADVISORY_LOCATIONS_REQUESTED'
export const CASES_ADVISORY_LOCATIONS_PROCESSING = 'CASES_ADVISORY_LOCATIONS_PROCESSING'
export const CASES_ADVISORY_LOCATIONS_SUCCESSED = 'CASES_ADVISORY_LOCATIONS_SUCCESSED'
export const CASES_ADVISORY_LOCATIONS_FAILED = 'CASES_ADVISORY_LOCATIONS_FAILED'

export const CASES_ADVISORY_ITEMS_REQUESTED = 'CASES_ADVISORY_ITEMS_REQUESTED'
export const CASES_ADVISORY_ITEMS_PROCESSING = 'CASES_ADVISORY_ITEMS_PROCESSING'
export const CASES_ADVISORY_ITEMS_SUCCESSED = 'CASES_ADVISORY_ITEMS_SUCCESSED'
export const CASES_ADVISORY_ITEMS_FAILED = 'CASES_ADVISORY_ITEMS_FAILED'

export const CASE_SUB_CATEGORIES_REQUESTED = 'CASE_SUB_CATEGORIES_REQUESTED'
export const CASE_SUB_CATEGORIES_PROCESSING = 'CASE_SUB_CATEGORIES_PROCESSING'
export const CASE_SUB_CATEGORIES_SUCCESSED = 'CASE_SUB_CATEGORIES_SUCCESSED'
export const CASE_SUB_CATEGORIES_FAILED = 'CASE_SUB_CATEGORIES_FAILED'

export const CASES_SUBDISPOSITIONS_REQUESTED = 'CASES_SUBDISPOSITIONS_REQUESTED'
export const CASES_SUBDISPOSITIONS_PROCESSING = 'CASES_SUBDISPOSITIONS_PROCESSING'
export const CASES_SUBDISPOSITIONS_SUCCESSED = 'CASES_SUBDISPOSITIONS_SUCCESSED'
export const CASES_SUBDISPOSITIONS_FAILED = 'CASES_SUBDISPOSITIONS_FAILED'

export const CASES_ADVISORY_USERS_REQUESTED = 'CASES_ADVISORY_USERS_REQUESTED'
export const CASES_ADVISORY_USERS_PROCESSING = 'CASES_ADVISORY_USERS_PROCESSING'
export const CASES_ADVISORY_USERS_SUCCESSED = 'CASES_ADVISORY_USERS_SUCCESSED'
export const CASES_ADVISORY_USERS_FAILED = 'CASES_ADVISORY_USERS_FAILED'

export const THREAT_INTELLIGENCE_EXPORT_PDF_REQUESTED = 'THREAT_INTELLIGENCE_EXPORT_PDF_REQUESTED'
export const THREAT_INTELLIGENCE_EXPORT_PDF_PROCESSING = 'THREAT_INTELLIGENCE_EXPORT_PDF_PROCESSING'
export const THREAT_INTELLIGENCE_EXPORT_PDF_SUCCESSED = 'THREAT_INTELLIGENCE_EXPORT_PDF_SUCCESSED'
export const THREAT_INTELLIGENCE_EXPORT_PDF_FAILED = 'THREAT_INTELLIGENCE_EXPORT_PDF_FAILED'

export const INCIDENT_TASK_CATEGORIES_REQUESTED = 'INCIDENT_TASK_CATEGORIES_REQUESTED '
export const INCIDENT_TASK_CATEGORIES_PROCESSING = 'INCIDENT_TASK_CATEGORIES_PROCESSING '
export const INCIDENT_TASK_CATEGORIES_SUCCESSED = 'INCIDENT_TASK_CATEGORIES_SUCCESSED '
export const INCIDENT_TASK_CATEGORIES_FAILED = 'INCIDENT_TASK_CATEGORIES_FAILED '

export const INCIDENT_TASK_USERS_REQUESTED = 'INCIDENT_TASK_USERS_REQUESTED '
export const INCIDENT_TASK_USERS_PROCESSING = 'INCIDENT_TASK_USERS_PROCESSING '
export const INCIDENT_TASK_USERS_SUCCESSED = 'INCIDENT_TASK_USERS_SUCCESSED '
export const INCIDENT_TASK_USERS_FAILED = 'INCIDENT_TASK_USERS_FAILED '

export const INCIDENT_TASK_DEPARTMENTS_REQUESTED = 'INCIDENT_TASK_DEPARTMENTS_REQUESTED '
export const INCIDENT_TASK_DEPARTMENTS_PROCESSING = 'INCIDENT_TASK_DEPARTMENTS_PROCESSING '
export const INCIDENT_TASK_DEPARTMENTS_SUCCESSED = 'INCIDENT_TASK_DEPARTMENTS_SUCCESSED '
export const INCIDENT_TASK_DEPARTMENTS_FAILED = 'INCIDENT_TASK_DEPARTMENTS_FAILED '

export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED '
export const GET_USERS_PROCESSING = 'GET_USERS_PROCESSING '
export const GET_USERS_SUCCESSED = 'GET_USERS_SUCCESSED '
export const GET_USERS_FAILED = 'GET_USERS_FAILED '

export const INCIDENT_TASK_CREATED_REQUESTED = 'INCIDENT_TASK_CREATED_REQUESTED '
export const INCIDENT_TASK_CREATED_PROCESSING = 'INCIDENT_TASK_CREATED_PROCESSING '
export const INCIDENT_TASK_CREATED_SUCCESSED = 'INCIDENT_TASK_CREATED_SUCCESSED '
export const INCIDENT_TASK_CREATED_FAILED = 'INCIDENT_TASK_CREATED_FAILED '

export const INCIDENT_TASK_DELETED_REQUESTED = 'INCIDENT_TASK_DELETED_REQUESTED '
export const INCIDENT_TASK_DELETED_PROCESSING = 'INCIDENT_TASK_DELETED_PROCESSING '
export const INCIDENT_TASK_DELETED_SUCCESSED = 'INCIDENT_TASK_DELETED_SUCCESSED '
export const INCIDENT_TASK_DELETED_FAILED = 'INCIDENT_TASK_DELETED_FAILED '


export const UPDATE_ADVERSORY_DETAILS_REQUESTED = 'UPDATE_ADVERSORY_DETAILS_REQUESTED '
export const UPDATE_ADVERSORY_DETAILS_PROCESSING = 'UPDATE_ADVERSORY_DETAILS_PROCESSING '
export const UPDATE_ADVERSORY_DETAILS_SUCCESSED = 'UPDATE_ADVERSORY_DETAILS_SUCCESSED '
export const UPDATE_ADVERSORY_DETAILS_FAILED = 'UPDATE_ADVERSORY_DETAILS_FAILED '
export const UPDATE_ADVERSORY_DETAILS_RESET = 'UPDATE_ADVERSORY_DETAILS_RESET'

export const GET_PRODUCT_LIST_REQUESTED = 'GET_PRODUCT_LIST_REQUESTED '
export const GET_PRODUCT_LIST_PROCESSING = 'GET_PRODUCT_LIST_PROCESSING '
export const GET_PRODUCT_LIST_SUCCESSED = 'GET_PRODUCT_LIST_SUCCESSED '
export const GET_PRODUCT_LIST_FAILED = 'GET_PRODUCT_LIST_FAILED'

export const INCIDENT_SEVERITY_REQUESTED = 'INCIDENT_SEVERITY_REQUESTED '
export const INCIDENT_SEVERITY_PROCESSING = 'INCIDENT_SEVERITY_PROCESSING '
export const INCIDENT_SEVERITY_SUCCESSED = 'INCIDENT_SEVERITY_SUCCESSED '
export const INCIDENT_SEVERITY_FAILED = 'INCIDENT_SEVERITY_FAILED'

export const UPDATE_PRODUCT_REQUESTED = 'UPDATE_PRODUCT_REQUESTED '
export const UPDATE_PRODUCT_PROCESSING = 'UPDATE_PRODUCT_PROCESSING '
export const UPDATE_PRODUCT_SUCCESSED = 'UPDATE_PRODUCT_SUCCESSED '
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED '

export const UPDATE_PARTIAL_THREAT_INTELLIGENCE_REQUESTED = 'UPDATE_PARTIAL_THREAT_INTELLIGENCE_REQUESTED '
export const UPDATE_PARTIAL_THREAT_INTELLIGENCE_PROCESSING = 'UPDATE_PARTIAL_THREAT_INTELLIGENCE_PROCESSING '
export const UPDATE_PARTIAL_THREAT_INTELLIGENCE_SUCCESSED = 'UPDATE_PARTIAL_THREAT_INTELLIGENCE_SUCCESSED '
export const UPDATE_PARTIAL_THREAT_INTELLIGENCE_FAILED = 'UPDATE_PARTIAL_THREAT_INTELLIGENCE_FAILED '
export const UPDATE_PARTIAL_THREAT_INTELLIGENCE_RESET = 'UPDATE_PARTIAL_THREAT_INTELLIGENCE_RESET '

export const INCIDENT_CATEGORY_REQUESTED = 'INCIDENT_CATEGORY_REQUESTED'
export const INCIDENT_CATEGORY_PROCESSING = 'INCIDENT_CATEGORY_PROCESSING'
export const INCIDENT_CATEGORY_SUCCESSED = 'INCIDENT_CATEGORY_SUCCESSED'
export const INCIDENT_CATEGORY_FAILED = 'INCIDENT_CATEGORY_FAILED'

export const INCIDENT_SUBCATEGORY_REQUESTED = 'INCIDENT_SUBCATEGORY_REQUESTED'
export const INCIDENT_SUBCATEGORY_PROCESSING = 'INCIDENT_SUBCATEGORY_PROCESSING'
export const INCIDENT_SUBCATEGORY_SUCCESSED = 'INCIDENT_SUBCATEGORY_SUCCESSED'
export const INCIDENT_SUBCATEGORY_FAILED = 'INCIDENT_SUBCATEGORY_FAILED'
export const INCIDENT_SUBCATEGORY_RESET = 'INCIDENT_SUBCATEGORY_RESET'

export const INCIDENT_DISPOSITION_REQUESTED = 'INCIDENT_DISPOSITION_REQUESTED'
export const INCIDENT_DISPOSITION_PROCESSING = 'INCIDENT_DISPOSITION_PROCESSING'
export const INCIDENT_DISPOSITION_SUCCESSED = 'INCIDENT_DISPOSITION_SUCCESSED'
export const INCIDENT_DISPOSITION_FAILED = 'INCIDENT_DISPOSITION_FAILED'
export const INCIDENT_SUBDISPOSITION_RESET = 'INCIDENT_SUBDISPOSITION_RESET'
export const INCIDENT_SUBDISPOSITION_REQUESTED = 'INCIDENT_SUBDISPOSITION_REQUESTED'
export const INCIDENT_SUBDISPOSITION_PROCESSING = 'INCIDENT_SUBDISPOSITION_PROCESSING'
export const INCIDENT_SUBDISPOSITION_SUCCESSED = 'INCIDENT_SUBDISPOSITION_SUCCESSED'
export const INCIDENT_SUBDISPOSITION_FAILED = 'INCIDENT_SUBDISPOSITION_FAILED'

export const INCIDENT_LOCATION_REQUESTED = 'INCIDENT_LOCATION_REQUESTED'
export const INCIDENT_LOCATION_PROCESSING = 'INCIDENT_LOCATION_PROCESSING'
export const INCIDENT_LOCATION_SUCCESSED = 'INCIDENT_LOCATION_SUCCESSED'
export const INCIDENT_LOCATION_FAILED = 'INCIDENT_LOCATION_FAILED'

export const INCIDENT_DETECTION_METHODS_REQUESTED = 'INCIDENT_DETECTION_METHODS_REQUESTED'
export const INCIDENT_DETECTION_METHODS_PROCESSING = 'INCIDENT_DETECTION_METHODS_PROCESSING'
export const INCIDENT_DETECTION_METHODS_SUCCESSED = 'INCIDENT_DETECTION_METHODS_SUCCESSED'
export const INCIDENT_DETECTION_METHODS_FAILED = 'INCIDENT_DETECTION_METHODS_FAILED'

export const INCIDENT_ARTIFACTS_REQUESTED = 'INCIDENT_ARTIFACTS_REQUESTED'
export const INCIDENT_ARTIFACTS_PROCESSING = 'INCIDENT_ARTIFACTS_PROCESSING'
export const INCIDENT_ARTIFACTS_SUCCESSED = 'INCIDENT_ARTIFACTS_SUCCESSED'
export const INCIDENT_ARTIFACTS_FAILED = 'INCIDENT_ARTIFACTS_FAILED'

export const GET_RUN_PLAY_BOOK_DATA_REQUESTED = 'GET_RUN_PLAY_BOOK_DATA_REQUESTED '
export const GET_RUN_PLAY_BOOK_DATA_PROCESSING = 'GET_RUN_PLAY_BOOK_DATA_PROCESSING '
export const GET_RUN_PLAY_BOOK_DATA_SUCCESSED = 'GET_RUN_PLAY_BOOK_DATA_SUCCESSED '
export const GET_RUN_PLAY_BOOK_DATA_FAILED = 'GET_RUN_PLAY_BOOK_DATA_FAILED '

export const EXECUTE_PLAY_BOOK_REQUESTED = 'EXECUTE_PLAY_BOOK_REQUESTED '
export const EXECUTE_PLAY_BOOK_PROCESSING = 'EXECUTE_PLAY_BOOK_PROCESSING '
export const EXECUTE_PLAY_BOOK_SUCCESSED = 'EXECUTE_PLAY_BOOK_SUCCESSED '
export const EXECUTE_PLAY_BOOK_FAILED = 'EXECUTE_PLAY_BOOK_FAILED '

export const ADVISORY_PUBLISH_REQUESTED = 'ADVISORY_PUBLISH_REQUESTED '
export const ADVISORY_PUBLISH_PROCESSING = 'ADVISORY_PUBLISH_PROCESSING '
export const ADVISORY_PUBLISH_SUCCESSED = 'ADVISORY_PUBLISH_SUCCESSED '
export const ADVISORY_PUBLISH_FAILED = 'ADVISORY_PUBLISH_FAILED '

export const GET_USER_EMAILS_LIST_REQUESTED = 'GET_USER_EMAILS_LIST_REQUESTED '
export const GET_USER_EMAILS_LIST_PROCESSING = 'GET_USER_EMAILS_LIST_PROCESSING '
export const GET_USER_EMAILS_LIST_SUCCESSED = 'GET_USER_EMAILS_LIST_SUCCESSED '
export const GET_USER_EMAILS_LIST_FAILED = 'GET_USER_EMAILS_LIST_FAILED '

export const SEND_EMAIL_REQUESTED = 'SEND_EMAIL_REQUESTED '
export const SEND_EMAIL_PROCESSING = 'SEND_EMAIL_PROCESSING '
export const SEND_EMAIL_SUCCESSED = 'SEND_EMAIL_SUCCESSED '
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED '
export const INCIDENT_TASK_LIST_REQUESTED = 'INCIDENT_TASK_LIST_REQUESTED '
export const INCIDENT_TASK_LIST_PROCESSING = 'INCIDENT_TASK_LIST_PROCESSING '
export const INCIDENT_TASK_LIST_SUCCESSED = 'INCIDENT_TASK_LIST_SUCCESSED '
export const INCIDENT_TASK_LIST_FAILED = 'INCIDENT_TASK_LIST_FAILED '

export const INCIDENT_TASK_EDIT_REQUESTED = 'INCIDENT_TASK_EDIT_REQUESTED '
export const INCIDENT_TASK_EDIT_PROCESSING = 'INCIDENT_TASK_EDIT_PROCESSING '
export const INCIDENT_TASK_EDIT_SUCCESSED = 'INCIDENT_TASK_EDIT_SUCCESSED '
export const INCIDENT_TASK_EDIT_FAILED = 'INCIDENT_TASK_EDIT_FAILED '

export const INCIDENT_TASK_CLEAR_REQUESTED = 'INCIDENT_TASK_CLEAR_REQUESTED'

export const GET_PRODUCT_LIST_REQUESTED1 = 'GET_PRODUCT_LIST_REQUESTED1 '
export const GET_PRODUCT_LIST_PROCESSING1 = 'GET_PRODUCT_LIST_PROCESSING1 '
export const GET_PRODUCT_LIST_SUCCESSED1 = 'GET_PRODUCT_LIST_SUCCESSED1 '
export const GET_PRODUCT_LIST_FAILED1 = 'GET_PRODUCT_LIST_FAILED1'

export const INCIDENT_ASSET_DELETE_REQUESTED = 'INCIDENT_ASSET_DELETE_REQUESTED '
export const INCIDENT_ASSET_DELETE_PROCESSING = 'INCIDENT_ASSET_DELETE_PROCESSING '
export const INCIDENT_ASSET_DELETE_SUCCESSED = 'INCIDENT_ASSET_DELETE_SUCCESSED '
export const INCIDENT_ASSET_DELETE_FAILED = 'INCIDENT_ASSET_DELETE_FAILED '

export const INCIDENT_ACTION_APPS_REQUESTED = 'INCIDENT_ACTION_APPS_REQUESTED'
export const INCIDENT_ACTION_APPS_PROCESSING = 'INCIDENT_ACTION_APPS_PROCESSING'
export const INCIDENT_ACTION_APPS_SUCCESSED = 'INCIDENT_ACTION_APPS_SUCCESSED'
export const INCIDENT_ACTION_APPS_FAILED = 'INCIDENT_ACTION_APPS_FAILED'

export const INCIDENT_ADD_MEMBER_REQUESTED = 'INCIDENT_ADD_MEMBER_REQUESTED'
export const INCIDENT_ADD_MEMBER_PROCESSING = 'INCIDENT_ADD_MEMBER_PROCESSING'
export const INCIDENT_ADD_MEMBER_SUCCESSED = 'INCIDENT_ADD_MEMBER_SUCCESSED'
export const INCIDENT_ADD_MEMBER_FAILED = 'INCIDENT_ADD_MEMBER_FAILED'

export const UPDATE_ARTIFACT_REQUESTED = 'UPDATE_ARTIFACT_REQUESTED'
export const UPDATE_ARTIFACT_PROCESSING = 'UPDATE_ARTIFACT_PROCESSING'
export const UPDATE_ARTIFACT_SUCCESSED = 'UPDATE_ARTIFACT_SUCCESSED'
export const UPDATE_ARTIFACT_FAILED = 'UPDATE_ARTIFACT_FAILED'
export const UPDATE_DETAILS_ARTIFACT = 'UPDATE_DETAILS_ARTIFACT'

export const EXECUTE_ACTION_REQUESTED = 'EXECUTE_ACTION_REQUESTED'
export const EXECUTE_ACTION_PROCESSING = 'EXECUTE_ACTION_PROCESSING'
export const EXECUTE_ACTION_SUCCESSED = 'EXECUTE_ACTION_SUCCESSED'
export const EXECUTE_ACTION_FAILED = 'EXECUTE_ACTION_FAILED'

export const MULTI_CONFIG_EXECUTION_REQUESTED = 'MULTI_CONFIG_EXECUTION_REQUESTED'
export const MULTI_CONFIG_EXECUTION_PROCESSING = 'MULTI_CONFIG_EXECUTION_PROCESSING'
export const MULTI_CONFIG_EXECUTION_SUCCESSED = 'MULTI_CONFIG_EXECUTION_SUCCESSED'
export const MULTI_CONFIG_EXECUTION_FAILED = 'MULTI_CONFIG_EXECUTION_FAILED'

export const ASSET_RULE_VIEW_CLEAR_REQUESTED = 'ASSET_RULE_VIEW_CLEAR_REQUESTED'
export const INCIDENT_ACTION_RUN_REQUESTED = 'INCIDENT_ACTION_RUN_REQUESTED'
export const INCIDENT_ACTION_RUN_PROCESSING = 'INCIDENT_ACTION_RUN_PROCESSING'
export const INCIDENT_ACTION_RUN_SUCCESSED = 'INCIDENT_ACTION_RUN_SUCCESSED'
export const INCIDENT_ACTION_RUN_FAILED = 'INCIDENT_ACTION_RUN_FAILED'
export const INCIDENT_ACTION_CLEAR_REQUESTED = 'INCIDENT_ACTION_CLEAR_REQUESTED'

export const INCIDENT_ARTIFACT_ADD_REQUESTED = 'INCIDENT_ARTIFACT_ADD_REQUESTED'
export const INCIDENT_ARTIFACT_ADD_PROCESSING = 'INCIDENT_ARTIFACT_ADD_PROCESSING'
export const INCIDENT_ARTIFACT_ADD_SUCCESSED = 'INCIDENT_ARTIFACT_ADD_SUCCESSED'
export const INCIDENT_ARTIFACT_ADD_FAILED = 'INCIDENT_ARTIFACT_ADD_FAILED'

export const ADVISORY_ADD_EVIDENCE_REQUESTED = 'ADVISORY_ADD_EVIDENCE_REQUESTED'
export const ADVISORY_ADD_EVIDENCE_PROCESSING = 'ADVISORY_ADD_EVIDENCE_PROCESSING'
export const ADVISORY_ADD_EVIDENCE_SUCCESSED = 'ADVISORY_ADD_EVIDENCE_SUCCESSED'
export const ADVISORY_ADD_EVIDENCE_FAILED = 'ADVISORY_ADD_EVIDENCE_FAILED'

export const ADVISORY_ARTIFACT_RAW_DATA_REQUESTED = 'ADVISORY_ARTIFACT_RAW_DATA_REQUESTED'
export const ADVISORY_ARTIFACT_RAW_DATA_PROCESSING = 'ADVISORY_ARTIFACT_RAW_DATA_PROCESSING'
export const ADVISORY_ARTIFACT_RAW_DATA_SUCCESSED = 'ADVISORY_ARTIFACT_RAW_DATA_SUCCESSED'
export const ADVISORY_ARTIFACT_RAW_DATA_FAILED = 'ADVISORY_ARTIFACT_RAW_DATA_FAILED'
export const ADVISORY_ARTIFACT_RAW_DATA_CLEAR = 'ADVISORY_ARTIFACT_RAW_DATA_CLEAR'

export const ADVISORY_DETAILS_PARTIAL_UPDATE_SUCCESSED = 'ADVISORY_DETAILS_PARTIAL_UPDATE_SUCCESSED'

export const GET_APPS_DETAILS_REQUESTED = 'GET_APPS_DETAILS_REQUESTED'
export const GET_APPS_DETAILS_PROCESSING = 'GET_APPS_DETAILS_PROCESSING'
export const GET_APPS_DETAILS_SUCCESSED = 'GET_APPS_DETAILS_SUCCESSED'
export const GET_APPS_DETAILS_FAILED = 'GET_APPS_DETAILS_FAILED'

export const GET_APPS_ACTION_DETAILS_REQUESTED = 'GET_APPS_ACTION_DETAILS_REQUESTED'
export const GET_APPS_ACTION_DETAILS_PROCESSING = 'GET_APPS_ACTION_DETAILS_PROCESSING'
export const GET_APPS_ACTION_DETAILS_SUCCESSED = 'GET_APPS_ACTION_DETAILS_SUCCESSED'
export const GET_APPS_ACTION_DETAILS_FAILED = 'GET_APPS_ACTION_DETAILS_FAILED'

export const GET_APPS_ACTION_WORKFLOW_REQUESTED = 'GET_APPS_ACTION_WORKFLOW_REQUESTED'
export const GET_APPS_ACTION_WORKFLOW_PROCESSING = 'GET_APPS_ACTION_WORKFLOW_PROCESSING'
export const GET_APPS_ACTION_WORKFLOW_SUCCESSED = 'GET_APPS_ACTION_WORKFLOW_SUCCESSED'
export const GET_APPS_ACTION_WORKFLOW_FAILED = 'GET_APPS_ACTION_WORKFLOW_FAILED'

export const UPDATE_APPS_ACTION_WORKFLOW_REQUESTED = 'UPDATE_APPS_ACTION_WORKFLOW_REQUESTED'
export const UPDATE_APPS_ACTION_WORKFLOW_PROCESSING = 'UPDATE_APPS_ACTION_WORKFLOW_PROCESSING'
export const UPDATE_APPS_ACTION_WORKFLOW_SUCCESSED = 'UPDATE_APPS_ACTION_WORKFLOW_SUCCESSED'
export const UPDATE_APPS_ACTION_WORKFLOW_FAILED = 'UPDATE_APPS_ACTION_WORKFLOW_FAILED'

export const UPDATE_APPS_ACTION_VALIDITY_REQUESTED = 'UPDATE_APPS_ACTION_VALIDITY_REQUESTED'
export const UPDATE_APPS_ACTION_VALIDITY_PROCESSING = 'UPDATE_APPS_ACTION_VALIDITY_PROCESSING'
export const UPDATE_APPS_ACTION_VALIDITY_SUCCESSED = 'UPDATE_APPS_ACTION_VALIDITY_SUCCESSED'
export const UPDATE_APPS_ACTION_VALIDITY_FAILED = 'UPDATE_APPS_ACTION_VALIDITY_FAILED'

export const UPDATE_APPS_ACTION_WORKFLOW_UPGRADE_LIST = 'UPDATE_APPS_ACTION_WORKFLOW_UPGRADE_LIST'
export const UPDATE_APPS_ACTION_UPGRADE_LIST = 'UPDATE_APPS_ACTION_UPGRADE_LIST'

export const UPDATE_APPLICATION_DETAILS_REQUESTED = 'UPDATE_APPLICATION_DETAILS_REQUESTED'
export const UPDATE_APPLICATION_DETAILS_PROCESSING = 'UPDATE_APPLICATION_DETAILS_PROCESSING'
export const UPDATE_APPLICATION_DETAILS_SUCCESSED = 'UPDATE_APPLICATION_DETAILS_SUCCESSED'
export const UPDATE_APPLICATION_DETAILS_FAILED = 'UPDATE_APPLICATION_DETAILS_FAILED'

export const INCIDENT_ARTIFACTS_LIST_REQUESTED = 'INCIDENT_ARTIFACTS_LIST_REQUESTED'
export const INCIDENT_ARTIFACTS_LIST_PROCESSING = 'INCIDENT_ARTIFACTS_LIST_PROCESSING'
export const INCIDENT_ARTIFACTS_LIST_SUCCESSED = 'INCIDENT_ARTIFACTS_LIST_SUCCESSED'
export const INCIDENT_ARTIFACTS_LIST_FAILED = 'INCIDENT_ARTIFACTS_LIST_FAILED'
export const CASE_MANAGEMENT_LIST_REQUESTED = 'CASE_MANAGEMENT_LIST_REQUESTED'
export const CASE_MANAGEMENT_LIST_PROCESSING = 'CASE_MANAGEMENT_LIST_PROCESSING'
export const CASE_MANAGEMENT_LIST_SUCCESSED = 'CASE_MANAGEMENT_LIST_SUCCESSED'
export const CASE_MANAGEMENT_LIST_FAILED = 'CASE_MANAGEMENT_LIST_REQUESTED'

export const CASE_SOURCES_LIST_REQUESTED = 'CASE_SOURCES_LIST_REQUESTED'
export const CASE_SOURCES_LIST_PROCESSING = 'CASE_SOURCES_LIST_PROCESSING'
export const CASE_SOURCES_LIST_SUCCESSED = 'CASE_SOURCES_LIST_SUCCESSED'
export const CASE_SOURCES_LIST_FAILED = 'CASE_SOURCES_LIST_FAILED'

export const CASE_CUSTOMERS_LIST_REQUESTED = 'CASE_CUSTOMERS_LIST_REQUESTED'
export const CASE_CUSTOMERS_LIST_PROCESSING = 'CASE_CUSTOMERS_LIST_PROCESSING'
export const CASE_CUSTOMERS_LIST_SUCCESSED = 'CASE_CUSTOMERS_LIST_SUCCESSED'
export const CASE_CUSTOMERS_LIST_FAILED = 'CASE_CUSTOMERS_LIST_FAILED'

export const CASE_SEVERITY_LIST_REQUESTED = 'CASE_SEVERITY_LIST_REQUESTED'
export const CASE_SEVERITY_LIST_PROCESSING = 'CASE_SEVERITY_LIST_PROCESSING'
export const CASE_SEVERITY_LIST_SUCCESSED = 'CASE_SEVERITY_LIST_SUCCESSED'
export const CASE_SEVERITY_LIST_FAILED = 'CASE_SEVERITY_LIST_FAILED'

export const CASE_DISPOSITION_LIST_REQUESTED = 'CASE_DISPOSITION_LIST_REQUESTED'
export const CASE_DISPOSITION_LIST_PROCESSING = 'CASE_DISPOSITION_LIST_PROCESSING'
export const CASE_DISPOSITION_LIST_SUCCESSED = 'CASE_DISPOSITION_LIST_SUCCESSED'
export const CASE_DISPOSITION_LIST_FAILED = 'CASE_DISPOSITION_LIST_FAILED'

export const CASE_SINGLE_EXPORT_PDF_LIST_REQUESTED = 'CASE_SINGLE_EXPORT_PDF_LIST_REQUESTED'
export const CASE_SINGLE_EXPORT_PDF_LIST_PROCESSING = 'CASE_SINGLE_EXPORT_PDF_LIST_PROCESSING'
export const CASE_SINGLE_EXPORT_PDF_LIST_SUCCESSED = 'CASE_SINGLE_EXPORT_PDF_LIST_SUCCESSED'
export const CASE_SINGLE_EXPORT_PDF_LIST_FAILED = 'CASE_SINGLE_EXPORT_PDF_LIST_FAILED'

export const CASE_DETAILS_REQUESTED = 'CASE_DETAILS_REQUESTED'
export const CASE_DETAILS_PROCESSING = 'CASE_DETAILS_PROCESSING'
export const CASE_DETAILS_SUCCESSED = 'CASE_DETAILS_SUCCESSED'
export const CASE_DETAILS_FAILED = 'CASE_DETAILS_FAILED'

export const CASE_DETAILS_UPDATE = 'CASE_DETAILS_UPDATE'

export const CASE_MANAGEMENT_CREATE_REQUESTED = 'CASE_MANAGEMENT_CREATE_REQUESTED'
export const CASE_MANAGEMENT_CREATE_PROCESSING = 'CASE_MANAGEMENT_CREATE_PROCESSING'
export const CASE_MANAGEMENT_CREATE_SUCCESSED = 'CASE_MANAGEMENT_CREATE_SUCCESSED'
export const CASE_MANAGEMENT_CREATE_FAILED = 'CASE_MANAGEMENT_CREATE_FAILED'

export const CASE_MANAGEMENT_EDIT_REQUESTED = 'CASE_MANAGEMENT_EDIT_REQUESTED'
export const CASE_MANAGEMENT_EDIT_PROCESSING = 'CASE_MANAGEMENT_EDIT_PROCESSING'
export const CASE_MANAGEMENT_EDIT_SUCCESSED = 'CASE_MANAGEMENT_EDIT_SUCCESSED'
export const CASE_MANAGEMENT_EDIT_FAILED = 'CASE_MANAGEMENT_EDIT_FAILED'
export const CASE_MANAGEMENT_RESET_REQUESTED = 'CASE_MANAGEMENT_RESET_REQUESTED'

export const CASE_EXPORT_PDF_LIST_REQUESTED = 'CASE_EXPORT_PDF_LIST_REQUESTED'
export const CASE_EXPORT_PDF_LIST_PROCESSING = 'CASE_EXPORT_PDF_LIST_PROCESSING'
export const CASE_EXPORT_PDF_LIST_SUCCESSED = 'CASE_EXPORT_PDF_LIST_SUCCESSED'
export const CASE_EXPORT_PDF_LIST_FAILED = 'CASE_EXPORT_PDF_LIST_FAILED'

export const CASE_EXPORT_EXCEL_LIST_REQUESTED = 'CASE_EXPORT_EXCEL_LIST_REQUESTED'
export const CASE_EXPORT_EXCEL_LIST_PROCESSING = 'CASE_EXPORT_EXCEL_LIST_PROCESSING'
export const CASE_EXPORT_EXCEL_LIST_SUCCESSED = 'CASE_EXPORT_EXCEL_LIST_SUCCESSED'
export const CASE_EXPORT_EXCEL_LIST_FAILED = 'CASE_EXPORT_EXCEL_LIST_FAILED'

export const CASE_SEND_EMAIL_REQUESTED = 'CASE_SEND_EMAIL_REQUESTED'
export const CASE_SEND_EMAIL_PROCESSING = 'CASE_SEND_EMAIL_PROCESSING'
export const CASE_SEND_EMAIL_SUCCESSED = 'CASE_SEND_EMAIL_SUCCESSED'
export const CASE_SEND_EMAIL_FAILED = 'CASE_SEND_EMAIL_FAILED'

export const CASE_POST_COMMENT_REQUESTED = 'CASE_POST_COMMENT_REQUESTED'
export const CASE_POST_COMMENT_PROCESSING = 'CASE_POST_COMMENT_PROCESSING'
export const CASE_POST_COMMENT_SUCCESSED = 'CASE_POST_COMMENT_SUCCESSED'
export const CASE_POST_COMMENT_FAILED = 'CASE_POST_COMMENT_FAILED'

export const CASE_ASSET_DELETE_REQUESTED = 'CASE_ASSET_DELETE_REQUESTED'
export const CASE_ASSET_DELETE_PROCESSING = 'CASE_ASSET_DELETE_PROCESSING'
export const CASE_ASSET_DELETE_SUCCESSED = 'CASE_ASSET_DELETE_SUCCESSED'
export const CASE_ASSET_DELETE_FAILED = 'CASE_ASSET_DELETE_FAILED'

export const CASE_ADD_MEMBER_REQUESTED = 'CASE_ADD_MEMBER_REQUESTED'
export const CASE_ADD_MEMBER_PROCESSING = 'CASE_ADD_MEMBER_PROCESSING'
export const CASE_ADD_MEMBER_SUCCESSED = 'CASE_ADD_MEMBER_SUCCESSED'
export const CASE_ADD_MEMBER_FAILED = 'CASE_ADD_MEMBER_FAILED'

export const CASE_MEMEBER_USER_REQUESTED = 'CASE_MEMEBER_USER_REQUESTED'
export const CASE_MEMEBER_USER_PROCESSING = 'CASE_MEMEBER_USER_PROCESSING'
export const CASE_MEMEBER_USER_SUCCESSED = 'CASE_MEMEBER_USER_SUCCESSED'
export const CASE_MEMEBER_USER_FAILED = 'CASE_MEMEBER_USER_FAILED'

export const CASE_MEMEBER_USER_GROUP_REQUESTED = 'CASE_MEMEBER_USER_GROUP_REQUESTED'
export const CASE_MEMEBER_USER_GROUP_PROCESSING = 'CASE_MEMEBER_USER_GROUP_PROCESSING'
export const CASE_MEMEBER_USER_GROUP_SUCCESSED = 'CASE_MEMEBER_USER_GROUP_SUCCESSED'
export const CASE_MEMEBER_USER_GROUP_FAILED = 'CASE_MEMEBER_USER_GROUP_FAILED'

export const CASE_SEVERITY_REQUESTED = 'CASE_SEVERITY_REQUESTED'
export const CASE_SEVERITY_PROCESSING = 'CASE_SEVERITY_PROCESSING'
export const CASE_SEVERITY_SUCCESSED = 'CASE_SEVERITY_SUCCESSED'
export const CASE_SEVERITY_FAILED = 'CASE_SEVERITY_FAILED'

export const CASE_CATEGORY_REQUESTED = 'CASE_CATEGORY_REQUESTED'
export const CASE_CATEGORY_PROCESSING = 'CASE_CATEGORY_PROCESSING'
export const CASE_CATEGORY_SUCCESSED = 'CASE_CATEGORY_SUCCESSED'
export const CASE_CATEGORY_FAILED = 'CASE_CATEGORY_FAILED'

export const CASE_SUBCATEGORY_REQUESTED = 'CASE_SUBCATEGORY_REQUESTED'
export const CASE_SUBCATEGORY_PROCESSING = 'CASE_SUBCATEGORY_PROCESSING'
export const CASE_SUBCATEGORY_SUCCESSED = 'CASE_SUBCATEGORY_SUCCESSED'
export const CASE_SUBCATEGORY_FAILED = 'CASE_SUBCATEGORY_FAILED'

export const CASE_DISPOSITION_REQUESTED = 'CASE_DISPOSITION_REQUESTED'
export const CASE_DISPOSITION_PROCESSING = 'CASE_DISPOSITION_PROCESSING'
export const CASE_DISPOSITION_SUCCESSED = 'CASE_DISPOSITION_SUCCESSED'
export const CASE_DISPOSITION_FAILED = 'CASE_DISPOSITION_FAILED'

export const CASE_SUBDISPOSITION_REQUESTED = 'CASE_SUBDISPOSITION_REQUESTED'
export const CASE_SUBDISPOSITION_PROCESSING = 'CASE_SUBDISPOSITION_PROCESSING'
export const CASE_SUBDISPOSITION_SUCCESSED = 'CASE_SUBDISPOSITION_SUCCESSED'
export const CASE_SUBDISPOSITION_FAILED = 'CASE_SUBDISPOSITION_FAILED'

export const CASE_LOCATION_REQUESTED = 'CASE_LOCATION_REQUESTED'
export const CASE_LOCATION_PROCESSING = 'CASE_LOCATION_PROCESSING'
export const CASE_LOCATION_SUCCESSED = 'CASE_LOCATION_SUCCESSED'
export const CASE_LOCATION_FAILED = 'CASE_LOCATION_FAILED'

export const CASE_DEPARTMENT_REQUESTED = 'CASE_DEPARTMENT_REQUESTED'
export const CASE_DEPARTMENT_PROCESSING = 'CASE_DEPARTMENT_PROCESSING'
export const CASE_DEPARTMENT_SUCCESSED = 'CASE_DEPARTMENT_SUCCESSED'
export const CASE_DEPARTMENT_FAILED = 'CASE_DEPARTMENT_FAILED'

export const CASE_DETECTION_METHODS_REQUESTED = 'CASE_DETECTION_METHODS_REQUESTED'
export const CASE_DETECTION_METHODS_PROCESSING = 'CASE_DETECTION_METHODS_PROCESSING'
export const CASE_DETECTION_METHODS_SUCCESSED = 'CASE_DETECTION_METHODS_SUCCESSED'
export const CASE_DETECTION_METHODS_FAILED = 'CASE_DETECTION_METHODS_FAILED'

export const CASE_ARTIFACTS_REQUESTED = 'CASE_ARTIFACTS_REQUESTED'
export const CASE_ARTIFACTS_PROCESSING = 'CASE_ARTIFACTS_PROCESSING'
export const CASE_ARTIFACTS_SUCCESSED = 'CASE_ARTIFACTS_SUCCESSED'
export const CASE_ARTIFACTS_FAILED = 'CASE_ARTIFACTS_FAILED'

export const CASE_ACTION_APPS_REQUESTED = 'CASE_ACTION_APPS_REQUESTED'
export const CASE_ACTION_APPS_PROCESSING = 'CASE_ACTION_APPS_PROCESSING'
export const CASE_ACTION_APPS_SUCCESSED = 'CASE_ACTION_APPS_SUCCESSED'
export const CASE_ACTION_APPS_FAILED = 'CASE_ACTION_APPS_FAILED'

export const CASE_ACTION_RUN_REQUESTED = 'CASE_ACTION_RUN_REQUESTED'
export const CASE_ACTION_RUN_PROCESSING = 'CASE_ACTION_RUN_PROCESSING'
export const CASE_ACTION_RUN_SUCCESSED = 'CASE_ACTION_RUN_SUCCESSED'
export const CASE_ACTION_RUN_FAILED = 'CASE_ACTION_RUN_FAILED'

export const CASE_ARTIFACT_ADD_REQUESTE = 'CASE_ARTIFACT_ADD_REQUESTE'
export const CASE_ARTIFACT_ADDPROCESSING = 'CASE_ARTIFACT_ADDPROCESSING'
export const CASE_ARTIFACT_ADDSUCCESSED = 'CASE_ARTIFACT_ADDSUCCESSED'
export const CASE_ARTIFACT_ADDFAILED = 'CASE_ARTIFACT_ADDFAILED'

export const UPDATE_APPLICATION_STATUS_REQUESTED = 'UPDATE_APPLICATION_STATUS_REQUESTED'
export const UPDATE_APPLICATION_STATUS_PROCESSING = 'UPDATE_APPLICATION_STATUS_PROCESSING'
export const UPDATE_APPLICATION_STATUS_SUCCESSED = 'UPDATE_APPLICATION_STATUS_SUCCESSED'
export const UPDATE_APPLICATION_STATUS_FAILED = 'UPDATE_APPLICATION_STATUS_FAILED'

export const UPDATE_APP_WORKFLOW_TOGGLE = 'UPDATE_APP_WORKFLOW_TOGGLE'

export const GET_CONFIGURATION_DETAILS_REQUESTED = 'GET_CONFIGURATION_DETAILS_REQUESTED'
export const GET_CONFIGURATION_DETAILS_PROCESSING = 'GET_CONFIGURATION_DETAILS_PROCESSING'
export const GET_CONFIGURATION_DETAILS_SUCCESSED = 'GET_CONFIGURATION_DETAILS_SUCCESSED'
export const GET_CONFIGURATION_DETAILS_FAILED = 'GET_CONFIGURATION_DETAILS_FAILED'

export const GET_CONFIGURATION_FIELD_VALUE_REQUESTED = 'GET_CONFIGURATION_FIELD_VALUE_REQUESTED'
export const GET_CONFIGURATION_FIELD_VALUE_PROCESSING = 'GET_CONFIGURATION_FIELD_VALUE_PROCESSING'
export const GET_CONFIGURATION_FIELD_VALUE_SUCCESSED = 'GET_CONFIGURATION_FIELD_VALUE_SUCCESSED'
export const GET_CONFIGURATION_FIELD_VALUE_FAILED = 'GET_CONFIGURATION_FIELD_VALUE_FAILED'

export const UPDATE_CONFIGURATION_FILEDS_DETAILS_REQUESTED = 'UPDATE_CONFIGURATION_FILEDS_DETAILS_REQUESTED'
export const UPDATE_CONFIGURATION_FILEDS_DETAILS_PROCESSING = 'UPDATE_CONFIGURATION_FILEDS_DETAILS_PROCESSING'
export const UPDATE_CONFIGURATION_FILEDS_DETAILS_SUCCESSED = 'UPDATE_CONFIGURATION_FILEDS_DETAILS_SUCCESSED'
export const UPDATE_CONFIGURATION_FILEDS_DETAILS_FAILED = 'UPDATE_CONFIGURATION_FILEDS_DETAILS_FAILED'

export const UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_REQUESTED = 'UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_REQUESTED'
export const UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_PROCESSING = 'UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_PROCESSING'
export const UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_SUCCESSED = 'UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_SUCCESSED'
export const UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_FAILED = 'UPDATE_MULTI_CONFIGURATION_FILEDS_DETAILS_FAILED'

export const AUTOMATION_LIST_REQUESTED = 'AUTOMATION_LIST_REQUESTED'
export const AUTOMATION_LIST_PROCESSING = 'AUTOMATION_LIST_PROCESSING'
export const AUTOMATION_LIST_SUCCESSED = 'AUTOMATION_LIST_SUCCESSED'
export const AUTOMATION_LIST_FAILED = 'AUTOMATION_LIST_FAILED'

export const AUTOMATION_UPDATE_REQUESTED = 'AUTOMATION_UPDATE_REQUESTED'
export const AUTOMATION_UPDATE_PROCESSING = 'AUTOMATION_UPDATE_PROCESSING'
export const AUTOMATION_UPDATE_SUCCESSED = 'AUTOMATION_UPDATE_SUCCESSED'
export const AUTOMATION_UPDATE_FAILED = 'AUTOMATION_UPDATE_FAILED'

export const AUTOMATION_DETAILS_LIST_REQUESTED = 'AUTOMATION_DETAILS_LIST_REQUESTED'
export const AUTOMATION_DETAILS_LIST_PROCESSING = 'AUTOMATION_DETAILS_LIST_PROCESSING'
export const AUTOMATION_DETAILS_LIST_SUCCESSED = 'AUTOMATION_DETAILS_LIST_SUCCESSED'
export const AUTOMATION_DETAILS_LIST_FAILED = 'AUTOMATION_DETAILS_LIST_FAILED'

export const DELETE_AUTOMATION_REQUESTED = 'DELETE_AUTOMATION_REQUESTED'
export const DELETE_AUTOMATION_PROCESSING = 'DELETE_AUTOMATION_PROCESSING'
export const DELETE_AUTOMATION_SUCCESSED = 'DELETE_AUTOMATION_SUCCESSED'
export const DELETE_AUTOMATION_FAILED = 'DELETE_AUTOMATION_FAILED'

export const GET_AUTOMATION_APPLICATION_LIST_REQUESTED = 'GET_AUTOMATION_APPLICATION_LIST_REQUESTED'
export const GET_AUTOMATION_APPLICATION_LIST_PROCESSING = 'GET_AUTOMATION_APPLICATION_LIST_PROCESSING'
export const GET_AUTOMATION_APPLICATION_LIST_SUCCESSED = 'GET_AUTOMATION_APPLICATION_LIST_SUCCESSED'
export const GET_AUTOMATION_APPLICATION_LIST_FAILED = 'GET_AUTOMATION_APPLICATION_LIST_FAILED'

export const GET_AUTOMATION_ACTION_LIST_REQUESTED = 'GET_AUTOMATION_ACTION_LIST_REQUESTED'
export const GET_AUTOMATION_ACTION_LIST_PROCESSING = 'GET_AUTOMATION_ACTION_LIST_PROCESSING'
export const GET_AUTOMATION_ACTION_LIST_SUCCESSED = 'GET_AUTOMATION_ACTION_LIST_SUCCESSED'
export const GET_AUTOMATION_ACTION_LIST_FAILED = 'GET_AUTOMATION_ACTION_LIST_FAILED'

export const GET_AUTOMATION_ACTION_TYPES_REQUESTED = 'GET_AUTOMATION_ACTION_TYPES_REQUESTED'
export const GET_AUTOMATION_ACTION_TYPES_PROCESSING = 'GET_AUTOMATION_ACTION_TYPES_PROCESSING'
export const GET_AUTOMATION_ACTION_TYPES_SUCCESSED = 'GET_AUTOMATION_ACTION_TYPES_SUCCESSED'
export const GET_AUTOMATION_ACTION_TYPES_FAILED = 'GET_AUTOMATION_ACTION_TYPES_FAILED'


export const AUTOMATION_ACTION_DUPLICATE_REQUESTED = 'AUTOMATION_ACTION_DUPLICATE_REQUESTED'
export const AUTOMATION_ACTION_DUPLICATE_PROCESSING = 'AUTOMATION_ACTION_DUPLICATE_PROCESSING'
export const AUTOMATION_ACTION_DUPLICATE_SUCCESSED = 'AUTOMATION_ACTION_DUPLICATE_SUCCESSED'
export const AUTOMATION_ACTION_DUPLICATE_FAILED = 'AUTOMATION_ACTION_DUPLICATE_FAILED'


export const GET_AUTOMATION_INPUT_LIST_REQUESTED = 'GET_AUTOMATION_INPUT_LIST_REQUESTED'
export const GET_AUTOMATION_INPUT_LIST_PROCESSING = 'GET_AUTOMATION_INPUT_LIST_PROCESSING'
export const GET_AUTOMATION_INPUT_LIST_SUCCESSED = 'GET_AUTOMATION_INPUT_LIST_SUCCESSED'
export const GET_AUTOMATION_INPUT_LIST_FAILED = 'GET_AUTOMATION_INPUT_LIST_FAILED'
export const RESET_AUTOMATION_INPUT_LIST_REQUESTED = 'RESET_AUTOMATION_INPUT_LIST_REQUESTED'

export const CREATE_AUTOMATION_REQUESTED = 'CREATE_AUTOMATION_REQUESTED'
export const CREATE_AUTOMATION_PROCESSING = 'CREATE_AUTOMATION_PROCESSING'
export const CREATE_AUTOMATION_SUCCESSED = 'CREATE_AUTOMATION_SUCCESSED'
export const CREATE_AUTOMATION_FAILED = 'CREATE_AUTOMATION_FAILED'

export const OPEN_ACTION_DRAWER_REQUESTED = 'OPEN_ACTION_DRAWER_REQUESTED'
export const CLOSE_ACTION_DRAWER_REQUESTED = 'CLOSE_ACTION_DRAWER_REQUESTED'

export const AUTOMATION_ARTIFACT_LIST_REQUESTED = 'AUTOMATION_ARTIFACT_LIST_REQUESTED'
export const AUTOMATION_ARTIFACT_LIST_PROCESSING = 'AUTOMATION_ARTIFACT_LIST_PROCESSING'
export const AUTOMATION_ARTIFACT_LIST_SUCCESSED = 'AUTOMATION_ARTIFACT_LIST_SUCCESSED'
export const AUTOMATION_ARTIFACT_LIST_FAILED = 'AUTOMATION_ARTIFACT_LIST_FAILED'

export const AUTOMATION_ARTIFACT_OCCURENCE_LIST_REQUESTED = 'AUTOMATION_ARTIFACT_OCCURENCE_LIST_REQUESTED'
export const AUTOMATION_ARTIFACT_OCCURENCE_LIST_SUCCESSED = 'AUTOMATION_ARTIFACT_OCCURENCE_LIST_SUCCESSED'
export const AUTOMATION_ARTIFACT_OCCURENCE_LIST_FAILED = 'AUTOMATION_ARTIFACT_OCCURENCE_LIST_FAILED'

export const CHECK_IS_MULTI_CONFIG_REQUESTED = 'CHECK_IS_MULTI_CONFIG_REQUESTED'
export const CHECK_IS_MULTI_CONFIG_PROCESSING = 'CHECK_IS_MULTI_CONFIG_PROCESSING'
export const CHECK_IS_MULTI_CONFIG_SUCCESSED = 'CHECK_IS_MULTI_CONFIG_SUCCESSED'
export const CHECK_IS_MULTI_CONFIG_FAILED = 'CHECK_IS_MULTI_CONFIG_FAILED'

export const AUTOMATION_ARTIFACT_TYPE_LIST_REQUESTED = 'AUTOMATION_ARTIFACT_TYPE_LIST_REQUESTED'
export const AUTOMATION_ARTIFACT_TYPE_LIST_PROCESSING = 'AUTOMATION_ARTIFACT_TYPE_LIST_PROCESSING'
export const AUTOMATION_ARTIFACT_TYPE_LIST_SUCCESSED = 'AUTOMATION_ARTIFACT_TYPE_LIST_SUCCESSED'
export const AUTOMATION_ARTIFACT_TYPE_LIST_FAILED = 'AUTOMATION_ARTIFACT_TYPE_LIST_FAILED'

export const AUTOMATION_ARTIFACT_DELETE_REQUESTED = 'AUTOMATION_ARTIFACT_DELETE_REQUESTED'
export const AUTOMATION_ARTIFACT_DELETE_PROCESSING = 'AUTOMATION_ARTIFACT_DELETE_PROCESSING'
export const AUTOMATION_ARTIFACT_DELETE_SUCCESSED = 'AUTOMATION_ARTIFACT_DELETE_SUCCESSED'
export const AUTOMATION_ARTIFACT_DELETE_FAILED = 'AUTOMATION_ARTIFACT_DELETE_FAILED'

export const CREATE_AUTOMATION_ARTIFACT_REQUESTED = 'CREATE_AUTOMATION_ARTIFACT_REQUESTED'
export const CREATE_AUTOMATION_ARTIFACT_SUCCESSED = 'CREATE_AUTOMATION_ARTIFACT_SUCCESSED'
export const CREATE_AUTOMATION_ARTIFACT_FAILED = 'CREATE_AUTOMATION_ARTIFACT_FAILED'

export const DELETE_COMMENT_REQUESTED = 'DELETE_COMMENT_REQUESTED'
export const DELETE_COMMENT_PROCESSING = 'DELETE_COMMENT_PROCESSING'
export const DELETE_COMMENT_SUCCESSED = 'DELETE_COMMENT_SUCCESSED'
export const DELETE_COMMENT_FAILED = 'DELETE_COMMENT_FAILED'


export const DELETE_ATTACHMENT_REQUESTED = 'DELETE_ATTACHMENT_REQUESTED'
export const DELETE_ATTACHMENT_PROCESSING = 'DELETE_ATTACHMENT_PROCESSING'
export const DELETE_ATTACHMENT_SUCCESSED = 'DELETE_ATTACHMENT_SUCCESSED'
export const DELETE_ATTACHMENT_FAILED = 'DELETE_ATTACHMENT_FAILED'

export const SET_FEEDBACK_ALERT = 'SET_FEEDBACK_ALERT'

export const ACTION_ROW_OUTPUT_REQUESTED = 'ACTION_ROW_OUTPUT_REQUESTED'
export const ACTION_ROW_OUTPUT_PROCESSING = 'ACTION_ROW_OUTPUT_PROCESSING'
export const ACTION_ROW_OUTPUT_SUCCESSED = 'ACTION_ROW_OUTPUT_SUCCESSED'
export const ACTION_ROW_OUTPUT_FAILED = 'ACTION_ROW_OUTPUT_FAILED'
export const ACTION_ROW_OUTPUT_SET = 'ACTION_ROW_OUTPUT_SET'

export const AUTOMATION_APPROVAL_LIST_REQUESTED = 'AUTOMATION_APPROVAL_LIST_REQUESTED'
export const AUTOMATION_APPROVAL_LIST_PROCESSING = 'AUTOMATION_APPROVAL_LIST_PROCESSING'
export const AUTOMATION_APPROVAL_LIST_SUCCESSED = 'AUTOMATION_APPROVAL_LIST_SUCCESSED'
export const AUTOMATION_APPROVAL_LIST_FAILED = 'AUTOMATION_APPROVAL_LIST_FAILED'

export const NOTIFICATION_APPROVAL_LIST_REQUESTED = 'NOTIFICATION_APPROVAL_LIST_REQUESTED'
export const NOTIFICATION_APPROVAL_LIST_SUCCESSED = 'NOTIFICATION_APPROVAL_LIST_SUCCESSED'
export const NOTIFICATION_APPROVAL_LIST_FAILED = 'NOTIFICATION_APPROVAL_LIST_FAILED'

export const AUTOMATION_RECORD_APPROVE_REQUESTED = 'AUTOMATION_RECORD_APPROVE_REQUESTED'
export const AUTOMATION_RECORD_APPROVE_PROCESSING = 'AUTOMATION_RECORD_APPROVE_PROCESSING'
export const AUTOMATION_RECORD_APPROVE_SUCCESSED = 'AUTOMATION_RECORD_APPROVE_SUCCESSED'
export const AUTOMATION_RECORD_APPROVE_FAILED = 'AUTOMATION_RECORD_APPROVE_FAILED'

export const GET_AUTOMATION_EXECUTION_APPLICATION_LIST_REQUESTED = 'GET_AUTOMATION_EXECUTION_APPLICATION_LIST_REQUESTED'
export const GET_AUTOMATION_EXECUTION_APPLICATION_LIST_SUCCESSED = 'GET_AUTOMATION_EXECUTION_APPLICATION_LIST_SUCCESSED'
export const GET_AUTOMATION_EXECUTION_APPLICATION_LIST_FAILED = 'GET_AUTOMATION_EXECUTION_APPLICATION_LIST_FAILED'

export const GET_AUTOMATION_EXECUTION_ACTION_LIST_REQUESTED = 'GET_AUTOMATION_EXECUTION_ACTION_LIST_REQUESTED'
export const GET_AUTOMATION_EXECUTION_ACTION_LIST_SUCCESSED = 'GET_AUTOMATION_EXECUTION_ACTION_LIST_SUCCESSED'
export const GET_AUTOMATION_EXECUTION_ACTION_LIST_FAILED = 'GET_AUTOMATION_EXECUTION_ACTION_LIST_FAILED'

export const AUTOMATION_EXECUTION_REQUESTED = 'AUTOMATION_EXECUTION_REQUESTED'
export const AUTOMATION_EXECUTION_SUCCESSED = 'AUTOMATION_EXECUTION_SUCCESSED'
export const AUTOMATION_EXECUTION_FAILED = 'AUTOMATION_EXECUTION_FAILED'

export const CHANGE_STATUS_AUTOMATION_REQUESTED = 'CHANGE_STATUS_AUTOMATION_REQUESTED'
export const CHANGE_STATUS_AUTOMATION_SUCCESSED = 'CHANGE_STATUS_AUTOMATION_SUCCESSED'
export const CHANGE_STATUS_AUTOMATION_FAILED = 'CHANGE_STATUS_AUTOMATION_FAILED'

export const GET_REPORT_TYPES_REQUESTED = 'GET_REPORT_TYPES_REQUESTED'
export const GET_REPORT_TYPES_PROCESSING = 'GET_REPORT_TYPES_PROCESSING'
export const GET_REPORT_TYPES_SUCCESSED = 'GET_REPORT_TYPES_SUCCESSED'
export const GET_REPORT_TYPES_FAILED = 'GET_REPORT_TYPES_FAILED'

export const GENERATE_REPORT_REQUESTED = 'GENERATE_REPORT_REQUESTED'
export const GENERATE_REPORT_PROCESSING = 'GENERATE_REPORT_PROCESSING'
export const GENERATE_REPORT_SUCCESSED = 'GENERATE_REPORT_SUCCESSED'
export const GENERATE_REPORT_FAILED = 'GENERATE_REPORT_FAILED'

export const COVER_IMAGES_REQUESTED = 'COVER_IMAGES_REQUESTED'
export const COVER_IMAGES_SUCCESSED = 'COVER_IMAGES_SUCCESSED'
export const COVER_IMAGES_FAILED = 'COVER_IMAGES_FAILED'

export const GET_WIDGET_REQUESTED = 'GET_WIDGET_REQUESTED'
export const GET_WIDGET_SUCCESSED = 'GET_WIDGET_SUCCESSED'
export const GET_WIDGET_FAILED = 'GET_WIDGET_FAILED'

export const WIDGET_LIST_REQUESTED = 'WIDGET_LIST_REQUESTED'
export const WIDGET_LIST_SUCCESSED = 'WIDGET_LIST_SUCCESSED'
export const WIDGET_LIST_FAILED = 'WIDGET_LIST_FAILED'

export const REPORTS_GET_WIDGET_DATA_REQUESTED = 'REPORTS_GET_WIDGET_DATA_REQUESTED'
export const REPORTS_GET_WIDGET_DATA_SUCCESSED = 'REPORTS_GET_WIDGET_DATA_SUCCESSED'
export const REPORTS_GET_WIDGET_DATA_FAILED = 'REPORTS_GET_WIDGET_DATA_FAILED'

export const UPDATE_WIDGETS_LAYOUT_REQUESTED = 'UPDATE_WIDGETS_LAYOUT_REQUESTED'
export const UPDATE_REPORT_DATA_REQUESTED = 'UPDATE_REPORT_DATA_REQUESTED'

export const ADVISORY_GENERATE_REPORT_REQUESTED = 'ADVISORY_GENERATE_REPORT_REQUESTED'
export const ADVISORY_GENERATE_REPORT_PROCESSING = 'ADVISORY_GENERATE_REPORT_PROCESSING'
export const ADVISORY_GENERATE_REPORT_SUCCESSED = 'ADVISORY_GENERATE_REPORT_SUCCESSED'
export const ADVISORY_GENERATE_REPORT_FAILED = 'ADVISORY_GENERATE_REPORT_FAILED'

export const GET_APPLICATION_VENDOR_LIST_REQUESTED = 'GET_APPLICATION_VENDOR_LIST_REQUESTED'
export const GET_APPLICATION_VENDOR_LIST_PROCESSING = 'GET_APPLICATION_VENDOR_LIST_PROCESSING'
export const GET_APPLICATION_VENDOR_LIST_SUCCESSED = 'GET_APPLICATION_VENDOR_LIST_SUCCESSED'
export const GET_APPLICATION_VENDOR_LIST_FAILED = 'GET_APPLICATION_VENDOR_LIST_FAILED'

export const GET_APPLICATION_PUBLISHERS_LIST_REQUESTED = 'GET_APPLICATION_PUBLISHERS_LIST_REQUESTED'
export const GET_APPLICATION_PUBLISHERS_LIST_PROCESSING = 'GET_APPLICATION_PUBLISHERS_LIST_PROCESSING'
export const GET_APPLICATION_PUBLISHERS_LIST_SUCCESSED = 'GET_APPLICATION_PUBLISHERS_LIST_SUCCESSED'
export const GET_APPLICATION_PUBLISHERS_LIST_FAILED = 'GET_APPLICATION_PUBLISHERS_LIST_FAILED'

export const GET_APPLICATION_TYPE_LIST_REQUESTED = 'GET_APPLICATION_TYPE_LIST_REQUESTED'
export const GET_APPLICATION_TYPE_LIST_PROCESSING = 'GET_APPLICATION_TYPE_LIST_PROCESSING'
export const GET_APPLICATION_TYPE_LIST_SUCCESSED = 'GET_APPLICATION_TYPE_LIST_SUCCESSED'
export const GET_APPLICATION_TYPE_LIST_FAILED = 'GET_APPLICATION_TYPE_LIST_FAILED'

export const GET_APPLICATION_RATE_LIST_REQUESTED = 'GET_APPLICATION_RATE_LIST_REQUESTED'
export const GET_APPLICATION_RATE_LIST_PROCESSING = 'GET_APPLICATION_RATE_LIST_PROCESSING'
export const GET_APPLICATION_RATE_LIST_SUCCESSED = 'GET_APPLICATION_RATE_LIST_SUCCESSED'
export const GET_APPLICATION_RATE_LIST_FAILED = 'GET_APPLICATION_RATE_LIST_FAILED'

export const CREATE_APPLICATION_REQUESTED = 'CREATE_APPLICATION_REQUESTED'
export const CREATE_APPLICATION_PROCESSING = 'CREATE_APPLICATION_PROCESSING'
export const CREATE_APPLICATION_SUCCESSED = 'CREATE_APPLICATION_SUCCESSED'
export const CREATE_APPLICATION_FAILED = 'CREATE_APPLICATION_FAILED'

export const UPDATE_APPLICATION_REQUESTED = 'UPDATE_APPLICATION_REQUESTED'
export const UPDATE_APPLICATION_SUCCESSED = 'UPDATE_APPLICATION_SUCCESSED'
export const UPDATE_APPLICATION_FAILED = 'UPDATE_APPLICATION_FAILED'

export const GET_APPROVAL_FLOW_LIST_REQUESTED = 'GET_APPROVAL_FLOW_LIST_REQUESTED'
export const GET_APPROVAL_FLOW_LIST_PROCESSING = 'GET_APPROVAL_FLOW_LIST_PROCESSING'
export const GET_APPROVAL_FLOW_LIST_SUCCESSED = 'GET_APPROVAL_FLOW_LIST_SUCCESSED'
export const GET_APPROVAL_FLOW_LIST_FAILED = 'GET_APPROVAL_FLOW_LIST_FAILED'
export const ADMINISTRATION_ORGANIZATION_LIST_REQUESTED = 'ADMINISTRATION_ORGANIZATION_LIST_REQUESTED'
export const ADMINISTRATION_ORGANIZATION_LIST_PROCESSING = 'ADMINISTRATION_ORGANIZATION_LIST_PROCESSING'
export const ADMINISTRATION_ORGANIZATION_LIST_SUCCESSED = 'ADMINISTRATION_ORGANIZATION_LIST_SUCCESSED'
export const ADMINISTRATION_ORGANIZATION_LIST_FAILED = 'ADMINISTRATION_ORGANIZATION_LIST_FAILED'

export const ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED = 'ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED'
export const ADMINISTRATION_CASES_CATEGORY_LIST_PROCESSING = 'ADMINISTRATION_CASES_CATEGORY_LIST_PROCESSING'
export const ADMINISTRATION_CASES_CATEGORY_LIST_SUCCESSED = 'ADMINISTRATION_CASES_CATEGORY_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_CATEGORY_LIST_FAILED = 'ADMINISTRATION_CASES_CATEGORY_LIST_FAILED'

export const ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED = 'ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED'
export const ADMINISTRATION_CASES_CATEGORY_DISPOSITION_PROCESSING = 'ADMINISTRATION_CASES_CATEGORY_DISPOSITION_PROCESSING'
export const ADMINISTRATION_CASES_CATEGORY_DISPOSITION_SUCCESSED = 'ADMINISTRATION_CASES_CATEGORY_DISPOSITION_SUCCESSED'
export const ADMINISTRATION_CASES_CATEGORY_DISPOSITION_FAILED = 'ADMINISTRATION_CASES_CATEGORY_DISPOSITION_FAILED'

export const ADMINISTRATION_CASES_CATEGORY_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_CATEGORY_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_CATEGORY_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_CATEGORY_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_CATEGORY_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_CATEGORY_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_CATEGORY_UPDATE_FAILED = 'ADMINISTRATION_CASES_CATEGORY_UPDATE_FAILED'

export const ADMINISTRATION_CASES_CATEGORY_CREATE_REQUESTED = 'ADMINISTRATION_CASES_CATEGORY_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_CATEGORY_CREATE_PROCESSING = 'ADMINISTRATION_CASES_CATEGORY_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_CATEGORY_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_CATEGORY_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_CATEGORY_CREATE_FAILED = 'ADMINISTRATION_CASES_CATEGORY_CREATE_FAILED'

export const ADMINISTRATION_CASES_CATEGORY_DELETE_REQUESTED = 'ADMINISTRATION_CASES_CATEGORY_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_CATEGORY_DELETE_PROCESSING = 'ADMINISTRATION_CASES_CATEGORY_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_CATEGORY_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_CATEGORY_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_CATEGORY_DELETE_FAILED = 'ADMINISTRATION_CASES_CATEGORY_DELETE_FAILED'

export const ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED = 'ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED'
export const ADMINISTRATION_CASES_SUBCATEGORY_LIST_PROCESSING = 'ADMINISTRATION_CASES_SUBCATEGORY_LIST_PROCESSING'
export const ADMINISTRATION_CASES_SUBCATEGORY_LIST_SUCCESSED = 'ADMINISTRATION_CASES_SUBCATEGORY_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_SUBCATEGORY_LIST_FAILED = 'ADMINISTRATION_CASES_SUBCATEGORY_LIST_FAILED'

export const ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_REQUESTED = 'ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_PROCESSING = 'ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_FAILED = 'ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_FAILED'

export const ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_FAILED = 'ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_FAILED'

export const ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_REQUESTED = 'ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_PROCESSING = 'ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_FAILED = 'ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_FAILED'

export const ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED = 'ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED'
export const ADMINISTRATION_PRIMARY_CATEGORY_LIST_PROCESSING = 'ADMINISTRATION_PRIMARY_CATEGORY_LIST_PROCESSING'
export const ADMINISTRATION_PRIMARY_CATEGORY_LIST_SUCCESSED = 'ADMINISTRATION_PRIMARY_CATEGORY_LIST_SUCCESSED'
export const ADMINISTRATION_PRIMARY_CATEGORY_LIST_FAILED = 'ADMINISTRATION_PRIMARY_CATEGORY_LIST_FAILED'

export const ADMINISTRATION_SUB_CATEGORY_LIST_REQUESTED = 'ADMINISTRATION_SUB_CATEGORY_LIST_REQUESTED'
export const ADMINISTRATION_SUB_CATEGORY_LIST_PROCESSING = 'ADMINISTRATION_SUB_CATEGORY_LIST_PROCESSING'
export const ADMINISTRATION_SUB_CATEGORY_LIST_SUCCESSED = 'ADMINISTRATION_SUB_CATEGORY_LIST_SUCCESSED'
export const ADMINISTRATION_SUB_CATEGORY_LIST_FAILED = 'ADMINISTRATION_SUB_CATEGORY_LIST_FAILED'

export const ADMINISTRATION_ORGANIZATION_UPDATE_REQUESTED = 'ADMINISTRATION_ORGANIZATION_UPDATE_REQUESTED'
export const ADMINISTRATION_ORGANIZATION_UPDATE_PROCESSING = 'ADMINISTRATION_ORGANIZATION_UPDATE_PROCESSING'
export const ADMINISTRATION_ORGANIZATION_UPDATE_SUCCESSED = 'ADMINISTRATION_ORGANIZATION_UPDATE_SUCCESSED'
export const ADMINISTRATION_ORGANIZATION_UPDATE_FAILED = 'ADMINISTRATION_ORGANIZATION_UPDATE_FAILED'

export const ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED = 'ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED'
export const ADMINISTRATION_GET_SELECTED_ORGANIZATION_PROCESSING = 'ADMINISTRATION_GET_SELECTED_ORGANIZATION_PROCESSING'
export const ADMINISTRATION_GET_SELECTED_ORGANIZATION_SUCCESSED = 'ADMINISTRATION_GET_SELECTED_ORGANIZATION_SUCCESSED'
export const ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED = 'ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED'

export const ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED = 'ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED'
export const ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_SUCCESSED = 'ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_SUCCESSED'
export const ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_FAILED = 'ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_FAILED'


export const EMAIL_CONFIGURATION_POST_REQUESTED = 'EMAIL_CONFIGURATION_POST_REQUESTED'
export const EMAIL_CONFIGURATION_POST_SUCCESSED = 'EMAIL_CONFIGURATION_POST_SUCCESSED'
export const EMAIL_CONFIGURATION_POST_FAILED = 'EMAIL_CONFIGURATION_POST_FAILED'

export const ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED = 'ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED'
export const ADMINISTRATION_GET_EMAIL_CONNECTIVITY_SUCCESSED = 'ADMINISTRATION_GET_EMAIL_CONNECTIVITY_SUCCESSED'
export const ADMINISTRATION_GET_EMAIL_CONNECTIVITY_FAILED = 'ADMINISTRATION_GET_EMAIL_CONNECTIVITY_FAILED'

export const ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED = 'ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED'
export const ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_SUCCESSED = 'ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_SUCCESSED'
export const ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_FAILED = 'ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_FAILED'

export const ADMINISTRATION_SET_SELECTED_ORGANIZATION_REQUESTED = 'ADMINISTRATION_SET_SELECTED_ORGANIZATION_REQUESTED'
export const ADMINISTRATION_SET_SELECTED_ORGANIZATION_PROCESSING = 'ADMINISTRATION_SET_SELECTED_ORGANIZATION_PROCESSING'
export const ADMINISTRATION_SET_SELECTED_ORGANIZATION_SUCCESSED = 'ADMINISTRATION_SET_SELECTED_ORGANIZATION_SUCCESSED'
export const ADMINISTRATION_SET_SELECTED_ORGANIZATION_FAILED = 'ADMINISTRATION_SET_SELECTED_ORGANIZATION_FAILED'

export const ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED = 'ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED'
export const ADMINISTRATION_CLASSIFICATION_LIST_PROCESSING = 'ADMINISTRATION_CLASSIFICATION_LIST_PROCESSING'
export const ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED = 'ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED'
export const ADMINISTRATION_CLASSIFICATION_LIST_FAILED = 'ADMINISTRATION_CLASSIFICATION_LIST_FAILED'

export const ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_REQUESTED'
export const ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_PROCESSING = 'ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_PROCESSING'
export const ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_SUCCESSED = 'ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_SUCCESSED'
export const ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_FAILED = 'ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_FAILED'

export const ADMINISTRATION_CLASSIFICATION_DELETE_REQUESTED = 'ADMINISTRATION_CLASSIFICATION_DELETE_REQUESTED'
export const ADMINISTRATION_CLASSIFICATION_DELETE_PROCESSING = 'ADMINISTRATION_CLASSIFICATION_DELETE_PROCESSING'
export const ADMINISTRATION_CLASSIFICATION_DELETE_SUCCESSED = 'ADMINISTRATION_CLASSIFICATION_DELETE_SUCCESSED'
export const ADMINISTRATION_CLASSIFICATION_DELETE_FAILED = 'ADMINISTRATION_CLASSIFICATION_DELETE_FAILED'

export const ADMINISTRATION_CLASSIFICATION_CREATE_LIST_REQUESTED = 'ADMINISTRATION_CLASSIFICATION_CREATE_LIST_REQUESTED'
export const ADMINISTRATION_CLASSIFICATION_CREATE_LIST_PROCESSING = 'ADMINISTRATION_CLASSIFICATION_CREATE_LIST_PROCESSING'
export const ADMINISTRATION_CLASSIFICATION_CREATE_LIST_SUCCESSED = 'ADMINISTRATION_CLASSIFICATION_CREATE_LIST_SUCCESSED'
export const ADMINISTRATION_CLASSIFICATION_CREATE_LIST_FAILED = 'ADMINISTRATION_CLASSIFICATION_CREATE_LIST_FAILED'

export const ADMINISTRATION_OWNERS_LIST_REQUESTED = 'ADMINISTRATION_OWNERS_LIST_REQUESTED'
export const ADMINISTRATION_OWNERS_LIST_PROCESSING = 'ADMINISTRATION_OWNERS_LIST_PROCESSING'
export const ADMINISTRATION_OWNERS_LIST_SUCCESSED = 'ADMINISTRATION_OWNERS_LIST_SUCCESSED'
export const ADMINISTRATION_OWNERS_LIST_FAILED = 'ADMINISTRATION_OWNERS_LIST_FAILED'

export const ADMINISTRATION_OWNERS_DELETE_REQUESTED = 'ADMINISTRATION_OWNERS_DELETE_REQUESTED'
export const ADMINISTRATION_OWNERS_DELETE_PROCESSING = 'ADMINISTRATION_OWNERS_DELETE_PROCESSING'
export const ADMINISTRATION_OWNERS_DELETE_SUCCESSED = 'ADMINISTRATION_OWNERS_DELETE_SUCCESSED'
export const ADMINISTRATION_OWNERS_DELETE_FAILED = 'ADMINISTRATION_OWNERS_DELETE_FAILED'

export const ADMINISTRATION_OWNERS_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_OWNERS_UPDATE_LIST_REQUESTED'
export const ADMINISTRATION_OWNERS_UPDATE_LIST_PROCESSING = 'ADMINISTRATION_OWNERS_UPDATE_LIST_PROCESSING'
export const ADMINISTRATION_OWNERS_UPDATE_LIST_SUCCESSED = 'ADMINISTRATION_OWNERS_UPDATE_LIST_SUCCESSED'
export const ADMINISTRATION_OWNERS_UPDATE_LIST_FAILED = 'ADMINISTRATION_OWNERS_UPDATE_LIST_FAILED'

export const ADMINISTRATION_OWNERS_CREATE_LIST_REQUESTED = 'ADMINISTRATION_OWNERS_CREATE_LIST_REQUESTED'
export const ADMINISTRATION_OWNERS_CREATE_LIST_PROCESSING = 'ADMINISTRATION_OWNERS_CREATE_LIST_PROCESSING'
export const ADMINISTRATION_OWNERS_CREATE_LIST_SUCCESSED = 'ADMINISTRATION_OWNERS_CREATE_LIST_SUCCESSED'
export const ADMINISTRATION_OWNERS_CREATE_LIST_FAILED = 'ADMINISTRATION_OWNERS_CREATE_LIST_FAILED'

export const ADMINISTRATION_ORAGANIZATION_CREATE_REQUESTED = 'ADMINISTRATION_ORAGANIZATION_CREATE_REQUESTED'
export const ADMINISTRATION_ORAGANIZATION_CREATE_PROCESSING = 'ADMINISTRATION_ORAGANIZATION_CREATE_PROCESSING'
export const ADMINISTRATION_ORAGANIZATION_CREATE_SUCCESSED = 'ADMINISTRATION_ORAGANIZATION_CREATE_SUCCESSED'
export const ADMINISTRATION_ORAGANIZATION_CREATE_FAILED = 'ADMINISTRATION_ORAGANIZATION_CREATE_FAILED'

export const ADMINISTRATION_ORAGANIZATION_DELETE_REQUESTED = 'ADMINISTRATION_ORAGANIZATION_DELETE_REQUESTED'
export const ADMINISTRATION_ORAGANIZATION_DELETE_PROCESSING = 'ADMINISTRATION_ORAGANIZATION_DELETE_PROCESSING'
export const ADMINISTRATION_ORAGANIZATION_DELETE_SUCCESSED = 'ADMINISTRATION_ORAGANIZATION_DELETE_SUCCESSED'
export const ADMINISTRATION_ORAGANIZATION_DELETE_FAILED = 'ADMINISTRATION_ORAGANIZATION_DELETE_FAILED'

export const ORAGANIZATION_REQUESTED = 'ORAGANIZATION_REQUESTED'
export const ORAGANIZATION_PROCESSING = 'ORAGANIZATION_PROCESSING'
export const ORAGANIZATION_SUCCESSED = 'ORAGANIZATION_SUCCESSED'
export const ORAGANIZATION_FAILED = 'ORAGANIZATION_FAILED'

export const ORAGANIZATION_USER_CREATE_REQUESTED = 'ORAGANIZATION_USER_CREATE_REQUESTED'
export const ORAGANIZATION_USER_CREATE_PROCESSING = 'ORAGANIZATION_USER_CREATE_PROCESSING'
export const ORAGANIZATION_USER_CREATE_SUCCESSED = 'ORAGANIZATION_USER_CREATE_SUCCESSED'
export const ORAGANIZATION_USER_CREATE_FAILED = 'ORAGANIZATION_USER_CREATE_FAILED'

export const ORAGANIZATION_USER_UPDATE_REQUESTED = 'ORAGANIZATION_USER_UPDATE_REQUESTED'
export const ORAGANIZATION_USER_UPDATE_PROCESSING = 'ORAGANIZATION_USER_UPDATE_PROCESSING'
export const ORAGANIZATION_USER_UPDATE_SUCCESSED = 'ORAGANIZATION_USER_UPDATE_SUCCESSED'
export const ORAGANIZATION_USER_UPDATE_FAILED = 'ORAGANIZATION_USER_UPDATE_FAILED'

export const ORAGANIZATION_USER_DELETE_REQUESTED = 'ORAGANIZATION_USER_DELETE_REQUESTED'
export const ORAGANIZATION_USER_DELETE_PROCESSING = 'ORAGANIZATION_USER_DELETE_PROCESSING'
export const ORAGANIZATION_USER_DELETE_SUCCESSED = 'ORAGANIZATION_USER_DELETE_SUCCESSED'
export const ORAGANIZATION_USER_DELETE_FAILED = 'ORAGANIZATION_USER_DELETE_FAILED'

export const ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED = 'ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED'
export const ADMINISTRATION_ORAGANIZATION_USERS_PROCESSING = 'ADMINISTRATION_ORAGANIZATION_USERS_PROCESSING'
export const ADMINISTRATION_ORAGANIZATION_USERS_SUCCESSED = 'ADMINISTRATION_ORAGANIZATION_USERS_SUCCESSED'
export const ADMINISTRATION_ORAGANIZATION_USERS_FAILED = 'ADMINISTRATION_ORAGANIZATION_USERS_FAILED'

export const ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED = 'ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED'
export const ADMINISTRATION_ORAGANIZATION_MEMBERS_PROCESSING = 'ADMINISTRATION_ORAGANIZATION_MEMBERS_PROCESSING'
export const ADMINISTRATION_ORAGANIZATION_MEMBERS_SUCCESSED = 'ADMINISTRATION_ORAGANIZATION_MEMBERS_SUCCESSED'
export const ADMINISTRATION_ORAGANIZATION_MEMBERS_FAILED = 'ADMINISTRATION_ORAGANIZATION_MEMBERS_FAILED'

export const ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED = 'ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED'
export const ADMINISTRATION_ORAGANIZATION_TIMEZONE_PROCESSING = 'ADMINISTRATION_ORAGANIZATION_TIMEZONE_PROCESSING'
export const ADMINISTRATION_ORAGANIZATION_TIMEZONE_SUCCESSED = 'ADMINISTRATION_ORAGANIZATION_TIMEZONE_SUCCESSED'
export const ADMINISTRATION_ORAGANIZATION_TIMEZONE_FAILED = 'ADMINISTRATION_ORAGANIZATION_TIMEZONE_FAILED'

export const ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED = 'ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED'
export const ADMINISTRATION_CASES_DISPOSITIONS_LIST_PROCESSING = 'ADMINISTRATION_CASES_DISPOSITIONS_LIST_PROCESSING'
export const ADMINISTRATION_CASES_DISPOSITIONS_LIST_SUCCESSED = 'ADMINISTRATION_CASES_DISPOSITIONS_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_DISPOSITIONS_LIST_FAILED = 'ADMINISTRATION_CASES_DISPOSITIONS_LIST_FAILED'

export const ADMINISTRATION_CASES_DISPOSITION_DELETE_REQUESTED = 'ADMINISTRATION_CASES_DISPOSITION_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_DISPOSITION_DELETE_PROCESSING = 'ADMINISTRATION_CASES_DISPOSITION_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_DISPOSITION_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_DISPOSITION_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_DISPOSITION_DELETE_FAILED = 'ADMINISTRATION_CASES_DISPOSITION_DELETE_FAILED'

export const ADMINISTRATION_CASES_DISPOSITION_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_DISPOSITION_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_DISPOSITION_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_DISPOSITION_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_DISPOSITION_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_DISPOSITION_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_DISPOSITION_UPDATE_FAILED = 'ADMINISTRATION_CASES_DISPOSITION_UPDATE_FAILED'

export const ADMINISTRATION_CASES_DISPOSITION_CREATE_REQUESTED = 'ADMINISTRATION_CASES_DISPOSITION_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_DISPOSITION_CREATE_PROCESSING = 'ADMINISTRATION_CASES_DISPOSITION_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_DISPOSITION_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_DISPOSITION_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_DISPOSITION_CREATE_FAILED = 'ADMINISTRATION_CASES_DISPOSITION_CREATE_FAILED'

export const ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_PROCESSING = 'ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_PROCESSING'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_SUCCESSED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_FAILED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_FAILED'

export const ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_PROCESSING = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_PROCESSING'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_SUCCESSED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_FAILED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_FAILED'

export const ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_REQUESTED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_PROCESSING = 'ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_FAILED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_FAILED'

export const ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_FAILED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_FAILED'

export const ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_REQUESTED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_PROCESSING = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_FAILED = 'ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_FAILED'

export const ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED = 'ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_LIST_PROCESSING = 'ADMINISTRATION_CASES_DITECTION_METHOD_LIST_PROCESSING'
export const ADMINISTRATION_CASES_DITECTION_METHOD_LIST_SUCCESSED = 'ADMINISTRATION_CASES_DITECTION_METHOD_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_LIST_FAILED = 'ADMINISTRATION_CASES_DITECTION_METHOD_LIST_FAILED'

export const ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_REQUESTED = 'ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_PROCESSING = 'ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_FAILED = 'ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_FAILED'

export const ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_FAILED = 'ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_FAILED'

export const ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_REQUESTED = 'ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_PROCESSING = 'ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_FAILED = 'ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_FAILED'


export const AUTOMATION_ACTION_CONFIGURATION_REQUESTED = 'AUTOMATION_ACTION_CONFIGURATION_REQUESTED'
export const AUTOMATION_ACTION_CONFIGURATION_PROCESSING = 'AUTOMATION_ACTION_CONFIGURATION_PROCESSING'
export const AUTOMATION_ACTION_CONFIGURATION_SUCCESSED = 'AUTOMATION_ACTION_CONFIGURATION_SUCCESSED'
export const AUTOMATION_ACTION_CONFIGURATION_FAILED = 'AUTOMATION_ACTION_CONFIGURATION_FAILED'

export const AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED = 'AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED'
export const AUTOMATION_ACTION_VIEW_SCRIPT_PROCESSING = 'AUTOMATION_ACTION_VIEW_SCRIPT_PROCESSING'
export const AUTOMATION_ACTION_VIEW_SCRIPT_SUCCESSED = 'AUTOMATION_ACTION_VIEW_SCRIPT_SUCCESSED'
export const AUTOMATION_ACTION_VIEW_SCRIPT_FAILED = 'AUTOMATION_ACTION_VIEW_SCRIPT_FAILED'

export const AUTOMATION_ACTION_CONFIGURATION_POST_REQUESTED = 'AUTOMATION_ACTION_CONFIGURATION_POST_REQUESTED'
export const AUTOMATION_ACTION_CONFIGURATION_POST_PROCESSING = 'AUTOMATION_ACTION_CONFIGURATION_POST_PROCESSING'
export const AUTOMATION_ACTION_CONFIGURATION_POST_SUCCESSED = 'AUTOMATION_ACTION_CONFIGURATION_POST_SUCCESSED'
export const AUTOMATION_ACTION_CONFIGURATION_POST_FAILED = 'AUTOMATION_ACTION_CONFIGURATION_POST_FAILED'

export const AUTOMATION_ACTION_MULTIDATA_POST_REQUESTED = 'AUTOMATION_ACTION_MULTIDATA_POST_REQUESTED'
export const AUTOMATION_ACTION_MULTIDATA_POST_PROCESSING = 'AUTOMATION_ACTION_MULTIDATA_POST_PROCESSING'
export const AUTOMATION_ACTION_MULTIDATA_POST_SUCCESSED = 'AUTOMATION_ACTION_MULTIDATA_POST_SUCCESSED'
export const AUTOMATION_ACTION_MULTIDATA_POST_FAILED = 'AUTOMATION_ACTION_MULTIDATA_POST_FAILED'


export const ADMINISTRATION_CASES_SLA_LIST_REQUESTED = 'ADMINISTRATION_CASES_SLA_LIST_REQUESTED'
export const ADMINISTRATION_CASES_SLA_LIST_PROCESSING = 'ADMINISTRATION_CASES_SLA_LIST_PROCESSING'
export const ADMINISTRATION_CASES_SLA_LIST_SUCCESSED = 'ADMINISTRATION_CASES_SLA_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_SLA_LIST_FAILED = 'ADMINISTRATION_CASES_SLA_LIST_FAILED'

export const ADMINISTRATION_CASES_SLA_CREATE_REQUESTED = 'ADMINISTRATION_CASES_SLA_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_SLA_CREATE_PROCESSING = 'ADMINISTRATION_CASES_SLA_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_SLA_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_SLA_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_SLA_CREATE_FAILED = 'ADMINISTRATION_CASES_SLA_CREATE_FAILED'


export const ADMINISTRATION_CASES_SLA_DELETE_REQUESTED = 'ADMINISTRATION_CASES_SLA_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_SLA_DELETE_PROCESSING = 'ADMINISTRATION_CASES_SLA_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_SLA_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_SLA_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_SLA_DELETE_FAILED = 'ADMINISTRATION_CASES_SLA_DELETE_FAILED'

export const ADMINISTRATION_CASES_SLA_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_SLA_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_SLA_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_SLA_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_SLA_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_SLA_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_SLA_UPDATE_FAILED = 'ADMINISTRATION_CASES_SLA_UPDATE_FAILED'

export const ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED = 'ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED'
export const ADMINISTRATION_CASES_SLA_VIEW_LIST_PROCESSING = 'ADMINISTRATION_CASES_SLA_VIEW_LIST_PROCESSING'
export const ADMINISTRATION_CASES_SLA_VIEW_LIST_SUCCESSED = 'ADMINISTRATION_CASES_SLA_VIEW_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_SLA_VIEW_LIST_FAILED = 'ADMINISTRATION_CASES_SLA_VIEW_LIST_FAILED'

export const ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED = 'ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED'
export const ADMINISTRATION_CASES_SLA_RULE_LIST_PROCESSING = 'ADMINISTRATION_CASES_SLA_RULE_LIST_PROCESSING'
export const ADMINISTRATION_CASES_SLA_RULE_LIST_SUCCESSED = 'ADMINISTRATION_CASES_SLA_RULE_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_SLA_RULE_LIST_FAILED = 'ADMINISTRATION_CASES_SLA_RULE_LIST_FAILED'

export const ADMINISTRATION_CASES_NCISS_LIST_REQUESTED = 'ADMINISTRATION_CASES_NCISS_LIST_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_LIST_PROCESSING = 'ADMINISTRATION_CASES_NCISS_LIST_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_LIST_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_LIST_FAILED = 'ADMINISTRATION_CASES_NCISS_LIST_FAILED'

export const ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED = 'ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_PROCESSING = 'ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_FAILED = 'ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_FAILED'

export const ADMINISTRATION_CASES_NCISS_DELETE_LIST_REQUESTED = 'ADMINISTRATION_CASES_NCISS_DELETE_LIST_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_DELETE_LIST_PROCESSING = 'ADMINISTRATION_CASES_NCISS_DELETE_LIST_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_DELETE_LIST_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_DELETE_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_DELETE_LIST_FAILED = 'ADMINISTRATION_CASES_NCISS_DELETE_LIST_FAILED'


export const ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED = 'ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINER_LIST_SUCCESS = 'ADMINISTRATION_CASES_CONTAINER_LIST_SUCCESS'
export const ADMINISTRATION_CASES_CONTAINER_LIST_FAILDED = 'ADMINISTRATION_CASES_CONTAINER_LIST_FAILDED'

export const ADMINISTRATION_CASES_DESPOSITION_REQUESTED = 'ADMINISTRATION_CASES_DESPOSITION_REQUESTED'
export const ADMINISTRATION_CASES_DESPOSITION_SUCCESS = 'ADMINISTRATION_CASES_DESPOSITION_SUCCESS'
export const ADMINISTRATION_CASES_DESPOSITION_FAILED = 'ADMINISTRATION_CASES_DESPOSITION_FAILED'

export const ADMINISTRATION_CASES_CATEGORY_REQUESTED = 'ADMINISTRATION_CASES_CATEGORY_REQUESTED'
export const ADMINISTRATION_CASES_CATEGORY_SUCCESS = 'ADMINISTRATION_CASES_CATEGORY_SUCCESS'
export const ADMINISTRATION_CASES_CATEGORY_FAILED = 'ADMINISTRATION_CASES_CATEGORY_FAILED'


export const ADMINISTRATION_CASES_NCISS_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_UPDATE_FAILED = 'ADMINISTRATION_CASES_NCISS_UPDATE_FAILED'

export const ADMINISTRATION_CASES_NCISS_CREATE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_CREATE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_CREATE_FAILED = 'ADMINISTRATION_CASES_NCISS_CREATE_FAILED'

export const ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_PROCESSING = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_FAILED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_FAILED'

export const ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_FAILED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_FAILED'

export const ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_FAILED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_FAILED'

export const ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_FAILED = 'ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_FAILED'

export const ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED = 'ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_PROCESSING = 'ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_FAILED = 'ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_FAILED'

export const ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_FAILED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_FAILED'

export const ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_FAILED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_FAILED'

export const ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_REQUESTED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_PROCESSING = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_FAILED = 'ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_FAILED'

export const ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED = 'ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED'
export const ADMINISTRATION_CASES_LOCATIONS_LIST_PROCESSING = 'ADMINISTRATION_CASES_LOCATIONS_LIST_PROCESSING'
export const ADMINISTRATION_CASES_LOCATIONS_LIST_SUCCESSED = 'ADMINISTRATION_CASES_LOCATIONS_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_LOCATIONS_LIST_FAILED = 'ADMINISTRATION_CASES_LOCATIONS_LIST_FAILED'

export const ADMINISTRATION_CASES_LOCATION_DELETE_REQUESTED = 'ADMINISTRATION_CASES_LOCATION_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_LOCATION_DELETE_PROCESSING = 'ADMINISTRATION_CASES_LOCATION_DELETE_PROCESSING'
export const ADMINISTRATION_CASES_LOCATION_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_LOCATION_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_LOCATION_DELETE_FAILED = 'ADMINISTRATION_CASES_LOCATION_DELETE_FAILED'

export const ADMINISTRATION_CASES_LOCATION_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_LOCATION_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_LOCATION_UPDATE_PROCESSING = 'ADMINISTRATION_CASES_LOCATION_UPDATE_PROCESSING'
export const ADMINISTRATION_CASES_LOCATION_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_LOCATION_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_LOCATION_UPDATE_FAILED = 'ADMINISTRATION_CASES_LOCATION_UPDATE_FAILED'

export const ADMINISTRATION_CASES_LOCATION_CREATE_REQUESTED = 'ADMINISTRATION_CASES_LOCATION_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_LOCATION_CREATE_PROCESSING = 'ADMINISTRATION_CASES_LOCATION_CREATE_PROCESSING'
export const ADMINISTRATION_CASES_LOCATION_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_LOCATION_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_LOCATION_CREATE_FAILED = 'ADMINISTRATION_CASES_LOCATION_CREATE_FAILED'

export const GET_PRIMARY_APPROVERS_LIST_REQUESTED = 'GET_PRIMARY_APPROVERS_LIST_REQUESTED'
export const GET_PRIMARY_APPROVERS_LIST_PROCESSING = 'GET_PRIMARY_APPROVERS_LIST_PROCESSING'
export const GET_PRIMARY_APPROVERS_LIST_SUCCESSED = 'GET_PRIMARY_APPROVERS_LIST_SUCCESSED'
export const GET_PRIMARY_APPROVERS_LIST_FAILED = 'GET_PRIMARY_APPROVERS_LIST_FAILED'

export const CREATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED = 'CREATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED'
export const CREATE_APPROVAL_WORKFLOW_DETAILS_PROCESSING = 'CREATE_APPROVAL_WORKFLOW_DETAILS_PROCESSING'
export const CREATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED = 'CREATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED'
export const CREATE_APPROVAL_WORKFLOW_DETAILS_FAILED = 'CREATE_APPROVAL_WORKFLOW_DETAILS_FAILED'

export const UPDATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED = 'UPDATE_APPROVAL_WORKFLOW_DETAILS_REQUESTED'
export const UPDATE_APPROVAL_WORKFLOW_DETAILS_PROCESSING = 'UPDATE_APPROVAL_WORKFLOW_DETAILS_PROCESSING'
export const UPDATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED = 'UPDATE_APPROVAL_WORKFLOW_DETAILS_SUCCESSED'
export const UPDATE_APPROVAL_WORKFLOW_DETAILS_FAILED = 'UPDATE_APPROVAL_WORKFLOW_DETAILS_FAILED'

export const DELETE_APPLICATION_REQUESTED = 'DELETE_APPLICATION_REQUESTED'
export const DELETE_APPLICATION_PROCESSING = 'DELETE_APPLICATION_PROCESSING'
export const DELETE_APPLICATION_SUCCESSED = 'DELETE_APPLICATION_SUCCESSED'
export const DELETE_APPLICATION_FAILED = 'DELETE_APPLICATION_FAILED'

export const DELETE_APPROVAL_WORKFLOW_REQUESTED = 'DELETE_APPROVAL_WORKFLOW_REQUESTED'
export const DELETE_APPROVAL_WORKFLOW_PROCESSING = 'DELETE_APPROVAL_WORKFLOW_PROCESSING'
export const DELETE_APPROVAL_WORKFLOW_SUCCESSED = 'DELETE_APPROVAL_WORKFLOW_SUCCESSED'
export const DELETE_APPROVAL_WORKFLOW_FAILED = 'DELETE_APPROVAL_WORKFLOW_FAILED'

export const ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED = 'ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED'
export const ADMINISTRATION_ASSETS_GROUP_LIST_PROCESSING = 'ADMINISTRATION_ASSETS_GROUP_LIST_PROCESSING'
export const ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED = 'ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED'
export const ADMINISTRATION_ASSETS_GROUP_LIST_FAILED = 'ADMINISTRATION_ASSETS_GROUP_LIST_FAILED'

export const ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED = 'ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED'
export const ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_PROCESSING = 'ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_PROCESSING'
export const ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_SUCCESSED = 'ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_SUCCESSED'
export const ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_FAILED = 'ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_FAILED'

export const ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED = 'ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED'
export const ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_PROCESSING = 'ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_PROCESSING'
export const ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_SUCCESSED = 'ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_SUCCESSED'
export const ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_FAILED = 'ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_FAILED'

export const ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED = 'ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED'
export const ADMINISTRATION_CREATE_ASSETS_GROUP_PROCESSING = 'ADMINISTRATION_CREATE_ASSETS_GROUP_PROCESSING'
export const ADMINISTRATION_CREATE_ASSETS_GROUP_SUCCESSED = 'ADMINISTRATION_CREATE_ASSETS_GROUP_SUCCESSED'
export const ADMINISTRATION_CREATE_ASSETS_GROUP_FAILED = 'ADMINISTRATION_CREATE_ASSETS_GROUP_FAILED'

export const ADMINISTRATION_DELETE_ASSETS_GROUPS_REQUESTED = 'ADMINISTRATION_DELETE_ASSETS_GROUPS_REQUESTED'
export const ADMINISTRATION_DELETE_ASSETS_GROUPS_PROCESSING = 'ADMINISTRATION_DELETE_ASSETS_GROUPS_PROCESSING'
export const ADMINISTRATION_DELETE_ASSETS_GROUPS_SUCCESSED = 'ADMINISTRATION_DELETE_ASSETS_GROUPS_SUCCESSED'
export const ADMINISTRATION_DELETE_ASSETS_GROUPS_FAILED = 'ADMINISTRATION_DELETE_ASSETS_GROUPS_FAILED'

export const ADMINISTRATION_EDIT_ASSETS_GROUPS_REQUESTED = 'ADMINISTRATION_EDIT_ASSETS_GROUPS_REQUESTED'
export const ADMINISTRATION_EDIT_ASSETS_GROUPS_PROCESSING = 'ADMINISTRATION_EDIT_ASSETS_GROUPS_PROCESSING'
export const ADMINISTRATION_EDIT_ASSETS_GROUPS_SUCCESSED = 'ADMINISTRATION_EDIT_ASSETS_GROUPS_SUCCESSED'
export const ADMINISTRATION_EDIT_ASSETS_GROUPS_FAILED = 'ADMINISTRATION_EDIT_ASSETS_GROUPS_FAILED'

export const ADMINISTRATION_WORKFLOW_LIST_REQUESTED = 'ADMINISTRATION_WORKFLOW_LIST_REQUESTED'
export const ADMINISTRATION_WORKFLOW_LIST_PROCESSING = 'ADMINISTRATION_WORKFLOW_LIST_PROCESSING'
export const ADMINISTRATION_WORKFLOW_LIST_SUCCESSED = 'ADMINISTRATION_WORKFLOW_LIST_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_LIST_FAILED = 'ADMINISTRATION_WORKFLOW_LIST_FAILED'

export const DOWNLOAD_ARTIFCATS_FILE = 'DOWNLOAD_ARTIFCATS_FILE'

export const ADMINISTRATION_CATEGORY_LIST_REQUESTED = 'ADMINISTRATION_CATEGORY_LIST_REQUESTED'
export const ADMINISTRATION_CATEGORY_LIST_PROCESSING = 'ADMINISTRATION_CATEGORY_LIST_PROCESSING'
export const ADMINISTRATION_CATEGORY_LIST_SUCCESSED = 'ADMINISTRATION_CATEGORY_LIST_SUCCESSED'
export const ADMINISTRATION_CATEGORY_LIST_FAILED = 'ADMINISTRATION_CATEGORY_LIST_FAILED'

export const ADMINISTRATION_CATEGORY_CREATE_LIST_REQUESTED = 'ADMINISTRATION_CATEGORY_CREATE_LIST_REQUESTED'
export const ADMINISTRATION_CATEGORY_CREATE_LIST_PROCESSING = 'ADMINISTRATION_CATEGORY_CREATE_LIST_PROCESSING'
export const ADMINISTRATION_CATEGORY_CREATE_LIST_SUCCESSED = 'ADMINISTRATION_CATEGORY_CREATE_LIST_SUCCESSED'
export const ADMINISTRATION_CATEGORY_CREATE_LIST_FAILED = 'ADMINISTRATION_CATEGORY_CREATE_LIST_FAILED'

export const ADMINISTRATION_CATEGORY_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_CATEGORY_UPDATE_LIST_REQUESTED'
export const ADMINISTRATION_CATEGORY_UPDATE_LIST_PROCESSING = 'ADMINISTRATION_CATEGORY_UPDATE_LIST_PROCESSING'
export const ADMINISTRATION_CATEGORY_UPDATE_LIST_SUCCESSED = 'ADMINISTRATION_CATEGORY_UPDATE_LIST_SUCCESSED'
export const ADMINISTRATION_CATEGORY_UPDATE_LIST_FAILED = 'ADMINISTRATION_CATEGORY_UPDATE_LIST_FAILED'

export const ADMINISTRATION_CATEGORY_DELETE_LIST_REQUESTED = 'ADMINISTRATION_CATEGORY_DELETE_LIST_REQUESTED'
export const ADMINISTRATION_CATEGORY_DELETE_LIST_PROCESSING = 'ADMINISTRATION_CATEGORY_DELETE_LIST_PROCESSING'
export const ADMINISTRATION_CATEGORY_DELETE_LIST_SUCCESSED = 'ADMINISTRATION_CATEGORY_DELETE_LIST_SUCCESSED'
export const ADMINISTRATION_CATEGORY_DELETE_LIST_FAILED = 'ADMINISTRATION_CATEGORY_DELETE_LIST_FAILED'

export const ADMINISTRATION_WORKFLOW_CHANGE_STATUS_REQUESTED = 'ADMINISTRATION_WORKFLOW_CHANGE_STATUS_REQUESTED'
export const ADMINISTRATION_WORKFLOW_CHANGE_STATUS_PROCESSING = 'ADMINISTRATION_WORKFLOW_CHANGE_STATUS_PROCESSING'
export const ADMINISTRATION_WORKFLOW_CHANGE_STATUS_SUCCESSED = 'ADMINISTRATION_WORKFLOW_CHANGE_STATUS_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_CHANGE_STATUS_FAILED = 'ADMINISTRATION_WORKFLOW_CHANGE_STATUS_FAILED'

export const ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED = 'ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_SUB_GROUP_LIST_PROCESSING = 'ADMINISTRATION_ASSET_SUB_GROUP_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_SUB_GROUP_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_SUB_GROUP_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_SUB_GROUP_LIST_FAILED = 'ADMINISTRATION_ASSET_SUB_GROUP_LIST_FAILED'

export const ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED = 'ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_PROCESSING = 'ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_FAILED = 'ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_FAILED'

export const ADMINISTRATION_CREATE_ASSET_SUB_GROUP_REQUESTED = 'ADMINISTRATION_CREATE_ASSET_SUB_GROUP_REQUESTED'
export const ADMINISTRATION_CREATE_ASSET_SUB_GROUP_PROCESSING = 'ADMINISTRATION_CREATE_ASSET_SUB_GROUP_PROCESSING'
export const ADMINISTRATION_CREATE_ASSET_SUB_GROUP_SUCCESSED = 'ADMINISTRATION_CREATE_ASSET_SUB_GROUP_SUCCESSED'
export const ADMINISTRATION_CREATE_ASSET_SUB_GROUP_FAILED = 'ADMINISTRATION_CREATE_ASSET_SUB_GROUP_FAILED'

export const ADMINISTRATION_EDIT_ASSET_SUB_GROUP_REQUESTED = 'ADMINISTRATION_EDIT_ASSET_SUB_GROUP_REQUESTED'
export const ADMINISTRATION_EDIT_ASSET_SUB_GROUP_PROCESSING = 'ADMINISTRATION_EDIT_ASSET_SUB_GROUP_PROCESSING'
export const ADMINISTRATION_EDIT_ASSET_SUB_GROUP_SUCCESSED = 'ADMINISTRATION_EDIT_ASSET_SUB_GROUP_SUCCESSED'
export const ADMINISTRATION_EDIT_ASSET_SUB_GROUP_FAILED = 'ADMINISTRATION_EDIT_ASSET_SUB_GROUP_FAILED'

export const ADMINISTRATION_DELETE_ASSET_SUB_GROUP_REQUESTED = 'ADMINISTRATION_DELETE_ASSET_SUB_GROUP_REQUESTED'
export const ADMINISTRATION_DELETE_ASSET_SUB_GROUP_PROCESSING = 'ADMINISTRATION_DELETE_ASSET_SUB_GROUP_PROCESSING'
export const ADMINISTRATION_DELETE_ASSET_SUB_GROUP_SUCCESSED = 'ADMINISTRATION_DELETE_ASSET_SUB_GROUP_SUCCESSED'
export const ADMINISTRATION_DELETE_ASSET_SUB_GROUP_FAILED = 'ADMINISTRATION_DELETE_ASSET_SUB_GROUP_FAILED'

export const ADMINISTRATION_WORKFLOW_DUPLICATE_REQUESTED = 'ADMINISTRATION_WORKFLOW_DUPLICATE_REQUESTED'
export const ADMINISTRATION_WORKFLOW_DUPLICATE_PROCESSING = 'ADMINISTRATION_WORKFLOW_DUPLICATE_PROCESSING'
export const ADMINISTRATION_WORKFLOW_DUPLICATE_SUCCESSED = 'ADMINISTRATION_WORKFLOW_DUPLICATE_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_DUPLICATE_FAILED = 'ADMINISTRATION_WORKFLOW_DUPLICATE_FAILED'

export const ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED = 'ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED'
export const ADMINISTRATION_WORKFLOW_CATEGORY_PROCESSING = 'ADMINISTRATION_WORKFLOW_CATEGORY_PROCESSING'
export const ADMINISTRATION_WORKFLOW_CATEGORY_SUCCESSED = 'ADMINISTRATION_WORKFLOW_CATEGORY_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_CATEGORY_FAILED = 'ADMINISTRATION_WORKFLOW_CATEGORY_FAILED'

export const ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED = 'ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED'
export const ADMINISTRATION_WORKFLOW_SUB_CATEGORY_PROCESSING = 'ADMINISTRATION_WORKFLOW_SUB_CATEGORY_PROCESSING'
export const ADMINISTRATION_WORKFLOW_SUB_CATEGORY_SUCCESSED = 'ADMINISTRATION_WORKFLOW_SUB_CATEGORY_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_SUB_CATEGORY_FAILED = 'ADMINISTRATION_WORKFLOW_SUB_CATEGORY_FAILED'

export const ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED = 'ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED'
export const ADMINISTRATION_WORKFLOW_TASK_CATEGORY_PROCESSING = 'ADMINISTRATION_WORKFLOW_TASK_CATEGORY_PROCESSING'
export const ADMINISTRATION_WORKFLOW_TASK_CATEGORY_SUCCESSED = 'ADMINISTRATION_WORKFLOW_TASK_CATEGORY_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_TASK_CATEGORY_FAILED = 'ADMINISTRATION_WORKFLOW_TASK_CATEGORY_FAILED'

export const ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED = 'ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED'
export const ADMINISTRATION_WORKFLOW_MITRE_TACTICS_PROCESSING = 'ADMINISTRATION_WORKFLOW_MITRE_TACTICS_PROCESSING'
export const ADMINISTRATION_WORKFLOW_MITRE_TACTICS_SUCCESSED = 'ADMINISTRATION_WORKFLOW_MITRE_TACTICS_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_MITRE_TACTICS_FAILED = 'ADMINISTRATION_WORKFLOW_MITRE_TACTICS_FAILED'

export const ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED = 'ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED'
export const ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_PROCESSING = 'ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_PROCESSING'
export const ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_SUCCESSED = 'ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_FAILED = 'ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_FAILED'

export const ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED = 'ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED'
export const ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_PROCESSING = 'ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_PROCESSING'
export const ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_SUCCESSED = 'ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_FAILED = 'ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_FAILED'

export const ADMINISTRATION_WORKFLOW_TAGS_REQUESTED = 'ADMINISTRATION_WORKFLOW_TAGS_REQUESTED'
export const ADMINISTRATION_WORKFLOW_TAGS_PROCESSING = 'ADMINISTRATION_WORKFLOW_TAGS_PROCESSING'
export const ADMINISTRATION_WORKFLOW_TAGS_SUCCESSED = 'ADMINISTRATION_WORKFLOW_TAGS_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_TAGS_FAILED = 'ADMINISTRATION_WORKFLOW_TAGS_FAILED'

export const ADMINISTRATION_WORKFLOW_DELETE_REQUESTED = 'ADMINISTRATION_WORKFLOW_DELETE_REQUESTED'
export const ADMINISTRATION_WORKFLOW_DELETE_PROCESSING = 'ADMINISTRATION_WORKFLOW_DELETE_PROCESSING'
export const ADMINISTRATION_WORKFLOW_DELETE_SUCCESSED = 'ADMINISTRATION_WORKFLOW_DELETE_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_DELETE_FAILED = 'ADMINISTRATION_WORKFLOW_DELETE_FAILED'

export const ADMINISTRATION_WORKFLOW_CREATE_REQUESTED = 'ADMINISTRATION_WORKFLOW_CREATE_REQUESTED'
export const ADMINISTRATION_WORKFLOW_CREATE_PROCESSING = 'ADMINISTRATION_WORKFLOW_CREATE_PROCESSING'
export const ADMINISTRATION_WORKFLOW_CREATE_SUCCESSED = 'ADMINISTRATION_WORKFLOW_CREATE_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_CREATE_FAILED = 'ADMINISTRATION_WORKFLOW_CREATE_FAILED'

export const ADMINISTRATION_WORKFLOW_UPDATE_REQUESTED = 'ADMINISTRATION_WORKFLOW_UPDATE_REQUESTED'
export const ADMINISTRATION_WORKFLOW_UPDATE_PROCESSING = 'ADMINISTRATION_WORKFLOW_UPDATE_PROCESSING'
export const ADMINISTRATION_WORKFLOW_UPDATE_SUCCESSED = 'ADMINISTRATION_WORKFLOW_UPDATE_SUCCESSED'
export const ADMINISTRATION_WORKFLOW_UPDATE_FAILED = 'ADMINISTRATION_WORKFLOW_UPDATE_FAILED'

export const ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED = 'ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_VALUE_LIST_PROCESSING = 'ADMINISTRATION_ASSET_VALUE_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_VALUE_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_VALUE_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_VALUE_LIST_FAILED = 'ADMINISTRATION_ASSET_VALUE_LIST_FAILED'

export const ADMINISTRATION_CREATE_ASSET_VALUE_LIST_REQUESTED = 'ADMINISTRATION_CREATE_ASSET_VALUE_LIST_REQUESTED'
export const ADMINISTRATION_CREATE_ASSET_VALUE_LIST_PROCESSING = 'ADMINISTRATION_CREATE_ASSET_VALUE_LIST_PROCESSING'
export const ADMINISTRATION_CREATE_ASSET_VALUE_LIST_SUCCESSED = 'ADMINISTRATION_CREATE_ASSET_VALUE_LIST_SUCCESSED'
export const ADMINISTRATION_CREATE_ASSET_VALUE_LIST_FAILED = 'ADMINISTRATION_CREATE_ASSET_VALUE_LIST_FAILED'

export const ADMINISTRATION_EDIT_ASSET_VALUE_REQUESTED = 'ADMINISTRATION_EDIT_ASSET_VALUE_REQUESTED'
export const ADMINISTRATION_EDIT_ASSET_VALUE_PROCESSING = 'ADMINISTRATION_EDIT_ASSET_VALUE_PROCESSING'
export const ADMINISTRATION_EDIT_ASSET_VALUE_SUCCESSED = 'ADMINISTRATION_EDIT_ASSET_VALUE_SUCCESSED'
export const ADMINISTRATION_EDIT_ASSET_VALUE_FAILED = 'ADMINISTRATION_EDIT_ASSET_VALUE_FAILED'

export const ADMINISTRATION_DELETE_ASSET_VALUE_REQUESTED = 'ADMINISTRATION_DELETE_ASSET_VALUE_REQUESTED'
export const ADMINISTRATION_DELETE_ASSET_VALUE_PROCESSING = 'ADMINISTRATION_DELETE_ASSET_VALUE_PROCESSING'
export const ADMINISTRATION_DELETE_ASSET_VALUE_SUCCESSED = 'ADMINISTRATION_DELETE_ASSET_VALUE_SUCCESSED'
export const ADMINISTRATION_DELETE_ASSET_VALUE_FAILED = 'ADMINISTRATION_DELETE_ASSET_VALUE_FAILED'

export const ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_REQUESTED = 'ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_REQUESTED'
export const ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_PROCESSING = 'ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_PROCESSING'
export const ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_SUCCESSED = 'ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_SUCCESSED'
export const ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_FAILED = 'ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_FAILED'

export const ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED = 'ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED'
export const ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_PROCESSING = 'ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_PROCESSING'
export const ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED = 'ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED'
export const ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_FAILED = 'ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_FAILED'

export const ADMINISTRATION_CREATE_OPERATING_SYSTEM_REQUESTED = 'ADMINISTRATION_CREATE_OPERATING_SYSTEM_REQUESTED'
export const ADMINISTRATION_CREATE_OPERATING_SYSTEM_PROCESSING = 'ADMINISTRATION_CREATE_OPERATING_SYSTEM_PROCESSING'
export const ADMINISTRATION_CREATE_OPERATING_SYSTEM_SUCCESSED = 'ADMINISTRATION_CREATE_OPERATING_SYSTEM_SUCCESSED'
export const ADMINISTRATION_CREATE_OPERATING_SYSTEM_FAILED = 'ADMINISTRATION_CREATE_OPERATING_SYSTEM_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED'


export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_FAILED'


export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_FAILED'


export const ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED'


export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_FAILED'

export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_PROCESSING = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_PROCESSING'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_SUCCESSED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_SUCCESSED'
export const ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_FAILED = 'ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_FAILED'

export const ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED'
export const ADMINISTRATION_AUTOMATION_LIST_PROCESSING = 'ADMINISTRATION_AUTOMATION_LIST_PROCESSING'
export const ADMINISTRATION_AUTOMATION_LIST_SUCCESSED = 'ADMINISTRATION_AUTOMATION_LIST_SUCCESSED'
export const ADMINISTRATION_AUTOMATION_LIST_FAILED = 'ADMINISTRATION_AUTOMATION_LIST_FAILED'

export const ADMINISTRATION_AUTOMATION_VENDORS_CREATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_VENDORS_CREATE_LIST_REQUESTED'
export const ADMINISTRATION_AUTOMATION_CREATE_LIST_PROCESSING = 'ADMINISTRATION_AUTOMATION_CREATE_LIST_PROCESSING'
export const ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED = 'ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED'
export const ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED = 'ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED'

export const ADMINISTRATION_AUTOMATION_VENDORS_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_VENDORS_UPDATE_LIST_REQUESTED'
export const ADMINISTRATION_AUTOMATION_UPDATE_LIST_PROCESSING = 'ADMINISTRATION_AUTOMATION_UPDATE_LIST_PROCESSING'
export const ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED = 'ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED'
export const ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED = 'ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED'



export const ADMINISTRATION_AUTOMATION_VENDORS_DELETE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_VENDORS_DELETE_LIST_REQUESTED'
export const ADMINISTRATION_AUTOMATION_DELETE_LIST_PROCESSING = 'ADMINISTRATION_AUTOMATION_DELETE_LIST_PROCESSING'
export const ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED = 'ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED'
export const ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED = 'ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED'

export const ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED'
export const ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_PROCESSING = 'ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_PROCESSING'
export const ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_SUCCESSED = 'ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_SUCCESSED'
export const ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_FAILED = 'ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_FAILED'

export const ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED'
export const ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_PROCESSING = 'ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_PROCESSING'
export const ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_SUCCESSED = 'ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_SUCCESSED'
export const ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_FAILED = 'ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_FAILED'

export const ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED'


export const ADMINISTRATION_AUTOMATION_PUBLISHERS_CREATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_PUBLISHERS_CREATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_PUBLISHERS_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_PUBLISHERS_UPDATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_PUBLISHERS_DELETE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_PUBLISHERS_DELETE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONIO_CREATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONIO_CREATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONIO_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONIO_UPDATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONIO_DELETE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONIO_DELETE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_INGESTION_CREATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_INGESTION_CREATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_INGESTION_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_INGESTION_UPDATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_INGESTION_DELETE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_INGESTION_DELETE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONS_CREATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONS_CREATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONS_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONS_UPDATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_ACTIONS_DELETE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_ACTIONS_DELETE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED'



export const ADMINISTRATION_AUTOMATION_THREAT_CREATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_THREAT_CREATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_THREAT_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_THREAT_UPDATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_THREAT_DELETE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_THREAT_DELETE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_CASE_FAMILY_CREATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_CASE_FAMILY_CREATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_CASE_FAMILY_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_CASE_FAMILY_UPDATE_LIST_REQUESTED'

export const ADMINISTRATION_AUTOMATION_CASE_FAMILY_DELETE_LIST_REQUESTED = 'ADMINISTRATION_AUTOMATION_CASE_FAMILY_DELETE_LIST_REQUESTED'

export const ADMINISTRATION_EDIT_OPERATING_SYSTEM_REQUESTED = 'ADMINISTRATION_EDIT_OPERATING_SYSTEM_REQUESTED'
export const ADMINISTRATION_EDIT_OPERATING_SYSTEM_PROCESSING = 'ADMINISTRATION_EDIT_OPERATING_SYSTEM_PROCESSING'
export const ADMINISTRATION_EDIT_OPERATING_SYSTEM_SUCCESSED = 'ADMINISTRATION_EDIT_OPERATING_SYSTEM_SUCCESSED'
export const ADMINISTRATION_EDIT_OPERATING_SYSTEM_FAILED = 'ADMINISTRATION_EDIT_OPERATING_SYSTEM_FAILED'

export const ADMINISTRATION_DELETE_OPERATING_SYSTEM_REQUESTED = 'ADMINISTRATION_DELETE_OPERATING_SYSTEM_REQUESTED'
export const ADMINISTRATION_DELETE_OPERATING_SYSTEM_PROCESSING = 'ADMINISTRATION_DELETE_OPERATING_SYSTEM_PROCESSING'
export const ADMINISTRATION_DELETE_OPERATING_SYSTEM_SUCCESSED = 'ADMINISTRATION_DELETE_OPERATING_SYSTEM_SUCCESSED'
export const ADMINISTRATION_DELETE_OPERATING_SYSTEM_FAILED = 'ADMINISTRATION_DELETE_OPERATING_SYSTEM_FAILED'

export const ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED = 'ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED'
export const ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_PROCESSING = 'ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_PROCESSING'
export const ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED = 'ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED'
export const ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED = 'ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED'

export const ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_REQUESTED = 'ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_REQUESTED'
export const ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_PROCESSING = 'ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_PROCESSING'
export const ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_SUCCESSED = 'ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_SUCCESSED'
export const ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_FAILED = 'ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_FAILED'

export const ADMINISTRATION_EDIT_DEPARTMENT_REQUESTED = 'ADMINISTRATION_EDIT_DEPARTMENT_REQUESTED'
export const ADMINISTRATION_EDIT_DEPARTMENT_PROCESSING = 'ADMINISTRATION_EDIT_DEPARTMENT_PROCESSING'
export const ADMINISTRATION_EDIT_DEPARTMENT_SUCCESSED = 'ADMINISTRATION_EDIT_DEPARTMENT_SUCCESSED'
export const ADMINISTRATION_EDIT_DEPARTMENT_FAILED = 'ADMINISTRATION_EDIT_DEPARTMENT_FAILED'

export const ADMINISTRATION_DELETE_DEPARTMENT_REQUESTED = 'ADMINISTRATION_DELETE_DEPARTMENT_REQUESTED'
export const ADMINISTRATION_DELETE_DEPARTMENT_PROCESSING = 'ADMINISTRATION_DELETE_DEPARTMENT_PROCESSING'
export const ADMINISTRATION_DELETE_DEPARTMENT_SUCCESSED = 'ADMINISTRATION_DELETE_DEPARTMENT_SUCCESSED'
export const ADMINISTRATION_DELETE_DEPARTMENT_FAILED = 'ADMINISTRATION_DELETE_DEPARTMENT_FAILED'

export const ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED = 'ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_PROCESSING = 'ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_FAILED = 'ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_FAILED'

export const ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_REQUESTED = 'ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_PROCESSING = 'ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_FAILED = 'ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_FAILED'

export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_REQUESTED = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_PROCESSING = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_FAILED = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_FAILED'

export const ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_REQUESTED = 'ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_PROCESSING = 'ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_FAILED = 'ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_FAILED'

export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_REQUESTED = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_REQUESTED'
export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_PROCESSING = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_PROCESSING'
export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_SUCCESSED = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_SUCCESSED'
export const ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_FAILED = 'ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_FAILED'

export const ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED = 'ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_TYPE_LIST_PROCESSING = 'ADMINISTRATION_ASSET_TYPE_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_TYPE_LIST_FAILED = 'ADMINISTRATION_ASSET_TYPE_LIST_FAILED'

export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_PROCESSING = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_PROCESSING'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_SUCCESSED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_SUCCESSED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED'

export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_REQUESTED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_REQUESTED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_PROCESSING = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_PROCESSING'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_SUCCESSED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_SUCCESSED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_FAILED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_FAILED'

export const ADMINISTRATION_CASE_FIELD_SETTING_LIST_REQUESTED = 'ADMINISTRATION_CASE_FIELD_SETTING_LIST_REQUESTED'
export const ADMINISTRATION_CASE_FIELD_SETTING_LIST_PROCESSING = 'ADMINISTRATION_CASE_FIELD_SETTING_LIST_PROCESSING'
export const ADMINISTRATION_CASE_FIELD_SETTING_LIST_SUCCESSED = 'ADMINISTRATION_CASE_FIELD_SETTING_LIST_SUCCESSED'
export const ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED = 'ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED'

export const ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_REQUESTED = 'ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_REQUESTED'
export const ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_PROCESSING = 'ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_SUCCESSED = 'ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_FAILED = 'ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_FAILED'


export const ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_REQUESTED = 'ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_REQUESTED'
export const ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_PROCESSING = 'ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_SUCCESSED'
export const ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_SUCCESSED = 'ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_SUCCESSED'
export const ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_FAILED = 'ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_FAILED'

export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_REQUESTED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_REQUESTED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_PROCESSING = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_PROCESSING'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_SUCCESSED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_SUCCESSED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_FAILED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_FAILED'

export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_REQUESTED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_REQUESTED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_PROCESSING = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_PROCESSING'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_SUCCESSED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_SUCCESSED'
export const ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_FAILED = 'ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_FAILED'



export const ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_REQUESTED = 'ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_REQUESTED'
export const ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_PROCESSING = 'ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_PROCESSING'
export const ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_SUCCESSED = 'ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_SUCCESSED'
export const ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_FAILED = 'ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_FAILED'

export const ADMINISTRATION_DELETE_ASSET_TYPE_REQUESTED = 'ADMINISTRATION_DELETE_ASSET_TYPE_REQUESTED'
export const ADMINISTRATION_DELETE_ASSET_TYPE_PROCESSING = 'ADMINISTRATION_DELETE_ASSET_TYPE_PROCESSING'
export const ADMINISTRATION_DELETE_ASSET_TYPE_SUCCESSED = 'ADMINISTRATION_DELETE_ASSET_TYPE_SUCCESSED'
export const ADMINISTRATION_DELETE_ASSET_TYPE_FAILED = 'ADMINISTRATION_DELETE_ASSET_TYPE_FAILED'

export const ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED = 'ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_REQUESTED = 'ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_PROCESSING = 'ADMINISTRATION_VULNERABILITY_PROCESSING'
export const ADMINISTRATION_VULNERABILITY_SUCCESSED = 'ADMINISTRATION_VULNERABILITY_SUCCESSED'
export const ADMINISTRATION_VULNERABILITY_FAILED = 'ADMINISTRATION_VULNERABILITY_FAILED'

export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED'
export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_PROCESSING = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_PROCESSING'
export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_SUCCESSED = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_SUCCESSED'
export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_FAILED = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_FAILED'

export const ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED = 'ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED'
export const ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_PROCESSING = 'ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_PROCESSING'
export const ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_SUCCESSED = 'ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_SUCCESSED'
export const ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_FAILED = 'ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_FAILED'

export const ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED = 'ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED'
export const ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_PROCESSING = 'ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_PROCESSING'
export const ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_SUCCESSED = 'ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_SUCCESSED'
export const ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_FAILED = 'ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_FAILED'

export const ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED = 'ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED'
export const ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_PROCESSING = 'ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_PROCESSING'
export const ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_SUCCESSED = 'ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_SUCCESSED'
export const ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_FAILED = 'ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_FAILED'

export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED'
export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_PROCESSING = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_PROCESSING'
export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_SUCCESSED = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_SUCCESSED'
export const ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_FAILED = 'ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_FAILED'

export const ADMINISTRATION_CREATE_ASSET_TYPE_REQUESTED = 'ADMINISTRATION_CREATE_ASSET_TYPE_REQUESTED'
export const ADMINISTRATION_CREATE_ASSET_TYPE_PROCESSING = 'ADMINISTRATION_CREATE_ASSET_TYPE_PROCESSING'
export const ADMINISTRATION_CREATE_ASSET_TYPE_SUCCESSED = 'ADMINISTRATION_CREATE_ASSET_TYPE_SUCCESSED'
export const ADMINISTRATION_CREATE_ASSET_TYPE_FAILED = 'ADMINISTRATION_CREATE_ASSET_TYPE_FAILED'
export const ADMINISTRATION_VULNERABILITY_TECHNICAL_DELETE_REQUESTED = 'ADMINISTRATION_VULNERABILITY_TECHNICAL_DELETE_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_DELETE_REQUESTED = 'ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_DELETE_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_DELETE_PROCESSING = 'ADMINISTRATION_VULNERABILITY_DELETE_PROCESSING'
export const ADMINISTRATION_VULNERABILITY_DELETE_SUCCESSED = 'ADMINISTRATION_VULNERABILITY_DELETE_SUCCESSED'
export const ADMINISTRATION_VULNERABILITY_DELETE_FAILED = 'ADMINISTRATION_VULNERABILITY_DELETE_FAILED'

export const ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED = 'ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_SEVERITY_REQUESTED = 'ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_SEVERITY_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_SEVERITY_PROCESSING = 'ADMINISTRATION_VULNERABILITY_SEVERITY_PROCESSING'
export const ADMINISTRATION_VULNERABILITY_SEVERITY_SUCCESSED = 'ADMINISTRATION_VULNERABILITY_SEVERITY_SUCCESSED'
export const ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED = 'ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED'

export const ADMINISTRATION_VULNERABILITY_TECHNICAL_CREATE_REQUESTED = 'ADMINISTRATION_VULNERABILITY_TECHNICAL_CREATE_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_CREATE_REQUESTED = 'ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_CREATE_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_CREATE_PROCESSING = 'ADMINISTRATION_VULNERABILITY_CREATE_PROCESSING'
export const ADMINISTRATION_VULNERABILITY_CREATE_SUCCESSED = 'ADMINISTRATION_VULNERABILITY_CREATE_SUCCESSED'
export const ADMINISTRATION_VULNERABILITY_CREATE_FAILED = 'ADMINISTRATION_VULNERABILITY_CREATE_FAILED'

export const ADMINISTRATION_VULNERABILITY_TECHNICAL_UPDATE_REQUESTED = 'ADMINISTRATION_VULNERABILITY_TECHNICAL_UPDATE_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_TECHNICAL_NON_UPDATE_REQUESTED = 'ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_UPDATE_REQUESTED'

export const ADMINISTRATION_VULNERABILITY_UPDATE_PROCESSING = 'ADMINISTRATION_VULNERABILITY_UPDATE_PROCESSING'
export const ADMINISTRATION_VULNERABILITY_UPDATE_SUCCESSED = 'ADMINISTRATION_VULNERABILITY_UPDATE_SUCCESSED'
export const ADMINISTRATION_VULNERABILITY_UPDATE_FAILED = 'ADMINISTRATION_VULNERABILITY_UPDATE_FAILED'

export const ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED = 'ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED'
export const ADMINISTRATION_VULNERABILITY_THREAT_PROCESSING = 'ADMINISTRATION_VULNERABILITY_THREAT_PROCESSING'
export const ADMINISTRATION_VULNERABILITY_THREAT_SUCCESSED = 'ADMINISTRATION_VULNERABILITY_THREAT_SUCCESSED'
export const ADMINISTRATION_VULNERABILITY_THREAT_FAILED = 'ADMINISTRATION_VULNERABILITY_THREAT_FAILED'

export const ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_LIST_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_LIST_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_LIST_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_LIST_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_LIST_FAILED = 'ADMINISTRATION_THREAT_INTEL_LIST_FAILED'

export const ADMINISTRATION_THREAT_INTEL_CATEGORY_CREATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_CATEGORY_CREATE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_CREATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_CREATE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_DISPOSITION_CREATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_DISPOSITION_CREATE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_CREATE_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_CREATE_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_CREATE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_CREATE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_CREATE_FAILED = 'ADMINISTRATION_THREAT_INTEL_CREATE_FAILED'

export const ADMINISTRATION_THREAT_INTEL_CATEGORY_UPDATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_CATEGORY_UPDATE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_UPDATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_UPDATE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_DISPOSITION_UPDATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_DISPOSITION_UPDATE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_UPDATE_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_UPDATE_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_UPDATE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_UPDATE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED = 'ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED'

export const ADMINISTRATION_THREAT_INTEL_CATEGORY_DELETE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_CATEGORY_DELETE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_DELETE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_DELETE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_DISPOSITION_DELETE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_DISPOSITION_DELETE_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_DELETE_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_DELETE_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_DELETE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_DELETE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_DELETE_FAILED = 'ADMINISTRATION_THREAT_INTEL_DELETE_FAILED'

export const ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_SEVERITY_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_SEVERITY_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_SEVERITY_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_SEVERITY_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_SEVERITY_FAILED = 'ADMINISTRATION_THREAT_INTEL_SEVERITY_FAILED'

export const ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_FAILED = 'ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_FAILED'

export const ADMINISTRATION_EDIT_ASSET_TYPE_REQUESTED = 'ADMINISTRATION_EDIT_ASSET_TYPE_REQUESTED'
export const ADMINISTRATION_EDIT_ASSET_TYPE_PROCESSING = 'ADMINISTRATION_EDIT_ASSET_TYPE_PROCESSING'
export const ADMINISTRATION_EDIT_ASSET_TYPE_SUCCESSED = 'ADMINISTRATION_EDIT_ASSET_TYPE_SUCCESSED'
export const ADMINISTRATION_EDIT_ASSET_TYPE_FAILED = 'ADMINISTRATION_EDIT_ASSET_TYPE_FAILED'

export const ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED = 'ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED'
export const ADMINISTRATION_GET_DISPOSITION_LIST_PROCESSING = 'ADMINISTRATION_GET_DISPOSITION_LIST_PROCESSING'
export const ADMINISTRATION_GET_DISPOSITION_LIST_SUCCESSED = 'ADMINISTRATION_GET_DISPOSITION_LIST_SUCCESSED'
export const ADMINISTRATION_GET_DISPOSITION_LIST_FAILED = 'ADMINISTRATION_GET_DISPOSITION_LIST_FAILED'

export const INCIDENT_DISPOSITION_KEY_REQUESTED = 'INCIDENT_DISPOSITION_KEY_REQUESTED'
export const INCIDENT_DISPOSITION_KEY_PROCESSING = 'INCIDENT_DISPOSITION_KEY_PROCESSING'
export const INCIDENT_DISPOSITION_KEY_SUCCESSED = 'INCIDENT_DISPOSITION_KEY_SUCCESSED'
export const INCIDENT_DISPOSITION_KEY_FAILED = 'INCIDENT_DISPOSITION_KEY_FAILED'

export const INCIDENT_DISPOSITION_FIELD_REQUESTED = 'INCIDENT_DISPOSITION_FIELD_REQUESTED'
export const INCIDENT_DISPOSITION_FIELD_PROCESSING = 'INCIDENT_DISPOSITION_FIELD_PROCESSING'
export const INCIDENT_DISPOSITION_FIELD_SUCCESSED = 'INCIDENT_DISPOSITION_FIELD_SUCCESSED'
export const INCIDENT_DISPOSITION_FIELD_FAILED = 'INCIDENT_DISPOSITION_FIELD_FAILED'

export const INCIDENT_APP_CONFIG_REQUESTED = 'INCIDENT_APP_CONFIG_REQUESTED'
export const INCIDENT_APP_CONFIG_SUCCESSED = 'INCIDENT_APP_CONFIG_SUCCESSED'
export const INCIDENT_APP_CONFIG_PROCESSING = 'INCIDENT_APP_CONFIG_PROCESSING'
export const INCIDENT_APP_CONFIG_FAILED = 'INCIDENT_APP_CONFIG_FAILED'
export const INCIDENT_APP_CONFIG_RESET = 'INCIDENT_APP_CONFIG_RESET';

export const BULK_UPDATE_REQUESTED = 'BULK_UPDATE_REQUESTED'
export const BULK_UPDATE_SUCCESSED = 'BULK_UPDATE_SUCCESSED'
export const BULK_UPDATE_FAILED = 'BULK_UPDATE_FAILED'

export const DELETE_INCIDENT_REQUESTED = 'DELETE_INCIDENT_REQUESTED'
export const DELETE_INCIDENT_PROCESSING = 'DELETE_INCIDENT_PROCESSING'
export const DELETE_INCIDENT_SUCCESSED = 'DELETE_INCIDENT_SUCCESSED'
export const DELETE_INCIDENT_FAILED = 'DELETE_INCIDENT_FAILED'

export const INGESTION_TYPE_REQUESTED = 'INGESTION_TYPE_REQUESTED'
export const INGESTION_TYPE_SUCCESSED = 'INGESTION_TYPE_SUCCESSED'
export const INGESTION_TYPE_FAILED = 'INGESTION_TYPE_FAILED'

export const ENABLE_ADVISORY_REQUESTED = 'ENABLE_ADVISORY_REQUESTED'
export const ENABLE_ADVISORY_SUCCESSED = 'ENABLE_ADVISORY_SUCCESSED'
export const ENABLE_ADVISORY_FAILED = 'ENABLE_ADVISORY_FAILED'

export const INGESTION_WIDGET_REQUESTED = 'INGESTION_WIDGET_REQUESTED'
export const INGESTION_WIDGET_SUCCESSED = 'INGESTION_WIDGET_SUCCESSED'
export const INGESTION_WIDGET_FAILED = 'INGESTION_WIDGET_FAILED'

export const INGESTION_FREQUENCY_REQUESTED = 'INGESTION_FREQUENCY_REQUESTED'
export const INGESTION_FREQUENCY_SUCCESSED = 'INGESTION_FREQUENCY_SUCCESSED'
export const INGESTION_FREQUENCY_FAILED = 'INGESTION_FREQUENCY_FAILED'

export const INGESTION_APPLICATION_REQUESTED = 'INGESTION_APPLICATION_REQUESTED'
export const INGESTION_APPLICATION_SUCCESSED = 'INGESTION_APPLICATION_SUCCESSED'
export const INGESTION_APPLICATION_FAILED = 'INGESTION_APPLICATION_FAILED'

export const INGESTION_USERS_REQUESTED = 'INGESTION_USERS_REQUESTED'
export const INGESTION_USERS_SUCCESSED = 'INGESTION_USERS_SUCCESSED'
export const INGESTION_USERS_FAILED = 'INGESTION_USERS_FAILED'

export const INGESTION_ACTION_REQUESTED = 'INGESTION_ACTION_REQUESTED'
export const INGESTION_ACTION_SUCCESSED = 'INGESTION_ACTION_SUCCESSED'
export const INGESTION_ACTION_FAILED = 'INGESTION_ACTION_FAILED'

export const PLAYBOOK_CATEOGRY_LIST_REQUESTED = 'PLAYBOOK_CATEOGRY_LIST_REQUESTED'
export const PLAYBOOK_CATEOGRY_LIST_SUCCESSED = 'PLAYBOOK_CATEOGRY_LIST_SUCCESSED'
export const PLAYBOOK_CATEOGRY_LIST_FAILED = 'PLAYBOOK_CATEOGRY_LIST_FAILED'


export const PLAYBOOK_QUEUE_LOG_REQUESTED = 'PLAYBOOK_QUEUE_LOG_REQUESTED'
export const PLAYBOOK_QUEUE_LOG_SUCCESSED = 'PLAYBOOK_QUEUE_LOG_SUCCESSED'
export const PLAYBOOK_QUEUE_LOG_FAILED = 'PLAYBOOK_QUEUE_LOG_FAILED'


export const PLAYBOOK_IMPORT_REQUESTED = 'PLAYBOOK_IMPORT_REQUESTED'
export const PLAYBOOK_IMPORT_SUCCESSED = 'PLAYBOOK_IMPORT_SUCCESSED'
export const PLAYBOOK_IMPORT_FAILED = 'PLAYBOOK_IMPORT_FAILED'


export const PLAYBOOK_DOWNLOAD_REQUESTED = 'PLAYBOOK_DOWNLOAD_REQUESTED'
export const PLAYBOOK_DOWNLOAD_SUCCESSED = 'PLAYBOOK_DOWNLOAD_SUCCESSED'
export const PLAYBOOK_DOWNLOAD_FAILED = 'PLAYBOOK_DOWNLOAD_FAILED'



export const PLAYBOOK_SUB_CATEOGRY_LIST_REQUESTED = 'PLAYBOOK_SUB_CATEOGRY_LIST_REQUESTED'
export const PLAYBOOK_SUB_CATEOGRY_LIST_SUCCESSED = 'PLAYBOOK_SUB_CATEOGRY_LIST_SUCCESSED'
export const PLAYBOOK_SUB_CATEOGRY_LIST_FAILED = 'PLAYBOOK_SUB_CATEOGRY_LIST_FAILED'

export const PLAYBOOK_DISPOSITION_LIST_REQUESTED = 'PLAYBOOK_DISPOSITION_LIST_REQUESTED'
export const PLAYBOOK_DISPOSITION_LIST_SUCCESSED = 'PLAYBOOK_DISPOSITION_LIST_SUCCESSED'
export const PLAYBOOK_DISPOSITION_LIST_FAILED = 'PLAYBOOK_DISPOSITION_LIST_FAILED'

export const PLAYBOOK_SUB_DISPOSITION_LIST_REQUESTED = 'PLAYBOOK_SUB_DISPOSITION_LIST_REQUESTED'
export const PLAYBOOK_SUB_DISPOSITION_LIST_SUCCESSED = 'PLAYBOOK_SUB_DISPOSITION_LIST_SUCCESSED'
export const PLAYBOOK_SUB_DISPOSITION_LIST_FAILED = 'PLAYBOOK_SUB_DISPOSITION_LIST_FAILED'

export const PLAYBOOK_LOCATION_LIST_REQUESTED = 'PLAYBOOK_LOCATION_LIST_REQUESTED'
export const PLAYBOOK_LOCATION_LIST_SUCCESSED = 'PLAYBOOK_LOCATION_LIST_SUCCESSED'
export const PLAYBOOK_LOCATION_LIST_FAILED = 'PLAYBOOK_LOCATION_LIST_FAILED'

export const PLAYBOOK_RISK_RATING_LIST_REQUESTED = 'PLAYBOOK_RISK_RATING_LIST_REQUESTED'
export const PLAYBOOK_RISK_RATING_LIST_SUCCESSED = 'PLAYBOOK_RISK_RATING_LIST_SUCCESSED'
export const PLAYBOOK_RISK_RATING_LIST_FAILED = 'PLAYBOOK_RISK_RATING_LIST_FAILED'

export const BULK_UPDATE_INCIDENT_REQUESTED = 'BULK_UPDATE_INCIDENT_REQUESTED'
export const BULK_UPDATE_INCIDENT_PROCESSING = 'BULK_UPDATE_INCIDENT_PROCESSING'
export const BULK_UPDATE_INCIDENT_SUCCESSED = 'BULK_UPDATE_INCIDENT_SUCCESSED'
export const BULK_UPDATE_INCIDENT_FAILED = 'BULK_UPDATE_INCIDENT_FAILED'
export const ACTIONS_TYPE_REQUESTED = 'ACTIONS_TYPE_REQUESTED'
export const ACTIONS_TYPE_SUCCESSED = 'ACTIONS_TYPE_SUCCESSED'
export const ACTIONS_TYPE_FAILED = 'ACTIONS_TYPE_FAILED'

export const ACTIONS_SCRIPT_TYPE_REQUESTED = 'ACTIONS_SCRIPT_TYPE_REQUESTED'
export const ACTIONS_SCRIPT_TYPE_SUCCESSED = 'ACTIONS_SCRIPT_TYPE_SUCCESSED'
export const ACTIONS_SCRIPT_TYPE_FAILED = 'ACTIONS_SCRIPT_TYPE_FAILED'

export const ACTIONS_IO_TYPE_REQUESTED = 'ACTIONS_IO_TYPE_REQUESTED'
export const ACTIONS_IO_TYPE_SUCCESSED = 'ACTIONS_IO_TYPE_SUCCESSED'
export const ACTIONS_IO_TYPE_FAILED = 'ACTIONS_IO_TYPE_FAILED'

export const ACTIONS_APPLICATION_REQUESTED = 'ACTIONS_APPLICATION_REQUESTED'
export const ACTIONS_APPLICATION_SUCCESSED = 'ACTIONS_APPLICATION_SUCCESSED'
export const ACTIONS_APPLICATION_FAILED = 'ACTIONS_APPLICATION_FAILED'

export const GET_INGESTION_MULTI_DATA_REQUESTED = 'GET_INGESTION_MULTI_DATA_REQUESTED'
export const GET_INGESTION_MULTI_DATA_SUCCESSED = 'GET_INGESTION_MULTI_DATA_SUCCESSED'
export const GET_INGESTION_MULTI_DATA_FAILED = 'GET_INGESTION_MULTI_DATA_FAILED'

export const GET_INGESTION_MAPPING_REQUESTED = 'GET_INGESTION_MAPPING_REQUESTED'
export const GET_INGESTION_MAPPING_SUCCESSED = 'GET_INGESTION_MAPPING_SUCCESSED'
export const GET_INGESTION_MAPPING_FAILED = 'GET_INGESTION_MAPPING_FAILED'

export const ADD_INGESTION_MULTI_DATA_REQUESTED = 'ADD_INGESTION_MULTI_DATA_REQUESTED'
export const ADD_INGESTION_MULTI_DATA_SUCCESSED = 'ADD_INGESTION_MULTI_DATA_SUCCESSED'
export const ADD_INGESTION_MULTI_DATA_FAILED = 'ADD_INGESTION_MULTI_DATA_FAILED'

export const ADMINISTRATION_LOGS_LIST_SUCCESSED = 'ADMINISTRATION_LOGS_LIST_SUCCESSED'
export const ADMINISTRATION_LOGS_LIST_FAILED = 'ADMINISTRATION_LOGS_LIST_FAILED'

export const APP_LOGS_NOTIFICATION_LIST_REQUESTED = 'APP_LOGS_NOTIFICATION_LIST_REQUESTED';
export const APP_LOGS_NOTIFICATION_LIST_SUCCESSED = 'APP_LOGS_NOTIFICATION_LIST_SUCCESSED';
export const APP_LOGS_NOTIFICATION_LIST_FAILED = 'APP_LOGS_NOTIFICATION_LIST_FAILED';

export const ADMINISTRATION_LOGS_APPS_LIST_REQUESTED = 'ADMINISTRATION_LOGS_APPS_LIST_REQUESTED'

export const ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED = 'ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED'

export const ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED = 'ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED'

export const ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED = 'ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED'

export const ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED = 'ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED'

export const ADMINISTRATION_PRODUCTS_LIST_SUCCESSED = 'ADMINISTRATION_PRODUCTS_LIST_SUCCESSED'
export const ADMINISTRATION_PRODUCTS_LIST_FAILED = 'ADMINISTRATION_PRODUCTS_LIST_FAILED'

export const ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED = 'ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED'

export const ADMINISTRATION_PRODUCT_FAILED_REGISTER_DELETE_REQUESTED = 'ADMINISTRATION_PRODUCT_FAILED_REGISTER_DELETE_REQUESTED'


export const ADMINISTRATION_ACCESS_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_LIST_FAILED = 'ADMINISTRATION_ACCESS_LIST_FAILED'


export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED'

export const ADMINISTRATION_ACCESS_USERS_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_USERS_DELETE_REQUESTED'

export const ADMINISTRATION_ACCESS_USERS_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_USERS_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_USERS_UPDATE_SUCCESS = 'ADMINISTRATION_ACCESS_USERS_UPDATE_SUCCESS'
export const ADMINISTRATION_ACCESS_USERS_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_USERS_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_REQUESTED = 'ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_REQUESTED'
export const ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_SUCCESS = 'ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_SUCCESS'
export const ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_FAILED = 'ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_FAILED'

export const ADMINISTRATION_ACCESS_USER_INVITE_REQUESTED = 'ADMINISTRATION_ACCESS_USER_INVITE_REQUESTED'
export const ADMINISTRATION_ACCESS_USER_INVITE_SUCCESSED = 'ADMINISTRATION_ACCESS_USER_INVITE_SUCCESSED'
export const ADMINISTRATION_ACCESS_USER_INVITE_FAILED = 'ADMINISTRATION_ACCESS_USER_INVITE_FAILED'

export const ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED = 'ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED'
export const ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_SUCCESSED = 'ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_SUCCESSED'
export const ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_FAILED = 'ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_FAILED'

export const ADMINISTRATION_ACCESS_PERMISSION_REQUESTED = 'ADMINISTRATION_ACCESS_PERMISSION_REQUESTED'
export const ADMINISTRATION_ACCESS_PERMISSION_SUCCESSED = 'ADMINISTRATION_ACCESS_PERMISSION_SUCCESSED'
export const ADMINISTRATION_ACCESS_PERMISSION_FAILED = 'ADMINISTRATION_ACCESS_PERMISSION_FAILED'

export const ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED = 'ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED'
export const ADMINISTRATION_ACCESS_DASHBOARDS_SUCCESSED = 'ADMINISTRATION_ACCESS_DASHBOARDS_SUCCESSED'
export const ADMINISTRATION_ACCESS_DASHBOARDS_FAILED = 'ADMINISTRATION_ACCESS_DASHBOARDS_FAILED'

export const ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED = 'ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED'
export const ADMINISTRATION_ACCESS_LANDING_PAGE_SUCCESSED = 'ADMINISTRATION_ACCESS_LANDING_PAGE_SUCCESSED'
export const ADMINISTRATION_ACCESS_LANDING_PAGE_FAILED = 'ADMINISTRATION_ACCESS_LANDING_PAGE_FAILED'

export const ADMINISTRATION_ACCESS_GROUP_REQUESTED = 'ADMINISTRATION_ACCESS_GROUP_REQUESTED'
export const ADMINISTRATION_ACCESS_GROUP_SUCCESSED = 'ADMINISTRATION_ACCESS_GROUP_SUCCESSED'
export const ADMINISTRATION_ACCESS_GROUP_FAILED = 'ADMINISTRATION_ACCESS_GROUP_FAILED'

export const ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED = 'ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED'
export const ADMINISTRATION_ACCESS_SESSION__PASSWORD_SUCCESSED = 'ADMINISTRATION_ACCESS_SESSION__PASSWORD_SUCCESSED'
export const ADMINISTRATION_ACCESS_SESSION__PASSWORD_FAILED = 'ADMINISTRATION_ACCESS_SESSION__PASSWORD_FAILED'

export const ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_SUCCESSED = 'ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUPS_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_GROUPS_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUPS_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_GROUPS_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLES_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_ROLES_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLES_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_ROLES_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_FAILED = 'ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_FAILED'


export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED'


export const ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_FAILED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_FAILED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_FAILED'

export const ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_REQUESTED = 'ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_REQUESTED'
export const ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED = 'ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED'
export const ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED_Old = 'ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED_Old'
export const ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_SUCCESSED = 'ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_SUCCESSED'
export const ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_FAILED = 'ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_FAILED'


export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_FAILED'


export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_FAILED'


export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_FAILED'



export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_FAILED'

export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_REQUESTED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_REQUESTED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_SUCCESSED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_SUCCESSED'
export const ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_FAILED = 'ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_FAILED'


export const ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED = 'ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED'
export const ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_SUCCESSED = 'ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_SUCCESSED'
export const ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_FAILED = 'ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_FAILED'

export const ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_REQUESTED = 'ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_REQUESTED'
export const ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_SUCCESSED = 'ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_SUCCESSED'
export const ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_FAILED = 'ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_FAILED'

export const ADMINISTRATION_PRODUCT_LICENSE_CREATE_REQUESTED = 'ADMINISTRATION_PRODUCT_LICENSE_CREATE_REQUESTED'
export const ADMINISTRATION_PRODUCT_LICENSE_CREATE_SUCCESSED = 'ADMINISTRATION_PRODUCT_LICENSE_CREATE_SUCCESSED'
export const ADMINISTRATION_PRODUCT_LICENSE_CREATE_FAILED = 'ADMINISTRATION_PRODUCT_LICENSE_CREATE_FAILED'


export const ADMINISTRATION_PRODUCT_LICENSE_UPDATE_REQUESTED = 'ADMINISTRATION_PRODUCT_LICENSE_UPDATE_REQUESTED'
export const ADMINISTRATION_PRODUCT_LICENSE_UPDATE_SUCCESSED = 'ADMINISTRATION_PRODUCT_LICENSE_UPDATE_SUCCESSED'
export const ADMINISTRATION_PRODUCT_LICENSE_UPDATE_FAILED = 'ADMINISTRATION_PRODUCT_LICENSE_UPDATE_FAILED'

export const ADMINISTRATION_PRODUCT_LICENSE_DELETE_REQUESTED = 'ADMINISTRATION_PRODUCT_LICENSE_DELETE_REQUESTED'
export const ADMINISTRATION_PRODUCT_LICENSE_DELETE_SUCCESSED = 'ADMINISTRATION_PRODUCT_LICENSE_DELETE_SUCCESSED'
export const ADMINISTRATION_PRODUCT_LICENSE_DELETE_FAILED = 'ADMINISTRATION_PRODUCT_LICENSE_DELETE_FAILED'

export const ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_REQUESTED = 'ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_REQUESTED'
export const ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_SUCCESSED = 'ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_SUCCESSED'
export const ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_FAILED = 'ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_FAILED'

export const ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED = 'ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED'
export const ADMINISTRATION_PRODUCT_LICENSE_SOURCES_SUCCESSED = 'ADMINISTRATION_PRODUCT_LICENSE_SOURCES_SUCCESSED'
export const ADMINISTRATION_PRODUCT_LICENSE_SOURCES_FAILED = 'ADMINISTRATION_PRODUCT_LICENSE_SOURCES_FAILED'

export const ADMINISTRATION_PRODUCT_SETTINGS_PATCH_REQUESTED = 'ADMINISTRATION_PRODUCT_SETTINGS_PATCH_REQUESTED'
export const ADMINISTRATION_PRODUCT_SETTINGS_PATCH_SUCCESSED = 'ADMINISTRATION_PRODUCT_SETTINGS_PATCH_SUCCESSED'
export const ADMINISTRATION_PRODUCT_SETTINGS_PATCH_FAILED = 'ADMINISTRATION_PRODUCT_SETTINGS_PATCH_FAILED'


export const APP_CONFIG_REQUESTED = 'APP_CONFIG_REQUESTED'
export const APP_CONFIG_SUCCESSED = 'APP_CONFIG_SUCCESSED'
export const APP_CONFIG_FAILED = 'APP_CONFIG_FAILED'

export const APP_CONFIG_ADD_REQUESTED = 'APP_CONFIG_ADD_REQUESTED'
export const APP_CONFIG_ADD_SUCCESSED = 'APP_CONFIG_ADD_SUCCESSED'
export const APP_CONFIG_ADD_FAILED = 'APP_CONFIG_ADD_FAILED'

export const ADMINISTRATION_NOTIFICATIONS_LIST_REQUESTED = 'ADMINISTRATION_NOTIFICATIONS_LIST_REQUESTED'
export const ADMINISTRATION_NOTIFICATIONS_LIST_SUCCESSED = 'ADMINISTRATION_NOTIFICATIONS_LIST_SUCCESSED'
export const ADMINISTRATION_NOTIFICATIONS_LIST_FAILED = 'ADMINISTRATION_NOTIFICATIONS_LIST_FAILED'

export const ADMINISTRATION_NOTIFICATIONS_UPDATE_REQUESTED = 'ADMINISTRATION_NOTIFICATIONS_UPDATE_REQUESTED'
export const ADMINISTRATION_NOTIFICATIONS_UPDATE_SUCCESSED = 'ADMINISTRATION_NOTIFICATIONS_UPDATE_SUCCESSED'
export const ADMINISTRATION_NOTIFICATIONS_UPDATE_FAILED = 'ADMINISTRATION_NOTIFICATIONS_UPDATE_FAILED'

export const ADMINISTRATION_FIELD_SETTING_LIST_REQUESTED = 'ADMINISTRATION_FIELD_SETTING_LIST_REQUESTED'
export const ADMINISTRATION_FIELD_SETTING_LIST_SUCCESSED = 'ADMINISTRATION_FIELD_SETTING_LIST_SUCCESSED'
export const ADMINISTRATION_FIELD_SETTING_LIST_FAILED = 'ADMINISTRATION_FIELD_SETTING_LIST_FAILED'

export const ADMINISTRATION_FIELD_SETTING_UPDATE_REQUESTED = 'ADMINISTRATION_FIELD_SETTING_UPDATE_REQUESTED'
export const ADMINISTRATION_FIELD_SETTING_UPDATE_SUCCESSED = 'ADMINISTRATION_FIELD_SETTING_UPDATE_SUCCESSED'
export const ADMINISTRATION_FIELD_SETTING_UPDATE_FAILED = 'ADMINISTRATION_FIELD_SETTING_UPDATE_FAILED'

export const ADMINISTRATION_GET_TABS_HEADING_REQUESTED = 'ADMINISTRATION_GET_TABS_HEADING_REQUESTED'
export const ADMINISTRATION_GET_TABS_HEADING_SUCCESSED = 'ADMINISTRATION_GET_TABS_HEADING_SUCCESSED'
export const ADMINISTRATION_GET_TABS_HEADING_FAILED = 'ADMINISTRATION_GET_TABS_HEADING_FAILED'

export const ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED = 'ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED'
export const ADMINISTRATION_RISK_MATRIX_LIST_PROCESSING = 'ADMINISTRATION_RISK_MATRIX_LIST_PROCESSING'
export const ADMINISTRATION_RISK_MATRIX_LIST_SUCCESSED = 'ADMINISTRATION_RISK_MATRIX_LIST_SUCCESSED'
export const ADMINISTRATION_RISK_MATRIX_LIST_FAILED = 'ADMINISTRATION_RISK_MATRIX_LIST_FAILED'



export const ADMINISTRATION_CREATE_RISK_MATRIX_REQUESTED = 'ADMINISTRATION_CREATE_RISK_MATRIX_REQUESTED'
export const ADMINISTRATION_CREATE_RISK_MATRIX_PROCESSING = 'ADMINISTRATION_CREATE_RISK_MATRIX_PROCESSING'
export const ADMINISTRATION_CREATE_RISK_MATRIX_SUCCESSED = 'ADMINISTRATION_CREATE_RISK_MATRIX_SUCCESSED'
export const ADMINISTRATION_CREATE_RISK_MATRIX_FAILED = 'ADMINISTRATION_CREATE_RISK_MATRIX_FAILED'


export const ADMINISTRATION_RESET_RISK_MATRIX_REQUESTED = 'ADMINISTRATION_RESET_RISK_MATRIX_REQUESTED'
export const ADMINISTRATION_RESET_RISK_MATRIX_PROCESSING = 'ADMINISTRATION_RESET_RISK_MATRIX_PROCESSING'
export const ADMINISTRATION_RESET_RISK_MATRIX_SUCCESSED = 'ADMINISTRATION_RESET_RISK_MATRIX_SUCCESSED'
export const ADMINISTRATION_RESET_RISK_MATRIX_FAILED = 'ADMINISTRATION_RESET_RISK_MATRIX_FAILED'


export const ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_REQUESTED = 'ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_REQUESTED'
export const ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_PROCESSING = 'ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_PROCESSING'
export const ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_SUCCESSED = 'ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_SUCCESSED'
export const ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_FAILED = 'ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_FAILED'

export const ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_SUCCESSED = 'ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_SUCCESSED'
export const ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_FAILED = 'ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_FAILED'

export const ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_SUCCESSED = 'ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_SUCCESSED'
export const ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_FAILED = 'ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_FAILED'

export const ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_SUCCESSED = 'ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_SUCCESSED'
export const ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_FAILED = 'ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_FAILED'






export const INCIDENT_ADD_EVIDENCE_REQUESTED = 'INCIDENT_ADD_EVIDENCE_REQUESTED'
export const INCIDENT_ADD_EVIDENCE_PROCESSING = 'INCIDENT_ADD_EVIDENCE_PROCESSING'
export const INCIDENT_ADD_EVIDENCE_SUCCESSED = 'INCIDENT_ADD_EVIDENCE_SUCCESSED'
export const INCIDENT_ADD_EVIDENCE_FAILED = 'INCIDENT_ADD_EVIDENCE_FAILED'
export const DASHBOARD_GET_LIST_REQUESTED = 'DASHBOARD_GET_LIST_REQUESTED'
export const DASHBOARD_GET_LIST_SUCCESSED = 'DASHBOARD_GET_LIST_SUCCESSED'
export const DASHBOARD_GET_LIST_FAILED = 'DASHBOARD_GET_LIST_FAILED'

export const DASHBOARD_EDIT_REQUESTED = 'DASHBOARD_EDIT_REQUESTED'
export const DASHBOARD_EDIT_SUCCESSED = 'DASHBOARD_EDIT_SUCCESSED'
export const DASHBOARD_EDIT_FAILED = 'DASHBOARD_EDIT_FAILED'

export const DASHBOARD_CREATE_REQUESTED = 'DASHBOARD_CREATE_REQUESTED'
export const DASHBOARD_RESET_REQUESTED = 'DASHBOARD_RESET_REQUESTED';
export const DASHBOARD_CREATE_SUCCESSED = 'DASHBOARD_CREATE_SUCCESSED'
export const DASHBOARD_CREATE_FAILED = 'DASHBOARD_CREATE_FAILED'

export const DASHBOARD_UPDATE_REQUESTED = 'DASHBOARD_UPDATE_REQUESTED'
export const DASHBOARD_UPDATE_SUCCESSED = 'DASHBOARD_UPDATE_SUCCESSED'
export const DASHBOARD_UPDATE_FAILED = 'DASHBOARD_UPDATE_FAILED'

export const DASHBOARD_GET_SINGLE_REQUESTED = 'DASHBOARD_GET_SINGLE_REQUESTED'
export const DASHBOARD_GET_SINGLE_SUCCESSED = 'DASHBOARD_GET_SINGLE_SUCCESSED'
export const DASHBOARD_GET_SINGLE_FAILED = 'DASHBOARD_GET_SINGLE_FAILED'

export const DASHBOARD_DELETE_REQUESTED = 'DASHBOARD_DELETE_REQUESTED'
export const DASHBOARD_DELETE_SUCCESSED = 'DASHBOARD_DELETE_SUCCESSED'
export const DASHBOARD_DELETE_FAILED = 'DASHBOARD_DELETE_FAILED'

export const DASHBOARD_REMOVE_REQUESTED = 'DASHBOARD_REMOVE_REQUESTED'
export const DASHBOARD_REMOVE_SUCCESSED = 'DASHBOARD_REMOVE_SUCCESSED'
export const DASHBOARD_REMOVE_FAILED = 'DASHBOARD_REMOVE_FAILED'

export const DASHBOARD_LIST_REQUESTED = 'DASHBOARD_LIST_REQUESTED'
export const DASHBOARD_LIST_SUCCESSED = 'DASHBOARD_LIST_SUCCESSED'
export const DASHBOARD_LIST_FAILED = 'DASHBOARD_LIST_FAILED'

export const DASHBOARD_GET_WIDGET_DATA_REQUESTED = 'DASHBOARD_GET_WIDGET_DATA_REQUESTED'
export const DASHBOARD_GET_WIDGET_DATA_SUCCESSED = 'DASHBOARD_GET_WIDGET_DATA_SUCCESSED'
export const DASHBOARD_GET_WIDGET_DATA_FAILED = 'DASHBOARD_GET_WIDGET_DATA_FAILED'

export const DASHBOARD_SET_DEFAULT_REQUESTED = 'DASHBOARD_SET_DEFAULT_REQUESTED'
export const DASHBOARD_SET_DEFAULT_SUCCESSED = 'DASHBOARD_SET_DEFAULT_SUCCESSED'
export const DASHBOARD_SET_DEFAULT_FAILED = 'DASHBOARD_SET_DEFAULT_FAILED'

export const SET_DEFAULT_DASHBOARD_VALUE = 'SET_DEFAULT_DASHBOARD_VALUE'


export const DASHBOARD_ADD_REQUESTED = 'DASHBOARD_ADD_REQUESTED'
export const DASHBOARD_ADD_SUCCESSED = 'DASHBOARD_ADD_SUCCESSED'
export const DASHBOARD_ADD_FAILED = 'DASHBOARD_ADD_FAILED'

export const DAHSBOARD_GET_WIDGET_LIST_REQUESTED = "DAHSBOARD_GET_WIDGET_LIST_REQUESTED"
export const DAHSBOARD_GET_WIDGET_LIST_SUCCESSED = "DAHSBOARD_GET_WIDGET_LIST_SUCCESSED"
export const DAHSBOARD_GET_WIDGET_LIST_FAILED = "DAHSBOARD_GET_WIDGET_LIST_FAILED"

export const DASHBOARD_WIDGET_GET_GROUPS_REQUESTED = "DASHBOARD_WIDGET_GET_GROUPS_REQUESTED"
export const DASHBOARD_WIDGET_GET_GROUPS_SUCCESSES = "DASHBOARD_WIDGET_GET_GROUPS_SUCCESSES"
export const DASHBOARD_WIDGET_GET_GROUPS_FAIELD = "DASHBOARD_WIDGET_GET_GROUPS_FAIELD"


export const MITRE_DASHBOARD_LIST_REQUESTED = 'MITRE_DASHBOARD_LIST_REQUESTED'
export const MITRE_DASHBOARD_LIST_SUCCESSED = 'MITRE_DASHBOARD_LIST_SUCCESSED'
export const MITRE_DASHBOARD_LIST_FAILED = 'MITRE_DASHBOARD_LIST_FAILED'

export const MITRE_WIDGETS_LIST_REQUESTED = 'MITRE_WIDGETS_LIST_REQUESTED'
export const MITRE_WIDGETS_LIST_SUCCESSED = 'MITRE_WIDGETS_LIST_SUCCESSED'
export const MITRE_WIDGETS_LIST_FAILED = 'MITRE_WIDGETS_LIST_FAILED'

export const MITRE_DETAIL_REQUESTED = 'MITRE_DETAIL_REQUESTED'
export const MITRE_DETAIL_SUCCESSED = 'MITRE_DETAIL_SUCCESSED'
export const MITRE_DETAIL_FAILED = 'MITRE_DETAIL_FAILED'

export const RESET_MITRE_DETAIL = 'RESET_MITRE_DETAIL'

export const DASHBOARD_SELECTED_WIDGET_LIST = "DASHBOARD_SELECTED_WIDGET_LIST"

export const RESET_DASHBOARD_SELECTED_WIDGET_LIST = "RESET_DASHBOARD_SELECTED_WIDGET_LIST"

export const DASHBOARD_DELETE_SELECTED_WIDGET = "DASHBOARD_DELETE_SELECTED_WIDGET"

export const DASHBOARD_SELECTED_WIDGET_LAYOUT = "DASHBOARD_SELECTED_WIDGET_LAYOUT"

export const DASHBOARD_REMOVED_WIDGET_ID = "DASHBOARD_REMOVED_WIDGET_ID"

export const DASHBOARD_ADDED_WIDGET_ID = "DASHBOARD_ADDED_WIDGET_ID"

export const DASHBOARD_ADD_WIDGET_REQUESTED = "DASHBOARD_ADD_WIDGET_REQUESTED"
export const DASHBOARD_ADD_WIDGET_SUCCESSED = "DASHBOARD_ADD_WIDGET_SUCCESSED"
export const DASHBOARD_ADD_WIDGET_FAILED = "DASHBOARD_ADD_WIDGET_FAILED"

export const DASHBOARD_DELETE_WIDGET_REQUESTED = "DASHBOARD_DELETE_WIDGET_REQUESTED"
export const DASHBOARD_DELETE_WIDGET_SUCCESSED = "DASHBOARD_DELETE_WIDGET_SUCCESSED"
export const DASHBOARD_DELETE_WIDGET_FAILED = "DASHBOARD_DELETE_WIDGET_FAILED"

export const DASHBOARD_UPDATE_WIDGET_REQUESTED = "DASHBOARD_UPDATE_WIDGET_REQUESTED"
export const DASHBOARD_UPDATE_WIDGET_SUCCESSED = "DASHBOARD_UPDATE_WIDGET_SUCCESSED"
export const DASHBOARD_UPDATE_WIDGET_FAILED = "DASHBOARD_UPDATE_WIDGET_FAILED"

export const GET_DASHBOARD_BY_ID_REQUESTED = 'GET_DASHBOARD_BY_ID_REQUESTED'

export const GET_DASHBOARD_WIDGETS_BY_ID_REQUESTED = 'GET_DASHBOARD_WIDGETS_BY_ID_REQUESTED'

export const GET_DASHBOARD_BY_ID_SUCCESSED = 'GET_DASHBOARD_BY_ID_SUCCESSED'
export const GET_DASHBOARD_BY_ID_FAILED = 'GET_DASHBOARD_BY_ID_FAILED'

export const GET_DASHBOARD_WIDGET_REQUESTED = 'GET_DASHBOARD_WIDGET_REQUESTED'

export const RESET_DASHBOARD_DROPDN_STATE = 'RESET_DASHBOARD_DROPDN_STATE'

export const DASHBOARD_LOAD_REQUEST = 'DASHBOARD_LOAD_REQUEST';

export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_FAILED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_FAILED'

export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED'

export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED'

export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED'
export const ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED = 'ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED'

export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_FAILED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_FAILED'

export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED'

export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED'

export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED'
export const ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED = 'ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED'


export const VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_GET_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_GET_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_GET_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_GET_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_GET_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_DELETE_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_DELETE_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_DELETE_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_DELETE_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_DELETE_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_DELETE_LIST_FAILED'

export const GET_VULNERABLITY_MANAGEMENT_BY_ID_REQUESTED = 'GET_VULNERABLITY_MANAGEMENT_BY_ID_REQUESTED'
export const GET_VULNERABLITY_MANAGEMENT_BY_ID_SUCCESSED = 'GET_VULNERABLITY_MANAGEMENT_BY_ID_SUCCESSED'
export const GET_VULNERABLITY_MANAGEMENT_BY_ID_FAILED = 'GET_VULNERABLITY_MANAGEMENT_BY_ID_FAILED'


export const VULNERABILITY_EXPORT_PDF_LIST_REQUESTED = 'VULNERABILITY_EXPORT_PDF_LIST_REQUESTED '
export const VULNERABILITY_EXPORT_PDF_LIST_PROCESSING = 'VULNERABILITY_EXPORT_PDF_LIST_PROCESSING '
export const VULNERABILITY_EXPORT_PDF_LIST_SUCCESSED = 'VULNERABILITY_EXPORT_PDF_LIST_SUCCESSED '
export const VULNERABILITY_EXPORT_PDF_LIST_FAILED = 'VULNERABILITY_EXPORT_PDF_LIST_FAILED '

export const VUNERABILITY_EXPORT_EXCEL_LIST_REQUESTED = 'VUNERABILITY_EXPORT_EXCEL_LIST_REQUESTED '
export const VUNERABILITY_EXPORT_EXCEL_LIST_PROCESSING = 'VUNERABILITY_EXPORT_EXCEL_LIST_PROCESSING '
export const VUNERABILITY_EXPORT_EXCEL_LIST_SUCCESSED = 'VUNERABILITY_EXPORT_EXCEL_LIST_SUCCESSED '
export const VUNERABILITY_EXPORT_EXCEL_LIST_FAILED = 'VUNERABILITY_EXPORT_EXCEL_LIST_FAILED '

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_GET_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_REQUESTED'
export const VULNERABILITY_MANAGEMENT_RESET_ASSESSMENT = 'VULNERABILITY_MANAGEMENT_RESET_ASSESSMENT'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORT_FAILED'

export const VULNERABILITY_MANAGEMENT_OPEN_CASES_REQUESTED = 'VULNERABILITY_MANAGEMENT_OPEN_CASES_REQUESTED'
export const VULNERABILITY_MANAGEMENT_OPEN_CASES_SUCCESSED = 'VULNERABILITY_MANAGEMENT_OPEN_CASES_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_OPEN_CASES_FAILED = 'VULNERABILITY_MANAGEMENT_OPEN_CASES_FAILED'

export const VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_CASE_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_CASE_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_CASE_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_CASE_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_CASE_LIST_FAILED'

export const VULNERABILITY_VULNERABILITY_ASSET_REQUESTED = 'VULNERABILITY_VULNERABILITY_ASSET_REQUESTED'
export const VULNERABILITY_VULNERABILITY_ASSET_SUCCESSED = 'VULNERABILITY_VULNERABILITY_ASSET_SUCCESSED'
export const VULNERABILITY_VULNERABILITY_ASSET_FAILED = 'VULNERABILITY_VULNERABILITY_ASSET_FAILED'

export const VULNERABILITY_SEARCH_BY_NAME_ASSET_REQUESTED = 'VULNERABILITY_SEARCH_BY_NAME_ASSET_REQUESTED'
export const VULNERABILITY_SEARCH_BY_NAME_ASSET_SUCCESSED = 'VULNERABILITY_SEARCH_BY_NAME_ASSET_SUCCESSED'
export const VULNERABILITY_SEARCH_BY_NAME_ASSET_FAILED = 'VULNERABILITY_SEARCH_BY_NAME_ASSET_FAILED'

export const UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_REQUESTED = 'UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_REQUESTED '
export const UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_PROCESSING = 'UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_PROCESSING '
export const UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_SUCCESSED = 'UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_SUCCESSED '
export const UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_FAILED = 'UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_FAILED '

export const UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_RESET = 'UPDATE_PARTIAL_VULNERABILITY_MANAGEMENT_RESET'

export const ADD_TASK_VULNERABILITY_MANAGEMENT_REQUESTED = 'ADD_TASK_VULNERABILITY_MANAGEMENT_REQUESTED'
export const ADD_TASK_VULNERABILITY_MANAGEMENT_PROCESSING = 'ADD_TASK_VULNERABILITY_MANAGEMENT_PROCESSING'
export const ADD_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED = 'ADD_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED'
export const ADD_TASK_VULNERABILITY_MANAGEMENT_FAILED = 'ADD_TASK_VULNERABILITY_MANAGEMENT_FAILED'

export const VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED = 'VIEW_TASK_VULNERABILITY_MANAGEMENT_REQUESTED'
export const VIEW_TASK_VULNERABILITY_MANAGEMENT_PROCESSING = 'VIEW_TASK_VULNERABILITY_MANAGEMENT_PROCESSING'
export const VIEW_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED = 'VIEW_TASK_VULNERABILITY_MANAGEMENT_SUCCESSED'
export const VIEW_TASK_VULNERABILITY_MANAGEMENT_FAILED = 'VIEW_TASK_VULNERABILITY_MANAGEMENT_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_UPDATE_REQUESTED = 'VULNERABILITY_MANAGEMENT_UPDATE_REQUESTED'
export const VULNERABILITY_MANAGEMENT_UPDATE_SUCCESSED = 'VULNERABILITY_MANAGEMENT_UPDATE_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_UPDATE_FAILED = 'VULNERABILITY_MANAGEMENT_UPDATE_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_GENERATE_REPORTS_FAILED'

export const VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED = 'VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_REQUESTED'
export const VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_SUCCESSED = 'VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_FAILED = 'VULNERABILITY_MANAGEMENT_GET_SINGLE_ASSESSMENT_FAILED'

export const VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_TEMPLATE_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_BULK_UPDATE_REQUESTED = 'VULNERABILITY_MANAGEMENT_BULK_UPDATE_REQUESTED'
export const VULNERABILITY_MANAGEMENT_BULK_UPDATE_SUCCESSED = 'VULNERABILITY_MANAGEMENT_BULK_UPDATE_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_BULK_UPDATE_FAILED = 'VULNERABILITY_MANAGEMENT_BULK_UPDATE_FAILED'

export const VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED = 'VULNERABILITY_MANAGEMENT_ATTACK_TYPE_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ATTACK_TYPE_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ATTACK_TYPE_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ATTACK_TYPE_FAILED = 'VULNERABILITY_MANAGEMENT_ATTACK_TYPE_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSET_TYPE_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSET_TYPE_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSET_TYPE_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSET_TYPE_FAILED = 'VULNERABILITY_MANAGEMENT_ASSET_TYPE_FAILED'

export const VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED = 'VULNERABILITY_MANAGEMENT_SEVERITY_REQUESTED'
export const VULNERABILITY_MANAGEMENT_SEVERITY_SUCCESSED = 'VULNERABILITY_MANAGEMENT_SEVERITY_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_SEVERITY_FAILED = 'VULNERABILITY_MANAGEMENT_SEVERITY_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_LOGS_FAILED'

export const CLEAR_ACTION_RUN_REQUESTED = 'CLEAR_ACTION_RUN_REQUESTED'

export const VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_REQUESTED = 'VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_REQUESTED'
export const VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_SUCCESSED = 'VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_FAILED = 'VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_CREATE_FAILED'

export const VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_REQUESTED = 'VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_REQUESTED'
export const VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_SUCCESSED = 'VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_FAILED = 'VULNERABILITY_MANAGEMENT_INTIAL_MANUAL_ASSESSMENT_UPDATE_FAILED'

export const VULNERABILITY_MANAGEMENT_ADD_ASSET_REQUESTED = 'VULNERABILITY_MANAGEMENT_ADD_ASSET_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ADD_ASSET_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ADD_ASSET_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ADD_ASSET_FAILED = 'VULNERABILITY_MANAGEMENT_ADD_ASSET_FAILED'

export const VULNERABILITY_MANAGEMENT_DELETE_ASSET_REQUESTED = 'VULNERABILITY_MANAGEMENT_DELETE_ASSET_REQUESTED'
export const VULNERABILITY_MANAGEMENT_DELETE_ASSET_SUCCESSED = 'VULNERABILITY_MANAGEMENT_DELETE_ASSET_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_DELETE_ASSET_FAILED = 'VULNERABILITY_MANAGEMENT_DELETE_ASSET_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSET_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSET_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSET_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSET_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSET_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSET_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_APPLICATION_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_FILTER_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_FAILED'
export const RESET_VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED = 'RESET_VULNERABILITY_MANAGEMENT_ASSESSMENT_ACTIONS_LIST_REQUESTED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_EXECUTE_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_MULTI_DELETE_LIST_FAILED'



export const VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_ADD_VULNERABILITY_FAILED'

export const VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_DELETE_VULNERABILITY_FAILED'

export const VULNERABILITY_MANAGEMENT_COMMENT_REQUESTED = 'VULNERABILITY_MANAGEMENT_COMMENT_REQUESTED'
export const VULNERABILITY_MANAGEMENT_COMMENT_SUCCESSED = 'VULNERABILITY_MANAGEMENT_COMMENT_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_COMMENT_FAILED = 'VULNERABILITY_MANAGEMENT_COMMENT_FAILED'

export const VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_REQUESTED = 'VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_REQUESTED'
export const VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_SUCCESSED = 'VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_FAILED = 'VULNERABILITY_MANAGEMENT_UPLOAD_NESSUS_FAILED'

export const VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED = 'VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_REQUESTED'
export const VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_SUCCESSED = 'VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_FAILED = 'VULNERABILITY_MANAGEMENT_TEMPLATE_TYPE_FAILED'

export const ASSESSMENT_TEMPLATE_CREATE_REQUESTED = 'ASSESSMENT_TEMPLATE_CREATE_REQUESTED'
export const ASSESSMENT_TEMPLATE_CREATE_SUCCESSED = 'ASSESSMENT_TEMPLATE_CREATE_SUCCESSED'
export const ASSESSMENT_TEMPLATE_CREATE_FAILED = 'ASSESSMENT_TEMPLATE_CREATE_FAILED'

export const VULNERABILITY_TASK_CATEGORIES_REQUESTED = 'VULNERABILITY_TASK_CATEGORIES_REQUESTED'
export const VULNERABILITY_TASK_CATEGORIES_SUCCESSED = 'VULNERABILITY_TASK_CATEGORIES_SUCCESSED'
export const VULNERABILITY_TASK_CATEGORIES_FAILED = 'VULNERABILITY_TASK_CATEGORIES_FAILED'

export const VULNERABILITY_TASK_DEPARTMENT_REQUESTED = 'VULNERABILITY_TASK_DEPARTMENT_REQUESTED'
export const VULNERABILITY_TASK_DEPARTMENT_SUCCESSED = 'VULNERABILITY_TASK_DEPARTMENT_SUCCESSED'
export const VULNERABILITY_TASK_DEPARTMENT_FAILED = 'VULNERABILITY_TASK_DEPARTMENT_FAILED'
// Delete Attact

export const VULNERABILITY_DELETE_ATTACHMENT_REQUESTED = 'VULNERABILITY_DELETE_ATTACHMENT_REQUESTED'
export const VULNERABILITY_DELETE_ATTACHMENT_SUCCESSED = 'VULNERABILITY_DELETE_ATTACHMENT_SUCCESSED'
export const VULNERABILITY_DELETE_ATTACHMENT_FAILED = 'VULNERABILITY_DELETE_ATTACHMENT_FAILED'

export const VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_NAME_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_NAME_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_NAME_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_NAME_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_NAME_LIST_FAILED'

export const VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED = 'VULNERABILITY_MANAGEMENT_CONTENT_REQUESTED'
export const VULNERABILITY_MANAGEMENT_CONTENT_SUCCESSED = 'VULNERABILITY_MANAGEMENT_CONTENT_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_CONTENT_FAILED = 'VULNERABILITY_MANAGEMENT_CONTENT_FAILED'

export const VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED = 'VULNERABILITY_MANAGEMENT_EDIT_SEARCH_REQUESTED'
export const VULNERABILITY_MANAGEMENT_EDIT_SEARCH_SUCCESSED = 'VULNERABILITY_MANAGEMENT_EDIT_SEARCH_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_EDIT_SEARCH_FAILED = 'V        ULNERABILITY_MANAGEMENT_EDIT_SEARCH_FAILED'

//imageload
export const VULNERABILITY_IMAGE_LOAD_REQUESTED = 'VULNERABILITY_IMAGE_LOAD_REQUESTED'
export const VULNERABILITY_IMAGE_LOAD_SUCCESSED = 'VULNERABILITY_IMAGE_LOAD_SUCCESSED'
export const VULNERABILITY_IMAGE_LOAD_FAILED = 'VULNERABILITY_IMAGE_LOAD_FAILED'

export const RISK_ASSESMENT_GET_LIST_REQUESTED = 'RISK_ASSESMENT_GET_LIST_REQUESTED'
export const RISK_ASSESMENT_GET_LIST_SUCCESSED = 'RISK_ASSESMENT_GET_LIST_SUCCESSED'
export const RISK_ASSESMENT_GET_LIST_FAILED = 'RISK_ASSESMENT_GET_LIST_FAILED'

export const RISK_ASSESMENT_DELETE_LIST_REQUESTED = 'RISK_ASSESMENT_DELETE_LIST_REQUESTED'
export const RISK_ASSESMENT_DELETE_LIST_SUCCESSED = 'RISK_ASSESMENT_DELETE_LIST_SUCCESSED'
export const RISK_ASSESMENT_DELETE_LIST_FAILED = 'RISK_ASSESMENT_DELETE_LIST_FAILED'

export const RISK_ASSESMENT_UPDATE_LIST_REQUESTED = 'RISK_ASSESMENT_UPDATE_LIST_REQUESTED'
export const RISK_ASSESMENT_UPDATE_LIST_SUCCESSED = 'RISK_ASSESMENT_UPDATE_LIST_SUCCESSED'
export const RISK_ASSESMENT_UPDATE_LIST_FAILED = 'RISK_ASSESMENT_UPDATE_LIST_FAILED'

export const RISK_ASSESMENT_LIST_MARK_REQUESTED = 'RISK_ASSESMENT_LIST_MARK_REQUESTED'
export const RISK_ASSESMENT_LIST_MARK_SUCCESSED = 'RISK_ASSESMENT_LIST_MARK_SUCCESSED'
export const RISK_ASSESMENT_LIST_MARK_FAILED = 'RISK_ASSESMENT_LIST_MARK_FAILED'

export const RISK_ASSESMENT_TEMPLATE_LIST_REQUESTED = 'RISK_ASSESMENT_TEMPLATE_LIST_REQUESTED'
export const RISK_ASSESMENT_TEMPLATE_LIST_SUCCESSED = 'RISK_ASSESMENT_TEMPLATE_LIST_SUCCESSED'
export const RISK_ASSESMENT_TEMPLATE_LIST_FAILED = 'RISK_ASSESMENT_TEMPLATE_LIST_FAILED'

export const RISK_ASSESMENT_GENERATE_REPORTS_REQUESTED = 'RISK_ASSESMENT_GENERATE_REPORTS_REQUESTED'
export const RISK_ASSESMENT_GENERATE_REPORTS_SUCCESSED = 'RISK_ASSESMENT_GENERATE_REPORTS_SUCCESSED'
export const RISK_ASSESMENT_GENERATE_REPORTS_FAILED = 'RISK_ASSESMENT_GENERATE_REPORTS_FAILED'

export const RISK_ASSESMENT_IMPORT_RISK_REQUESTED = 'RISK_ASSESMENT_IMPORT_RISK_REQUESTED'
export const RISK_ASSESMENT_IMPORT_RISK_SUCCESSED = 'RISK_ASSESMENT_IMPORT_RISK_SUCCESSED'
export const RISK_ASSESMENT_IMPORT_RISK_FAILED = 'RISK_ASSESMENT_IMPORT_RISK_FAILED'


export const RISK_ASSESMENT_EXPORT_EXEL_REQUESTED = 'RISK_ASSESMENT_GET_EXPORT_EXEL_REQUESTED'
export const RISK_ASSESMENT_EXPORT_EXEL_SUCCESSED = 'RISK_ASSESMENT_EXPORT_EXEL_SUCCESSED'
export const RISK_ASSESMENT_EXPORT_EXEL_FAILED = 'RISK_ASSESMENT_EXPORT_EXEL_FAILED'

export const RISK_ASSESMENT_EXPORT_PDF_REQUESTED = 'RISK_ASSESMENT_GET_EXPORT_PDF_REQUESTED'
export const RISK_ASSESMENT_EXPORT_PDF_SUCCESSED = 'RISK_ASSESMENT_EXPORT_PDF_SUCCESSED'
export const RISK_ASSESMENT_EXPORT_PDF_FAILED = 'RISK_ASSESMENT_EXPORT_PDF_FAILED'

export const RISK_ASSESMENT_INITIATOR_LIST_REQUESTED = 'RISK_ASSESMENT_GET_INITIATOR_LIST_REQUESTED'
export const RISK_ASSESMENT_INITIATOR_LIST_SUCCESSED = 'RISK_ASSESMENT_INITIATOR_LIST_SUCCESSED'
export const RISK_ASSESMENT_INITIATOR_LIST_FAILED = 'RISK_ASSESMENT_INITIATOR_LIST_FAILED'

export const RISK_ASSESMENT_SCOPE_LIST_REQUESTED = 'RISK_ASSESMENT_SCOPE_LIST_REQUESTED'
export const RISK_ASSESMENT_SCOPE_LIST_SUCCESSED = 'RISK_ASSESMENT_INITIATOR_LIST_SUCCESSED'
export const RISK_ASSESMENT_SCOPE_LIST_FAILED = 'RISK_ASSESMENT_SCOPE_LIST_FAILED'

export const RISK_ASSESMENT_RISK_GROUP_ASSEMENT_REQUESTED = 'RISK_ASSESMENT_RISK_GROUP_ASSEMENT_REQUESTED'
export const RISK_ASSESMENT_RISK_GROUP_ASSEMENT_SUCCESSED = 'RISK_ASSESMENT_RISK_GROUP_ASSEMENT_SUCCESSED'
export const RISK_ASSESMENT_RISK_GROUP_ASSEMENT_FAILED = 'RISK_ASSESMENT_RISK_GROUP_ASSEMENT_FAILED'

export const RISK_ASSESMENT_RISK_REGISTER_LIST_REQUESTED = 'RISK_ASSESMENT_RISK_REGISTER_LIST_REQUESTED'
export const RISK_ASSESMENT_RISK_REGISTER_LIST_SUCCESSED = 'RISK_ASSESMENT_RISK_REGISTER_LIST_SUCCESSED'
export const RISK_ASSESMENT_RISK_REGISTER_LIST_FAILED = 'RISK_ASSESMENT_RISK_REGISTER_LIST_FAILED'


export const GET_REPORT_LIST_REQUESTED = 'GET_REPORT_LIST_REQUESTED'
export const GET_REPORT_LIST_SUCCESSED = 'GET_REPORT_LIST_SUCCESSED'
export const GET_REPORT_LIST_FAILED = 'GET_REPORT_LIST_FAILED'

export const DELETE_REPORT_LIST_REQUESTED = 'DELETE_REPORT_LIST_REQUESTED'
export const DELETE_REPORT_LIST_SUCCESSED = 'DELETE_REPORT_LIST_SUCCESSED'
export const DELETE_REPORT_LIST_FAILED = 'DELETE_REPORT_LIST_FAILED'

export const ON_REPORT_DUPLICATE_REQUESTED = 'ON_REPORT_DUPLICATE_REQUESTED'
export const ON_REPORT_DUPLICATE_SUCCESSED = 'ON_REPORT_DUPLICATE_SUCCESSED'
export const ON_REPORT_DUPLICATE_FAILED = 'ON_REPORT_DUPLICATE_FAILED'


export const ADMINISTRATION_THREAT_ACTOR_REQUESTED = 'ADMINISTRATION_THREAT_ACTOR_REQUESTED'

export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_FAILED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_FAILED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_SUCCESSED'

export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_FAILED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_FAILED'

export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_FAILED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_FAILED'


export const ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_FAILED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_FAILED'

export const ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_FAILED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_FAILED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_FAILED = 'ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_FAILED'


export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_FAILED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_FAILED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_SUCCESSED'

export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_FAILED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_FAILED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_FAILED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_FAILED'

export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_PROCESSING = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_PROCESSING'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_SUCCESSED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_SUCCESSED'
export const ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_FAILED = 'ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_FAILED'

export const THREAT_INTEL_EXPORT_EXCEL_LIST_REQUESTED = 'THREAT_INTEL_EXPORT_EXCEL_LIST_REQUESTED'
export const THREAT_INTEL_EXPORT_EXCEL_LIST_PROCESSING = 'THREAT_INTEL_EXPORT_EXCEL_LIST_PROCESSING'
export const THREAT_INTEL_EXPORT_EXCEL_LIST_SUCCESSED = 'THREAT_INTEL_EXPORT_EXCEL_LIST_SUCCESSED'
export const THREAT_INTEL_EXPORT_EXCEL_LIST_FAILED = 'THREAT_INTEL_EXPORT_EXCEL_LIST_FAILED'

// widget management action types

export const WIDGET_MANAGEMENT_LIST_REQUESTED = 'WIDGET_MANAGEMENT_LIST_REQUESTED'
export const WIDGET_MANAGEMENT_LIST_SUCCESSED = 'WIDGET_MANAGEMENT_LIST_SUCCESSED'
export const WIDGET_MANAGEMENT_LIST_FAILED = 'WIDGET_MANAGEMENT_LIST_FAILED'

export const WIDGET_DELETION_REQUESTED = 'WIDGET_DELETION_REQUESTED'
export const WIDGET_DUPLICATION_REQUESTED = 'WIDGET_DUPLICATION_REQUESTED'

export const RENDER_WIDGET_REQUESTED = 'RENDER_WIDGET_REQUESTED'
export const RENDER_WIDGET_SUCCESSED = 'RENDER_WIDGET_SUCCESSED'
export const RENDER_WIDGET_FAILED = 'RENDER_WIDGET_FAILED'

export const CREATE_WIDGET_REQUESTED = 'CREATE_WIDGET_REQUESTED'
export const CREATE_WIDGET_SUCCESSED = 'CREATE_WIDGET_SUCCESSED'
export const CREATE_WIDGET_FAILED = 'CREATE_WIDGET_FAILED'

export const SAVE_WIDGET_REQUESTED = 'SAVE_WIDGET_REQUESTED'
export const SAVE_WIDGET_SUCCESSED = 'SAVE_WIDGET_SUCCESSED'
export const SAVE_WIDGET_FAILED = 'SAVE_WIDGET_FAILED'

export const RESET_WIDGET_DATA = 'RESET_WIDGET_DATA'

export const GET_WIDGET_SETTINGS_REQUESTED = 'GET_WIDGET_SETTINGS_REQUESTED'
export const GET_WIDGET_SETTINGS_SUCCESSED = 'GET_WIDGET_SETTINGS_SUCCESSED'
export const GET_WIDGET_SETTINGS_FAILED = 'GET_WIDGET_SETTINGS_FAILED'

export const GET_WIDGET_DATASOURCES_REQUESTED = 'GET_WIDGET_DATASOURCES_REQUESTED'
export const GET_WIDGET_DATASOURCES_SUCCESSED = 'GET_WIDGET_DATASOURCES_SUCCESSED'
export const GET_WIDGET_DATASOURCES_FAILED = 'GET_WIDGET_DATASOURCES_FAILED'

export const GET_WIDGET_DATA_FIELDS_REQUESTED = 'GET_WIDGET_DATA_FIELDS_REQUESTED'
export const GET_WIDGET_DATA_FIELDS_SUCCESSED = 'GET_WIDGET_DATA_FIELDS_SUCCESSED'
export const GET_WIDGET_DATA_FIELDS_FAILED = 'GET_WIDGET_DATA_FIELDS_FAILED'


// report management action types

export const REPORT_MANAGEMENT_LIST_REQUESTED = 'REPORT_MANAGEMENT_LIST_REQUESTED'
export const REPORT_MANAGEMENT_LIST_SUCCESSED = 'REPORT_MANAGEMENT_LIST_SUCCESSED'
export const REPORT_MANAGEMENT_LIST_FAILED = 'REPORT_MANAGEMENT_LIST_FAILED'

export const GET_REPORT_BY_ID_REQUESTED = 'GET_REPORT_BY_ID_REQUESTED'
export const GET_REPORT_BY_ID_SUCCESSED = 'GET_REPORT_BY_ID_SUCCESSED'
export const GET_REPORT_BY_ID_FAILED = 'GET_REPORT_BY_ID_FAILED'

export const SCHEDULE_LIST_REQUESTED = 'SCHEDULE_LIST_REQUESTED'
export const SCHEDULE_LIST_SUCCESSED = 'SCHEDULE_LIST_SUCCESSED'
export const SCHEDULE_LIST_FAILED = 'SCHEDULE_LIST_FAILED'

export const REPORT_DELETION_REQUESTED = 'REPORT_DELETION_REQUESTED'
export const REPORT_DUPLICATION_REQUESTED = 'REPORT_DUPLICATION_REQUESTED'
export const CREATE_REPORT_REQUESTED = 'CREATE_REPORT_REQUESTED'
export const CREATE_EXCEL_REPORT_REQUESTED = 'CREATE_EXCEL_REPORT_REQUESTED'

export const SAVE_SELECTED_WIDGET_LIST = 'SAVE_SELECTED_WIDGET_LIST'
export const UPDATE_SELECTED_WIDGET_LIST = 'UPDATE_SELECTED_WIDGET_LIST'
export const RESET_WIDGET_DATA_REQUESTED = 'RESET_WIDGET_DATA_REQUESTED'

export const CREATE_SCHEDULE_REQUESTED = 'CREATE_SCHEDULE_REQUESTED'
export const SCHEDULER_DELETION_REQUESTED = 'SCHEDULER_DELETION_REQUESTED'

export const GENERATE_PDF_REPORT_REQUESTED = 'GENERATE_PDF_REPORT_REQUESTED'

export const INCIDENT_SEARCH_LIST_REQUESTED = 'INCIDENT_SEARCH_LIST_REQUESTED'
export const INCIDENT_SEARCH_LIST_PROCESSING = 'INCIDENT_SEARCH_LIST_PROCESSING'
export const INCIDENT_SEARCH_LIST_SUCCESSED = 'INCIDENT_SEARCH_LIST_SUCCESSED'
export const INCIDENT_SEARCH_LIST_FAILED = 'INCIDENT_SEARCH_LIST_FAILED'

export const ARTICLE_LIST_SUCCESSED = 'ARTICLE_LIST_SUCCESSED'
export const ARTICLE_LIST_FAILED = 'ARTICLE_LIST_FAILED'
export const ARTICLE_LIST_REQUESTED = 'ARTICLE_LIST_REQUESTED'
export const ARTICLE_LIST_PROCESSING = 'ARTICLE_LIST_PROCESSING'

export const ARTICLE_CREATE_REQUESTED = 'ARTICLE_CREATE_REQUESTED'
export const ARTICLE_CREATE_SUCCESSED = 'ARTICLE_CREATE_SUCCESSED'
export const ARTICLE_CREATE_FAILED = 'ARTICLE_CREATE_FAILED'
export const ARTICLE_CREATE_PROCESSING = 'ARTICLE_CREATE_PROCESSING'

export const ARTICLE_EDIT_REQUESTED = 'ARTICLE_EDIT_REQUESTED'
export const ARTICLE_EDIT_SUCCESSED = 'ARTICLE_EDIT_SUCCESSED'
export const ARTICLE_EDIT_FAILED = 'ARTICLE_EDIT_FAILED'
export const ARTICLE_EDIT_PROCESSING = 'ARTICLE_EDIT_PROCESSING'

export const ARTICLE_DELETE_REQUESTED = 'ARTICLE_DELETE_REQUESTED'
export const ARTICLE_DELETE_SUCCESSED = 'ARTICLE_DELETE_SUCCESSED'
export const ARTICLE_DELETE_PROCESSING = 'ARTICLE_DELETE_PROCESSING'
export const ARTICLE_DELETE_FAILED = 'ARTICLE_DELETE_FAILED'
export const INCIDENT_SEARCH_LIST_RESET = 'INCIDENT_SEARCH_LIST_RESET'
export const BULK_EXECUTE_REQUESTED = 'BULK_EXECUTE_REQUESTED'
export const BULK_EXECUTE_PROCESSING = 'BULK_EXECUTE_PROCESSING'
export const BULK_EXECUTE_SUCCESSED = 'BULK_EXECUTE_SUCCESSED'
export const BULK_EXECUTE_FAILED = 'BULK_EXECUTE_FAILED'

export const BULK_DELETE_REQUESTED = 'BULK_DELETE_REQUESTED'
export const BULK_DELETE_SUCCESSED = 'BULK_DELETE_SUCCESSED'
export const BULK_DELETE_FAILED = 'BULK_DELETE_FAILED'
export const ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_REQUESTED = 'ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_REQUESTED'
export const ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_SUCCESSED = 'ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_SUCCESSED'
export const ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_FAILED = 'ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_FAILED'

export const ADMINISTRATION_NOTIFICATIONS_LOG_LIST_REQUESTED = 'ADMINISTRATION_NOTIFICATIONS_LOG_LIST_REQUESTED'
export const ADMINISTRATION_NOTIFICATIONS_LOG_LIST_SUCCESSED = 'ADMINISTRATION_NOTIFICATIONS_LOG_LIST_SUCCESSED'
export const ADMINISTRATION_NOTIFICATIONS_LOG_LIST_FAILED = 'ADMINISTRATION_NOTIFICATIONS_LOG_LIST_FAILED'

export const VULNERABILITY_UPDATE_ASSESSMENT_SUCCESSED = 'VULNERABILITY_UPDATE_ASSESSMENT_SUCCESSED'
export const VULNERABILITY_UPDATE_ASSESSMENT_FAILED = 'VULNERABILITY_UPDATE_ASSESSMENT_FAILED'
export const VULNERABILITY_UPDATE_ASSESSMENT_REQUESTED = 'VULNERABILITY_UPDATE_ASSESSMENT_REQUESTED'
export const NCIS_SCORE_PROCESSING = 'NCIS_SCORE_PROCESSING'
export const NCIS_SCORE_SUCCESSED = 'NCIS_SCORE_SUCCESSED'
export const NCIS_SCORE_FAILED = 'NCIS_SCORE_FAILED'
export const NCIS_SCORE_RESET = 'NCIS_SCORE_RESET'
export const NCIS_SCORE_REQUESTED = 'NCIS_SCORE_REQUESTED'
export const NCIS_SCORE_CREATE_REQUESTED = 'NCIS_SCORE_CREATE_REQUESTED'
export const NCIS_SCORE_CREATE_SUCCESSED = 'NCIS_SCORE_CREATE_SUCCESSED'
export const NCIS_SCORE_CREATE_RESET = 'NCIS_SCORE_CREATE_RESET'
export const NCIS_SCORE_CREATE_FAILED = 'NCIS_SCORE_CREATE_FAILED'

export const INCIDENT_ACTION_RUN_REQUESTED_DISPANSE = 'INCIDENT_ACTION_RUN_REQUESTED_DISPANSE'
export const INCIDENT_ACTION_RUN_PROCESSING_DISPANSE = 'INCIDENT_ACTION_RUN_PROCESSING_DISPANSE'
export const INCIDENT_ACTION_RUN_SUCCESSED_DISPANSE = 'INCIDENT_ACTION_RUN_SUCCESSED_DISPANSE'
export const INCIDENT_ACTION_RUN_FAILED_DISPANSE = 'INCIDENT_ACTION_RUN_FAILED_DISPANSE'
export const INCIDENT_ACTION_CLEAR_REQUESTED_DISPANSE = 'INCIDENT_ACTION_CLEAR_REQUESTED_DISPANSE'

export const CASE_ACTION_RUN_REQUESTED_DISPANSE = 'CASE_ACTION_RUN_REQUESTED_DISPANSE'
export const CASE_ACTION_RUN_PROCESSING_DISPANSE = 'CASE_ACTION_RUN_PROCESSING_DISPANSE'
export const CASE_ACTION_RUN_SUCCESSED_DISPANSE = 'CASE_ACTION_RUN_SUCCESSED_DISPANSE'
export const CASE_ACTION_RUN_FAILED_DISPANSE = 'CASE_ACTION_RUN_FAILED_DISPANSE'
export const CLEAR_ACTION_RUN_REQUESTED_DISPANSE = 'CLEAR_ACTION_RUN_REQUESTED_DISPANSE'

export const BULK_APPROVE_REQUESTED = 'BULK_APPROVE_REQUESTED'
export const BULK_APPROVE_SUCCESSED = 'BULK_APPROVE_SUCCESSED'
export const BULK_APPROVE_FAILED = 'BULK_APPROVE_FAILED'

export const BULK_DECLINE_REQUESTED = 'BULK_DECLINE_REQUESTED'
export const BULK_DECLINE_SUCCESSED = 'BULK_DECLINE_SUCCESSED'
export const BULK_DECLINE_FAILED = 'BULK_DECLINE_FAILED'

export const GET_APPROVAL_BY_ID_REQUESTED = 'GET_APPROVAL_BY_ID_REQUESTED'
export const GET_APPROVAL_BY_ID_SUCCESSED = 'GET_APPROVAL_BY_ID_SUCCESSED'
export const GET_APPROVAL_BY_ID_FAILED = 'GET_APPROVAL_BY_ID_FAILED'

export const VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_GET_LIST_FAILED'


export const VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_REQUESTED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_SUCCESSED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_FAILED = 'VULNERABILITY_MANAGEMENT_ASSESSMENT_IMPORTED_DATA_FAILED'

export const VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_REQUESTED = 'VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_REQUESTED'
export const VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_SUCCESSED = 'VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_SUCCESSED'
export const VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_FAILED = 'VULNERABILITY_MANAGEMENT_IMPORTED_ASSESSMENT_DELETE_LIST_FAILED'

export const DOWNLOAD_XML_NESSUS_FILE = 'DOWNLOAD_XML_NESSUS_FILE'

export const BULK_DELETE_ADVISORY_REQUESTED = 'BULK_DELETE_ADVISORY_REQUESTED'
export const BULK_DELETE_ADVISORY_SUCCESSED = 'BULK_DELETE_ADVISORY_SUCCESSED'
export const BULK_DELETE_ADVISORY_FAILED = 'BULK_DELETE_ADVISORY_FAILED'

export const BULK_EXECUTE_ADVISORY_REQUESTED = 'BULK_EXECUTE_ADVISORY_REQUESTED'
export const BULK_EXECUTE_ADVISORY_SUCCESSED = 'BULK_EXECUTE_ADVISORY_SUCCESSED'
export const BULK_EXECUTE_ADVISORY_FAILED = 'BULK_EXECUTE_ADVISORY_FAILED'

export const IMPORT_ADVISORY_REQUESTED = 'IMPORT_ADVISORY_REQUESTED'
export const IMPORT_ADVISORY_SUCCESSED = 'IMPORT_ADVISORY_SUCCESSED'
export const IMPORT_ADVISORY_FAILED = 'IMPORT_ADVISORY_FAILED'

export const ADMINISTRATION_CASES_LESSON_LIST_REQUESTED = 'ADMINISTRATION_CASES_LESSON_LIST_REQUESTED'
export const ADMINISTRATION_CASES_LESSON_LIST_PROCESSING = 'ADMINISTRATION_CASES_LESSON_LIST_PROCESSING'
export const ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED = 'ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_LESSON_LIST_FAILED = 'ADMINISTRATION_CASES_LESSON_LIST_FAILED'
export const ADMINISTRATION_CASES_LESSON_DELETE_REQUESTED = 'ADMINISTRATION_CASES_LESSON_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_LESSON_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_LESSON_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_LESSON_DELETE_FAILED = 'ADMINISTRATION_CASES_LESSON_DELETE_FAILED'
export const ADMINISTRATION_CASES_LESSON_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_LESSON_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_LESSON_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_LESSON_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_LESSON_UPDATE_FAILED = 'ADMINISTRATION_CASES_LESSON_UPDATE_FAILED'
export const ADMINISTRATION_CASES_LESSON_CREATE_REQUESTED = 'ADMINISTRATION_CASES_LESSON_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_LESSON_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_LESSON_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_LESSON_CREATE_FAILED = 'ADMINISTRATION_CASES_LESSON_CREATE_FAILED'



export const ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED = 'ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINED_BY_LIST_PROCESSING = 'ADMINISTRATION_CASES_CONTAINED_BY_LIST_PROCESSING'
export const ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED = 'ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED'
export const ADMINISTRATION_CASES_CONTAINED_BY_DELETE_REQUESTED = 'ADMINISTRATION_CASES_CONTAINED_BY_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINED_BY_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINED_BY_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINED_BY_DELETE_FAILED = 'ADMINISTRATION_CASES_CONTAINED_BY_DELETE_FAILED'
export const ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_FAILED = 'ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_FAILED'
export const ADMINISTRATION_CASES_CONTAINED_BY_CREATE_REQUESTED = 'ADMINISTRATION_CASES_CONTAINED_BY_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINED_BY_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINED_BY_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINED_BY_CREATE_FAILED = 'ADMINISTRATION_CASES_CONTAINED_BY_CREATE_FAILED'


export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_PROCESSING = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_PROCESSING'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_REQUESTED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_FAILED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_FAILED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_REQUESTED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_FAILED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_FAILED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_REQUESTED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_REQUESTED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_SUCCESSED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_SUCCESSED'
export const ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_FAILED = 'ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_FAILED'
export const INCIDENT_LESSON_LEARNED_REQUESTED = 'INCIDENT_LESSON_LEARNED_REQUESTED'
export const INCIDENT_LESSON_LEARNED_PROCESSING = 'INCIDENT_LESSON_LEARNED_PROCESSING'
export const INCIDENT_LESSON_LEARNED_SUCCESSED = 'INCIDENT_LESSON_LEARNED_SUCCESSED'
export const INCIDENT_LESSON_LEARNED_FAILED = 'INCIDENT_LESSON_LEARNED_FAILED'

export const INCIDENT_CONTAINED_BY_REQUESTED = 'INCIDENT_CONTAINED_BY_REQUESTED'
export const INCIDENT_CONTAINED_BY_PROCESSING = 'INCIDENT_CONTAINED_BY_PROCESSING'
export const INCIDENT_CONTAINED_BY_SUCCESSED = 'INCIDENT_CONTAINED_BY_SUCCESSED'
export const INCIDENT_CONTAINED_BY_FAILED = 'INCIDENT_CONTAINED_BY_FAILED'

export const INCIDENT_CONTAINMENT_STATUS_REQUESTED = 'INCIDENT_CONTAINMENT_STATUS_REQUESTED'
export const INCIDENT_CONTAINMENT_STATUS_PROCESSING = 'INCIDENT_CONTAINMENT_STATUS_PROCESSING'
export const INCIDENT_CONTAINMENT_STATUS_SUCCESSED = 'INCIDENT_CONTAINMENT_STATUS_SUCCESSED'
export const INCIDENT_CONTAINMENT_STATUS_FAILED = 'INCIDENT_CONTAINMENT_STATUS_FAILED'

export const ASSESSMENT_DUPLICATE_REQUESTED = 'ASSESSMENT_DUPLICATE_REQUESTED'
export const ASSESSMENT_DUPLICATE_PROCESSING = 'ASSESSMENT_DUPLICATE_PROCESSING'
export const ASSESSMENT_DUPLICATE_SUCCESSED = 'ASSESSMENT_DUPLICATE_SUCCESSED'
export const ASSESSMENT_DUPLICATE_FAILED = 'ASSESSMENT_DUPLICATE_FAILED'
export const RESET_DUPLICATE_ASSESSMENT_STORE = 'RESET_DUPLICATE_ASSESSMENT_STORE'

export const ADMINISTRATION_ALERTS_NOTIFY_LIST_REQUESTED = 'ADMINISTRATION_ALERTS_NOTIFY_LIST_REQUESTED'
export const ADMINISTRATION_ALERTS_NOTIFY_LIST_SUCCESSED = 'ADMINISTRATION_ALERTS_NOTIFY_LIST_SUCCESSED'
export const ADMINISTRATION_ALERTS_NOTIFY_LIST_FAILED = 'ADMINISTRATION_ALERTS_NOTIFY_LIST_FAILED'

export const ADMINISTRATION_ALERTS_NOTIFY_UPDATE_REQUESTED = 'ADMINISTRATION_ALERTS_NOTIFY_UPDATE_REQUESTED'
export const ADMINISTRATION_ALERTS_NOTIFY_UPDATE_SUCCESSED = 'ADMINISTRATION_ALERTS_NOTIFY_UPDATE_SUCCESSED'
export const ADMINISTRATION_ALERTS_NOTIFY_UPDATE_FAILED = 'ADMINISTRATION_ALERTS_NOTIFY_UPDATE_FAILED'

export const TEST_CONNECTION_REQUESTED = 'TEST_CONNECTION_REQUESTED'
export const TEST_CONNECTION_PROCESSING = 'TEST_CONNECTION_PROCESSING'
export const TEST_CONNECTION_SUCCESSED = 'TEST_CONNECTION_SUCCESSED'
export const TEST_CONNECTION_FAILED = 'TEST_CONNECTION_FAILED'

export const INGESTION_OUTPUT_REQUESTED = 'INGESTION_OUTPUT_REQUESTED'
export const INGESTION_OUTPUT_PROCESSING = 'INGESTION_OUTPUT_PROCESSING'
export const INGESTION_OUTPUT_SUCCESSED = 'INGESTION_OUTPUT_SUCCESSED'
export const INGESTION_OUTPUT_FAILED = 'INGESTION_OUTPUT_FAILED'

export const UPDATE_INGESTION_OUTPUT_REQUESTED = 'UPDATE_INGESTION_OUTPUT_REQUESTED'
export const UPDATE_INGESTION_OUTPUT_SUCCESSED = 'UPDATE_INGESTION_OUTPUT_SUCCESSED'
export const UPDATE_INGESTION_OUTPUT_FAILED = 'UPDATE_INGESTION_OUTPUT_FAILED'

export const ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED = 'ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED'
export const ADMINISTRATION_AFFECTED_VENDOR_LIST_SUCCESSED = 'ADMINISTRATION_AFFECTED_VENDOR_LIST_SUCCESSED'
export const ADMINISTRATION_AFFECTED_VENDOR_LIST_FAILED = 'ADMINISTRATION_AFFECTED_VENDOR_LIST_FAILED'

export const ADMINISTRATION_AFFECTED_VENDOR_CREATE_REQUESTED = 'ADMINISTRATION_AFFECTED_VENDOR_CREATE_REQUESTED'
export const ADMINISTRATION_AFFECTED_VENDOR_CREATE_SUCCESSED = 'ADMINISTRATION_AFFECTED_VENDOR_CREATE_SUCCESSED'
export const ADMINISTRATION_AFFECTED_VENDOR_CREATE_FAILED = 'ADMINISTRATION_AFFECTED_VENDOR_CREATE_FAILED'

export const ADMINISTRATION_AFFECTED_VENDOR_UPDATE_REQUESTED = 'ADMINISTRATION_AFFECTED_VENDOR_UPDATE_REQUESTED'
export const ADMINISTRATION_AFFECTED_VENDOR_UPDATE_SUCCESSED = 'ADMINISTRATION_AFFECTED_VENDOR_UPDATE_SUCCESSED'
export const ADMINISTRATION_AFFECTED_VENDOR_UPDATE_FAILED = 'ADMINISTRATION_AFFECTED_VENDOR_UPDATE_FAILED'

export const ADMINISTRATION_AFFECTED_VENDOR_DELETE_REQUESTED = 'ADMINISTRATION_AFFECTED_VENDOR_DELETE_REQUESTED'
export const ADMINISTRATION_AFFECTED_VENDOR_DELETE_SUCCESSED = 'ADMINISTRATION_AFFECTED_VENDOR_DELETE_SUCCESSED'
export const ADMINISTRATION_AFFECTED_VENDOR_DELETE_FAILED = 'ADMINISTRATION_AFFECTED_VENDOR_DELETE_FAILED'

export const ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED = 'ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED'
export const ADMINISTRATION_AFFECTED_PRODUCT_LIST_SUCCESSED = 'ADMINISTRATION_AFFECTED_PRODUCT_LIST_SUCCESSED'
export const ADMINISTRATION_AFFECTED_PRODUCT_LIST_FAILED = 'ADMINISTRATION_AFFECTED_PRODUCT_LIST_FAILED'

export const ADMINISTRATION_AFFECTED_PRODUCT_CREATE_REQUESTED = 'ADMINISTRATION_AFFECTED_PRODUCT_CREATE_REQUESTED'
export const ADMINISTRATION_AFFECTED_PRODUCT_CREATE_SUCCESSED = 'ADMINISTRATION_AFFECTED_PRODUCT_CREATE_SUCCESSED'
export const ADMINISTRATION_AFFECTED_PRODUCT_CREATE_FAILED = 'ADMINISTRATION_AFFECTED_PRODUCT_CREATE_FAILED'

export const ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_REQUESTED = 'ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_REQUESTED'
export const ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_SUCCESSED = 'ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_SUCCESSED'
export const ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_FAILED = 'ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_FAILED'

export const ADMINISTRATION_AFFECTED_PRODUCT_DELETE_REQUESTED = 'ADMINISTRATION_AFFECTED_PRODUCT_DELETE_REQUESTED'
export const ADMINISTRATION_AFFECTED_PRODUCT_DELETE_SUCCESSED = 'ADMINISTRATION_AFFECTED_PRODUCT_DELETE_SUCCESSED'
export const ADMINISTRATION_AFFECTED_PRODUCT_DELETE_FAILED = 'ADMINISTRATION_AFFECTED_PRODUCT_DELETE_FAILED'

export const  PRE_INGESTION_RULE_REQUESTED = ' PRE_INGESTION_RULE_REQUESTED'
export const  PRE_INGESTION_RULE_SUCCESSED = ' PRE_INGESTION_RULE_SUCCESSED'
export const  PRE_INGESTION_RULE_FAILED = ' PRE_INGESTION_RULE_FAILED'

export const  PRE_INGESTION_RULE_CREATE_REQUESTED = ' PRE_INGESTION_RULE_CREATE_REQUESTED'
export const  PRE_INGESTION_RULE_CREATE_SUCCESSED = ' PRE_INGESTION_RULE_CREATE_SUCCESSED'
export const  PRE_INGESTION_RULE_CREATE_FAILED = ' PRE_INGESTION_RULE_CREATE_FAILED'

export const  PRE_INGESTION_RULE_UPDATE_REQUESTED = ' PRE_INGESTION_RULE_UPDATE_REQUESTED'
export const  PRE_INGESTION_RULE_UPDATE_SUCCESSED = ' PRE_INGESTION_RULE_UPDATE_SUCCESSED'
export const  PRE_INGESTION_RULE_UPDATE_FAILED = ' PRE_INGESTION_RULE_UPDATE_FAILED'

export const  PRE_INGESTION_RULE_DELETE_REQUESTED = ' PRE_INGESTION_RULE_DELETE_REQUESTED'
export const  PRE_INGESTION_RULE_DELETE_SUCCESSED = ' PRE_INGESTION_RULE_DELETE_SUCCESSED'
export const  PRE_INGESTION_RULE_DELETE_FAILED = ' PRE_INGESTION_RULE_DELETE_FAILED'

export const SHARE_PLAYBOOK_REQUESTED = 'SHARE_PLAYBOOK_REQUESTED'
export const SHARE_PLAYBOOK_SUCCESSED = 'SHARE_PLAYBOOK_SUCCESSED'
export const SHARE_PLAYBOOK_FAILED = 'SHARE_PLAYBOOK_FAILED'

export const UPDATE_SLA_STATUS_REQUESTED = 'UPDATE_SLA_STATUS_REQUESTED'
export const UPDATE_SLA_STATUS_PROCESSING = 'UPDATE_SLA_STATUS_PROCESSING'
export const UPDATE_SLA_STATUS_SUCCESSED = 'UPDATE_SLA_STATUS_SUCCESSED'
export const UPDATE_SLA_STATUS_FAILED = 'UPDATE_SLA_STATUS_FAILED'

export const ALERTS_LIST_REQUESTED = 'ALERTS_LIST_REQUESTED'
export const ALERTS_LIST_SUCCESSED = 'ALERTS_LIST_SUCCESSED'
export const ALERTS_LIST_FAILED = 'ALERTS_LIST_FAILED'
export const RESET_ALERTS_LIST_REQUESTED = 'RESET_ALERTS_LIST_REQUESTED'
export const SET_ALERT_COUNT = 'SET_ALERT_COUNT'

export const INCIDENT_LINK_ALERTS_REQUESTED = 'INCIDENT_LINK_ALERTS_REQUESTED';
export const INCIDENT_LINK_ALERTS_SUCCESSED = 'INCIDENT_LINK_ALERTS_SUCCESSED';
export const INCIDENT_LINK_ALERTS_FAILED = 'INCIDENT_LINK_ALERTS_FAILED';


export const AUTOMATION_GENERATE_REPORT_REQUESTED = 'AUTOMATION_GENERATE_REPORT_REQUESTED';
export const AUTOMATION_GENERATE_REPORT_SUCCESSED = 'AUTOMATION_GENERATE_REPORT_SUCCESSED';
export const AUTOMATION_GENERATE_REPORT_FAILED = 'AUTOMATION_GENERATE_REPORT_FAILED';
