import styled from 'styled-components';

export const BoxContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c1c24;
  flex-direction: column;
  // padding-top: 120px;
  // @media (min-width: 1440px) {
  //   padding-top: 0px;
  // }
  
`;

// export const ContentContainer = styled.div`

//   width: 370px;
//   height: auto;
//   display: flex;
//   flex-direction: column;
//   background-color: #2c2c38;
//   border-radius: 10px;
//   overflow: hidden;
//   padding: 70px 48px 50px;
//   margin-bottom: 14px;
//   @media (min-width: 1440px) {
//     margin-bottom: 2px;
//     padding: 1rem 2.5rem 1rem;

//   }
// `;
export const ContentContainer = styled.div`
  width: 23vw;
  display: flex;
  flex-direction: column;
  overflow:hidden;
  background-color: #2C2C38;
  border-radius: 10px;
  padding: 5rem 3.4rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1440px) and (min-width: 768px) {
    // background-color: red !important;
    width: 24vw;
    height: 1%;
    padding-top:0.1rem;
    padding-bottom:1rem;
    margin-top:0.5rem;
  }

  @media screen and (max-width: 1680px) and (min-width: 1440px) {
    // background-color: red !important;
    width: 23vw;
    padding-top:1.4rem;
    padding-bottom:0.1rem;
    margin-top:0.5rem;
  }

  @media screen and (max-width: 1920px) and (min-width: 1680px) {
    // background-color: red !important;
    width: 22vw;
    height: 1%;
    padding: 3rem 2.5rem;
  }

  @media screen and (min-width: 1920px) {
    // background-color: red !important;
    width: 20vw;
    height: 1%;
  }

`;


export const BoxHeader = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top:1rem;
`;

export const LogoImage = styled.img`
  width: 110px;
  height: 34px;
  display: flex;
`;

export const BoxContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const CopyrightBox = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  flex-direction: column;
`;
export const UpperText = styled.p`
  font-size: 1.07rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-bottom: 17px;
`;
export const Text = styled.p`
  font-size: 1.07rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0px;
`;
