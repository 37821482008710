import { Alert } from 'antd'
import styled from 'styled-components'

export const StyledAlert = styled(Alert)`
    &.ant-alert{
        margin: 1rem 0;
        background-color: #13131a;
        border-radius: 5px;
        border: 1px solid ${props => props.type === 'error' ? '#ff4d4f' : '#007FD9'};

        .ant-alert-message {
            color: #fff;
        }
        
        .ant-alert-description {
            color: #fff;
        }
        
        .ant-alert-description ul {
            padding-left: 0px;
        }

        .ant-alert-description div ul {
            padding-left: 0px;
        }

        .ant-alert-close-icon span svg {
            fill: #fff;
        }
    }
`
