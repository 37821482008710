import {
  MITRE_DASHBOARD_LIST_REQUESTED,
  MITRE_DASHBOARD_LIST_SUCCESSED,
  MITRE_DASHBOARD_LIST_FAILED,
  MITRE_DETAIL_SUCCESSED,
  MITRE_WIDGETS_LIST_SUCCESSED,
  RESET_MITRE_DETAIL,
} from '../../constants/actionTypes';

const initialState = {
  mitreDashboardList: {
    listData: null,
    ranges: null
  },
  mitreDetail: {},
  mitreAssets: {},
};

export const mitreDashboardStore = (state = initialState, action) => {
  switch (action.type) {
    case MITRE_DASHBOARD_LIST_REQUESTED: {
      return {
        ...state,
        mitreDashboardList: {
          ...state.mitreDashboardList,
          listData: null,
        },
      };
    }

    case MITRE_DASHBOARD_LIST_SUCCESSED: {
      return {
        ...state,
        mitreDashboardList: {
          ...state.mitreDashboardList,
          listData: action.data?.data_all,
          ranges: action.data?.data_range
        },
      };
    }

    case MITRE_DASHBOARD_LIST_FAILED: {
      return {
        ...state,
        mitreDashboardList: {
          ...state.mitreDashboardList,
          listData: null,
        },
      };
    }

    case MITRE_DETAIL_SUCCESSED: {
      return {
        ...state,
        mitreDetail: action?.data
      };
    }

    case RESET_MITRE_DETAIL: {
      return {
        ...state,
        mitreDetail: {}
      };
    }

    case MITRE_WIDGETS_LIST_SUCCESSED: {
      return {
        ...state,
        mitreAssets: action?.data
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};