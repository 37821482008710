import {
    GET_REPORT_LIST_FAILED,
    GET_REPORT_LIST_SUCCESSED
} from '../../constants/actionTypes';

const initialState = {
    reportsList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [
            {
                index: '1',
                name: 'Weekly incident report',
                creator: 'Arnoldas',
                created: 'Jun 21, 2019',
                send: 'Every week',
                lastsend: '2020-04-10, 14:15',
                nexton: '2020-04-17 16:59',
                format: 'PDF',
                operation: '',
            },
        ],
    },
};

const reportsReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_REPORT_LIST_SUCCESSED: {
            return {
                ...state,
                reportsList: {
                    ...state.reportsList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case GET_REPORT_LIST_FAILED: {
            return {
                ...state,
                reportsList: {
                    ...state.reportsList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }

        default:
            return state
    }
}

export default reportsReducer;