import {
    REPORT_MANAGEMENT_LIST_REQUESTED,
    REPORT_DELETION_REQUESTED,
    REPORT_DUPLICATION_REQUESTED,
    CREATE_REPORT_REQUESTED,
    CREATE_EXCEL_REPORT_REQUESTED,
    SCHEDULE_LIST_REQUESTED,
    COVER_IMAGES_REQUESTED,
    GET_WIDGET_REQUESTED,
    UPDATE_WIDGETS_LAYOUT_REQUESTED,
    GET_REPORT_BY_ID_REQUESTED,
    SAVE_SELECTED_WIDGET_LIST,
    UPDATE_SELECTED_WIDGET_LIST,
    UPDATE_REPORT_DATA_REQUESTED,
    GET_WIDGET_SUCCESSED,
    RESET_WIDGET_DATA_REQUESTED,
    WIDGET_LIST_REQUESTED,
    REPORTS_GET_WIDGET_DATA_REQUESTED,
    CREATE_SCHEDULE_REQUESTED,
    SCHEDULER_DELETION_REQUESTED,
    GENERATE_PDF_REPORT_REQUESTED,
} from '../constants/actionTypes';

export function listReports(payload) {
    return {
        type: REPORT_MANAGEMENT_LIST_REQUESTED,
        payload: payload,
    };
}

export function listWidgets(payload) {
    return {
        type: WIDGET_LIST_REQUESTED,
        payload: payload,
    };
}

export function deleteReport(payload,fromSavedSearch) {
    return {
        type: REPORT_DELETION_REQUESTED,
        payload: payload,
        fromSavedSearch
    };
}

export function duplicateReport(payload) {
    return {
        type: REPORT_DUPLICATION_REQUESTED,
        payload: payload,
    };
}

export function createReport(payload) {
    return {
        type: CREATE_REPORT_REQUESTED,
        payload: payload,
    };
}

export function createExcelReport(payload) {
    return {
        type: CREATE_EXCEL_REPORT_REQUESTED,
        payload: payload,
    };
}

export function createSchedule(payload) {
    return {
        type: CREATE_SCHEDULE_REQUESTED,
        payload: payload,
    };
}

export function getScheduleList(payload) {
    return {
        type: SCHEDULE_LIST_REQUESTED,
        payload: payload,
    };
}

export function getCoverImages(payload) {
    return {
        type: COVER_IMAGES_REQUESTED,
        payload: payload,
    };
}

export function getWidgetById(payload) {
    return {
        type: GET_WIDGET_REQUESTED,
        payload: payload,
    };
}

export function setWidgetById(payload) {
    return {
        type: GET_WIDGET_SUCCESSED,
        payload: payload,
    };
}

export function onWidgetsLayoutChange(payload) {
    return {
        type: UPDATE_WIDGETS_LAYOUT_REQUESTED,
        payload: payload,
    };
}

export const onGetReportsWidgetData = (payload) => {
    return {
        type: REPORTS_GET_WIDGET_DATA_REQUESTED,
        payload
    }
}

export function getReportById(payload) {
    return {
        type: GET_REPORT_BY_ID_REQUESTED,
        payload: payload,
    };
}

export const onSaveSelectedWidget = (payload) => {
    return {
        type: SAVE_SELECTED_WIDGET_LIST,
        payload
    }
}

export const onUpdateSelectedWidget = (payload) => {
    return {
        type: UPDATE_SELECTED_WIDGET_LIST,
        payload
    }
}

export const onUpdateReportData = (payload) => {
    return {
        type: UPDATE_REPORT_DATA_REQUESTED,
        payload
    }
}

export const resetWidgetData = (payload) => {
    return {
        type: RESET_WIDGET_DATA_REQUESTED,
        payload
    }
}

export function deleteScheduler(payload) {
    return {
        type: SCHEDULER_DELETION_REQUESTED,
        payload: payload,
    };
}

export function generatePdfReport(payload) {
    return {
        type: GENERATE_PDF_REPORT_REQUESTED,
        payload: payload,
    };
}
