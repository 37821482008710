import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  THREAT_INTELLIGENCE_LIST_SUCCESSED,
  THREAT_INTELLIGENCE_LIST_FAILED,
  THREAT_INTELLIGENCE_LIST_REQUESTED,
  THREAT_INTELLIGENCE_DETAILS_LIST_FAILED,
  THREAT_INTELLIGENCE_DETAILS_LIST_SUCCESSED,
  THREAT_INTELLIGENCE_DETAILS_LIST_REQUESTED,
  //   FILTERED_CASES_SUCCESSED,
  //   FILTERED_CASES_FAILED,
  DELETE_THREAT_INTELLIGENCE_REQUESTED,
  DELETE_THREAT_INTELLIGENCE_SUCCESSED,
  DELETE_THREAT_INTELLIGENCE_FAILED,
  THREAT_INTELLIGENCE_CATEGORIES_REQUESTED,
  THREAT_INTELLIGENCE_CATEGORIES_SUCCESSED,
  THREAT_INTELLIGENCE_CATEGORIES_FAILED,
  THREAT_INTELLIGENCE_SUB_CATEGORIES_REQUESTED,
  THREAT_INTELLIGENCE_SUB_CATEGORIES_SUCCESSED,
  THREAT_INTELLIGENCE_SUB_CATEGORIES_FAILED,
  THREAT_INTELLIGENCE_CASE_CATEGORIES_REQUESTED,
  THREAT_INTELLIGENCE_CASE_CATEGORIES_SUCCESSED,
  THREAT_INTELLIGENCE_CASE_CATEGORIES_FAILED,
  SHOW_LOADER,
  HIDE_LOADER,
  THREAT_INTELLIGENCE_SEVERITY_REQUESTED,
  THREAT_INTELLIGENCE_SEVERITY_SUCCESSED,
  THREAT_INTELLIGENCE_SEVERITY_FAILED,
  THREAT_INTELLIGENCE_ASSET_REQUESTED,
  THREAT_INTELLIGENCE_ASSET_SUCCESSED,
  THREAT_INTELLIGENCE_ASSET_FAILED,
  THREAT_INTELLIGENCE_DEPARTMENTS_REQUESTED,
  THREAT_INTELLIGENCE_DEPARTMENTS_SUCCESSED,
  THREAT_INTELLIGENCE_DEPARTMENTS_FAILED,
  THREAT_INTELLIGENCE_EVIDENCE_REQUESTED,
  THREAT_INTELLIGENCE_EVIDENCE_SUCCESSED,
  THREAT_INTELLIGENCE_EVIDENCE_FAILED,
  THREAT_INTELLIGENCE_DISPOSITION_FAILED,
  THREAT_INTELLIGENCE_DISPOSITION_SUCCESSED,
  THREAT_INTELLIGENCE_DISPOSITION_REQUESTED,
  THREAT_INTELLIGENCE_PRODUCTS_FAILED,
  THREAT_INTELLIGENCE_PRODUCTS_SUCCESSED,
  THREAT_INTELLIGENCE_PRODUCTS_REQUESTED,
  THREAT_INTELLIGENCE_ADVISORY_VENDORS_REQUESTED,
  THREAT_INTELLIGENCE_ADVISORY_VENDORS_SUCCESSED,
  THREAT_INTELLIGENCE_ADVISORY_VENDORS_FAILED,
  THREAT_INTELLIGENCE_EXPORT_PDF_REQUESTED,
  THREAT_INTELLIGENCE_EXPORT_PDF_SUCCESSED,
  THREAT_INTELLIGENCE_EXPORT_PDF_FAILED,
  GET_PRODUCT_LIST_REQUESTED,
  GET_PRODUCT_LIST_SUCCESSED,
  GET_PRODUCT_LIST_FAILED,
  UPDATE_ADVERSORY_DETAILS_SUCCESSED,
  UPDATE_ADVERSORY_DETAILS_FAILED,
  UPDATE_ADVERSORY_DETAILS_REQUESTED,
  UPDATE_PARTIAL_THREAT_INTELLIGENCE_REQUESTED,
  UPDATE_PARTIAL_THREAT_INTELLIGENCE_SUCCESSED,
  UPDATE_PARTIAL_THREAT_INTELLIGENCE_FAILED,
  GET_RUN_PLAY_BOOK_DATA_REQUESTED,
  GET_RUN_PLAY_BOOK_DATA_SUCCESSED,
  GET_RUN_PLAY_BOOK_DATA_FAILED,
  THREAT_INTELLIGENCE_CREATE_ADVISORY_REQUESTED,
  THREAT_INTELLIGENCE_CREATE_ADVISORY_FAILED,
  THREAT_INTELLIGENCE_CREATE_ADVISORY_SUCCESSED,
  EXECUTE_PLAY_BOOK_SUCCESSED,
  EXECUTE_PLAY_BOOK_FAILED,
  EXECUTE_PLAY_BOOK_REQUESTED,

  ADVISORY_PUBLISH_SUCCESSED,
  ADVISORY_PUBLISH_FAILED,
  ADVISORY_PUBLISH_REQUESTED,

  IMPORT_ADVISORY_REQUESTED,
  IMPORT_ADVISORY_SUCCESSED,
  IMPORT_ADVISORY_FAILED,

  GET_USER_EMAILS_LIST_REQUESTED,
  GET_USER_EMAILS_LIST_SUCCESSED,
  GET_USER_EMAILS_LIST_FAILED,
  SEND_EMAIL_REQUESTED,
  SEND_EMAIL_SUCCESSED,
  SEND_EMAIL_FAILED,
  ADVISORY_SOURCE_FAILED,
  ADVISORY_SOURCE_REQUESTED,
  ADVISORY_SOURCE_SUCCESSED,
  ADVISORY_GENERATE_REPORT_SUCCESSED,
  ADVISORY_GENERATE_REPORT_FAILED,
  ADVISORY_GENERATE_REPORT_REQUESTED,

  ADVISORY_THREAT_ACTORS_FAILED,
  ADVISORY_THREAT_ACTORS_REQUESTED,
  ADVISORY_THREAT_ACTORS_SUCCESSED,
  CASES_ADVISORY_LOCATIONS_SUCCESSED,
  CASES_ADVISORY_LOCATIONS_FAILED,
  CASES_ADVISORY_LOCATIONS_REQUESTED,
  OPEN_CASES_REQUESTED,
  OPEN_CASES_FAILED,
  OPEN_CASES_SUCCESSED,
  UPDATE_ARTIFACT_REQUESTED,
  UPDATE_ARTIFACT_SUCCESSED,
  UPDATE_ARTIFACT_FAILED,
  EXECUTE_ACTION_REQUESTED,
  EXECUTE_ACTION_SUCCESSED,
  EXECUTE_ACTION_FAILED,
  MULTI_CONFIG_EXECUTION_REQUESTED,
  MULTI_CONFIG_EXECUTION_SUCCESSED,
  MULTI_CONFIG_EXECUTION_FAILED,
  ADVISORY_ADD_EVIDENCE_REQUESTED,
  ADVISORY_ADD_EVIDENCE_SUCCESSED,
  ADVISORY_ADD_EVIDENCE_FAILED,
  ADVISORY_ARTIFACT_RAW_DATA_SUCCESSED,
  ADVISORY_ARTIFACT_RAW_DATA_FAILED,
  ADVISORY_ARTIFACT_RAW_DATA_REQUESTED,
  THREAT_INTELLIGENCE_FEED_DELETE_REQUESTED,
  THREAT_INTELLIGENCE_FEED_LIST_SUCCESSED,
  THREAT_INTELLIGENCE_FEED_LIST_FAILED,
  THREAT_INTELLIGENCE_SEND_ADVISORY_SUCCESSED,
  THREAT_INTELLIGENCE_SEND_ADVISORY_FAILED,
  THREAT_INTELLIGENCE_SEND_ADVISORY_REQUESTED,
  BULK_UPDATE_REQUESTED,
  BULK_UPDATE_SUCCESSED,
  BULK_UPDATE_FAILED,
  BULK_DELETE_ADVISORY_REQUESTED,
  BULK_DELETE_ADVISORY_SUCCESSED,
  BULK_DELETE_ADVISORY_FAILED,
  BULK_EXECUTE_ADVISORY_REQUESTED,
  BULK_EXECUTE_ADVISORY_SUCCESSED,
  BULK_EXECUTE_ADVISORY_FAILED,
  UPDATE_DETAILS_ARTIFACT,
  ADVISORY_DETAILS_PARTIAL_UPDATE_SUCCESSED,
} from '../../constants/actionTypes';

import {
  threatIntelligenceSaga,
  threatIntelligenceDetailsSaga,
  deleteSaga,
  deleteFeed,
  sendAdviosry,
  threatIntelligenceCategorySaga,
  threatIntelligenceCaseCategorySaga,
  threatIntelligenceSeveritySaga,
  threatIntelligenceAssetSaga,
  threatIntelligenceDepartmentSaga,
  threatIntelligenceEvidencesaga,
  threatIntelligenceDispositionssaga,
  threatIntelligenceProductsaga,
  threatIntelligenceExportAsPDF,
  getProductDatails,
  updateAdvisoryDetails,
  updatePartialThreatIntelApi,
  getRunPlayBookDataApi,
  createAdvisory,
  executePlayBookAPI,
  publishAdvisoryAPI,
  getUserEmailListApi,
  sendEmailApi,
  threatIntelligenceVendors,
  threatIntelligenceSources,
  threatIntelligenceThreatActors,
  threatIntelligenceCasesAdvisoryLocation,
  threatIntelligenceOpenCaseSaga,
  updateArtifactsApi,
  executeActionApi,
  multiExecuteActionApi,
  addEvidenceSaga,
  rawDataSaga,
  bulkUpdateAPI,
  generateReport,
  bulkDelete,
  bulkExecute,
  importSaga,
  threatIntelligenceSubCategorySaga,
} from '../../api/threatIntelligenceSaga';

import {
  threatIntelligenceDetail,
  threatIntelligenceStore,
} from '../../actions/threatIntelligence';
import { dismissToastLoader, showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

const getResponseMessage = (data) => {
  let { module, action } = data || {};
  switch (true) {
    case (module == 'assets' && action == 'add'):
      return 'Asset added';
    case (module == 'assets' && action == 'remove'):
      return 'Asset deleted';
    case (module == 'affectedVendors' && action == 'add'):
      return 'Affected Vendor added'
    case (module == 'affectedVendors' && action == 'remove'):
      return 'Affected Vendor deleted'
    case (module == 'affectedProduct' && action == 'add'):
      return 'Affected Product added'
    case (module == 'affectedProduct' && action == 'remove'):
      return 'Affected Product deleted'
    case (module == 'remediation' && action == 'update'):
      return 'Remediation updated'
    case (module == 'advSummary' && action == 'update'):
      return 'Analysis Summary updated'
    case (module == 'advDescription' && action == 'update'):
      return 'Description updated'
    case (module == 'advImpact' && action == 'update'):
      return 'Impact updated'
    default:
      return 'Threat Intelligence updated';
  }
}

export function* threatIntelligenceList(action) {
  try {
    const threatIntelligenceData = yield call(
      threatIntelligenceSaga,
      action.payload
    );

    if (threatIntelligenceData.success) {
      yield put({
        type: THREAT_INTELLIGENCE_LIST_SUCCESSED,
        data: threatIntelligenceData,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: THREAT_INTELLIGENCE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchthreatIntelligenceDetails(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const { id, edit } = action.payload || {};
    const response = yield call(threatIntelligenceDetailsSaga, action.payload);
    if (response.success) {
      yield put({
        type: THREAT_INTELLIGENCE_DETAILS_LIST_SUCCESSED,
        data: response,
      });
      yield put({ type: HIDE_LOADER });
    } else {
      yield put({
        type: THREAT_INTELLIGENCE_DETAILS_LIST_FAILED,
        data: null,
      });
    }
    if (window.location.pathname.split('/')[1] == "threatIntelligence") {
      const response = yield call(threatIntelligenceDetailsSaga, action.payload);
      if (response.success) {
        yield put({
          type: THREAT_INTELLIGENCE_DETAILS_LIST_SUCCESSED,
          data: response,
        });
      } else {
        yield put({
          type: THREAT_INTELLIGENCE_DETAILS_LIST_FAILED,
          data: null,
        });
      }
      yield put({ type: HIDE_LOADER });
    }
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: THREAT_INTELLIGENCE_DETAILS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDelete(action) {
  const loaderHandle = showToastLoader('Deleting threat intelligence...')
  try {
    const deleteThreatIntelData = yield call(deleteSaga, action.id);

    if (deleteThreatIntelData.success) {
      showToastSuccess('Threat intelligence deleted', loaderHandle)
      yield put({
        type: DELETE_THREAT_INTELLIGENCE_SUCCESSED,
        data: deleteThreatIntelData,
      });
      yield put(
        threatIntelligenceStore({ queryItem: action.query, path: action.path })
      );
    } else {
      showToastError('Threat intelligence delete failed', loaderHandle)
      yield put({ type: DELETE_THREAT_INTELLIGENCE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Threat intelligence delete failed', loaderHandle)
    yield put({
      type: DELETE_THREAT_INTELLIGENCE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchFeedDelete(action) {
  const loaderHandle = showToastLoader('Deleting feed...')
  try {
    const response = yield call(deleteFeed, action.id);

    if (response.success) {
      showToastSuccess('Feed deleted', loaderHandle)
      yield put({
        type: THREAT_INTELLIGENCE_FEED_LIST_SUCCESSED,
        data: response?.data,
      });
      yield put(
        threatIntelligenceStore({ queryItem: action.query, path: action?.path })
      );
    } else {
      showToastError('Feed delete failed', loaderHandle)
      yield put({ type: THREAT_INTELLIGENCE_FEED_LIST_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Feed delete failed', loaderHandle)
    yield put({
      type: THREAT_INTELLIGENCE_FEED_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* wacthSendAdvisory(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(sendAdviosry, action.id);
    if (response.success) {
      yield put({
        type: THREAT_INTELLIGENCE_SEND_ADVISORY_SUCCESSED,
        data: response?.data,
      });
      yield put(
        threatIntelligenceStore({ queryItem: action.query, path: action?.path })
      );
    } else {
      yield put({ type: THREAT_INTELLIGENCE_SEND_ADVISORY_FAILED, data: null });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: THREAT_INTELLIGENCE_SEND_ADVISORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCategoryFilter(action) {
  try {
    const categoryData = yield call(threatIntelligenceCategorySaga, action.id);
    if (categoryData.success) {
      yield put({
        type: THREAT_INTELLIGENCE_CATEGORIES_SUCCESSED,
        data: categoryData?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_CATEGORIES_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_CATEGORIES_FAILED, data: err });
  }
}
export function* watchSubCategoryFilter(action) {
  try {
    const subCategoryData = yield call(threatIntelligenceSubCategorySaga, action.id);
    if (subCategoryData.success) {
      yield put({
        type: THREAT_INTELLIGENCE_SUB_CATEGORIES_SUCCESSED,
        data: subCategoryData?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_SUB_CATEGORIES_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_SUB_CATEGORIES_FAILED, data: err });
  }
}

export function* watchAdvCategoryFilter(action) {
  try {
    const categoryData = yield call(threatIntelligenceCaseCategorySaga, action.id);
    if (categoryData.success) {
      yield put({
        type: THREAT_INTELLIGENCE_CASE_CATEGORIES_SUCCESSED,
        data: categoryData?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_CASE_CATEGORIES_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_CASE_CATEGORIES_FAILED, data: err });
  }
}

export function* watchSeverityFilter(action) {
  try {
    const severityData = yield call(threatIntelligenceSeveritySaga, action.id);
    if (severityData.success) {
      yield put({
        type: THREAT_INTELLIGENCE_SEVERITY_SUCCESSED,
        data: severityData?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_SEVERITY_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_SEVERITY_FAILED, data: err });
  }
}

export function* watchSources() {
  try {
    const sourceData = yield call(threatIntelligenceSources);
    if (sourceData.success) {
      yield put({ type: ADVISORY_SOURCE_SUCCESSED, data: sourceData?.data });
    } else {
      yield put({ type: ADVISORY_SOURCE_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: ADVISORY_SOURCE_FAILED, data: err });
  }
}

export function* watchThreatActors() {
  try {
    const sourceData = yield call(threatIntelligenceThreatActors);
    if (sourceData.success) {
      yield put({ type: ADVISORY_THREAT_ACTORS_SUCCESSED, data: sourceData?.data });
    } else {
      yield put({ type: ADVISORY_THREAT_ACTORS_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: ADVISORY_THREAT_ACTORS_FAILED, data: err });
  }
}

export function* watchOpenCase(action) {
  const loaderHandle = showToastLoader('Opening case...')

  try {

    const response = yield call(
      threatIntelligenceOpenCaseSaga,
      action.id?.id,
      action.id?.data
    );
    if (response.success) {
      showToastSuccess(response?.data[1], loaderHandle)
      yield put({ type: OPEN_CASES_SUCCESSED, data: response?.data });

      if (action.id?.callback) action.id.callback({ isSuccess: true });
    } else {

      showToastError('Threat Intelligence case create failed', loaderHandle)
      yield put({ type: OPEN_CASES_FAILED, data: response?.data });

      if (action.id?.callback) action.id.callback({ isSuccess: false });
    }
  } catch (err) {
    showToastError('Threat Intelligence case create failed', loaderHandle)
    yield put({
      type: OPEN_CASES_FAILED,
      data: err?.response?.data?.data,
    });
    if (action.id?.callback) action.id.callback({ isSuccess: false });
  }
}

export function* watchAssetFilter(action) {
  try {
    const assetData = yield call(threatIntelligenceAssetSaga, action.id);
    if (assetData.success) {
      yield put({
        type: THREAT_INTELLIGENCE_ASSET_SUCCESSED,
        data: assetData?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_ASSET_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_ASSET_FAILED, data: err });
  }
}

export function* watchDepartmentsFilter(action) {
  try {
    const departmentData = yield call(threatIntelligenceDepartmentSaga, action.id);
    if (departmentData.success) {
      yield put({
        type: THREAT_INTELLIGENCE_DEPARTMENTS_SUCCESSED,
        data: departmentData?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_DEPARTMENTS_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_DEPARTMENTS_FAILED, data: err });
  }
}

export function* watchEvidence(action) {
  try {
    const EvidenceSaga = yield call(threatIntelligenceEvidencesaga);
    if (EvidenceSaga.success) {
      yield put({
        type: THREAT_INTELLIGENCE_EVIDENCE_SUCCESSED,
        data: EvidenceSaga?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_EVIDENCE_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_EVIDENCE_FAILED, data: err });
  }
}

export function* watchDisposition() {
  try {
    const DispostionSaga = yield call(threatIntelligenceDispositionssaga);
    if (DispostionSaga.success) {
      yield put({
        type: THREAT_INTELLIGENCE_DISPOSITION_SUCCESSED,
        data: DispostionSaga?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_DISPOSITION_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_DISPOSITION_FAILED, data: err });
  }
}

export function* watchVendors(action) {
  try {
    const VendorSaga = yield call(threatIntelligenceVendors, action.payload);
    if (VendorSaga.success) {
      yield put({
        type: THREAT_INTELLIGENCE_ADVISORY_VENDORS_SUCCESSED,
        data: VendorSaga?.data,
      });
    } else {
      yield put({
        type: THREAT_INTELLIGENCE_ADVISORY_VENDORS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_ADVISORY_VENDORS_FAILED, data: err });
  }
}

export function* watchExportAsPDF(action) {
  const loaderHandle = showToastLoader('Downloading PDF...')
  try {
    const response = yield call(threatIntelligenceExportAsPDF, action.payload);
    if (response.size) {
      showToastSuccess('PDF downloaded', loaderHandle)
      yield put({
        type: THREAT_INTELLIGENCE_EXPORT_PDF_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('PDF download failed', loaderHandle)
      yield put({ type: THREAT_INTELLIGENCE_EXPORT_PDF_FAILED, data: null });
    }
  } catch (err) {
    showToastError('PDF download failed', loaderHandle)
    yield put({ type: THREAT_INTELLIGENCE_EXPORT_PDF_FAILED, data: err });
  }
}

export function* watchProduct(action) {
  try {
    const ProductSaga = yield call(threatIntelligenceProductsaga, action.payload);
    if (ProductSaga.success) {
      yield put({
        type: THREAT_INTELLIGENCE_PRODUCTS_SUCCESSED,
        data: ProductSaga?.data,
      });
    } else {
      yield put({ type: THREAT_INTELLIGENCE_PRODUCTS_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: THREAT_INTELLIGENCE_PRODUCTS_FAILED, data: err });
  }
}

export function* watchCaseAdvisoryLocation() {
  try {
    const casesAdvisorylocation = yield call(
      threatIntelligenceCasesAdvisoryLocation
    );
    if (casesAdvisorylocation.success) {
      yield put({
        type: CASES_ADVISORY_LOCATIONS_SUCCESSED,
        data: casesAdvisorylocation?.data,
      });
    } else {
      yield put({ type: CASES_ADVISORY_LOCATIONS_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: CASES_ADVISORY_LOCATIONS_FAILED, data: err });
  }
}

export function* getProductDetails(data) {
  try {
    const response = yield call(getProductDatails, data);
    if (response.success) {
      yield put({ type: GET_PRODUCT_LIST_SUCCESSED, data: response?.data });
    } else {
      yield put({ type: GET_PRODUCT_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: GET_PRODUCT_LIST_FAILED, data: err });
  }
}

export function* getUpdateAdversoryDetails(action) {
  const loaderHandle = showToastLoader('Updating threat intelligence...')
  try {
    const response = yield call(
      updateAdvisoryDetails,
      action.id,
      action.id
    );
    if (response.success) {
      const msg = 'Threat Intelligence updated'
      showToastSuccess(msg, loaderHandle)
      yield put({
        type: UPDATE_ADVERSORY_DETAILS_SUCCESSED,
        data: response?.data,
      });
      if (action?.isList) {
        yield put(
          threatIntelligenceStore({
            queryItem: action?.query,
            path: action?.path,
          })
        );
      } else {
        yield put(threatIntelligenceDetail(action?.id?.id));
      }
      if (action.id?.callback) action.id.callback({ isSuccess: true });
    } else {
      showToastError('Threat Intelligence update failed', loaderHandle)
      yield put({ type: UPDATE_ADVERSORY_DETAILS_FAILED, data: null });
      if (action.id?.callback) action.id.callback({ isSuccess: false });
    }
  } catch (err) {
    showToastError('Threat Intelligence update failed', loaderHandle)
    yield put({ type: UPDATE_ADVERSORY_DETAILS_FAILED, data: err });
    if (action.id?.callback) action.id.callback({ isSuccess: false });
  }
}

export function* watchUpdatePartialThreatIntel(action) {
  const loaderHandle = showToastLoader('Updating threat intel...')
  try {
    const response = yield call(updatePartialThreatIntelApi, action?.payload);
    if (response.success) {
      const msg = getResponseMessage(action.payload.module);
      showToastSuccess(`${msg}`, loaderHandle)
      const key = Object.keys(action?.payload.data);
      const label = key.find((item) => item === 'adv_asset_or_type')
      yield put({
        type: ADVISORY_DETAILS_PARTIAL_UPDATE_SUCCESSED,
        data: action?.payload?.data,
        label: label || '',
        response: response?.data
      });
      yield put({
        type: UPDATE_PARTIAL_THREAT_INTELLIGENCE_SUCCESSED,
        data: response?.data,
      });
      // Call callback function after update
      if (action.callback) action.callback(response);

      // Call threat Details API when we change status from pending to release or vice versa
      if (action.payload?.data?.adv_status) {
        const threatDetailResponse = yield call(threatIntelligenceDetailsSaga, { id: action.payload?.id });
        if (threatDetailResponse.success) {
          yield put({
            type: THREAT_INTELLIGENCE_DETAILS_LIST_SUCCESSED,
            data: threatDetailResponse,
          });
          yield put({ type: HIDE_LOADER });
        } else {
          yield put({
            type: THREAT_INTELLIGENCE_DETAILS_LIST_FAILED,
            data: null,
          });
        }
      }
    } else {
      showToastError('Threat intel update failed', loaderHandle)
      yield put({
        type: UPDATE_PARTIAL_THREAT_INTELLIGENCE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Threat intel update failed', loaderHandle)
    yield put({ type: UPDATE_PARTIAL_THREAT_INTELLIGENCE_FAILED, data: err });
  }
}

export function* watchCreateAdversory(action) {
  const loaderHandle = showToastLoader('Creating threat intelligence...')
  try {
    const response = yield call(createAdvisory, action.payload);
    if (response.success) {
      const msg = response.data[1] || 'Threat Intelligence created'
      showToastSuccess(msg, loaderHandle)
      yield put({
        type: THREAT_INTELLIGENCE_CREATE_ADVISORY_SUCCESSED,
        data: response?.data,
      });

      if (action.path === 'pending' || action.path === 'release') {
        yield put(threatIntelligenceStore({ queryItem: action.query, path: action?.path }));
      }
      if (action.payload?.callback) action.payload.callback({ isSuccess: true });
    } else {
      showToastError('Threat Intelligence create failed', loaderHandle)
      yield put({
        type: THREAT_INTELLIGENCE_CREATE_ADVISORY_FAILED,
        data: null,
      });
      if (action.payload?.callback) action.payload.callback({ isSuccess: false });
    }
  } catch (err) {
    showToastError('Threat Intelligence create failed', loaderHandle)
    yield put({ type: THREAT_INTELLIGENCE_CREATE_ADVISORY_FAILED, data: err });
    action.payload.callback({ isSuccess: false });
  }
}

export function* getRunPlayBookData(data) {
  try {
    const response = yield call(getRunPlayBookDataApi, data);
    if (response.success) {
      yield put({
        type: GET_RUN_PLAY_BOOK_DATA_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({ type: GET_RUN_PLAY_BOOK_DATA_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: GET_RUN_PLAY_BOOK_DATA_FAILED, data: err });
  }
}

export function* executePlayBook(data) {
  const loaderHandle = showToastLoader('Executing playbook...')
  try {
    const response = yield call(executePlayBookAPI, data);
    if (response.success) {
      const msg = `Playbook "${data?.payload?.playbookName}" executed`;
      showToastSuccess(msg, loaderHandle)
      yield put({ type: EXECUTE_PLAY_BOOK_SUCCESSED, data: response?.data });
    } else {
      showToastError('Playbook execute failed', loaderHandle)
      yield put({ type: EXECUTE_PLAY_BOOK_FAILED, data: null });
    }

    if (data?.payload?.callback) {
      data.payload.callback();
    }
  } catch (err) {
    showToastError('Playbook execute failed', loaderHandle)
    yield put({ type: EXECUTE_PLAY_BOOK_FAILED, data: err });
    if (data?.payload?.callback) {
      data.payload.callback();
    }
  }
}

export function* publishAdvisory(data) {
  const loaderHandle = showToastLoader('Publishing threat intel...')
  try {
    const response = yield call(publishAdvisoryAPI, data);
    if (response.success) {
      const msg = 'Threat intel published to selected organizations';
      showToastSuccess(msg, loaderHandle)
      yield put({ type: ADVISORY_PUBLISH_SUCCESSED, data: response?.data });
    } else {
      showToastError('Threat intel publish failed', loaderHandle)
      yield put({ type: ADVISORY_PUBLISH_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Threat intel publish failed', loaderHandle)
    yield put({ type: ADVISORY_PUBLISH_FAILED, data: err });
  }
}

export function* getUserEmailsList(data) {
  try {
    const response = yield call(getUserEmailListApi, data);
    if (response.success) {
      yield put({ type: GET_USER_EMAILS_LIST_SUCCESSED, data: response?.data });
    } else {
      yield put({ type: GET_USER_EMAILS_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: GET_USER_EMAILS_LIST_FAILED, data: err });
  }
}

export function* sendEmail(data) {
  const loaderHandle = showToastLoader('Sending email...')
  try {
    const response = yield call(sendEmailApi, data);
    if (response.success) {
      yield put({ type: SEND_EMAIL_SUCCESSED, data: response?.data });
      showToastSuccess('Email sent', loaderHandle)
    } else {
      yield put({ type: SEND_EMAIL_FAILED, data: null });
      showToastError('Email sending failed', loaderHandle)
    }
  } catch (err) {
    yield put({ type: SEND_EMAIL_FAILED, data: err });
    showToastError('Email sending failed', loaderHandle)
  }
}

export function* updateArtifacts(data) {
  const alertType = data?.payload?.type
  let msg = `${alertType === 'remove' ? 'Deleting' : 'Adding'} artifact...`;
  const loaderHandle = showToastLoader(msg)
  const errorMsg = `Artifact ${alertType === 'remove' ? 'delete' : 'add'} failed`
  const successMsg = `Artifact ${alertType === 'remove' ? 'deleted' : 'added'}`
  try {
    const response = yield call(updateArtifactsApi, data);
    if (response.success) {
      showToastSuccess(successMsg, loaderHandle)
      yield put({ type: UPDATE_DETAILS_ARTIFACT, data: response?.data });
      yield put({ type: UPDATE_ARTIFACT_SUCCESSED, data: response?.data });
      if (data.payload?.callback) {
        data.payload.callback(response);
      }
    } else {
      showToastError(errorMsg, loaderHandle)
      yield put({ type: UPDATE_ARTIFACT_FAILED, data: null });
    }
  } catch (err) {
    showToastError(errorMsg, loaderHandle)
    yield put({ type: UPDATE_ARTIFACT_FAILED, data: err });
  }
}

export function* executeAction(action) {
  const loaderHandle = showToastLoader('Processing...')
  try {
    const response = yield call(executeActionApi, action);
    if (response.success) {
      if (response?.data[1] === 'Application scheduled!') {
        showToastSuccess('Action executed', loaderHandle)
      } else {
        dismissToastLoader(loaderHandle);
      }
      yield put({ type: EXECUTE_ACTION_SUCCESSED, data: response?.data });
    } else {
      showToastError('Action execute failed', loaderHandle)
      yield put({ type: EXECUTE_ACTION_FAILED, data: null });
    }
    if (typeof action?.payload?.callback === 'function') {
      action?.payload?.callback();
    }
  } catch (err) {
    showToastError('Action execute failed', loaderHandle)
    yield put({ type: EXECUTE_ACTION_FAILED, data: err });
  }
}

export function* rawOutput(action) {
  try {
    const response = yield call(rawDataSaga, action?.payload);
    if (response.success) {
      yield put({
        type: ADVISORY_ARTIFACT_RAW_DATA_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({ type: ADVISORY_ARTIFACT_RAW_DATA_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: ADVISORY_ARTIFACT_RAW_DATA_FAILED, data: err });
  }
}

export function* AddEvidence(action) {
  const loaderHandle = showToastLoader('Adding evidence...')
  try {
    const response = yield call(addEvidenceSaga, action?.payload);
    if (response.success) {
      showToastSuccess('Added to evidence', loaderHandle)
      yield put({
        type: ADVISORY_ADD_EVIDENCE_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Evidence add failed', loaderHandle)
      yield put({ type: ADVISORY_ADD_EVIDENCE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Evidence add failed', loaderHandle)
    yield put({ type: ADVISORY_ADD_EVIDENCE_FAILED, data: err });
  }
}

export function* multiConfigExecuteAction(data) {
  const loaderHandle = showToastLoader('Processing...')
  try {
    const response = yield call(multiExecuteActionApi, data);
    if (response.success) {
      if (response?.data[1] === 'Application configured!') {
        showToastSuccess('Action executed', loaderHandle)
      } else {
        dismissToastLoader(loaderHandle);
      }
      yield put({
        type: MULTI_CONFIG_EXECUTION_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Action execute failed', loaderHandle)
      yield put({ type: MULTI_CONFIG_EXECUTION_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Action execute failed', loaderHandle)
    yield put({ type: MULTI_CONFIG_EXECUTION_FAILED, data: err });
  }
}

export function* addBulkUpdate(action) {
  const loaderHandle = showToastLoader('Updating records...')
  try {
    const response = yield call(bulkUpdateAPI, action.payload);
    if (response.success) {
      showToastSuccess('Records updated', loaderHandle)
      yield put({
        type: BULK_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(
        threatIntelligenceStore({ queryItem: action.query, path: action.path })
      );
    } else {
      showToastError('Records update failed', loaderHandle)
      yield put({ type: BULK_UPDATE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Records update failed', loaderHandle)
    yield put({ type: BULK_UPDATE_FAILED, data: err });
  }
}
export function* addBulkExecute(action) {
  const loaderHandle = showToastLoader('Executing playbook...')
  try {
    const advisoryData = yield call(bulkExecute, action.payload);

    if (advisoryData.success === true) {
      showToastSuccess('Playbook executed', loaderHandle)
      yield put({ type: BULK_EXECUTE_ADVISORY_SUCCESSED, data: advisoryData });
      yield put(
        threatIntelligenceStore({ queryItem: action.query, path: action.path })
      );

    } else {
      showToastError('Playbook execute failed', loaderHandle)
      yield put({ type: BULK_EXECUTE_ADVISORY_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Playbook execute failed', loaderHandle)
    yield put({
      type: BULK_EXECUTE_ADVISORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* addBulkDelete(action) {
  const loaderHandle = showToastLoader('Deleting records...')
  try {
    const deleteAddData = yield call(bulkDelete, action.payload);
    if (deleteAddData.success === true) {
      showToastSuccess('Records deleted', loaderHandle)
      yield put({ type: BULK_DELETE_ADVISORY_SUCCESSED, data: deleteAddData });
      const response = yield call(threatIntelligenceSaga, action.payload.data);
      if (response.success) {
        yield put({ type: THREAT_INTELLIGENCE_LIST_SUCCESSED, data: response });
      } else {
        yield put({ type: THREAT_INTELLIGENCE_LIST_FAILED, data: null });
      }
    } else {
      showToastError('Records delete failed', loaderHandle)
      yield put({ type: BULK_DELETE_ADVISORY_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Records delete failed', loaderHandle)
    yield put({
      type: BULK_DELETE_ADVISORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchGenerateReport(action) {

  const loaderHandle = showToastLoader(action?.module == 'asset' ? 'Scheduling report...' : 'Generating report...')
  try {
    const response = yield call(generateReport, action);
    if (response.success) {
      yield put({ type: ADVISORY_GENERATE_REPORT_SUCCESSED, data: response });
    } else {
      yield put({ type: ADVISORY_GENERATE_REPORT_FAILED, data: null });
    }
    showToastSuccess(action?.module == 'asset' ? 'Report scheduled' : 'Report generated', loaderHandle)
  } catch (err) {
    showToastError(action?.module == 'asset' ? 'Report schedule failed' : 'Report generation failed', loaderHandle)
    yield put({
      type: ADVISORY_GENERATE_REPORT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAdvisoryImport(action) {
  const loaderHandle = showToastLoader('Importing file...')
  try {
    const response = yield call(importSaga, action.payload);
    if (response.success) {
      showToastSuccess('Import file uploaded', loaderHandle)
      yield put({ type: IMPORT_ADVISORY_SUCCESSED, data: response });
    } else {
      showToastError('Import failed', loaderHandle)
      yield put({ type: IMPORT_ADVISORY_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Import failed', loaderHandle)
    yield put({ type: IMPORT_ADVISORY_FAILED, data: err?.response?.data?.data });
  }
}

export default function* watcher() {
  yield takeLatest(THREAT_INTELLIGENCE_LIST_REQUESTED, threatIntelligenceList);
  yield takeLatest(
    THREAT_INTELLIGENCE_DETAILS_LIST_REQUESTED,
    watchthreatIntelligenceDetails
  );
  yield takeLatest(
    THREAT_INTELLIGENCE_SEND_ADVISORY_REQUESTED,
    wacthSendAdvisory
  );
  yield takeLatest(DELETE_THREAT_INTELLIGENCE_REQUESTED, watchDelete);
  yield takeLatest(THREAT_INTELLIGENCE_FEED_DELETE_REQUESTED, watchFeedDelete);
  yield takeLatest(
    THREAT_INTELLIGENCE_CATEGORIES_REQUESTED,
    watchCategoryFilter
  );
  yield takeLatest(
    THREAT_INTELLIGENCE_SUB_CATEGORIES_REQUESTED,
    watchSubCategoryFilter
  );
  yield takeLatest(
    THREAT_INTELLIGENCE_CASE_CATEGORIES_REQUESTED,
    watchAdvCategoryFilter
  );
  yield takeLatest(THREAT_INTELLIGENCE_SEVERITY_REQUESTED, watchSeverityFilter);
  yield takeLatest(THREAT_INTELLIGENCE_ASSET_REQUESTED, watchAssetFilter);
  yield takeLatest(
    THREAT_INTELLIGENCE_DEPARTMENTS_REQUESTED,
    watchDepartmentsFilter
  );
  yield takeLatest(THREAT_INTELLIGENCE_EVIDENCE_REQUESTED, watchEvidence);
  yield takeLatest(THREAT_INTELLIGENCE_DISPOSITION_REQUESTED, watchDisposition);
  yield takeLatest(THREAT_INTELLIGENCE_PRODUCTS_REQUESTED, watchProduct);
  yield takeLatest(
    THREAT_INTELLIGENCE_ADVISORY_VENDORS_REQUESTED,
    watchVendors
  );
  yield takeLatest(THREAT_INTELLIGENCE_EXPORT_PDF_REQUESTED, watchExportAsPDF);
  yield takeLatest(GET_PRODUCT_LIST_REQUESTED, getProductDetails);
  yield takeLatest(GET_PRODUCT_LIST_REQUESTED, getProductDetails);
  yield takeLatest(
    UPDATE_ADVERSORY_DETAILS_REQUESTED,
    getUpdateAdversoryDetails
  );
  yield takeLatest(ADVISORY_GENERATE_REPORT_REQUESTED, watchGenerateReport);
  yield takeLatest(GET_RUN_PLAY_BOOK_DATA_REQUESTED, getRunPlayBookData);
  yield takeLatest(
    THREAT_INTELLIGENCE_CREATE_ADVISORY_REQUESTED,
    watchCreateAdversory
  );
  yield takeLatest(
    UPDATE_PARTIAL_THREAT_INTELLIGENCE_REQUESTED,
    watchUpdatePartialThreatIntel
  );
  yield takeLatest(EXECUTE_PLAY_BOOK_REQUESTED, executePlayBook);
  yield takeLatest(ADVISORY_PUBLISH_REQUESTED, publishAdvisory);
  yield takeLatest(GET_USER_EMAILS_LIST_REQUESTED, getUserEmailsList);
  yield takeLatest(SEND_EMAIL_REQUESTED, sendEmail);
  yield takeLatest(ADVISORY_SOURCE_REQUESTED, watchSources);
  yield takeLatest(ADVISORY_THREAT_ACTORS_REQUESTED, watchThreatActors);
  yield takeLatest(
    CASES_ADVISORY_LOCATIONS_REQUESTED,
    watchCaseAdvisoryLocation
  );
  yield takeLatest(OPEN_CASES_REQUESTED, watchOpenCase);
  yield takeLatest(UPDATE_ARTIFACT_REQUESTED, updateArtifacts);
  yield takeLatest(EXECUTE_ACTION_REQUESTED, executeAction);
  yield takeLatest(MULTI_CONFIG_EXECUTION_REQUESTED, multiConfigExecuteAction);
  yield takeLatest(ADVISORY_ARTIFACT_RAW_DATA_REQUESTED, rawOutput);
  yield takeEvery(ADVISORY_ADD_EVIDENCE_REQUESTED, AddEvidence);
  yield takeLatest(BULK_UPDATE_REQUESTED, addBulkUpdate);
  yield takeLatest(BULK_DELETE_ADVISORY_REQUESTED, addBulkDelete);
  yield takeLatest(BULK_EXECUTE_ADVISORY_REQUESTED, addBulkExecute);
  yield takeLatest(IMPORT_ADVISORY_REQUESTED, watchAdvisoryImport);
}
