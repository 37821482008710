import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SPButton from '../../../../../components/SPButton';
import InputBox from '../../../../../components/InputBox';
import { forgotPassword } from '../../../../../actions/auth';
import {
  FormContainer,
  FormDiv,
  QueryDiv,
  Query,
  ButtonContainer,
  MyForm
} from './StyledComponents';
import SPMessage from '../../../../../components/SPMessage'

const Form = ({ forgotPasswordStore,forgotPasswordAction }) => {
  const history = useHistory();

  useEffect(() => {
    const msg = forgotPasswordStore?.data?.data?.message;
    if (msg === 'Email sent') {
      setTimeout(function () { history.push('/login') }, 3000);
    }
  }, [forgotPasswordStore?.data?.data?.message])

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid Email Format').required('Required')
    }),
    onSubmit: values => {
      forgotPasswordAction({
        usr_email: values.email
      })
      return values
    }
  })

  return (
    <FormContainer>
      <br />
      {
        forgotPasswordStore.data?.data?.message === 'User not found' &&
          <SPMessage
            message={forgotPasswordStore?.data?.data?.message}
            type='error'
            closable
          />
      }
      <MyForm onSubmit={formik.handleSubmit}>
        <FormDiv>
          <InputBox
            id='email'
            label='Email'
            type='email'
            name='email'
            placeholder='example@email.com'
            onInputChange={formik.handleChange}
            errorMessage={formik.errors.email}
            touched={formik.touched.email}
            value={formik.values.email}
          />
          <ButtonContainer>
            <SPButton
              title='Forgot Password'
              htmlType='submit'
            />
          </ButtonContainer>
          <Link to='/login'>
            <QueryDiv>
              <Query>Have an account?</Query>
            </QueryDiv>
          </Link>
        </FormDiv>
      </MyForm>
    </FormContainer>
  );
};

const mapStateToProps = state => {
  return {
    forgotPasswordStore: state.forgotPasswordStore,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPasswordAction: data => {
      return dispatch(forgotPassword(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
