import { RISK_ASSESMENT_GET_LIST_FAILED, RISK_ASSESMENT_GET_LIST_REQUESTED, RISK_ASSESMENT_GET_LIST_SUCCESSED, RISK_ASSESMENT_RISK_REGISTER_LIST_FAILED, RISK_ASSESMENT_RISK_REGISTER_LIST_REQUESTED, RISK_ASSESMENT_RISK_REGISTER_LIST_SUCCESSED, RISK_ASSESMENT_SCOPE_LIST_FAILED, RISK_ASSESMENT_SCOPE_LIST_REQUESTED, RISK_ASSESMENT_SCOPE_LIST_SUCCESSED, RISK_ASSESMENT_TEMPLATE_LIST_FAILED, RISK_ASSESMENT_TEMPLATE_LIST_REQUESTED, RISK_ASSESMENT_TEMPLATE_LIST_SUCCESSED } from '../../constants/actionTypes';


const initialState = {
    riskAssessmentList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    riskAssessmentTemplateList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    riskAssessmentScopeList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
    riskRegisterList: {
        isProcessing: null,
        isSuccess: null,
        hasErrors: null,
        listData: [],
    },
}


export const riskAssessmentStore = (state = initialState, action) => {
    switch (action.type) {
        case RISK_ASSESMENT_GET_LIST_REQUESTED: {
            return {
                ...state,
                riskAssessmentList: {
                    ...state.riskAssessmentList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }
        case RISK_ASSESMENT_GET_LIST_SUCCESSED: {
            return {
                ...state,
                riskAssessmentList: {
                    ...state.riskAssessmentList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case RISK_ASSESMENT_GET_LIST_FAILED: {
            return {
                ...state,
                riskAssessmentList: {
                    ...state.riskAssessmentList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case RISK_ASSESMENT_TEMPLATE_LIST_REQUESTED: {
            return {
                ...state,
                riskAssessmentTemplateList: {
                    ...state.riskAssessmentTemplateList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }
        case RISK_ASSESMENT_TEMPLATE_LIST_SUCCESSED: {
            return {
                ...state,
                riskAssessmentTemplateList: {
                    ...state.riskAssessmentTemplateList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case RISK_ASSESMENT_TEMPLATE_LIST_FAILED: {
            return {
                ...state,
                riskAssessmentTemplateList: {
                    ...state.riskAssessmentTemplateList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        case RISK_ASSESMENT_SCOPE_LIST_REQUESTED: {
            return {
                ...state,
                riskAssessmentScopeList: {
                    ...state.riskAssessmentScopeList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }
        case RISK_ASSESMENT_SCOPE_LIST_SUCCESSED: {
            return {
                ...state,
                riskAssessmentScopeList: {
                    ...state.riskAssessmentScopeList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case RISK_ASSESMENT_SCOPE_LIST_FAILED: {
            return {
                ...state,
                riskAssessmentScopeList: {
                    ...state.riskAssessmentScopeList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }

        case RISK_ASSESMENT_RISK_REGISTER_LIST_REQUESTED: {
            return {
                ...state,
                riskRegisterList: {
                    ...state.riskRegisterList,
                    listData: [],
                    error: null,
                    loading: true,
                    requested: true,
                },
            };
        }
        case RISK_ASSESMENT_RISK_REGISTER_LIST_SUCCESSED: {
            return {
                ...state,
                riskRegisterList: {
                    ...state.riskRegisterList,
                    listData: action.data,
                    error: null,
                    loading: false,
                    requested: false,
                },
            };
        }

        case RISK_ASSESMENT_RISK_REGISTER_LIST_FAILED: {
            return {
                ...state,
                riskRegisterList: {
                    ...state.riskRegisterList,
                    listData: [],
                    error: action.data,
                    loading: false,
                    requested: false,
                },
            };
        }
        // riskAssessmentScopeList
        default: {
            return state;
        }
    }
}

