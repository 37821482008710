import { EXPORT_ASSET_LOGS_FAILED, 
    EXPORT_ASSET_LOGS_PROCESSING, 
    EXPORT_ASSET_LOGS_REQUESTED, 
    EXPORT_ASSET_LOGS_SUCCESSED 
} from "../../constants/actionTypes";

const initialState = {
    listData: {},
    isUpdated: null,
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };

export const assetExportLogsStore = (state = initialState, action) => {
    switch (action.type) {
        case EXPORT_ASSET_LOGS_REQUESTED:{
            return {
                ...state,
                listData: {},
                error: null,
                loading: true,
                requested: true,
            };
        }
        case EXPORT_ASSET_LOGS_PROCESSING:{
            return {
                ...state,
                listData: {},
                error: null,
                loading: true,
                requested: false,
            };
        }
        case EXPORT_ASSET_LOGS_SUCCESSED: {
            return {
                ...state,
                listData: action.data,
                error: null,
                loading: false,
                requested: false,
            }
        }
        case EXPORT_ASSET_LOGS_FAILED: {
            return {
                ...state,
                listData: {},
                error: action.data,
                loading: false,
                requested: false,
            }
        }
        default:{
            return {
                ...state,
            }
        }
    }
}

export default assetExportLogsStore;