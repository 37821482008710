const Colors = {
  transparent: '#FFFFFF00',
  secondaryTransparent: '#FFFFFF10',
  // primaryGreen: '#007FD9',
  // lightGreen: '#3DD598',
  primaryGreen: '#007FD9',
  lightGreen: '#15C1FE',
  primaryWhite: '#FFFFFF',
  primarySmokeWhite: '#BDBDBD',
  secondaryRed: '#FF2D2E',
  // secondaryLightGreen: '#3DD598',
  secondaryLightGreen: '#15C1FE',
  secondaryBlue: '#1E75FF',
  secondaryYellow: '#FFC542',
  secondaryLightGray: '#525268',
  backgroundSmokeBlack: '#1C1C24',
  backgroundDarkGray: '#14142B',
  backgroundGray: '#373747',
  darkGray: '#2C2C38',
  backgroundNightBlack: '#13131A',
  offWhite: '#92929d',
  progressDangerStart: '#FC5A5A',
  progressDangerEnd: '#B80505',
  progressDangerBackColor: '#FC5A5A10',
  progressWarnStart: '#FFC542',
  progressWarnEnd: '#FF6B00',
  progressWarnBackColor: '#FC5A5A10',
  progressSuccessStart: '#01F1E3',
  progressSuccessEnd: '#5EFF5A',
  progressSuccessBackColor: '#007FD910',
  loaderBackground: '#222222',
  activityBackground: '#23232C',
};

export default Colors;
