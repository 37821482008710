import {
    TEST_CONNECTION_REQUESTED,
    TEST_CONNECTION_PROCESSING,
    TEST_CONNECTION_SUCCESSED,
    TEST_CONNECTION_FAILED,
  } from '../../constants/actionTypes';
   
  const initialState = {
    listData: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const testConnection = (state = initialState, action) => {
    switch (action.type) {
      case TEST_CONNECTION_REQUESTED: {
        return {
          ...state,
          listData: null,
          hasErrors: null,
          isProcessing: true,
          isSuccess: null,
        };
      }
      case TEST_CONNECTION_PROCESSING: {
        return {
          ...state,
          listData: null,
          hasErrors: null,
          isProcessing: true,
          isSuccess: null,
        };
      }
      case TEST_CONNECTION_SUCCESSED: {
        return {
          listData: action.data,
          hasErrors: null,
          isProcessing: false,
          isSuccess: true,
        };
      }
      case TEST_CONNECTION_FAILED: {
        return {
          listData: action.data,
          hasErrors: true,
          isProcessing: false,
          isSuccess: null,
        };
      }
      default: {
        return {
          ...state,
        };
      }
    }
  };
  
  export default testConnection;
  
