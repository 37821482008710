import {
    INCIDENT_LESSON_LEARNED_PROCESSING,
    INCIDENT_LESSON_LEARNED_REQUESTED,
    INCIDENT_LESSON_LEARNED_SUCCESSED,
    INCIDENT_LESSON_LEARNED_FAILED,
  } from '../../constants/actionTypes';
   
  const initialState = {
    result: {},
    isProcessing: null,
    isSuccess: null,
    hasErrors: null,
  };
  
  export const incidentMasterLessonLearned = (state = initialState, action) => {
    switch (action.type) {
      case INCIDENT_LESSON_LEARNED_REQUESTED: {
        return {
          ...state,
          error: null,
          loading: true,
          requested: true,
        };
      }
      case INCIDENT_LESSON_LEARNED_PROCESSING: {
        return {
          ...state,
          error: null,
          loading: true,
          requested: false,
        };
      }
      case INCIDENT_LESSON_LEARNED_SUCCESSED: {
        return {
          ...state,
          result: action.data,
          error: null,
          loading: false,
          requested: false,
        };
      }
      case INCIDENT_LESSON_LEARNED_FAILED: {
        return {
          ...state,
          result: {},
          error: action.data,
          loading: false,
          requested: false,
        };
      }
  
      default: {
        return {
          ...state,
        };
      }
    }
  };
  
  export default incidentMasterLessonLearned;
  