import { put, call, takeLatest } from 'redux-saga/effects';
import { getScoreApi } from '../../api/incidentManagementSaga';
import {
  NCIS_SCORE_REQUESTED,
  NCIS_SCORE_SUCCESSED,
  NCIS_SCORE_FAILED,
  NCIS_SCORE_CREATE_SUCCESSED,
  NCIS_SCORE_CREATE_FAILED,
} from '../../constants/actionTypes';

export function* watchGetScore(action) {
  try {
    const response = yield call(getScoreApi, action.payload);
    if (response.success === true) {
      yield put({
        type: NCIS_SCORE_SUCCESSED,
        data: response
      });
    }
  } catch (err) {
    yield put({
      type: NCIS_SCORE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCalculateScore(action) {
  try {
    const response = yield call(createSaga, action.payload);
    if (response.success) {
      yield put({ type: NCIS_SCORE_CREATE_SUCCESSED, data: response });
    } else {
      yield put({ type: NCIS_SCORE_CREATE_FAILED, data: null });
    }
    if (action.payload?.callback) {
      action.payload.callback(response?.data);
    }
  } catch (err) {
    yield put({
      type: NCIS_SCORE_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export default function* watcher() {
  yield takeLatest(NCIS_SCORE_REQUESTED, watchGetScore)

}
