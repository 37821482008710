import axios from 'axios';
import API from '../config/endpoints.config';

export function* loginSaga(loginData) {

  const response = yield axios.get(API.login, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' + btoa(loginData.email + ':' + loginData.password),
    },
  });
  return response.data;
}

export function* googleLoginSaga(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('code', data.code);
  const response = yield axios.post(API.googleLogin, bodyFormData, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}
export function* masterLoginSaga(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('code', data.code);
  const response = yield axios.post(API.masterLogin, bodyFormData, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export function* forgotPasswordSaga(data) {

  const response = yield axios.post(API.forgotPassword, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}

export function* resetPasswordSaga(data) {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const response = yield axios.post(API.resetPassword + token, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}

export function* changePasswordSaga(data) {
  const response = yield axios.post(`${API.administrationModule.accessUsersPasswordExpire}?id=${data?.userId}`, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}

export function* invitedUserSaga(data) {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const id = queryParams.get('id');
  const response = yield axios.post(API.invitedUser + `?id=${id}` + `&token=${token}`, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}


export function* verifyCodeSaga(data) {


  const bodyFormData = new FormData();
  if (data.code) {
    bodyFormData.append('code', data.code);
  }
  if (data.mastercode) {
    bodyFormData.append('mastercode', data.mastercode);
  }

  const userToken = localStorage.getItem('AccessToken2FA');
  const response = yield axios.post(API.verifyCode, bodyFormData, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + userToken,
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      'Accept': '*/*'
    },
  });
  return response.data;
}

export function* userConfirmationSaga(status) {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('pp_id');
  const body = {
    confimation: status
  };
  const response = yield axios.post(API.playbooks + '/confirmation' + `?pp_id=${id}&status=approve`, body, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}


export default { loginSaga, forgotPasswordSaga, verifyCodeSaga, resetPasswordSaga, userConfirmationSaga };
