import 'antd/dist/antd.css';
import './containers/App.css';
import './index.css';
import 'react-toastify/dist/ReactToastify.css'
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from 'react-router';
import { store } from './redux/store/index';
import PrivateRoute from './containers/PrivateRoute';
import RedirectPage from './containers/pages/RedirectPage';
import ForgotPassword from './containers/pages/ForgotPassword';
import ResetPassword from './containers/pages/ResetPassword';
import ChangePassword from './containers/pages/ChangePassword';
import LicenseUpdate from './containers/pages/LicenseUpdate';
import UserConfirmation from './containers/pages/UserConfirmation';
import GoogleCallBack from './containers/pages/GoogleCallBack';
import InvitedUser from './containers/pages/InvitedUser';
import GoogleAuthentication from './containers/pages/GoogleAuthentication';
import SlackAuthentication from './containers/pages/SlackAuthentication';
import VerifyAuthCode from './containers/pages/VerifyAuthCode';
import VerifyAuthMasterCode from './containers/pages/VerifyAuthMasterCode';
import TermsAndConditions from './containers/pages/TermsAndConditions';
import PatchUpdate from './containers/pages/PatchUpdate';
import MsspLogin from './containers/pages/MsspLogin';
import { ToastContainer } from 'react-toastify'

const history = createBrowserHistory();
const previousPath = history.location.pathname;

const App = lazy(() => import('./containers/App'));
const Login = lazy(() => import('./containers/pages/Login'));
const PageNotFound = React.lazy(() =>
  import("./containers/pages/NotFoundPage")
);

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      theme='dark'
      position='top-center'
    />
    <Router history={history}>
      <Suspense fallback={<RedirectPage />}>
        <Switch>
          <Route
            exact
            path="/login"
            render={props => <Login {...props} previousPath={previousPath} />}
          />
          <Route
            exact
            path="/forgot-password"
            render={props => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/reset-password"
            render={props => <ResetPassword {...props} />}
          />
          <Route
            exact
            path="/change-password/:id"
            render={props => <ChangePassword {...props} />}
          />
          <Route
            exact
            path="/invited-user"
            render={props => <InvitedUser {...props} />}
          />
          <Route
            exact
            path="/google-authenticate"
            render={props => <GoogleAuthentication {...props} />}
          />
          <Route
            exact
            path="/callback/google"
            render={props => <GoogleCallBack {...props} />}
          />
          <Route
            exact
            path="/mssp/login"
            render={props => <MsspLogin {...props} />}
          />
          <Route
            exact
            path="/verify-code"
            render={props => <VerifyAuthCode {...props} />}
          />

          <Route
            exact
            path="/verify-master-code"
            render={props => <VerifyAuthMasterCode {...props} />}
          />

          <Route
            exact
            path="/terms-of-services"
            render={props => <TermsAndConditions {...props} />}
          />

          <Route
            exact
            path="/patch"
            render={props => <PatchUpdate {...props} />}
          />

          <Route
            exact
            path="/update-license"
            render={props => <LicenseUpdate {...props} />}
          />

          <Route
            exact
            path="/playbooks/confirm"
            render={props => <UserConfirmation {...props} />}
          />

          <Route
            exact
            path="/slack-auth"
            render={props => <SlackAuthentication {...props} />}
          />

          <PrivateRoute path="/" component={App} history={history} />

          <Route component={PageNotFound} />

        </Switch>
      </Suspense>
    </Router>
  </Provider>,
  document.getElementById('root')
);
