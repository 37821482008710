import {
  NETWORK_BLOCK_LIST_REQUESTED,
  NETWORK_BLOCK_LIST_PROCESSING,
  NETWORK_BLOCK_LIST_SUCCESSED,
  NETWORK_BLOCK_LIST_FAILED,
  NETWORK_BLOCK_CREATE_REQUESTED,
  NETWORK_BLOCK_CREATE_PROCESSING,
  NETWORK_BLOCK_CREATE_SUCCESSED,
  NETWORK_BLOCK_CREATE_FAILED,
  NETWORK_BLOCK_EDIT_REQUESTED,
  NETWORK_BLOCK_EDIT_PROCESSING,
  NETWORK_BLOCK_EDIT_SUCCESSED,
  NETWORK_BLOCK_EDIT_FAILED,

  NETWORK_BLOCK_DELETE_REQUESTED,
  NETWORK_BLOCK_DELETE_PROCESSING,
  NETWORK_BLOCK_DELETE_SUCCESSED,
  NETWORK_BLOCK_DELETE_FAILED,

  } from '../../constants/actionTypes';

const initialState = {
  listData: {},
  isUpdated: null,
  isProcessing: null,
  isSuccess: null,
  hasErrors: null,
  isDeleted:null,

};

export const networkBlocksStore = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_BLOCK_LIST_REQUESTED: {
      return {
        ...state,
        listData: {},
        error: null,
        loading: true,
        requested: true,
      };
    }
    case NETWORK_BLOCK_LIST_PROCESSING: {
      return {
        ...state,
        listData: {},
        error: null,
        loading: true,
        requested: false,
      };
    }
    case NETWORK_BLOCK_LIST_SUCCESSED: {
      return {
        ...state,
        listData: action.data,
        error: null,
        loading: false,
        requested: false,
      };
    }
    case NETWORK_BLOCK_LIST_FAILED: {
      return {
        ...state,
        listData: {},
        error: action.data,
        loading: false,
        requested: false,
      };
    }
    case NETWORK_BLOCK_CREATE_REQUESTED: {
      return {
        ...state,
        data: null,
        isProcessing: true,
        isSuccess: null,
        hasErrors: null,
      };
    }
    case NETWORK_BLOCK_CREATE_PROCESSING: {
      return {
        ...state,
        data: null,
        isProcessing: true,
        isSuccess: null,
        hasErrors: null,
      };
    }
    case NETWORK_BLOCK_CREATE_SUCCESSED: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isSuccess: true,
        hasErrors: false,
      };
    }
    case NETWORK_BLOCK_CREATE_FAILED: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isSuccess: false,
        hasErrors: true,
      };
    }
    case NETWORK_BLOCK_EDIT_REQUESTED: {
      return {
        ...state,
        data: null,
        isProcessing: true,
        isSuccess: null,
        isUpdated:false,
        hasErrors: null,
      };
    }
    case NETWORK_BLOCK_EDIT_PROCESSING: {
      return {
        ...state,
        data: null,
        isProcessing: true,
        isSuccess: null,
        isUpdated:false,
        hasErrors: null,
      };
    }
    case NETWORK_BLOCK_EDIT_SUCCESSED: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isSuccess: true,
        isUpdated:true,
        hasErrors: false,
      };
    }
    case NETWORK_BLOCK_EDIT_FAILED: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isSuccess: false,
        isUpdated:false,
        hasErrors: true,
      };
    }
    case NETWORK_BLOCK_DELETE_REQUESTED: {
      return {
        ...state,
        data: null,
        isProcessing: true,
        isSuccess: null,
        isDeleted:false,
        hasErrors: null,
      };
    }
    case NETWORK_BLOCK_DELETE_PROCESSING: {
      return {
        ...state,
        data: null,
        isProcessing: true,
        isSuccess: null,
        isDeleted:false,
        hasErrors: null,
      };
    }
    case NETWORK_BLOCK_DELETE_SUCCESSED: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isSuccess: true,
        isDeleted:true,
        hasErrors: false,
      };
    }
    case NETWORK_BLOCK_DELETE_FAILED: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isSuccess: false,
        isDeleted:false,
        hasErrors: true,
      };
    }






    default: {
      return {
        ...state,
      };
    }
  }
};

export default networkBlocksStore;
