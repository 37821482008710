import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import SPButton from '../../../../../components/SPButton';
import InputBox from '../../../../../components/InputBox';
import { resetPassword } from '../../../../../actions/auth';

import {
  FormContainer,
  FormDiv,
  ButtonContainer,
  MyForm
} from './StyledComponents';
import SPMessage from '../../../../../components/SPMessage';

const Form = ({ resetPasswordAction, resetPasswordStore }) => {
  useEffect(() => {
    if (resetPasswordStore.isSuccess) {
      setTimeout(function () { history.push('/') }, 3000);
    }
  }, [resetPasswordStore.isSuccess]);

  const history = useHistory();

  function generateError (data) {
    let errorItem = null;
    if (typeof data === 'object' && data !== null) {
      const items = Object.keys(data).map((item, index) =>
        <li key={index}> {data[item][0]} </li>
      );
      errorItem = items;
    } else {
      errorItem = data.message;
    }

    return errorItem;
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      passconf: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Required').min(8, 'Min 8 characters required'),
      passconf: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),
    onSubmit: values => {
      const dataItem = {
        password: values.password,
        passconf: values.passconf
      }
      resetPasswordAction(dataItem)

      return values;
    },
  });

  return (
    <FormContainer>
      <br />
      {
        resetPasswordStore.hasErrors &&
          <SPMessage
            message='Password Error'
            description={<ul className='margin-10'>{generateError(resetPasswordStore.hasErrors)}</ul>}
            type='error'
            closable
          />
      }
      <MyForm onSubmit={formik.handleSubmit}>
        <FormDiv>
          <InputBox
            id='password'
            label='Password'
            type='password'
            name='password'
            onInputChange={formik.handleChange}
            value={formik.values.password}
            errorMessage={formik.errors.password}
            touched={formik.touched.password}
          />

          <InputBox
            id='passconf'
            label='Confirm Password'
            type='password'
            name='passconf'
            onInputChange={formik.handleChange}
            value={formik.values.passconf}
            errorMessage={formik.errors.passconf}
            touched={formik.touched.password}
          />
        </FormDiv>

        <ButtonContainer>
          <SPButton
            title='Reset Password'
            htmlType='submit'
            isLoading={resetPasswordStore.loading}
          />
        </ButtonContainer>
      </MyForm>
    </FormContainer>
  );
};

const mapStateToProps = state => {
  return {
    resetPasswordStore: state.resetPasswordStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordAction: data => {
      return dispatch(resetPassword(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
