import React, { useEffect } from 'react';
import InviteBox from '../../../components/InviteBox';
import Form from './components/Form';

const InvitedUser = () => {
  useEffect(() => {
    document.title = 'Invited User';
  }, []);
  return (
    <InviteBox>
      <Form />
    </InviteBox>
  );
};

export default InvitedUser;
