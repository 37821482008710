import axios from 'axios';
import API from '../config/endpoints.config';

export async function listSaga(payload) {

  const userToken = localStorage.getItem('AccessToken');
  let api = API.networkBlocks;
  if (payload.parameter) {
    api = API.networkBlocks+"?NetworkBlocksSearch[search]="+payload.parameter
  }
  const response = await axios.get(api, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}

export async function createSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.networkBlocks, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function editSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.put(API.networkBlocks + '/' + data.id, data, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function deleteSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.delete(API.networkBlocks + '/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
