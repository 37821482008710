import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';
import Colors from '../../../theme/Colors';
import Logo from '../../../assets/images/logo.png';
import { getSlackAppData } from '../../../api/appsSaga';
import { showToastSuccess } from '../../../utils/toasts';
import SpLoader from '../../../components/SPLoader';

export const StyledHeading = styled.h1`
   color: #FFF;
   font-weight: bold;
   text-align: center;
   font-size: 3rem;
`;

export const StyledPara = styled.p`
  text-align: center;
  border: 1px solid #ffffff5c;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: 50rem;
  word-wrap: break-word;
`;

export const StyledImageWrapper = styled.div`
    padding: 2rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`;

export const StyledContainer = styled.div`
    background-color: ${Colors.backgroundSmokeBlack};
    padding: 1rem 3rem;
    height: 100vh;
`;

export const StyledWrapper = styled.div`
    color: #FFF;
    padding: 1rem 3rem;
    margin: 0 auto;
    max-width: 90%;
`;

export const StyledFlex = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 4rem;
    ${({ flexRow }) => flexRow && `
     margin-top: 0rem;
     flex-direction: row;
     align-items: center;
     display: flex;
     gap: 2rem;
     justify-content: space-between;
     width: 1000px;
     p {
         font-size: 1.5rem;
         text-align: justify;
     }
  `}
`;

const SlackAuthentication = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const code = query.get('code');

    const [slackData, setSlackData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = 'Slack Authentication';
        setIsLoading(true);
        if (code) {
            getSlackAppData(code)
                .then(response => {
                    setSlackData(response?.data);
                    setIsLoading(false);
                })
        }

    }, []);

    const onCopyValue = (text) => {
        navigator.clipboard.writeText(text);
        showToastSuccess('Text copied');
    };

    const { access_token, incoming_webhook } = slackData || {};

    return (
        <StyledContainer>
            <StyledImageWrapper>
                <img src={Logo} width={150} />
            </StyledImageWrapper>
            <StyledWrapper>
                <StyledHeading>THANK YOU!</StyledHeading>
                <StyledHeading> <CheckCircleOutlined style={{ fontSize: '4rem', color: 'green' }} /></StyledHeading>
                {
                    isLoading ?
                        <SpLoader isLoading={isLoading} /> :
                        <>
                            <StyledFlex>
                                <StyledFlex flexRow>
                                    <p style={{ width: '15rem' }}> ACCESS TOKEN: </p>
                                    <StyledPara> {access_token || '(Not Set)'} </StyledPara>
                                    <p>
                                        <CopyOutlined
                                            onClick={(() => {
                                                onCopyValue(access_token);
                                            })}
                                            style={{ fontSize: '2rem', color: Colors.primaryGreen }}
                                        />
                                    </p>
                                </StyledFlex>
                                <StyledFlex flexRow>
                                    <p style={{ width: '15rem' }}> CHANNEL: </p>
                                    <StyledPara> {incoming_webhook?.channel || '(Not Set)'} </StyledPara>
                                    <p>
                                        <CopyOutlined
                                            onClick={(() => {
                                                onCopyValue(incoming_webhook?.channel);
                                            })}
                                            style={{ fontSize: '2rem', color: Colors.primaryGreen }}
                                        />
                                    </p>
                                </StyledFlex>
                                <StyledFlex flexRow>
                                    <p style={{ width: '15rem' }}> CHANNEL ID: </p>
                                    <StyledPara> {incoming_webhook?.channel_id || '(Not Set)'} </StyledPara>
                                    <p>
                                        <CopyOutlined
                                            onClick={(() => {
                                                onCopyValue(incoming_webhook?.channel_id);
                                            })}
                                            style={{ fontSize: '2rem', color: Colors.primaryGreen }}
                                        />
                                    </p>
                                </StyledFlex>
                                <StyledFlex flexRow>
                                    <p style={{ width: '15rem' }}> URL: </p>
                                    <StyledPara> {incoming_webhook?.url || '(Not Set)'} </StyledPara>
                                    <p>
                                        <CopyOutlined
                                            onClick={(() => {
                                                onCopyValue(incoming_webhook?.url);
                                            })}
                                            style={{ fontSize: '2rem', color: Colors.primaryGreen }}
                                        />
                                    </p>
                                </StyledFlex>
                            </StyledFlex>
                        </>
                }

            </StyledWrapper>
        </StyledContainer>
    );
};

export default SlackAuthentication;
